import React, { useState } from 'react';
import { TextField, Button, Select, FormControl, InputLabel, MenuItem, Card, Grid, CardContent, CardHeader, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Redirect } from 'react-router'
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Programas, fileHelperText } from '../../../../constants/AppConstants';
import { tipoFormaciones } from '../../../../constants/AppConstants';
import { postPatchFormacion } from '../../../../helpers/editProfile/editProfileHelper';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),

  },
  textFieldRoot: {
    width: "65%",
    margin: 15,
  },
  title: {
    textAlign: 'center',
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  button: {
    margin: 15
  },
  errorText: {
    marginInline: 14
  },
  switch:{
    margin:"15px 0"
  },
  buttons:{
    display:"flex",
    justifyContent:"flex-start",
  },
  color: {
    color: 'red'
  },
}));

const IngresarFormacion = (props, { variant = 'default', candidato }) => {
  const fecha = new Date();
  const fechaActual = fecha.toISOString().split('T')[0];
  const classes = useStyles({ variant });
  const [redirect, setRedirect] = useState(false);
  let inputSwitchVar = true;
  let urlFormacion = {}
  if (props.location.state === undefined) window.location.href = '/signin'
  if (props.location.state !== undefined && props.location.state.actualizar) {
    if (props.location.state.formacion.nombre !== undefined) urlFormacion.nombre = props.location.state.formacion.nombre;
    else urlFormacion.nombre = '';
    if (props.location.state.formacion.centroEducativo !== undefined) urlFormacion.centroEducativo = props.location.state.formacion.centroEducativo;
    else urlFormacion.centroEducativo = '';
    if (props.location.state.formacion.tipo !== undefined) urlFormacion.tipo = props.location.state.formacion.tipo;
    else urlFormacion.tipo = '';
    if (props.location.state.formacion.tarjeta !== undefined) urlFormacion.tarjeta = props.location.state.formacion.tarjeta;
    else urlFormacion.tarjeta = false;
    if (props.location.state.formacion.fechaCulminacion !== undefined) urlFormacion.fechaCulminacion = props.location.state.formacion.fechaCulminacion.replaceAll("/", "-").split("-").reverse().join("-");
    else urlFormacion.fechaCulminacion = '';
    if (props.location.state.formacion.fechaExpedicionTarjetaProfesional !== undefined &&
      props.location.state.formacion.fechaExpedicionTarjetaProfesional !== null) urlFormacion.fechaExpedicionTarjetaProfesional = props.location.state.formacion.fechaExpedicionTarjetaProfesional.replaceAll("/", "-").split("-").reverse().join("-");
    else urlFormacion.fechaExpedicionTarjetaProfesional = '';
    if(props.location.state !== undefined && props.location.state.actualizar && !props.location.state.formacion.tarjeta){
      inputSwitchVar = props.location.state.formacion.tarjeta
    }
  } else {
    urlFormacion = {
      nombre: '',
      centroEducativo: '',
      fechaCulminacion: '',
      tipo: '',
      tarjeta: false,
      fechaExpedicionTarjetaProfesional: '',
    }
  }
  const [inputSwitch, setInputSwitch] = React.useState(inputSwitchVar);
  const [tarjProf, setTarjProf] = useState(urlFormacion.tipo === 'Pregrado' ? true : false)
  const [isPregrado, setIsPregrado] = useState(urlFormacion.tipo === 'Pregrado' ? true : false)
  const [formacion, setFormacion] = useState(urlFormacion);
  const [error, setError] = useState({
    nombre: false, nombreHelper: '',
    tipo: false, tipoHelper: '',
    centroEducativo: false, centroEducativoHelper: '',
    fechaCulminacion: false, fechaCulminacionHelper: '',
    diploma: false, diplomaHelper: fileHelperText,
    tarjetaProfesional: false, tarjetaProfesionalHelper: fileHelperText,
    certificado: false, certificadoHelper: fileHelperText,
    fechaExpedicionTarjetaProfesional: false, fechaExpedicionTarjetaProfesionalHelper: '',
  })
  const [fileDiploma, setFileDiploma] = useState('');
  const [fileTarjetaProfesional, setFileTarjetaProfesional] = useState('');
  const [fileCertificado, setFileCertificado] = useState('');
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormacion({
      ...formacion,
      [name]: value,
    });
    if (name === "tipo" ) {
      if (value === "Pregrado") {
        setIsPregrado(true)
        if(inputSwitch){
          setError({
            ...error,
            tarjetaProfesional: false, tarjetaProfesionalHelper: '',
            fechaExpedicionTarjetaProfesional: false, fechaExpedicionTarjetaProfesionalHelper: '',
            certificado: false, certificadoHelper: '',
          });
          setTarjProf(true)
        }else{
          setFileCertificado('')
          setFileTarjetaProfesional('')
          setTarjProf(false)
        }
      } else {
        setFileCertificado('')
        setFileTarjetaProfesional('')
        setTarjProf(false)
        setIsPregrado(false)
      }
      setFormacion({
        ...formacion, tarjeta: false,
        fechaExpedicionTarjetaProfesional: '',
        [name]: value,
      })
    }
    if (formacion.tipo === "Bachiller" && formacion.nombre !== "Bachiller") {
      setFormacion({
        ...formacion,
        nombre: "Bachiller"
      })
    }
  }

  const handleSwitch = (event) => {
    setInputSwitch(event.target.checked);
    if (event.target.checked) {
      setTarjProf(true);
    }
  };

  useEffect(() => {
    if (inputSwitch) {
      setFileTarjetaProfesional("")
      setFileCertificado("")
      if(props.location.state !== undefined && props.location.state.crear){
        setFormacion({...formacion, fechaExpedicionTarjetaProfesional:""})
      }
    }
    // eslint-disable-next-line
  },[inputSwitch]);

  const OnSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();
    let formacionQuery = Object.assign({}, formacion);
    if(!inputSwitch) {
      formacionQuery.tarjeta = false;
      formacionQuery.fechaExpedicionTarjetaProfesional = "";
    };
    if (props.location.state.actualizar) formacionQuery.id = props.location.state.formacion.id
    formacionQuery.tipo = formacion.tipo.toUpperCase()
    formData.append('formacion', new Blob([JSON.stringify(formacionQuery)], { type: "application/json" }));
    if(fileDiploma !== '')  formData.append('diploma', fileDiploma);
    if(fileTarjetaProfesional !== '')  formData.append('tarjetaProfesional', fileTarjetaProfesional);
    if(fileCertificado !== '')  formData.append('certificado', fileCertificado);
    let method;
    if (props.location.state.actualizar) method = 'patch';
    else method = 'post';
    await postPatchFormacion(method, formData)
      .then(function (response) {
        setRedirect(true)
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  const defaultAutoComplete =()=>{
    const Nombres = (nombre) => nombre === formacion.nombre;
    return Programas[Programas.findIndex(Nombres)]
}
  return (
    <Card >
      <CardContent className={classes.container}>
        {props.location.state.actualizar ?
          <CardHeader title="Editar Formación para el candidato " className={classes.title} titleTypographyProps={{ variant: 'h1' }} />
          :
          <CardHeader title="Crear Formación para el candidato" className={classes.title} titleTypographyProps={{ variant: 'h1' }} />
        }
        {redirect ? <Redirect to={{ pathname: '/postulacion/update', state: props.location.state, search: props.location.search }} /> : null}
        <form className={classes.form} onSubmit={(e) => {
          e.preventDefault();
          if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
            && formacion.tipo !== '' && formacion.tipo !== 'Pregrado' && !tarjProf  && fileDiploma !== '') {
            if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
              && !error.tipo && !error.diploma) {
              OnSubmit(e)
            }
          }
          if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
            && formacion.tipo !== '' && formacion.tipo === 'Pregrado' && !inputSwitch && fileDiploma !== '') {
            if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
              && !error.tipo && !error.diploma) {
              OnSubmit(e)
            }
          }
          if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
            && formacion.tipo === 'Pregrado' && inputSwitch && formacion.fechaExpedicionTarjetaProfesional !== ''
            && fileDiploma !== '' && fileCertificado !== '' && fileTarjetaProfesional !== '') {
            if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
              && !error.tipo && !error.tarjetaProfesional && !error.diploma
              && !error.fechaExpedicionTarjetaProfesional && !error.certificado) {
              OnSubmit(e)
            }
          }
          if (formacion.nombre === '') { setError({ ...error, nombre: true, nombreHelper: ' Este campo es requerido' }); }
          if (formacion.centroEducativo === '') { setError({ ...error, centroEducativo: true, centroEducativoHelper: ' Este campo es requerido' }); }
          if (formacion.fechaCulminacion === '') { setError({ ...error, fechaCulminacion: true, fechaCulminacionHelper: ' Este campo es requerido' }); }
          if (fileDiploma === '') { setError({ ...error, diploma: true, diplomaHelper: ' Este campo es requerido' }); }
          if (formacion.fechaExpedicionTarjetaProfesional === '' && inputSwitch) { setError({ ...error, fechaExpedicionTarjetaProfesional: true, fechaExpedicionTarjetaProfesionalHelper: ' Este campo es requerido' }); }
          if (fileTarjetaProfesional === '' && inputSwitch) { setError({ ...error, tarjetaProfesional: true, tarjetaProfesionalHelper: ' Este campo es requerido' }); }
          if (fileCertificado === '' && inputSwitch) { setError({ ...error, certificado: true, certificadoHelper: ' Este campo es requerido' }); }
          if (formacion.nombre === '' && formacion.centroEducativo === '' && formacion.fechaCulminacion === ''
            && formacion.tipo === 'Pregrado') {
            setError({
              ...error,
              nombre: true, nombreHelper: 'Este campo es requerido',
              centroEducativo: true, centroEducativoHelper: 'Este campo es requerido',
              fechaCulminacion: true, fechaCulminacionHelper: 'Este campo es requerido',
              diploma: true, diplomaHelper: 'Este campo es requerido',
            });
          }

        }}>
          <div>
            <InputLabel>Tipo de formación<span className={classes.color}>*</span></InputLabel>
            <FormControl
              required
              error={error.tipo}
              variant="outlined"
              className={classes.textFieldRoot}
            >
              <Select
                onChange={handleChange}
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, tipo: true, tipoHelper: ' Este campo es requerido' });
                  }
                  else setError({ ...error, tipo: false, tipoHelper: '' });
                }} name='tipo'
                value={formacion.tipo}
                id="tipo"
              >
                <MenuItem value="" />
                { tipoFormaciones.map((formacionList)=>{
                  return <MenuItem key={formacionList.value} value={formacionList.value} > {formacionList.text}</MenuItem>
                })}
              </Select>
              <Typography className={classes.errorText} variant="caption" color="error"> {error.tipoHelper} </Typography>
            </FormControl>
          </div>
          {formacion.tipo !== "Bachiller" ? (
            <div>
              <InputLabel>Nombre del programa<span className={classes.color}>*</span></InputLabel>
              {tarjProf ?
                <FormControl
                  required
                  variant="outlined"
                  className={classes.textFieldRoot}
                >
                  <Autocomplete
                    options={Programas}
                    defaultValue={defaultAutoComplete()}
                    onChange={(e, newValue) => {
                      var event = {
                        value: newValue,
                        name: "nombre"
                      }
                      handleChange({ target: event })
                    }}
                    renderInput={(params) => <TextField {...params} defaultValue={defaultAutoComplete()} variant="outlined" />}
                  />
                </FormControl>
                :
                <TextField
                  required
                  defaultValue={formacion.nombre}
                  onChange={handleChange} name='nombre'
                  variant="outlined"
                  className={classes.textFieldRoot}
                  id="nombre"
                  inputProps={{ maxLength: 81 }}
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, nombre: true, nombreHelper: 'Este campo es requerido' });
                    } else setError({ ...error, nombre: false, nombreHelper: '' });
                  }}
                  error={error.nombre}
                  helperText={error.nombreHelper    }
                />
              }
            </div>
          ) : (
            <></>
          )}
          <div>
            <InputLabel>Centro educativo<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, centroEducativo: true, centroEducativoHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length >= 40) {
                    setError({ ...error, centroEducativo: true, centroEducativoHelper: ' Máximo 40 caracteres' });
                  }
                  else setError({ ...error, centroEducativo: false, centroEducativoHelper: '' });
              }}
              required
              error={error.centroEducativo}
              helperText={error.centroEducativoHelper}
              defaultValue={formacion.centroEducativo}
              onChange={handleChange} name='centroEducativo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="centroEducativo"
              inputProps={{ maxLength: 40 }}
            />
          </div>
          <div>
            <InputLabel>Fecha de culminación<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaCulminacion: true, fechaCulminacionHelper: ' Este campo es requerido' });
                } else
                  if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                    setError({ ...error, fechaCulminacion: true, fechaCulminacionHelper: ' La fecha debe ser anterior a ' + fechaActual });
                  }
                  else setError({ ...error, fechaCulminacion: false, fechaCulminacionHelper: '' });
              }}
              required
              error={error.fechaCulminacion}
              helperText={error.fechaCulminacionHelper}
              defaultValue={formacion.fechaCulminacion}
              type="date"
              onChange={handleChange} name='fechaCulminacion'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaCulminacion"
            />
          </div>

          <div>
            <InputLabel>Adjunte aquí su diploma<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={event => {
                if (event.target.files[0] === undefined) {
                  setError({ ...error, diploma: true, diplomaHelper: "Este campo es requerido" });
                } else if (event.target.files[0].size > 6291456) {
                  setError({ ...error, diploma: true, diplomaHelper: "El archivo sobrepasa los 6 mb" });
                } else {
                  setError({ ...error, diploma: false, diplomaHelper: fileHelperText });
                }
              }}
              error={error.diploma}
              type="file"
              onChange={event => setFileDiploma(event.target.files[0])}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldRoot}
              id="diploma"
              InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
              helperText={error.diplomaHelper}
            />
          </div>
          <div>
              {isPregrado ?
                <div>
                    <Grid container item xs={12} spacing={3} className={classes.switch}>
                      <Grid item xs={5} className={classes.left}>
                      <InputLabel>Tarjeta Profesional</InputLabel>
                      </Grid>
                      <Grid item xs={4} className={classes.center}>
                        <Grid component="label" container alignItems="center" spacing={1} className={classes.center}>
                          <Grid item>No</Grid>
                          <Grid item>
                            <Switch
                              checked={inputSwitch}
                              onChange={handleSwitch}
                              color="primary"
                              name="inputSwitch"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              label="normal"
                            />
                          </Grid>
                          <Grid item>Sí</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                </div>
                : null}
                {
                  tarjProf && inputSwitch ?
                  <div>
                    <div>
                    <InputLabel>Expedición de tarjeta profesional<span className={classes.color}>*</span></InputLabel>
                    <TextField
                      type="date"
                      onBlur={event => {
                        if (event.target.value === '' && inputSwitch) {
                          setError({ ...error, fechaExpedicionTarjetaProfesional: true, fechaExpedicionTarjetaProfesionalHelper: ' Este campo es requerido' });
                        } else
                          if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                            setError({ ...error, fechaExpedicionTarjetaProfesional: true, fechaExpedicionTarjetaProfesionalHelper: ' La fecha debe ser anterior a ' + fechaActual });
                          }
                          else setError({ ...error, fechaExpedicionTarjetaProfesional: false, fechaExpedicionTarjetaProfesionalHelper: '' });
                      }}
                      required
                      error={error.fechaExpedicionTarjetaProfesional}
                      helperText={error.fechaExpedicionTarjetaProfesionalHelper}
                      defaultValue={formacion.fechaExpedicionTarjetaProfesional}
                      onChange={handleChange} name='fechaExpedicionTarjetaProfesional'
                      variant="outlined"
                      className={classes.textFieldRoot}
                      id="fechaTarjeta"
                      InputProps={{ inputProps: { max: fechaActual } }}
                    />
                    </div>
                    <div>
                      <InputLabel>Adjunte la tarjeta profesional<span className={classes.color}>*</span></InputLabel>
                      <TextField
                        onBlur={event => {
                          if (props.location.state.crear && event.target.files[0] === undefined) {
                            setError({ ...error, tarjetaProfesional: true, tarjetaProfesionalHelper: ' Este campo es requerido' });
                          }
                          else setError({ ...error, tarjetaProfesional: false, tarjetaProfesionalHelper: fileHelperText });
                        }}
                        error={error.tarjetaProfesional}
                        type="file"
                        onChange={async event => {
                          setFileTarjetaProfesional(event.target.files[0])
                          if (fileCertificado !== "") {
                            await setFormacion({ ...formacion, tarjeta: true })
                          }
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.textFieldRoot}
                        id="tarjetaPro"
                        InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                        helperText={error.tarjetaProfesionalHelper}
                      />
                    </div>
                    <div>
                    <InputLabel>Adjunte el certificado de tarjeta profesional<span className={classes.color}>*</span></InputLabel>
                    <TextField
                      onBlur={event => {
                        if (props.location.state.crear && event.target.files[0] === undefined) {
                          setError({ ...error, certificado: true, certificadoHelper: ' Este campo es requerido' });
                        }
                        else setError({ ...error, certificado: false, certificadoHelper: fileHelperText });
                      }}
                      error={error.certificado}
                      type="file"
                      onChange={async event => {
                        setFileCertificado(event.target.files[0])
                        if (fileTarjetaProfesional !== "") {
                          await setFormacion({ ...formacion, tarjeta: true })
                        }
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className={classes.textFieldRoot}
                      id="certificado"
                      InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                      helperText={error.certificadoHelper}
                    />
                  </div>
                  </div>
                  : null
                }
          </div>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              onClick={() => { setRedirect(true) }}
              className={classes.button} >
              Volver
            </Button>
            {props.location.state.actualizar ?
              <Button
                type="submit"
                variant="contained" color="primary"
                className={classes.button}>
                Actualizar Formación
              </Button>
              :
              <Button
                type="submit"
                variant="contained" color="primary"
                className={classes.button}>
                Crear Formación
              </Button>
            }
          </div>
        </form>
      </CardContent>
    </Card>
  )
};

export default IngresarFormacion