import React from "react";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../../components/GridContainer";
import PageContainer from "../../../components/PageComponents/layouts/PageContainer";
import Card from "@material-ui/core/Card";
import { NavLink, Redirect } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getOfertasAvailable } from "../../../helpers/ofertas/ofertaHelper";
import ReactQuill from "react-quill";
import '../../../utils/toolbar.css';
import 'react-quill/dist/quill.snow.css';
import { dateFormat } from "../../../constants/AppConstants";

const useStyles = makeStyles(() => ({
  internalCard: {
    margin: 15,
    padding: 15,
  },
  textCard: {
      marginBottom: 10,
  },
  containerButton:{
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: 14,
  },
  bold: {
    fontWeight: 'bold',
    display: 'contents'
  },
  display: {
    display: 'contents',
  }
}));

const useSingleton = (
  callBack = () => {
    //Empty callback
  }
) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const MainPage = () => {
  const auth = !!sessionStorage.getItem("token");
  
  const classes = useStyles();

  const [ofertas, setOfertas] = React.useState([]);
  const [error, setError] = React.useState(false);
  const history = useHistory();
  const initialState = async () => {
    const ofertaQuery = await getOfertasAvailable()
      .then((response) => response.data)
      .catch(() => {
        setError(true);
      });
    setOfertas(ofertaQuery);
  };

  useSingleton(async () => {
    await initialState({});
  });
  
  return (
    <PageContainer heading="Bienvenido al Sistema de gestión de Grupo IS Colombia">
      {auth ? <Redirect push to={{ pathname: "/user_page" }} /> : null}
        <GridContainer>
          <Typography>
          A continuación se encuentra el listado de ofertas disponibles, para poder aplicar a una oferta {" "}
          <NavLink to="/signup">crea un usuario</NavLink> o{" "}
          <NavLink to="/signin">inicia sesión</NavLink>{" "}
          </Typography>
          <Grid item xs={12}>
            <Typography>{error ? "Ocurrió un error intentando obtener las ofertas, por favor intente más tarde": ""}</Typography>
              <br />
                {ofertas && ofertas.length > 0 ? (
                  ofertas.map((oferta) => {
                    return (
                      <Card key={oferta.id} className={classes.internalCard} variant="outlined">
                        <Typography style={{ overflowWrap: 'anywhere' }} className={classes.textCard} variant="h1" color="textPrimary">
                                      {oferta.nombre}
                        </Typography>
                        <Grid className={classes.textCard} item xs={12}>
                          <Typography className={classes.bold} style={{ overflowWrap: 'anywhere' }} variant="h5" color="textPrimary">
                            Descripción:
                          </Typography>
                          <ReactQuill
                            className='readOnly'
                            defaultValue={oferta.descripcion}
                            readOnly={true}
                            theme={"bubble"}
                          />
                        </Grid>
                        <Grid className={classes.textCard} item xs={12}>
                          <Typography className={classes.bold} variant="h5" color="textPrimary">
                            Proyecto: {" "}
                          </Typography>
                          <Typography  className={classes.display}  variant="h5" color="textPrimary">
                            {oferta.proyecto.nombre}
                          </Typography>
                        </Grid>
                        <Grid className={classes.textCard} item xs={12}>
                          <Typography className={classes.bold} variant="h5" color="textPrimary">
                            Ciudad: {" "}
                          </Typography>
                          <Typography  className={classes.display}  variant="h5" color="textPrimary">
                            {oferta.ciudad.nombre}
                          </Typography>
                        </Grid>
                        <Grid className={classes.textCard} item xs={12}>
                          <Typography className={classes.bold} variant="h5" color="textPrimary">
                            Fecha de cierre: {" "}
                          </Typography>
                          <Typography  className={classes.display}  variant="h5" color="textPrimary">
                            {dateFormat(oferta.fechaCierre)}
                          </Typography>
                        </Grid>
                        <div className={classes.containerButton}>
                        <Button onClick={()=> history.push("/signin", {oferta: oferta.id})} className={classes.button} variant="contained" color="primary" >
                                        Aplicar a la oferta
                        </Button>
                        </div>
                      </Card>
                    );
                  })
                ) : (
                  <Typography>No hay ofertas disponibles por el momento</Typography>
                )}
          </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default MainPage;
