import axios from 'axios'
import {BASE_URL} from '../baseUrl'
import {getToken} from '../../services/auth/jwt/getToken';
const contentType = 'application/json'
const token = getToken()
export const getExperienciasCandidato = async(id) => {
    const config = {
        method: 'get',
        url: BASE_URL+'experiencia_candidato/'+id,
        headers: {
          'Authorization': token,
          'Content-Type': contentType
        },
        withCredentials: true,
      }

      return await axios(config)
}

export const getExperienciasPreempleado = async(id) => {
  const config = {
      method: 'get',
      url: BASE_URL+'experiencia_preempleado/'+id,
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
    }

    return await axios(config)
}

export const postExperienciasCandidato = async(data) => {
  const config = {
      method: 'post',
      url: BASE_URL+'experiencias',
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
      data:data
    }

    return await axios(config)
}
