import React, { useState } from 'react';
import { TextField, Button, Select, FormControl, InputLabel, MenuItem, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Redirect } from 'react-router'
import { makeStyles, lighten } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getDepartamentosId, getDepartamentos } from '../../../../helpers/departamentos/departamentosHelper';
import { patchtCandidato } from '../../../../helpers/candidato/candidatoHelper';
import { fileHelperText } from '../../../../constants/AppConstants';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../../utils/orderHelper';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  textFieldRoot: {
    width: "65%",
    margin: 15,
  },
  title: {
    textAlign: 'center',
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  button: {
    margin: 31
  },
  errorText: {
    marginInline: 14
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: 15
    },
  },
  color: {
    color: 'red'
  },
}));
const MySwal = withReactContent(Swal);

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const EditarCandidato = (props, { variant = 'default' }) => {

  const fecha = new Date();
  const fechaActual = fecha.toISOString().split('T')[0];
  const compCorreo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  const compNumero = /^\d+$/;
  const classes = useStyles();
  let urlCandidato = {}
  if (props.location.state === undefined) window.location.href = '/signin'
  if (props.location.state !== undefined && props.location.state.actualizar) {
    if (props.location.state.candidato.nombres !== undefined) urlCandidato.nombre = props.location.state.candidato.nombres;
    else urlCandidato.nombre = '';
    if (props.location.state.candidato.apellidos !== undefined) urlCandidato.apellidos = props.location.state.candidato.apellidos;
    else urlCandidato.apellidos = '';
    if (props.location.state.candidato.tipoDocumento !== undefined) urlCandidato.tipoDocumento = props.location.state.candidato.tipoDocumento;
    else urlCandidato.tipoDocumento = '';
    if (props.location.state.candidato.numeroDocumento !== undefined) urlCandidato.numeroDocumento = props.location.state.candidato.numeroDocumento;
    else urlCandidato.numeroDocumento = '';
    if (props.location.state.candidato.direccion !== undefined) urlCandidato.direccion = props.location.state.candidato.direccion;
    else urlCandidato.direccion = '';
    if (props.location.state.candidato.celular !== undefined) urlCandidato.celular = props.location.state.candidato.celular;
    else urlCandidato.celular = '';
    if (props.location.state.candidato.telefonoFijo !== undefined) urlCandidato.telefonoFijo = props.location.state.candidato.telefonoFijo;
    else urlCandidato.telefonoFijo = '';
    if (props.location.state.candidato.fechaNacimiento !== undefined) urlCandidato.fechaNacimiento = props.location.state.candidato.fechaNacimiento;
    else urlCandidato.fechaNacimiento = '';
    if (props.location.state.candidato.genero !== undefined) urlCandidato.genero = props.location.state.candidato.genero;
    else urlCandidato.genero = '';
    if (props.location.state.candidato.rh !== undefined) urlCandidato.rh = props.location.state.candidato.rh;
    else urlCandidato.rh = '';
    if (props.location.state.candidato.madreCabezaHogar !== undefined) urlCandidato.madreCabezaHogar = props.location.state.candidato.madreCabezaHogar;
    else urlCandidato.madreCabezaHogar = '';
    if (props.location.state.candidato.correo !== undefined) urlCandidato.correo = props.location.state.candidato.correo;
    else urlCandidato.correo = '';
    if (props.location.state.candidato.ciudad.id !== undefined) {
      urlCandidato.ciudad = props.location.state.candidato.ciudad;
      urlCandidato.departamento = props.location.state.candidato.ciudad.departamento;
    }
    else urlCandidato.idCiudad = '';
    if (props.location.state.candidato.versionTerminosYCondiciones !== undefined) urlCandidato.versionTerminosYCondiciones = props.location.state.candidato.versionTerminosYCondiciones;
    else urlCandidato.versionTerminosYCondiciones = '';
  } else {
    urlCandidato = {
      nombres: '',
      apellidos: '',
      tipoDocumento: '',
      numeroDocumento: '',
      direccion: '',
      celular: '',
      telefonoFijo: '',
      fechaNacimiento: '',
      genero: '',
      rh: '',
      madreCabezaHogar: '',
      correo: '',
      idCiudad: null,
      versionTerminosYCondiciones: '1.0.0'
    }
  }

  const [candidato, setCandidato] = useState(urlCandidato);
  const [redirect, setRedirect] = useState(false);
  const [redirect2, setRedirect2] = useState(false);
  const [departamentoList, setDepartamentoList] = useState([]);
  const [ciudadList, setCiudadList] = useState([]);
  const [fileDocumento, setFileDocumento] = useState('');

  const [error, setError] = useState({
    nombre: false, nombreHelper: '',
    apellidos: false, apellidosHelper: '',
    tipoDocumento: false, tipoDocumentoHelper: '',
    numeroDocumento: false, numeroDocumentoHelper: '',
    direccion: false, direccionHelper: '',
    celular: false, celularHelper: '',
    telefono: false, telefonoHelper: '',
    fechaNacimiento: false, fechaNacimientoHelper: '',
    genero: false, generoHelper: '',
    rh: false, rhHelper: '',
    madreCabezaHogar: false, madreCabezaHogarHelper: '',
    correo: false, correoHelper: '',
    ciudad: false, ciudadHelper: '',
    departamendo: false, departamentoHelper: '',
    documento: false, documentoHelper: fileHelperText,
  })

  const mensajesError = {
    noError: '',
    errorEmail: 'Ingrese un correo válido',
    errorVacio: 'Ingrese alǵun dato',
    errorMinLength: 'Ingrese al menos 5 caracteres',
    errorNumero: 'Ingrese un número válido',
  }

  useSingleton(async () => {
    const departamentos = await getDepartamentos()
      .then(response => sortDepartamentosAlphabetically(response.data))
      .catch(err => err)
    setDepartamentoList(departamentos)
    const ciudades = await getDepartamentosId(candidato.departamento.id)
      .then(response => sortCiudadesAlphabetically(response.data.ciudades, true))
      .catch(err => err)
    setCiudadList(ciudades)
  })

  const handleChange = (event) => {
    setCandidato({
      ...candidato,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false
    });
  };

  const handleDepartamento = async (e) => {
    if (e.target.value !== "") {
      const ciudades = await getDepartamentosId(e.target.value)
        .then((res) => {
          return sortCiudadesAlphabetically(res.data.ciudades, true);
        })
        .catch((err) => {
          console.log(err);
        });
      if (ciudades !== null) {
        setCiudadList(ciudades);
      }
    }
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    // formData.append('candidato', new Blob([JSON.stringify(candidato)], { type: "application/json" }));
    formData.append('file', fileDocumento);
    if (candidato.correo !== props.location.state.candidato.correo) {
      formData.append('candidato', new Blob([JSON.stringify(candidato)], { type: "application/json" }));
      MySwal.fire({
        title: 'Estas seguro?',
        text: "Si cambias tu correo tendrás que volver a iniciar sesión!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then(async result => {
        if (result.value) {
          await patchtCandidato(formData)
            .then(function (response) {
              setRedirect2(true);
            })
            .catch(function (response) {
              console.log(response);
            });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          await setCandidato({
            ...candidato,
            correo: props.location.state.candidato.correo,
          });
          MySwal.fire('Cancelado', '', 'error');
        }
      });

    } else {
      delete candidato.correo
      formData.append('candidato', new Blob([JSON.stringify(candidato)], { type: "application/json" }));
      await patchtCandidato(formData)
        .then(function (response) {
          setRedirect(true);
        })
        .catch(function (response) {
          console.log(response);
        });
    }


  };

  return (
    <Card >
      <CardContent>
        <CardHeader title="Editar datos personales" className={classes.title} titleTypographyProps={{ variant: 'h1' }} />
        {redirect ? <Redirect to={{ pathname: '/postulacion/update', state: props.location.state, search: props.location.search }} /> : null}
        {redirect2 ? <Redirect to='/signin' /> : null}
        <form className={classes.form} onSubmit={(e) => {
          e.preventDefault()
          if (candidato.nombre !== '' && candidato.apellidos !== '' && candidato.tipoDocumento !== ''
            && candidato.numeroDocumento !== '' && candidato.direccion !== '' && candidato.celular !== ''
            && candidato.fechaNacimiento !== '' && candidato.genero !== '' && candidato.rh !== ''
            && candidato.madreCabezaHogar !== '' && candidato.correo !== '' && candidato.idCiudad !== '' && fileDocumento !== ''
          ) {
            if (!error.nombre && !error.apellidos && !error.tipoDocumento && !error.numeroDocumento
              && !error.direccion && !error.celular && !error.fechaNacimiento && !error.genero
              && !error.rh && !error.madreCabezaHogar && !error.correo && !error.idCiudad && !error.documento)
              onSubmit(e);
          }
          else {
            if (fileDocumento === '') { setError({ ...error, documento: true, documentoHelper: ' Este campo es requerido' }); }
            if (candidato.nombre === '') { setError({ ...error, nombre: true, nombreHelper: ' Este campo es requerido' }); }
            if (candidato.apellidos === '') { setError({ ...error, apellidos: true, apellidosHelper: ' Este campo es requerido' }); }
            if (candidato.tipoDocumento === '') { setError({ ...error, tipoDocumento: true, tipoDocumentoHelper: ' Este campo es requerido' }); }
            if (candidato.numeroDocumento === '') { setError({ ...error, numeroDocumento: true, numeroDocumentoHelper: ' Este campo es requerido' }); }
            if (candidato.direccion === '') { setError({ ...error, direccion: true, direccionHelper: ' Este campo es requerido' }); }
            if (candidato.celular === '') { setError({ ...error, celular: true, celularHelper: ' Este campo es requerido' }); }
            if (candidato.fechaNacimiento === '') { setError({ ...error, fechaNacimiento: true, fechaNacimientoHelper: ' Este campo es requerido' }); }
            if (candidato.genero === '') { setError({ ...error, genero: true, generoHelper: ' Este campo es requerido' }); }
            if (candidato.rh === '') { setError({ ...error, rh: true, rhHelper: ' Este campo es requerido' }); }
            if (candidato.madreCabezaHogar === '') { setError({ ...error, madreCabezaHogar: true, madreCabezaHogarHelper: ' Este campo es requerido' }); }
            if (candidato.correo === '') { setError({ ...error, correo: true, correoHelper: ' Este campo es requerido' }); }
            if (candidato.idCiudad === '') { setError({ ...error, idCiudad: true, idCiudadHelper: ' Este campo es requerido' }); }
          }
        }}>
          <div>
            <InputLabel>Nombres<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, nombre: true, nombreHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length >= 25) {
                    setError({ ...error, nombre: true, nombreHelper: ' Máximo 25 caracteres' });
                  }
                  else setError({ ...error, nombre: false, nombreHelper: '' });
              }}
              error={error.nombre}
              helperText={error.nombreHelper}
              defaultValue={candidato.nombre}
              onChange={handleChange} name='nombres'
              variant="outlined"
              className={classes.textFieldRoot}
              inputProps={{ maxLength: 25 }}
            />
          </div>
          <div>
            <InputLabel>Apellidos<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, apellidos: true, apellidosHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length >= 25) {
                    setError({ ...error, apellidos: true, apellidosHelper: ' Máximo 25 caracteres' });
                  }
                  else setError({ ...error, apellidos: false, apellidosHelper: '' });
              }}
              error={error.apellidos}
              helperText={error.apellidosHelper}
              defaultValue={candidato.apellidos}
              onChange={handleChange} name='apellidos'
              variant="outlined"
              className={classes.textFieldRoot}
              inputProps={{ maxLength: 25 }}
            />
          </div>
          <div>
            <InputLabel>Tipo de Documento<span className={classes.color}>*</span></InputLabel>
            <FormControl
              disabled
              variant="outlined"
              className={classes.textFieldRoot}
            >
              <Select
                disabled
                defaultValue={candidato.tipoDocumento}
                onChange={handleChange} name='tipoDocumento'
              >
                <MenuItem value={"CC"}>Cedula de Ciudadania</MenuItem>
                <MenuItem value={"CE"}>Cedula de Extranjeria</MenuItem>
                <MenuItem value={"NIT"}>NIT</MenuItem>
                <MenuItem value={"DE"}>Tipo de documento extranjero</MenuItem>
                <MenuItem value={"PEP"}>Permiso especial de permanencia</MenuItem>
                <MenuItem value={"PPT"}>Permiso por Protección Temporal</MenuItem>
                {/* Para agregar más tipos de documentos */}
              </Select>
            </FormControl>
          </div>
          <div>
            <InputLabel>Número de Documento<span className={classes.color}>*</span></InputLabel>
            <TextField
              disabled
              defaultValue={candidato.numeroDocumento}
              onChange={handleChange} name='numeroDocumento'
              onBlur={async event => {
                if (event.target.value !== '' && !compNumero.test(event.target.value)) await setError({ ...error, numeroDocumento: true }); else
                  await setError({ ...error, numeroDocumento: false });
              }}
              variant="outlined"
              className={classes.textFieldRoot}
              error={candidato.numeroDocumento !== '' && !compNumero.test(candidato.numeroDocumento)}
              helperText={
                compNumero.test(candidato.numeroDocumento)
                  ? mensajesError.noError : candidato.numeroDocumento === ""
                    ? mensajesError.noError : mensajesError.errorNumero
              }
              id="numeroDocumento"
            />
          </div>
          <div>
            <InputLabel>Adjunte aquí su documento de identidad<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={event => {
                if (event.target.files[0] === undefined) {
                  setError({ ...error, documento: true, documentoHelper: ' Este campo es requerido' });
                }
                else setError({ ...error, documento: false, documentoHelper: "Sólo se aceptan PDF y JPG" });
              }}
              error={error.documento}
              type="file"
              defaultValue={fileDocumento}
              onChange={event => setFileDocumento(event.target.files[0])}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
              helperText={error.documentoHelper}
            />
          </div>
          <div>
            <InputLabel>Departamento<span className={classes.color}>*</span></InputLabel>
            <FormControl
              required
              variant="outlined"
              className={classes.textFieldRoot}
              error={error.departamento}
            >
              <Select
                defaultValue={candidato.departamento.id}
                onChange={event => { handleDepartamento(event) }}
                required
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, departamento: true, departamentoHT: mensajesError.errorVacio });
                  } else setError({ ...error, departamento: false, departamentoHT: '' });
                }}
              >
                <MenuItem value="" />
                {departamentoList.length > 0 ?
                  departamentoList.map((departamento) => {
                    return (<MenuItem key={departamento.id} value={departamento.id}> {departamento.nombre} </MenuItem>)
                  })
                  : <Typography value="">No se encontraron departamentos</Typography>}
              </Select>
              <Typography variant='caption' color='error' className={classes.Errortypography}>{error.departamentoHT}</Typography>
            </FormControl>
          </div>
          <div>
            <InputLabel>Ciudad<span className={classes.color}>*</span></InputLabel>
            <FormControl
              required
              variant="outlined"
              className={classes.textFieldRoot}
              error={error.ciudad}
            >
              <Select
                defaultValue={candidato.ciudad.id}
                onChange={event => handleChange(event)} name='idCiudad'
                required
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, ciudad: true, ciudadHT: mensajesError.errorVacio });
                  } else setError({ ...error, ciudad: false, ciudadHT: '' });
                }}
              >
                <MenuItem value="" />
                {ciudadList.length > 0 ?
                  ciudadList.map((ciudad) => {
                    return (<MenuItem key={ciudad.id} value={ciudad.id}> {ciudad.nombre} </MenuItem>)
                  })
                  : <Typography value="">No se encontraron ciudades</Typography>}
              </Select>
              <Typography variant='caption' color='error' className={classes.Errortypography}>{error.ciudadHT}</Typography>
            </FormControl>
          </div>
          <div>
            <InputLabel>Dirección<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, direccion: true, direccionHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length > 80) {
                    setError({ ...error, direccion: true, direccionHelper: ' Máximo 80 caracteres' });
                  } else if (event.target.value.length < 5) {
                    setError({ ...error, direccion: true, direccionHelper: mensajesError.errorMinLength });
                  }
                  else setError({ ...error, direccion: false, direccionHelper: '' });
              }}
              error={error.direccion}
              helperText={error.direccionHelper}
              defaultValue={candidato.direccion}
              onChange={handleChange} name='direccion'
              variant="outlined"
              className={classes.textFieldRoot}
              inputProps={{ maxLength: 81 }}
            />
          </div>
          <div>
            <InputLabel>Celular<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, celular: true, celularHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length !== 10) {
                    setError({ ...error, celular: true, celularHelper: 'Este campo debe tener 10 caracteres' });
                  } else
                    if (!compNumero.test(event.target.value)) {
                      setError({ ...error, celular: true, celularHelper: ' Ingrese únicamente caracteres númericos' });
                    }
                    else setError({ ...error, celular: false, celularHelper: '' });
              }}
              error={error.celular}
              helperText={error.celularHelper}
              defaultValue={candidato.celular}
              onChange={handleChange} name='celular'
              variant="outlined"
              className={classes.textFieldRoot}
              id="celular"
              inputProps={{ maxLength: 10 }}
            />
          </div>
          <div>
            <InputLabel>Teléfono</InputLabel>
            <TextField
              onBlur={async event => {
                const empty = event.target.value !== '';
                if (event.target.value.length !== 10 && empty) {
                  setError({ ...error, telefono: true, telefonoHelper: 'Este campo debe tener 10 caracteres' });
                }
                else setError({ ...error, telefono: false, telefonoHelper: '' });
                if (!compNumero.test(event.target.value) && empty) {
                  setError({ ...error, telefono: true, telefonoHelper: ' Ingrese únicamente caracteres númericos' });
                }
              }}
              error={error.telefono}
              helperText={error.telefonoHelper}
              defaultValue={candidato.telefonoFijo}
              onChange={handleChange} name='telefonoFijo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="telefono"
              inputProps={{ maxLength: 10 }}
            />
          </div>
          <div>
            <InputLabel>Correo<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, correo: true, correoHelper: ' Este campo es requerido' });
                } else
                  if (!compCorreo.test(event.target.value)) {
                    setError({ ...error, correo: true, correoHelper: 'Ingrese un correo válido' });
                  } else
                    if (event.target.value.length >= 50) {
                      setError({ ...error, correo: true, correoHelper: ' Máximo 50 caracteres' });
                    }
                    else setError({ ...error, correo: false, correoHelper: '' });
              }}
              error={error.correo}
              helperText={error.correoHelper}
              defaultValue={candidato.correo}
              onChange={handleChange} name='correo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="correo"
              inputProps={{ maxLength: 50 }}
            />
          </div>
          <div>
            <InputLabel>Fecha de Nacimiento<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaNacimiento: true, fechaNacimientoHelper: ' Este campo es requerido' });
                } else
                  if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                    setError({ ...error, fechaNacimiento: true, fechaNacimientoHelper: ' La fecha debe ser anterior a ' + fechaActual });
                  }
                  else setError({ ...error, fechaNacimiento: false, fechaNacimientoHelper: '' });
              }}
              error={error.fechaNacimiento}
              helperText={error.fechaNacimientoHelper}
              defaultValue={candidato.fechaNacimiento}
              type="date"
              onChange={handleChange} name='fechaNacimiento'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaNacimiento"
            />
          </div>
          <div>
            <InputLabel>Género<span className={classes.color}>*</span></InputLabel>
            <FormControl
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, genero: true, generoHelper: ' Este campo es requerido' });
                }
                else setError({ ...error, genero: false, generoHelper: '' });
              }}
              error={error.genero}
              variant="outlined"
              className={classes.textFieldRoot}
            >
              <Select
                onChange={handleChange} name='genero'
                defaultValue={candidato.genero}
              >
                <MenuItem value="" />
                <MenuItem value={"F"}>Mujer</MenuItem>
                <MenuItem value={"M"}>Hombre</MenuItem>
                <MenuItem value={"O"}>Otros</MenuItem>
              </Select>
              <Typography className={classes.errorText} variant="caption" color="error"> {error.generoHelper} </Typography>
            </FormControl>
          </div>
          <div>
            <InputLabel>¿Es madre cabeza de hogar?<span className={classes.color}>*</span></InputLabel>
            <FormControl
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, madreCabezaHogar: true, madreCabezaHogarHelper: ' Este campo es requerido' });
                }
                else setError({ ...error, madreCabezaHogar: false, madreCabezaHogarHelper: '' });
              }}
              error={error.madreCabezaHogar}
              variant="outlined"
              className={classes.textFieldRoot}
            >
              <Select
                onChange={handleChange} name='madreCabezaHogar'
                defaultValue={candidato.madreCabezaHogar}
              >
                <MenuItem value="" />
                <MenuItem value={true}>Si</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
              <Typography className={classes.errorText} variant="caption" color="error"> {error.madreCabezaHogarHelper} </Typography>
            </FormControl>
          </div>
          <div>
            <InputLabel>Tipo de sangre<span className={classes.color}>*</span></InputLabel>
            <FormControl
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, rh: true, rhHelper: ' Este campo es requerido' });
                }
                else setError({ ...error, rh: false, rhHelper: '' });
              }}
              error={error.rh}
              variant="outlined"
              className={classes.textFieldRoot}
            >
              <Select
                onChange={handleChange} name='rh'
                defaultValue={candidato.rh}
              >
                <MenuItem value="" />
                <MenuItem value="O+">O+</MenuItem>
                <MenuItem value="O-">O-</MenuItem>
                <MenuItem value="A+">A+</MenuItem>
                <MenuItem value="A-">A-</MenuItem>
                <MenuItem value="B+">B+</MenuItem>
                <MenuItem value="B-">B-</MenuItem>
                <MenuItem value="AB+">AB+</MenuItem>
                <MenuItem value="AB-">AB-</MenuItem>
              </Select>
              <Typography className={classes.errorText} variant="caption" color="error"> {error.rhHelper} </Typography>
            </FormControl>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => { setRedirect(true) }}
              className={classes.button}>
              Volver
            </Button>
            <Button variant="contained" className={classes.button}
              color="primary" type="submit">
              Editar Datos
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditarCandidato;