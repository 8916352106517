
import { useDropzone } from 'react-dropzone';
import React, { useMemo, useCallback } from 'react';
import { Box, List, Typography, Grid } from '@material-ui/core';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#000000',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ed0930',
    color: '#ed0930',
};

const defineAcceptedExtension = (name) => {
    if(name === 'contratoFirmado') {
        return  { extensionAccepted : 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  messageAccepted: '*.pdf, *.doc o *.docx' };
    } else {
        return { extensionAccepted : 'application/pdf',
                 messageAccepted: '*.pdf'};
    }
}

export const CustomDropzone = ({ classes, nombre = "archvivo", handleChange, hasError, name, formState ,setFormState, formFiles, setFormFiles }) => {
    const extension = defineAcceptedExtension(name);
    
    const onDrop = useCallback( file  => {
        
        if(file[0].size > 1000000) {
            setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  [name]: file[0].size,
                },
                touched: {
                  ...formState.touched,
                  [name]: true,
                },
                errors:{
                    ...formState.errors,
                    [name]: true
                }
            });
            setFormFiles({
                ...formFiles,
                [name]: file[0]
            });
        }
        else if (file.length > 0) {
            setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  [name]: file[0].size,
                },
                touched: {
                  ...formState.touched,
                  [name]: true,
                },
              });
            setFormFiles({
                ...formFiles,
                [name]: file[0]
            });
        } else {
            setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  [name]: file[0].size,
                },
                touched: {
                  ...formState.touched,
                  [name]: true,
                },
                errors:{
                    ...formState.errors,
                    [name]:true
                }
            });
            setFormFiles({
                ...formFiles,
                [name]: file[0]
            });
        }
    }, [formState, setFormState, name, formFiles, setFormFiles])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({ accept: extension.extensionAccepted, maxFiles: 1, onDrop });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
         ...(hasError(name) ? rejectStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        hasError,
        name,
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (

        <Grid container xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
                <Typography variant="body1" className={classes.typography}>{nombre}<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
                <Box>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps({
                            onChange: (e) => { handleChange(e.target.files,name) }
                        })} />
                        <Typography>Arrastra y suelta o selecciona el archivo</Typography>
                        <em>(Solo documentos con extension {extension.messageAccepted} son aceptados y de tamaño máximo 1MB)</em>
                    </div>

                    {hasError(name) ? <Typography className={classes.errorHelper} color="error" variant="caption">{formState.errors[name]}</Typography> : null}
                    <aside>
                        <Typography component="h4" variant="inherit">
                            Archivo
                        </Typography>
                        <List>{files}</List>
                    </aside>
                </Box>
            </Grid>
        </Grid>

    )

}

