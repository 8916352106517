import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, FormControl, Typography, Grid, MenuItem, IconButton, Collapse } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { fileHelperText, Programas } from '../../../constants/AppConstants';
import { tipoFormaciones} from '../../../constants/AppConstants';
import { postFormaciones } from '../../../helpers/formacionHelper/formacionHelper';


const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  marginField: {
    marginBottom: 15,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    width: "100%",
  },
  color: {
    color: 'red'
  }
}));

const IngresarFormacion = ({ variant = 'default', idCandidato, tokenGeneral, setInfo }) => {

  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 1);
  const fechaActual = fecha.toISOString().split('T')[0];
  const classes = useStyles({ variant });
  const idFormacion = idCandidato
  const [isPregrado, setIsPregrado] = useState(false);
  const [tarjProf, setTarjProf] = useState(false)
  const [infoTarjeta, setInfoTarjeta] = useState(false);
  const [inputSwitch, setInputSwitch] = React.useState(true);
  const [open, setOpen] = useState(false)         //Alerta de espacios erróneos
  const [formacion, setFormacion] = useState({
    nombre: '',
    centroEducativo: '',
    fechaCulminacion: '',
    tipo: '',
    tarjeta: false,
    fechaExpedicionTarjetaProfesional: '',
    idCandidato: idFormacion
  });
  const [fileDiploma, setFileDiploma] = useState('');
  const [fileTarjetaProfesional, setFileTarjetaProfesional] = useState('');
  const [fileCertificado, setFileCertificado] = useState('');
  const mensajesError = {
    noError: '',
    errorVacio: 'Ingrese algún dato',
    errorTipoFormacion: 'Tipo de formación no debe estar vacío',
    errorNombrePrograma: 'Nombre del programa no debe estar vacío',
    errorCentroEducativo: 'Centro educativo no debe estar vacío',
    errorFechaCulminacion: 'Fecha de culminación no debe estar vacío',
    errorDiploma: 'Debe adjuntar el diploma',
    errorExpedicionTarjetaProfesional: 'Expedicion de tarjeta profesional no debe estar vacio',
    errorTarjetaProfesional: 'Debe adjuntar la tarjeta profesional',
    errorCertificadoTarjetaProfesional: 'Debe adjuntar el certificado de tarjeta profesional',
    mensajeFile: fileHelperText,
    errorFile: 'El archivo sobrepasa los 6 mb'
  }
  const [error, setError] = useState({
    centroEducativo: false,
    centroEducativoHT: '',
    nombrePrograma: false,
    nombreProgramaHT: '',
    tipoFormacion: false,
    tipoFormacionHT: '',
    fechaCulminacion: false,
    fechaCulminacionHT: '',
    fileDiploma: false,
    fileDiplomaHT: mensajesError.mensajeFile,
    fileTarjetaProfesional: false,
    fileTarjetaProfesionalHT: mensajesError.mensajeFile,
    fileCertificado: false,
    fileCertificadoHT: mensajesError.mensajeFile,
    fechaExpedicionTarjetaProfesional: false,
    fechaExpedicionTarjetaProfesionalHT: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormacion({
      ...formacion,
      [name]: value,
    });
    if (formacion.tipo === 'Bachiller' && formacion.nombre !== "Bachiller") {
      setFormacion({
        ...formacion,
        nombre: 'Bachiller',

      })
    }
    if (name === "tipo") {
      if (value === "Pregrado") {
        setIsPregrado(true);
        if(inputSwitch){
          setError({
            ...error,
            tarjetaProfesional: false, 
            tarjetaProfesionalHelper: mensajesError.mensajeFile,
            fechaExpedicionTarjetaProfesional: false, 
            fechaExpedicionTarjetaProfesionalHelper: '',
            certificado: false, 
            certificadoHelper: mensajesError.mensajeFile,
          });
          setTarjProf(true)
          setInfoTarjeta(true)
        }else{
          setFileCertificado('')
          setFileTarjetaProfesional('')
          setInfoTarjeta(false)
        }

      } else {
        setFileCertificado('')
        setFileTarjetaProfesional('')
        setTarjProf(false)
        setInfoTarjeta(false)
        setIsPregrado(false);
      }
      setFormacion({
        ...formacion, tarjeta: false,
        fechaExpedicionTarjetaProfesional: '',
        [name]: value,
      });
    }
  }

  const MySwal = withReactContent(Swal);
  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Formación creada'
    })
  }

  const handleSwitch = (event) => {
    setInputSwitch(event.target.checked);
    if (event.target.checked) {
      setInfoTarjeta(true)
    }
  };


  useEffect(() => {
    if (inputSwitch) {
      setFileTarjetaProfesional("")
      setFileCertificado("")
      setFormacion({...formacion, fechaExpedicionTarjetaProfesional:""})
    }
    // eslint-disable-next-line
  },[inputSwitch]);

  const OnSubmit = async (e) => {
    const formData = new FormData();
    let formacionQuery = Object.assign({}, formacion);
    if(!inputSwitch) formacionQuery.tarjeta = false;
    formacionQuery.tipo = formacion.tipo.toUpperCase()
    formData.append('formacion', new Blob([JSON.stringify(formacionQuery)], { type: "application/json" }));
    formData.append('diploma', fileDiploma);
    if(inputSwitch){
      formData.append('tarjetaProfesional', fileTarjetaProfesional);
      formData.append('certificado', fileCertificado);
    }

    await postFormaciones(formData)
      .then(function (response) {
        sweetAlerts()
        setInfo(1)
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  return (
    <React.Fragment>
      <Typography variant='h1' color="textPrimary" className={classes.subtitle}>Añadir formación académica</Typography>
      <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
      <form onSubmit={(e) => {
          e.preventDefault()
        if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
          && formacion.tipo !== '' && formacion.tipo !== 'Pregrado' && !tarjProf && fileDiploma !== '') {
          if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
            && !error.tipo && !error.diploma) {
            OnSubmit(e)
          }
        }
        if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
          && formacion.tipo !== '' && formacion.tipo === 'Pregrado' && !inputSwitch && fileDiploma !== '') {
          if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
            && !error.tipo && !error.diploma) {
              OnSubmit(e)
          }
        }
        if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
          && formacion.tipo === 'Pregrado' && inputSwitch && formacion.fechaExpedicionTarjetaProfesional !== ''
          && fileDiploma !== '' && fileCertificado !== '' && fileTarjetaProfesional !== '') {
          if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
            && !error.tipo && !error.tarjetaProfesional && !error.diploma
            && !error.fechaExpedicionTarjetaProfesional && !error.certificado) {
            OnSubmit(e)
          }
        }
        if (formacion.nombre === '') { setError({ ...error, nombrePrograma: true, nombreProgramaHT: ' Este campo es requerido' }); }
        if (formacion.centroEducativo === '') { setError({ ...error, centroEducativo: true, centroEducativoHT: ' Este campo es requerido' }); }
        if (formacion.fechaCulminacion === '') { setError({ ...error, fechaCulminacion: true, fechaCulminacionHT: ' Este campo es requerido' }); }
        if (fileDiploma === '') { setError({ ...error, fileDiploma: true, fileDiplomaHT: ' Este campo es requerido' }); }
        if (formacion.fechaExpedicionTarjetaProfesional === '' && inputSwitch) { 
          setError({ ...error, fechaExpedicionTarjetaProfesional: true, fechaExpedicionTarjetaProfesionalHT: ' Este campo es requerido' }); }
        if (fileTarjetaProfesional === '' && inputSwitch) { setError({ ...error, fileTarjetaProfesional: true, fileTarjetaProfesionalHT: ' Este campo es requerido' }); }
        if (fileCertificado === '' && inputSwitch) { setError({ ...error, fileCertificado: true, fileCertificadoHT: ' Este campo es requerido' }); }
        if (formacion.nombre === '' && formacion.centroEducativo === '' && formacion.fechaCulminacion === ''
          && formacion.tipo === 'Pregrado' && fileDiploma === '') {
          setError({
            ...error,
            nombre: true, nombreHelper: 'Este campo es requerido',
            centroEducativo: true, centroEducativoHelper: 'Este campo es requerido',
            fechaCulminacion: true, fechaCulminacionHelper: 'Este campo es requerido',
            diploma: true, diplomaHelper: 'Este campo es requerido',
          });
        }
      }}>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Tipo de formación<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <FormControl
              required
              variant="outlined"
              className={classes.textFieldRoot}
              error={error.tipoFormacion}
            >
              <Select
                onChange={handleChange} name='tipo'
                defaultValue=""
                id="tipoFormacion"
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, tipoFormacion: true, tipoFormacionHT: mensajesError.errorTipoFormacion });
                  } else setError({ ...error, tipoFormacion: false, tipoFormacionHT: '' });
                }}
              >
                <MenuItem value={""}></MenuItem>
                { tipoFormaciones.map((formacionList)=>{
                  return <MenuItem key={formacionList.value} value={formacionList.value} > {formacionList.text}</MenuItem>
                })}
              </Select>
              <Typography variant='caption' color='error' className={classes.Errortypography}>{error.tipoFormacionHT}</Typography>
            </FormControl>
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          {formacion.tipo !== 'Bachiller' ? (  
            <><Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Nombre del programa<span className={classes.color}>*</span></Typography>
            </Grid><Grid item xs={7}>
                {formacion.tipo === 'Pregrado' ?
                  <FormControl
                    variant="outlined"
                    className={classes.textFieldRoot}
                    error={error.nombrePrograma}
                  >
                    <Autocomplete
                      options={Programas}
                      onChange={(e, newValue) => {
                        var event = {
                          value: newValue,
                          name: "nombre"
                        };
                        handleChange({ target: event });
                      } }
                      onBlur={event => {
                        if (event.target.value === '') {
                          setError({ ...error, nombrePrograma: true, nombreProgramaHT: mensajesError.errorNombrePrograma });
                        } else if (event.target.value.length > 81) {
                          setError({ ...error, nombrePrograma: true, nombreProgramaHT: ' Máximo 81 caracteres' });
                        }
                        else
                          setError({ ...error, nombrePrograma: false, nombreProgramaHT: '' });
                      } }
                      renderInput={(params) => <TextField {...params} variant="outlined" />} />
                    <Typography variant='caption' color='error' className={classes.Errortypography}>{error.nombreProgramaHT}</Typography>
                  </FormControl>
                  :
                  <TextField
                    required
                    defaultValue=""
                    onChange={handleChange} name='nombre'
                    variant="outlined"
                    className={classes.textFieldRoot}
                    id="tipo"
                    inputProps={{ maxLength: 81 }}
                    onBlur={event => {
                      if (event.target.value === '') {
                        setError({ ...error, nombrePrograma: true, nombreProgramaHT: mensajesError.errorNombrePrograma });
                      } else
                        setError({ ...error, nombrePrograma: false, nombreProgramaHT: '' });
                    } }
                    error={error.nombrePrograma}
                    helperText={error.nombreProgramaHT} />}
              </Grid></>
          ) : (
            <></>
          )}
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Centro educativo<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              defaultValue=""
              onChange={handleChange} name='centroEducativo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="centroEducativo"
              inputProps={{ maxLength: 40 }}
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, centroEducativo: true, centroEducativoHT: mensajesError.errorCentroEducativo });
                } else setError({ ...error, centroEducativo: false, centroEducativoHT: '' });
              }}
              error={error.centroEducativo}
              helperText={error.centroEducativoHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Fecha de culminación<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              type="date"
              defaultValue=""
              onChange={handleChange} name='fechaCulminacion'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaCulminacion"
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaCulminacion: true, fechaCulminacionHT: mensajesError.errorFechaCulminacion });
                } else setError({ ...error, fechaCulminacion: false, fechaCulminacionHT: '' });
              }}
              error={error.fechaCulminacion}
              helperText={error.fechaCulminacionHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Adjunte aquí su diploma<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              type="file"
              defaultValue={fileDiploma}
              onChange={event => setFileDiploma(event.target.files[0])}
              onBlur={event => {
                if (event.target.files[0] === undefined) {
                  setError({ ...error, fileDiploma: true, fileDiplomaHT: mensajesError.errorDiploma });
                } else if (event.target.files[0].size > 6291456) {
                  setError({ ...error, fileDiploma: true, fileDiplomaHT: mensajesError.errorFile });
                } else {
                  setError({ ...error, fileDiploma: false, fileDiplomaHT: mensajesError.mensajeFile });
                }
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldRoot}
              id="diploma"
              InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
              error={error.fileDiploma}
              helperText={error.fileDiplomaHT}
            />
          </Grid>
        </Grid>
        {isPregrado ? (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant="body1" className={classes.typography}>
                Tarjeta Profesional
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.center}>
              <Grid component="label" container alignItems="center" spacing={1} className={classes.center}>
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    checked={inputSwitch}
                    onChange={handleSwitch}
                    color="primary"
                    name="inputSwitch"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    label="normal"
                  />
                </Grid>
                <Grid item>Sí</Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {inputSwitch && infoTarjeta ?
          <div>
            <Grid className={classes.marginField} container item xs={12} spacing={3}>
              <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Expedición de tarjeta profesional</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="date"
                  defaultValue=""
                  onChange={handleChange} name='fechaExpedicionTarjetaProfesional'
                  variant="outlined"
                  className={classes.textFieldRoot}
                  id="fechaTarjeta"
                  InputProps={{ inputProps: { max: fechaActual } }}
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, fechaExpedicionTarjetaProfesional: true, fechaExpedicionTarjetaProfesionalHT: mensajesError.errorExpedicionTarjetaProfesional });
                    } else setError({ ...error, fechaExpedicionTarjetaProfesional: false, fechaExpedicionTarjetaProfesionalHT: '' });
                  }}
                  error={error.fechaExpedicionTarjetaProfesional}
                  helperText={error.fechaExpedicionTarjetaProfesionalHT}
                />
              </Grid>
              <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Adjunte la tarjeta profesional</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="file"
                  defaultValue=""
                  onChange={async event => {
                    if (fileCertificado !== "") {
                      await setFormacion({ ...formacion, tarjeta: true })
                    }
                    setFileTarjetaProfesional(event.target.files[0])
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textFieldRoot}
                  InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                  onBlur={event => {
                    if (event.target.files[0] === undefined) {
                      setError({ ...error, fileTarjetaProfesional: true, fileTarjetaProfesionalHT: mensajesError.errorTarjetaProfesional });
                    } else if (event.target.files[0].size > 6291456) {
                      setError({ ...error, fileTarjetaProfesional: true, fileTarjetaProfesionalHT: mensajesError.errorFile });
                    } else {
                      setError({ ...error, fileTarjetaProfesional: false, fileTarjetaProfesionalHT: mensajesError.mensajeFile });
                    }
                  }}
                  error={error.fileTarjetaProfesional}
                  helperText={error.fileTarjetaProfesionalHT}
                />
              </Grid>
              <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Adjunte el certificado de tarjeta profesional</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="file"
                  defaultValue=""
                  onChange={async event => {
                    if (fileTarjetaProfesional !== "") {
                      await setFormacion({ ...formacion, tarjeta: true })
                    }
                    setFileCertificado(event.target.files[0])
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textFieldRoot}
                  InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                  onBlur={event => {
                    if (event.target.files[0] === undefined) {
                      setError({ ...error, fileCertificado: true, fileCertificadoHT: mensajesError.errorCertificadoTarjetaProfesional });
                    } else if (event.target.files[0].size > 6291456) {
                      setError({ ...error, fileCertificado: true, fileCertificadoHT: mensajesError.errorFile });
                    } else {
                      setError({ ...error, fileCertificado: false, fileCertificadoHT: mensajesError.mensajeFile });
                    }
                  }}
                  error={error.fileCertificado}
                  helperText={error.fileCertificadoHT}
                />
              </Grid>
            </Grid>

          </div>
          : null}
        <Grid container item xs={12} spacing={3} >
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="secondary" onClick={event => setInfo(1)}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="primary" type="submit">
              Crear Formación
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} className={classes.button}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              variant="filled"
              severity="error"
            >
              ¡Aún hay campos en rojo!
            </Alert>
          </Collapse>
        </Grid>
      </form>
    </React.Fragment>
  )
};

export default IngresarFormacion