import React from "react";
import { Card, CardContent, Typography,  Accordion, AccordionSummary, AccordionDetails, Button } from "@material-ui/core";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, lighten } from "@material-ui/core/styles";
import { getCandidato } from "../../../../helpers/candidato/candidatoHelper";
import { getPostulacionKey } from "../../../../helpers/preempleado/preempleadoHelper";
import { useState } from "react";
import { Redirect } from "react-router";
import { useRef } from "react";
import { dateFormat } from "../../../../constants/AppConstants";
import { downloadDocumentsCandidato } from "../../../../helpers/fileHelper/downloadHelper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CONTRATO_FIRMADO_URL, CONTRATO_SIN_FIRMAR_URL, DOCUMENTOS_CONTRATO_URL } from "../../../../helpers/contratosUrl";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ArrowRight} from "@material-ui/icons";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  textFieldRoot: {
    width: "65%",
    margin: 15,
  },
  title: {
    textAlign: "center",
  },
  form: {
    paddingLeft: "25%",
    paddingTop: 15,
  },
  button: {
    margin: 15,
  },
  errorText: {
    marginInline: 14,
  },
  switch: {
    margin: "15px 0",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  color: {
    color: "red",
  },
  postulaciones:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px'
  },
    buttonCompleteDocuments: {
        margin: 15,
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "green"
    }
}));

const useSingleton = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const Postulaciones = () => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [preempleados, setPreempleados] = useState([]);
  const [postulaciones, setPostulaciones] = useState([]);
  const MySwal = withReactContent(Swal);
  const initialState = async () => {
    const data = await getCandidato()
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        setRedirect(true);
      });
    if (data !== undefined) {
      setPreempleados(data.preempleados);
      setPostulaciones(data.postulaciones);
    }
  };

  useSingleton(async () => {
    await initialState();
  });

    const obtenerPostulacionKey = async (preempleado)  => {
        const data = await getPostulacionKey(preempleado.id)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                setRedirect(true);
            });
        return data;
    }

  const alertContratoSinFirmar = (postulaciones) => {
    if( postulaciones.length > 0 ) {
      postulaciones.forEach((item) => {
        if (item.estado === "CONTRATADO" && item.preempleado?.contrato?.contratoFirmado === null && item.preempleado?.contrato?.contratoSinFirmar !== null) {
          MySwal.fire({
            icon: 'info',
            title: 'Contrato sin firmar',
            text: 'No has firmado el contrato de la oferta '+ item.oferta.nombre + ', revisa tu correo electrónico o haz clic en el botón Completar Documentos para continuar con el proceso  y subir  los archivos requeridos del contrato'
          })
        }
      })
    }
  }

  alertContratoSinFirmar(postulaciones);


  const asignarPreempleados = () => {
    for (const postulacion of postulaciones) {
      postulacion.preempleado = preempleados.find(preempleado => preempleado.postulacion.id === postulacion.id);
    }
  }

  const handleDownload = (numeroDocumento, filename) => {
    downloadDocumentsCandidato(numeroDocumento, filename)
      .then((res) => res.data)
      .then((fileBits) => {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = "data:text/plain;base64," + fileBits;
        link.download = filename;
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) =>
        MySwal.fire("Error", "Ocurrió un error generando la descarga", "error")
      );
  };

    const history = useHistory();
    const handleRedirect = (preempleado) => {
        obtenerPostulacionKey(preempleado)
            .then((value) => {
                history.push("documentos_contrato?key=" + value);
            })
            .catch((err) =>
                MySwal.fire("Error", "Ocurrió un error redirigiendo a la página, por favor intente más tarde.", "error")
            );
    };



  const PostulacionesTable = () => {
    asignarPreempleados();
    return postulaciones.length > 0
      ? postulaciones.map((item) => {
          return (
            <TableRow key={item.id} className={classes.internalCard}>
              <TableCell className={classes.cell}>
                {" "}
                {dateFormat(item.fechaPostulacion)}{" "}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.oferta.proyecto.nombre}{" "}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.oferta.nombre}{" "}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.oferta.descripcion.replace(/<(.|\n)*?>/g, ' ').trim()}{" "}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {(item.estado.slice(0, 1).toUpperCase() +
                  item.estado.substring(1).toLowerCase()).replace("_", " ")}{" "}
              </TableCell>
              <TableCell className={classes.cell}>
                  {(item.estado === 'CONTRATADO' && item.preempleado?.contrato?.contratoFirmado === null && item.preempleado?.contrato?.contratoSinFirmar !== null) ?
                      <div>
                          <Button variant="contained" size='small' color="secondary" onClick={() => handleRedirect(item.preempleado)}
                                  className={classes.buttonCompleteDocuments} endIcon={<ArrowRight />}>Completar Documentos</Button>
                      </div>
                     :" "}

                  {item.estado === 'CONTRATADO' || item.estado === 'DESVINCULADO' ?
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>Descargar</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          {item.preempleado.contrato.contratoSinFirmar ?
                            <><Typography variant="h4" align="left" color="textPrimary">Contrato sin firmar</Typography>
                              <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleDownload(item.preempleado.numeroDocumento, CONTRATO_SIN_FIRMAR_URL + item.preempleado.contrato.contratoSinFirmar)}
                                className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button></>
                            : null}
                          {item.preempleado.contrato.contratoFirmado ?
                            <><Typography variant="h4" align="left" color="textPrimary">Contrato firmado</Typography>
                              <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleDownload(item.preempleado.numeroDocumento, DOCUMENTOS_CONTRATO_URL + item.preempleado.contrato.numeroContrato + "/" + CONTRATO_FIRMADO_URL + item.preempleado.contrato.contratoFirmado)}
                                className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button></>
                            : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                : "..."}
              </TableCell>
            </TableRow>
          );
        })
      : null;
  };

  return (
    <Card>
      {redirect ? (
        <Redirect
          to={{ pathname: "/signin", state: { goBack: "/postulaciones" } }}
        />
      ) : null}

      <CardContent className={classes.root}>
        <Typography variant="h1" fontSize={20} color="textPrimary">
          Postulaciones
        </Typography>
        <Typography className={classes.subtitle}>
          Visualiza ofertas a las que te has postulado y el estado en el que se encuentran. Si has sido contratado, podrás descargar y revisar tu contrato de trabajo
        </Typography>
        {postulaciones.length > 0 ? (
          <div style={{ overflow: "auto" }}>
            <MaUTable className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Fecha de postulación</strong>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Proyecto</strong>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Nombre de la oferta</strong>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Descripción de la oferta</strong>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Estado</strong>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {" "}
                    <strong>Opciones</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{PostulacionesTable()}</TableBody>
            </MaUTable>
          </div>
        ) : (
          <div className={classes.postulaciones}>
            <Typography variant="h4" align="left" color="textPrimary">
              No te has postulado a ninguna oferta
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Postulaciones;
