import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Redirect } from 'react-router'
import AuthWrapper from '../../../components/authComponents/AuthWrapper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { postPatchExperiencia } from '../../../helpers/editProfile/editProfileHelper';
import { fileHelperText } from '../../../constants/AppConstants';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 auto',
        backgroundColor: lighten(theme.palette.background.paper, 0.1),

    },
    textFieldRoot: {
        width: "100%",
    },
    title: {
        textAlign: 'center',
    },
    form: {
        paddingLeft: '25%',
        paddingTop: 15
    },
    centerItems: {
        width: '100%',
        alignItems: 'center',
    },
    typography: {
        paddingLeft: theme.spacing(8),
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    marginField: {
        marginBottom: 15,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        marginTop: '30px',
    },
    color: {
        color: 'red'
    }
}));

const IngresarExperiencia = (props, { variant = 'default' }) => {

    const fecha = new Date();
    const fechaActual = fecha.toISOString().split('T')[0];
    const classes = useStyles({ variant });
    let urlExperiencia = {}
    if (props.location.state === undefined) window.location.href = '/signin'
    if (props.location.state !== undefined && props.location.state.actualizar) {
        if (props.location.state.experiencia.empresa !== undefined) urlExperiencia.empresa = props.location.state.experiencia.empresa;
        else urlExperiencia.empresa = '';
        if (props.location.state.experiencia.area !== undefined) urlExperiencia.area = props.location.state.experiencia.area;
        else urlExperiencia.area = '';
        if (props.location.state.experiencia.cargo !== undefined) urlExperiencia.cargo = props.location.state.experiencia.cargo;
        else urlExperiencia.cargo = '';
        if (props.location.state.experiencia.fechaInicio !== undefined) urlExperiencia.fechaInicio = props.location.state.experiencia.fechaInicio.replaceAll("/", "-").split("-").reverse().join("-");
        else urlExperiencia.fechaInicio = '';
        if (props.location.state.experiencia.fechaFinal !== undefined) urlExperiencia.fechaFinal = props.location.state.experiencia.fechaFinal.replaceAll("/", "-").split("-").reverse().join("-");
        else urlExperiencia.fechaFinal = '';
        if (props.location.state.experiencia.descripcion !== undefined) urlExperiencia.descripcion = props.location.state.experiencia.descripcion;
        else urlExperiencia.descripcion = '';
    } else {
        urlExperiencia = {
            empresa: '',
            area: '',
            cargo: '',
            fechaInicio: '',
            fechaFinal: '',
            descripcion: ''
        }
    }
    const [experiencia, setExperiencia] = useState(urlExperiencia);
    const [fileExperiencia, setFileExperiencia] = useState('');
    const [error, setError] = useState({
        empresa: false, empresaHelper: '',
        area: false, areaHelper: '',
        cargo: false, cargoHelper: '',
        fechaInicio: false, fechaInicioHelper: '',
        fechaFinal: false, fechaFinalHelper: '',
        descripcion: false, descripcionHelper: '',
        documento: false, documentoHelper: fileHelperText
    })
    const [redirect, setRedirect] = React.useState(false)
    const handleChange = (event) => {
        setExperiencia({
            ...experiencia,
            [event.target.name]: event.target.value
        })
    };

    const onSubmit = async (props, e) => {
        e.preventDefault()

        const formData = new FormData();
        let experienciaQuery = experiencia
        if (props.location.state.actualizar) experienciaQuery.id = props.location.state.experiencia.id
        formData.append('experiencia', new Blob([JSON.stringify(experienciaQuery)], { type: "application/json" }));
        if (fileExperiencia !== '') formData.append('certificado', fileExperiencia);

        let method;
        if (props.location.state.actualizar) method = 'patch';
        else method = 'post';
        await postPatchExperiencia(method, formData)
            .then(response => {
                if (response.status === 200) {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 401) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error de autenticación',
                        text: 'inicia sesión y vuelve a intentarlo'
                    }).then(result => {
                        window.location.pathname = '/signin'
                    })
                }
            });
    }
    return (
        <AuthWrapper variant="crearCandidato">
            <div className={classes.centerItems}>
                {redirect ? <Redirect to='/account/profile' /> : null}
                <React.Fragment>
                    <Typography variant='h1' color="textPrimary" className={classes.subtitle}>Editar experiencia</Typography>
                    <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
                    <form onSubmit={(e) => onSubmit(props, e)}>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Nombre de la Empresa<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    helperText={error.empresaHelper}
                                    error={error.empresa}
                                    defaultValue={experiencia.empresa}
                                    onChange={handleChange} name='empresa'
                                    onBlur={async event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, empresa: true, empresaHelper: ' Este campo es requerido' });
                                        } else
                                            if (event.target.value.length >= 100) {
                                                setError({ ...error, empresa: true, empresaHelper: ' Máximo 100 caracteres' });
                                            }
                                            else setError({ ...error, empresa: false, empresaHelper: '' });
                                    }}
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    id="nombreEmpresa"
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Área Encargada<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    onBlur={async event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, area: true, areaHelper: ' Este campo es requerido' });
                                        } else
                                            if (event.target.value.length >= 30) {
                                                setError({ ...error, area: true, areaHelper: ' Máximo 30 caracteres' });
                                            }
                                            else setError({ ...error, area: false, areaHelper: '' });
                                    }}
                                    error={error.area}
                                    helperText={error.areaHelper}
                                    defaultValue={experiencia.area}
                                    onChange={handleChange} name='area'
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    id="area"
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Fecha de Inicio<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    type="date"
                                    onBlur={event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, fechaInicio: true, fechaInicioHelper: ' Este campo es requerido' });
                                        } else
                                            if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                                                setError({ ...error, fechaInicio: true, fechaInicioHelper: ' La fecha debe ser anterior a ' + fechaActual });
                                            }
                                            else setError({ ...error, fechaInicio: false, fechaInicioHelper: '' });
                                    }}
                                    error={error.fechaInicio}
                                    helperText={error.fechaInicioHelper}
                                    defaultValue={experiencia.fechaInicio}
                                    onChange={handleChange} name='fechaInicio'
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    InputProps={{ inputProps: { max: fechaActual } }}
                                    id="fechaInicio"
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Fecha de Culminación<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    type="date"
                                    onBlur={event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, fechaFinal: true, fechaFinalHelper: ' Este campo es requerido' });
                                        } else
                                            if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                                                setError({ ...error, fechaFinal: true, fechaFinalHelper: ' La fecha debe ser anterior a ' + fechaActual });
                                            }
                                            else setError({ ...error, fechaFinal: false, fechaFinalHelper: '' });
                                    }}
                                    error={error.fechaFinal}
                                    helperText={error.fechaFinalHelper}
                                    defaultValue={experiencia.fechaFinal}
                                    onChange={handleChange} name='fechaFinal'
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    InputProps={{ inputProps: { max: fechaActual } }}
                                    id="fechaFinalizacion"
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Cargo<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    required
                                    multiline
                                    maxRows={8}
                                    onBlur={async event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, cargo: true, cargoHelper: ' Este campo es requerido' });
                                        } else
                                            if (event.target.value.length >= 400) {
                                                setError({ ...error, cargo: true, cargoHelper: ' Máximo 400 caracteres' });
                                            }
                                            else setError({ ...error, cargo: false, cargoHelper: '' });
                                    }}
                                    error={error.cargo}
                                    helperText={error.cargoHelper}
                                    defaultValue={experiencia.cargo}
                                    onChange={handleChange} name='cargo'
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    id="cargo"
                                    inputProps={{ maxLength: 400 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.marginField} container item xs={12} spacing={3}>
                            <Grid item xs={5} className={classes.left}>
                                <Typography variant='body1' className={classes.typography}>Descripción del Cargo<span className={classes.color}>*</span></Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    onBlur={async event => {
                                        if (event.target.value === '') {
                                            setError({ ...error, descripcion: true, descripcionHelper: ' Este campo es requerido' });
                                        } else
                                            if (event.target.value.length > 2000) {
                                                setError({ ...error, descripcion: true, descripcionHelper: ' Máximo 2000 caracteres' });
                                            }
                                            else setError({ ...error, descripcion: false, descripcionHelper: '' });
                                    }}
                                    error={error.descripcion}
                                    helperText={error.descripcionHelper}
                                    multiline
                                    defaultValue={experiencia.descripcion}
                                    onChange={handleChange} name='descripcion'
                                    variant="outlined"
                                    className={classes.textFieldRoot}
                                    id="descripcion"
                                    inputProps={{ maxLength: 2001 }}
                                />
                            </Grid>
                        </Grid>
                        <div>
                            <Grid className={classes.marginField} container item xs={12} spacing={3}>
                                <Grid item xs={5} className={classes.left}>
                                    <Typography variant='body1' className={classes.typography}>Adjunte la Constancia de la Empresa<span className={classes.color}>*</span></Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        required={props.location.state.crear}
                                        type="file"
                                        defaultValue={fileExperiencia}
                                        onChange={event => setFileExperiencia(event.target.files[0])}
                                        onBlur={event => {
                                            if (props.location.state.crear && event.target.files[0] === undefined) {
                                                setError({ ...error, documento: true, documentoHelper: ' Este campo es requerido' });
                                            }
                                            else setError({ ...error, documento: false, documentoHelper: 'Sólo se aceptan PDF y JPG' });
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.textFieldRoot}
                                        id="constancia"
                                        InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                                        helperText={error.documentoHelper}
                                        error={error.documento}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={6} className={classes.button}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setRedirect(true)}>
                                        Cancelar
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.button}>
                                    {props.location.state.actualizar ?
                                        <Button
                                            type="submit"
                                            variant="contained" color="primary"
                                            className={classes.button}>
                                            Actualizar Experiencia
                                        </Button>
                                        :
                                        <Button
                                            type="submit"
                                            variant="contained" color="primary"
                                            className={classes.button}>
                                            Registrar experiencia
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </React.Fragment>
            </div>
        </AuthWrapper>
    )
};

export default IngresarExperiencia;