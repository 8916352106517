import React, { useState, useRef, useEffect } from 'react';
import GridContainer from '../../../components/GridContainer';
import PageContainer from '../../../components/PageComponents/layouts/PageContainer';
import Card from "@material-ui/core/Card";
import { Alert, Grid } from '@mui/material';
import { Typography, Button } from "@material-ui/core";
import { getCandidato } from '../../../helpers/candidato/candidatoHelper';
import { candidatosPostulados, postPostulacion } from '../../../helpers/postulacion/postulacionHelper';
import { Redirect, useHistory, useLocation } from 'react-router'
import { makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getOfertasAvailable } from '../../../helpers/ofertas/ofertaHelper';
import ReactQuill from "react-quill";
import '../../../utils/toolbar.css';
import 'react-quill/dist/quill.snow.css';
import { dateFormat } from '../../../constants/AppConstants';
const CryptoJS = require("crypto-js");

const useStyles = makeStyles(() => ({
  internalCard: {
    margin: 15,
    padding: 15,
  },
  textCard: {
    marginBottom: 10,
  },
  containerButton:{
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
        margin: 14,
  },
  bold: {
    fontWeight: 'bold',
    display: 'contents'
  },
  display: {
    display: 'contents',
  }
}));

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const UserPage = () => {
  
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false)
  const [candidato, setCandidato] = useState({})
  const [postulaciones, setPostulaciones] = useState([])
  const [ofertas, setOfertas] = React.useState([]);
  const location = useLocation();
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const oferta = location.pathname.split("/").length === 3 ? location.pathname.split("/")[2] : null;

  const initialState = async() => {
    const postulacionesInfo = await candidatosPostulados()
      .then(response => response.data)
      .catch(err => setRedirect(err))
    setPostulaciones(postulacionesInfo)

    const candidatoInfo = await getCandidato()
      .then(response => response.data)
      .catch(err => {
        setRedirect(true)
        return {id:''}
      })
    setCandidato(candidatoInfo)

    const ofertaQuery = await getOfertasAvailable()
      .then((response) => response.data)
      .catch((err) => console.log(err));
    setOfertas(ofertaQuery);
  }
  useSingleton(async() => {
    await initialState()
  })

  const postulacionOferta = (idOferta) =>{
    MySwal.fire({
      title: '¿Estás seguro de postularse a la oferta?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(async result => {
      if (result.value) {
        await postPostulacion(idOferta)
          .then(() => {
            MySwal.fire({
              title:"¡Buen trabajo!",
              text: "Has aplicado a la oferta, recibirás un correo electrónico con los pasos a seguir",
              icon: 'success'
            })
             initialState()
           })
          .catch(err => {
            if(err.response.status === 400 && err.response.data.title){
              MySwal.fire({
                title:"Hubo un problema",
                text: "Por favor comunicate con Grupo IS Colombia para poder postularte a esta oferta",
                icon: 'error'
              })
            }
            if (err.response.status === 500) {
              if (err.response.data.detail === "You have already applied to this offer") {
                MySwal.fire({
                  title:"No puedes aplicar a la oferta",
                  text: "Ya te encuentras vinculado a esta oferta, intenta aplicar a una oferta diferente",
                  icon: 'error'
                })
              } else {
                MySwal.fire({
                  title:"Ocurrió problema",
                  text: `Ocurrió un error al tratar de vincularse a la oferta`,
                  icon: 'error'
                })
              }
            }
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        MySwal.fire('Cancelado', 'No se postulo a la oferta', 'info');
      }
    });
  }

  useEffect(() => {
    if(oferta != null){
      const idOferta = CryptoJS.AES.decrypt(decodeURIComponent(oferta), 'S661S-CR1PT0_S3CUR1T1_V53R').toString(CryptoJS.enc.Utf8);
      postulacionOferta(idOferta)
    }
    // eslint-disable-next-line
  }, [])
  

  const RenderAlerta = () => {
    if (postulaciones.length > 0 ){
      const id = postulaciones[0]
      return(
        <Alert
          variant="filled"
          severity="warning"
          action={
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={event => history.push('/postulacion/update?key='+id)}
            >
              Continuar
            </Button>
          }
        >
          ¡{candidato.nombres}, tienes postulaciones sin confirmar!
        </Alert>
      )
    } else {
      return(
        <></>
      )
    }
  }

  const nombres = candidato.nombres ? candidato.nombres : ""
  const apellidos = candidato.apellidos ? candidato.apellidos: "";
  const message = "Bienvenido " + nombres + " " + apellidos;

  return (
    <PageContainer heading={message}>
      {redirect? <Redirect to={{ pathname:"/signin", state: {goBack: '/user_page' } }}/> : null }
      <GridContainer>
        <Grid item xs={12}>
          <RenderAlerta />
        </Grid>
        <Typography>
          A continuación se encuentra el listado de ofertas disponibles
        </Typography>
        <Grid item xs={12}>
          {ofertas && ofertas.length > 0 ? (
            ofertas.map((oferta) => {
              return (
                <Card key={oferta.id} className={classes.internalCard} variant="outlined">
                  <Typography style={{ overflowWrap: 'anywhere' }} className={classes.textCard} variant="h1" color="textPrimary">
                                      {oferta.nombre}
                  </Typography>
                  <Grid className={classes.textCard} item xs={12}>
                    <Typography style={{ overflowWrap: 'anywhere' }} variant="h5" color="textPrimary">
                      Descripción:
                    </Typography>
                    <ReactQuill
                      className='readOnly'
                      defaultValue={oferta.descripcion}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </Grid>
                  <Grid className={classes.textCard} item xs={12}>
                    <Typography className={classes.bold} variant="h5" color="textPrimary">
                      Proyecto: {" "}
                    </Typography>
                    <Typography  className={classes.display}  variant="h5" color="textPrimary">
                      {oferta.proyecto.nombre}
                    </Typography>
                  </Grid>
                  <Grid className={classes.textCard} item xs={12}>
                    <Typography className={classes.bold} variant="h5" color="textPrimary">
                      Ciudad: {" "}
                    </Typography>
                    <Typography  className={classes.display}  variant="h5" color="textPrimary">
                      {oferta.ciudad.nombre}
                    </Typography>
                  </Grid>
                  <Grid className={classes.textCard} item xs={12}>
                    <Typography className={classes.bold} variant="h5" color="textPrimary">
                      Fecha de cierre: {" "}
                    </Typography>
                    <Typography  className={classes.display}  variant="h5" color="textPrimary">
                      {dateFormat(oferta.fechaCierre)}
                    </Typography>
                  </Grid>
                  <div className={classes.containerButton}>
                  <Button onClick={()=> postulacionOferta(oferta.id)} className={classes.button} variant="contained" color="primary" >
                                  Aplicar a la oferta
                  </Button>
                  </div>
                </Card>
              );
            })
          ) : (
            <Typography>No hay ofertas disponibles por el momento</Typography>
          )}
          </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default UserPage