import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const postCandidato = (data) =>{
    let config = {
        method: 'post',
        url: BASE_URL + 'candidato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data:data,
    }
    return axios(config)
}

export const patchtCandidato = (data) =>{
    let config = {
        method: 'patch',
        url: BASE_URL + 'candidato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data:data,
    }
    return axios(config)
}

export const getCandidato = () =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'candidato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getAuthenticate = () =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'authenticate',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getCandidatoId = (id) =>{
    let config = {
        method: 'get',
        url: BASE_URL + `candidatoes/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getCandidatoPage = ({page=0, pageSize=10}) =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'candidatoes',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            pageNumber : page,
            pageSize: pageSize,
        }
    }
    return axios(config)
}

export const candidatoAdmin = (data) =>{
    let config = {
        method: 'post',
        url: BASE_URL + 'candidatoAdmin',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data:data
    }
    return axios(config)
}

export const putCandidato = (data, idCandidato) =>{
    let config = {
        method: 'put',
        url: BASE_URL + 'candidato'+idCandidato,
        headers: {
            'Authorization': token,
        },
        withCredentials: true,
        data:data
    }
    return axios(config)
}

