import axios from 'axios'
import {BASE_URL} from '../baseUrl'
import {getToken} from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json';

export const getFormacionesCandidato = async(id) => {
    const config = {
        method: 'get',
        url: BASE_URL+'formacion_candidato/'+id,
        headers: {
          'Authorization': token,
          'Content-Type': contentType
        },
        withCredentials: true,
      }

      return await axios(config)
}

export const getFormacionesPreempleado = async(id) => {
  const config = {
      method: 'get',
      url: BASE_URL+'formacion_preempleado/'+id,
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
    }

    return await axios(config)
}

export const postFormaciones = async(data) => {
  const config = {
      method: 'post',
      url: BASE_URL+'formacions',
      headers: {
        'Authorization': token,
      },
      withCredentials: true,
      data:data
    }

    return await axios(config)
}
