import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
/// For the CandidatosSearchTable component
import { useTable, useRowSelect, usePagination } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Card from '@material-ui/core/Card';
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Typography, Paper, TableContainer } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router'
import withReactContent from 'sweetalert2-react-content';
import { Alert } from '@material-ui/lab';
import { deleteEditProfile } from '../../../../helpers/editProfile/editProfileHelper';
import { getAuthenticate, getCandidato } from '../../../../helpers/candidato/candidatoHelper';
import { confirmarDatosPostulacion } from '../../../../helpers/postulacion/postulacionHelper';
import { dateFormat } from '../../../../constants/AppConstants';

const useStyles = makeStyles(theme=>({
	root: {
		maxWidth: "100%",
		minWidth: "100%",
		margin: '0 auto',
		backgroundColor: lighten(theme.palette.background.paper, 0.1),
		marginBottom: 15,
	},
	internalCard: {
		margin: 15,
		padding: 15,
	},
	subtitleCard: {
		marginInline: 15,
	},
	cell: {
		maxWidth: 100,
		overflowWrap: 'anywhere',
	},
	title:{
		marginLeft: theme.spacing(3),
		display: 'flex',
		alignItems: 'center',
		marginBottom: '30px',
		marginTop: '20px',
	},
	table: {
		maxWidth: "80%",
		margin: "0 auto",
		marginBottom: 15,
	},
	tableCellTitle:{
		fontWeight:"bold"
	},
	marginField: {
		marginBottom: 15,
	}, 
	containerNoData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '50px',
	  }
}));
const MySwal = withReactContent(Swal);


const EditLink = (row, formacionExperiencia) => {
	let state = {}
	let path = ''
	if (formacionExperiencia) {
		path = '/actualizar_formacion_postulacion'
		state = { actualizar: true, formacion: row.original }
	}
	else {
		path = '/actualizar_experiencia_postulacion'
		state = { actualizar: true, experiencia: row.original }
	}
	return (
		<Link color="primary" to={{ pathname: path, state: state, search: window.location.search }}>
			<CreateIcon />
		</Link>
	)
}
const DeleteLink = (row, formacionExperiencia, initialState) => {
	const Borrar = async (id, route, initialState) => {
		MySwal.fire({
			title: 'Estas seguro?',
			text: "No podrás revertir esta acción!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			reverseButtons: true,
		}).then(async result => {
			if (result.value) {
				await deleteEditProfile(route,id)
					.then(response => {
						initialState()
						MySwal.fire('Eliminado', 'El registro fue eliminado.', 'success');
					})
					.catch(err => err)
			} else if (
				result.dismiss === Swal.DismissReason.cancel
			) {
				MySwal.fire('Cancelado', '', 'error');
			}
		});

	}
	let route;
	if (formacionExperiencia) route = 'formacions/';
	else route = 'experiencias/'
	return (
		<Button color="primary" onClick={() => Borrar(row.original.id, route, initialState)}>
			<DeleteIcon />
		</Button>
	)
}
function Table({ props, columns, data, formacionExperiencia, initialState }) {
	const classes = useStyles();
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageSize: 5 }
		},
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columnsHooked => [
				// Let's make a column for selection
				...columnsHooked,
				{
					id: 'edit',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<div>
							{/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							{EditLink(row, formacionExperiencia)}
						</div>
					),
				},
				{
					id: 'delete',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<div>
							{/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							{DeleteLink(row, formacionExperiencia, initialState)}
						</div>
					),
				},

			])
		}
	)

	return (
		<Grid container>
			<div style={{ width: '100%', 'overflow': 'auto' }}>
				<MaUTable {...getTableProps()}>
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell className={classes.cell} {...column.getHeaderProps()}>
										<strong>{column.render('Header')}</strong>
										{/* Render the columns filter UI */}
										{/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row)
							return (
								<TableRow {...row.getRowProps()}>
									{row.cells.map(cell => {
										return <TableCell className={classes.cell} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</MaUTable>
			</div>
			<div className="pagination">
				<Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
					{'<<'}
				</Button>{' '}
				<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
					{'<'}
				</Button>{' '}
				<Button onClick={() => nextPage()} disabled={!canNextPage}>
					{'>'}
				</Button>{' '}
				<Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{'>>'}
				</Button>{' '}
				<span>
					Página{' '}
					<strong>
						{pageIndex + 1} de {pageOptions.length}
					</strong>{' '}
				</span>
				<span>
					| Ir a la página:{' '}
					<Input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(pageNumber)
						}}
						style={{ width: '50px' }}
					/>
				</span>{' '}
				<Select
					value={pageSize}
					onChange={e => {
						setPageSize(Number(e.target.value))
					}}
				>
					{[5, 10, 15, 20, 30].map(pageSizeMaper => (
						<option key={pageSizeMaper} value={pageSizeMaper}>
							Mostrar {pageSizeMaper}
						</option>
					))}
				</Select>
			</div>
		</Grid>
	)
}
const FormacionesTable = (props, formaciones, initialState) => {
	const classes = useStyles();
	const columns = [
		{
			Header: 'Nombre del programa',
			accessor: 'nombre',
		},
		{
			Header: "Tipo de programa",
			accessor: "tipo",
		},
		{
			Header: 'Centro educativo',
			accessor: 'centroEducativo',
		},
		{
			Header: 'Fecha de culminación',
			accessor: 'fechaCulminacion',
		},
		{
			Header: 'Fecha expedición tarjeta profesional',
			accessor: 'fechaExpedicionTarjetaProfesional',
		},
	]
	if (formaciones) {
		return (
			<div>
				{formaciones.length > 0 ?
					<Table props={props} columns={columns} data={formaciones}
						formacionExperiencia={true} initialState={initialState} />
					: 
					<div className={classes.containerNoData}>
						<Typography variant="h2" color="textPrimary"> No tiene formaciones registradas </Typography>
					</div> }
			</div>
		)
	}
	else {
		return null
	}
}

const ExperienciasTable = (props, experiencias, initialState) => {
	const classes = useStyles();
	const columns = [
		{
			Header: 'Empresa',
			accessor: 'empresa',
		},
		{
			Header: 'Cargo',
			accessor: 'cargo',
		},
		{
			Header: 'Fecha de inicio',
			accessor: 'fechaInicio',
		},
		{
			Header: 'Fecha de finalización',
			accessor: 'fechaFinal',
		},
	]
	if (experiencias) {
		return (
			<div>
				{experiencias.length > 0 ?
					<Table props={props} columns={columns} data={experiencias}
						formacionExperiencia={false} initialState={initialState} /> :
					<div className={classes.containerNoData}>
						<Typography variant="h2" color="textPrimary"> No se encontraron experiencias registradas </Typography>
					</div> }
			</div>
		)
	} else return null
}
const qs = require('qs');

const useSingleton = (callBack = () => { }) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}


const DatosCandidato = (props) => {
	const [candidato, setCandidato] = React.useState({
		nombres: '',
		apellidos: '',
		tipoDocumento: '',
		numeroDocumento: '',
		direccion: '',
		celular: '',
		telefonoFijo: '',
		ciudad: '',
		fechaNacimiento: '',
		genero: '',
		rh: '',
		madreCabezaHogar: '',
		correo: '',
		idCiudad: null,
		versionTerminosYCondiciones: '1.0.0',
		experiencias: [],
		formaciones: [],
	})
	const [redirect, setRedirect] = React.useState(false)
	const keyParam = qs.parse(props.location.search)['?key']
	let history = useHistory();
	const classes = useStyles();
	const initialState = async () => {
		await getAuthenticate()
		.then(response => response.data)
		.then(data => { 
			if(data === '' ) setRedirect(true);
		})
		.catch(err => err)
		const candidatoQuery = await getCandidato()
			.then(response => {
				response.data.formaciones = response.data.formaciones.map(formacion => {
					formacion.fechaCulminacion = dateFormat(formacion.fechaCulminacion);
					formacion.fechaExpedicionTarjetaProfesional = formacion.tarjetaProfesional ? dateFormat(formacion.fechaExpedicionTarjetaProfesional) : '---';
					return formacion;
				})
				response.data.experiencias = response.data.experiencias.map(experiencia => {
					experiencia.fechaInicio = dateFormat(experiencia.fechaInicio);
					experiencia.fechaFinal = dateFormat(experiencia.fechaFinal);
					return experiencia;
				})
				return response.data;
			})
			.catch(err => err)
		setCandidato(candidatoQuery)
	}
	useSingleton(async () => {
		await initialState()
	});
	function createData(name, description) {
		return { name, description };
	}
	React.useEffect(() => {
		initialState()
	  },[])

	const rows = [
		createData('Nombres', candidato.nombres),
		createData('Apellidos', candidato.apellidos),
		createData('Tipo de documento', candidato.tipoDocumento),
		createData('Número de documento', candidato.numeroDocumento),
		createData('Dirección', candidato.direccion),
		createData('Celular', candidato.celular),
		createData('Teléfono', candidato.telefonoFijo),
		createData('Ciudad', candidato.ciudad.nombre),
		createData('Fecha de Nacimiento', dateFormat(candidato.fechaNacimiento)),
		createData('Género', candidato.genero),
		createData('RH', candidato.rh),
		createData('Correo', candidato.correo),
	  ]

	const actualizarDatos =async()=>{
		let idPostulacion = ''
		if (qs.parse(window.location.search)['?key'] !== undefined) {
			idPostulacion = qs.parse(window.location.search)['?key']
		}
		await confirmarDatosPostulacion(idPostulacion)
      	.then((response) => {
		setRedirect(true)
        MySwal.fire(
          "Datos enviados",
          "Los datos fueron enviados correctamente.",
          "success"
        );
      })
      .catch((error) => {
        if (error.response && error.response.data.errorKey === "Postulacion Not Found") {
          MySwal.fire("Hubo un problema", "La postulación no existe", "error");
        }else if(error.response &&
			error.response.data.title === "The candidate has already confirmed the data"){
			MySwal.fire("Datos ya confirmados", "Ya confirmaste previamente los datos para esta postulación", "error");
			history.push("/user_page")
		} 
		else if (error.response && error.response.data.title === "URL no válida") {
          MySwal.fire("Hubo un problema", "Por favor válide la URL", "error");
        } else{
		  MySwal.fire("Ocurrió un error", "Ocurrió un error al procesar la solicitud, por favor intente más tarde", "error");
		}
      });
	}
	return (
		<Card className={classes.internalCard}>
			<Typography  className={classes.title} variant="h1" color="textPrimary"> Información del candidato</Typography>
			{redirect ? <Redirect to={{pathname:'/signin' , state:{ goBack: window.location.pathname}, search: window.location.search }} /> : null}
			{keyParam === undefined? <Alert severity="warning" className={classes.internalCard}> Por favor ingrese la url enviada al correo nuevamente </Alert>: null}
			<React.Fragment>
				<Card className={classes.root}>
				<Grid container className={classes.marginField} item xs={12} spacing={3}>
				<Grid item xs={8}>
					<Typography className={classes.title} variant="h1" color="textPrimary"> Información básica </Typography>
				</Grid>
				<Grid item xs={4} align="right">
					<Link to={{ pathname: '/actualizar_candidato', state: {actualizar:true, candidato:candidato}, search: window.location.search }}>
						<Button className={classes.title} 
						variant="contained" color="primary">
						Editar información</Button>
					</Link>
				</Grid>
				</Grid>
				<TableContainer component={Paper} className={classes.table}>
              		<MaUTable aria-label="simple table">
                		<TableBody>
                  		{rows.map((row) => (
                    		<TableRow key={row.name}>
                      		<TableCell component="th" scope="row" size="small" className={classes.tableCellTitle}>
                       		 	{row.name}:
                      		</TableCell>
                      		<TableCell align="left" size="small" className={classes.tableCell}>
                        		{row.description}
                      		</TableCell>
                    	</TableRow>
                  		))}
                		</TableBody>
	    	        </MaUTable>
    	        </TableContainer>
				</Card>
				<Card className={classes.marginField}>
					<Grid container className={classes.marginField} item xs={12} spacing={3}>
						<Grid item xs={8}>
							<Typography className={classes.title} variant="h1" color="textPrimary"> Formación </Typography>
						</Grid>
						<Grid item xs={4}>
							<Link to={{ pathname: '/crear_formacion', state: { crear: true }, search: window.location.search }}>
								<Button color="primary" className={classes.title} variant="contained">
									Añadir formación académica</Button>
							</Link>
						</Grid>
					</Grid>
					{FormacionesTable(props, candidato.formaciones, initialState)}
				</Card>
				<Card className={classes.marginField}>
					<Grid container className={classes.marginField} item xs={12} spacing={3}>
						<Grid item xs={8}>
							<Typography className={classes.title} variant="h1"
								color="textPrimary"> Experiencia laboral</Typography>
						</Grid>
						<Grid item xs={4}>
							<Link to={{ pathname: '/crear_experiencia', state: { crear: true }, search: window.location.search }}>
								<Button color="primary" className={classes.title} variant="contained">
								Añadir experiencia laboral</Button>
							</Link>
						</Grid>
					</Grid>
					{ExperienciasTable(props, candidato.experiencias, initialState)}
				</Card>
				<Grid item xs={12} align="center">
					<Button color="primary" className={classes.subtitleCard} variant="contained"
					onClick ={(e) => actualizarDatos(e,initialState) }>
					Confirmar datos </Button>

				</Grid>
			</React.Fragment>

		</Card>
	)
}

export default DatosCandidato

