import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Grid, Typography, FormControl, MenuItem, Select, Collapse, IconButton } from '@material-ui/core';
import { Redirect } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthWrapper from '../../../components/authComponents/AuthWrapper';
import { getDepartamentos, getDepartamentosId } from '../../../helpers/departamentos/departamentosHelper';
import { postCandidato } from '../../../helpers/candidato/candidatoHelper';
import { fileHelperText } from '../../../constants/AppConstants';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../utils/orderHelper';
const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
  },
  title: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '20px',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(3),
  },
  Errortypography: {
    marginInline: 15,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  centerItems: {
    width: '100%',
    alignItems: 'center',
  },
  marginField: {
    marginBottom: 15,
    marginTop: 4
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    width: "100%",
    height: "60px",
    paddingLeft: theme.spacing(3),
  },
  textFileRoot: {
    width: "100%",
    height: "15%",
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  tabla: {
    maxWidth: "60%",
    marginLeft: theme.spacing(3),
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

export default function Register() {

  const classes = useStyles();

  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 1);
  const fechaActual = fecha.toISOString().split('T')[0];
  const compCorreo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;       //Regex de correo
  const compNumero = /^\d+$/;                     //Regex de número
  const [open, setOpen] = useState(false)         //Alerta de espacios erróneos
  const [redirect, setRedirect] = useState(false)
  const [candidato, setCandidato] = useState({
    nombres: '',
    apellidos: '',
    tipoDocumento: '',
    numeroDocumento: '',
    direccion: '',
    celular: '',
    telefonoFijo: '',
    fechaNacimiento: '',
    genero: '',
    rh: '',
    madreCabezaHogar: '',
    correo: '',
    idCiudad: null,
    versionTerminosYCondiciones: '1.0.0'
  });
  const [ciudadList, setCiudadList] = useState([]);
  const [departamentoList, setDepartamentoList] = useState([]);
  const [fileDocumento, setFileDocumento] = useState('');
  const [error, setError] = useState({
    nombres: false,
    nombresHT: '',
    apellidos: false,
    apellidosHT: '',
    tipoDocumento: false,
    tipoDocumentoHT: '',
    numeroDocumento: false,
    numeroDocumentoHT: '',
    departamento: false,
    departamentoHT: '',
    ciudad: false,
    ciudadHT: '',
    fileDocumento: false,
    fileDocumentoHT: fileHelperText,
    direccion: false,
    direccionHT: '',
    celular: false,
    celularHT: '',
    telefonoFijo: false,
    telefonoFijoHT: '',
    fechaNacimiento: false,
    fechaNacimientoHT: '',
    correo: false,
    correoHT: '',
    genero: false,
    generoHT: '',
    tipoSangre: false,
    tipoSangreHT: '',
    madreCH: false,
    madreCHHT: '',
  })
  const mensajesError = {
    errorEmail: 'Ingrese un correo válido',
    errorNumero: 'Ingrese un número válido',
    errorVacio: 'Este campo es obligatorio',
    errorMinLength: 'Ingrese al menos 5 caracteres',
    mensajeFile: fileHelperText,
    errorFile: 'El archivo sobrepasa los 6 mb'
  }
  const MySwal = withReactContent(Swal);
  const sweetAlertsCandidato = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Candidato creado',
      text: 'Revisa tu email para activar tu cuenta y seguir el proceso'
    })
  }
  const sweetAlertsEmail = () => {
    MySwal.fire({
      icon: 'error',
      title: 'Este email ya está en uso'
    })
  }
  const sweetAlertsDocumento = () => {
    MySwal.fire({
      icon: 'error',
      title: 'El documento ya está en uso'
    })
  }

  useSingleton(async () => {
    const departamentos = await getDepartamentos()
      .then(response => {
        return sortDepartamentosAlphabetically(response.data)
      })
      .catch(err => err)
    setDepartamentoList(departamentos)
  });

  const handleChange = (event) => {
    setCandidato({
      ...candidato,
      [event.target.name]: event.target.value,
    });
    setError({
      ...error,
      [event.target.name]: false
    });
  };

  const handleDepartamento = async (e) => {
    if (e.target.value !== "") {
      const ciudades = await getDepartamentosId(e.target.value)
        .then((res) => {
          return sortCiudadesAlphabetically(res.data.ciudades, true);
        })
        .catch((err) => {
          console.log(err);
        });
      if (ciudades !== null) {
        setCiudadList(ciudades);
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();
    if (candidato.telefonoFijo === "") delete candidato.telefonoFijo
    formData.append('candidato', new Blob([JSON.stringify(candidato)], { type: "application/json" }));
    formData.append('file', fileDocumento);

    await postCandidato(formData)
      .then(function (response) {
        sweetAlertsCandidato()
        setRedirect(true)
      })
      .catch(function (error) {
        if (error.response.data.errorKey === 'emailexists') {
          sweetAlertsEmail()
        }
        if (error.response.data.detail === 'Document is already being used') {
          sweetAlertsDocumento()
        }
      });
  };

  return (
    <AuthWrapper variant="crearCandidato">
      <div className={classes.centerItems}>
        {redirect ? <Redirect to="/signin" /> : null}
        <form onSubmit={(e) => {
          e.preventDefault()
          if (candidato.nombres === '' && candidato.apellidos === '' && candidato.tipoDocumento === '' && candidato.numeroDocumento === '' &&
            candidato.direccion === '' && candidato.celular === '' && candidato.fechaNacimiento === '' &&
            candidato.genero === '' && candidato.rh === '' && candidato.madreCabezaHogar === '' && candidato.correo === '') {
            setError({
              ...error,
              nombres: true, nombresHT: mensajesError.errorVacio,
              apellidos: true, apellidosHT: mensajesError.errorVacio,
              tipoDocumento: true, tipoDocumentoHT: mensajesError.errorVacio,
              numeroDocumento: true, numeroDocumentoHT: mensajesError.errorVacio,
              direccion: true, direccionHT: mensajesError.errorVacio,
              celular: true, celularHT: mensajesError.errorVacio,
              fechaNacimiento: true, fechaNacimientoHT: mensajesError.errorVacio,
              genero: true, generoHT: mensajesError.errorVacio,
              madreCH: true, madreCHHT: mensajesError.errorVacio,
              correo: true, correoHT: mensajesError.errorVacio,
              departamento: true, departamentoHT: mensajesError.errorVacio,
              ciudad: true, ciudadHT: mensajesError.errorVacio,
              tipoSangre: true, tipoSangreHT: mensajesError.errorVacio,
              fileDocumento: true, fileDocumentoHT: mensajesError.errorVacio,
            })
            setOpen(true)
          } else {
            if (!error.nombres && !error.apellidos && !error.tipoDocumento &&
              !error.numeroDocumetno && !error.departamento && !error.ciudad && !error.fileDocumento &&
              !error.direccion && !error.celular && !error.telefonoFijo && !error.fechaNacimiento &&
              !error.correo && !error.genero && !error.tipoSangre && !error.madreCH) {
              setOpen(false)
              onSubmit(e)
            } else {
              setOpen(true)
            }
          }
        }}>
          <Typography variant='h1' color='textPrimary'>Registro</Typography>
          <Typography variant='body1' className={classes.subtitle}>Ingresa tu información básica</Typography>
          <Typography variant='body3' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
          <Grid className={classes.marginField} container item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Nombres<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='nombres'
                variant="outlined"
                className={classes.textFieldRoot}
                inputProps={{ maxLength: 25 }}
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, nombres: true, nombresHT: mensajesError.errorVacio });
                  } else setError({ ...error, nombres: false, nombresHT: '' });
                }}
                error={error.nombres}
                helperText={error.nombresHT}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Apellidos<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='apellidos'
                variant="outlined"
                className={classes.textFieldRoot}
                inputProps={{ maxLength: 25 }}
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, apellidos: true, apellidosHT: mensajesError.errorVacio });
                  } else setError({ ...error, apellidos: false, apellidosHT: '' });
                }}
                error={error.apellidos}
                helperText={error.apellidosHT}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Tipo de documento<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.tipoDocumento}
              >
                <Select
                  defaultValue=""
                  onChange={handleChange} name='tipoDocumento'
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, tipoDocumento: true, tipoDocumentoHT: mensajesError.errorVacio });
                    } else setError({ ...error, tipoDocumento: false, tipoDocumentoHT: '' });
                  }}
                >
                  <MenuItem value={"CC"}>Cedula de Ciudadania</MenuItem>
                  <MenuItem value={"CE"}>Cedula de Extranjeria</MenuItem>
                  <MenuItem value={"NIT"}>NIT</MenuItem>
                  <MenuItem value={"DE"}>Tipo de documento extranjero</MenuItem>
                  <MenuItem value={"PEP"}>Permiso especial de permanencia</MenuItem>
                  <MenuItem value={"PPT"}>Permiso por Protección Temporal</MenuItem>
                  {/* Para agregar más tipos de documentos */}
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.tipoDocumentoHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Número de documento<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='numeroDocumento'
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, numeroDocumento: true, numeroDocumentoHT: mensajesError.errorVacio });
                  } else if (!compNumero.test(candidato.numeroDocumento)) {
                    setError({ ...error, numeroDocumento: true, numeroDocumentoHT: mensajesError.errorNumero });
                  } else {
                    setError({ ...error, numeroDocumento: false, numeroDocumentoHT: '' });
                  }
                }}
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.numeroDocumento}
                helperText={error.numeroDocumentoHT}
                id="numeroDocumento"
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Adjunte su documento de identidad<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="file"
                defaultValue=""
                onChange={event => setFileDocumento(event.target.files[0])}
                onBlur={event => {
                  if (event.target.files[0] === undefined) {
                    setError({ ...error, fileDocumento: true, fileDocumentoHT: mensajesError.errorVacio });
                  } else if (event.target.files[0].size > 6291456) {
                    setError({ ...error, fileDocumento: true, fileDocumentoHT: mensajesError.errorFile });
                  } else {
                    setError({ ...error, fileDocumento: false, fileDocumentoHT: mensajesError.mensajeFile });
                  }
                }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textFileRoot}
                InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                error={error.fileDocumento}
                helperText={error.fileDocumentoHT}
                id="fileDocumento"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Departamento<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.departamento}
              >
                <Select
                  defaultValue=""
                  onChange={event => { handleDepartamento(event) }}
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, departamento: true, departamentoHT: mensajesError.errorVacio });
                    } else setError({ ...error, departamento: false, departamentoHT: '' });
                  }}
                >
                  <MenuItem value="" />
                  {departamentoList.length > 0 ?
                    departamentoList.map((departamento) => {
                      return (<MenuItem key={departamento.id} value={departamento.id}> {departamento.nombre} </MenuItem>)
                    })
                    : <Typography value="">No se encontraron departamentos</Typography>}
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.departamentoHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Ciudad<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.ciudad}
              >
                <Select
                  defaultValue=""
                  onChange={event => handleChange(event)} name='idCiudad'
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, ciudad: true, ciudadHT: mensajesError.errorVacio });
                    } else setError({ ...error, ciudad: false, ciudadHT: '' });
                  }}
                >
                  <MenuItem value="" />
                  {ciudadList.length > 0 ?
                    ciudadList.map((ciudad) => {
                      return (<MenuItem key={ciudad.id} value={ciudad.id}> {ciudad.nombre} </MenuItem>)
                    })
                    : <Typography value="">No se encontraron ciudades</Typography>}
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.ciudadHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Dirección<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='direccion'
                variant="outlined"
                className={classes.textFieldRoot}
                inputProps={{ maxLength: 80 }}
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, direccion: true, direccionHT: mensajesError.errorVacio });
                  } else if (event.target.value.length < 5) {
                    setError({ ...error, direccion: true, direccionHT: mensajesError.errorMinLength });
                  } else setError({ ...error, direccion: false, direccionHT: '' });
                }}
                error={error.direccion}
                helperText={error.direccionHT}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Celular<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='celular'
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, celular: true, celularHT: mensajesError.errorVacio });
                  } else if (event.target.value.length !== 10) {
                    setError({ ...error, celular: true, celularHT: 'Este campo debe tener 10 caracteres' });
                  } else if (!compNumero.test(candidato.celular)) {
                    setError({ ...error, celular: true, celularHT: mensajesError.errorNumero });
                  } else {
                    setError({ ...error, celular: false, celularHT: '' });
                  }
                }}
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.celular}
                helperText={error.celularHT}
                id="celular"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Teléfono</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue=""
                onChange={handleChange} name='telefonoFijo'
                onBlur={event => {
                  const empty = event.target.value !== ''
                  if (event.target.value.length !== 7 && empty) {
                    setError({ ...error, telefonoFijo: true, telefonoFijoHT: 'Este campo debe tener 7 caracteres' });
                  } else if (!compNumero.test(candidato.telefonoFijo) && empty) {
                    setError({ ...error, telefonoFijo: true, telefonoFijoHT: mensajesError.errorNumero });
                  } else {
                    setError({ ...error, telefonoFijo: false, telefonoFijoHT: '' });
                  }
                }}
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.telefonoFijo}
                helperText={error.telefonoFijoHT}
                id="telefonoFijo"
                inputProps={{ maxLength: 7 }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Correo<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                defaultValue=""
                onChange={handleChange} name='correo'
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, correo: true, correoHT: mensajesError.errorVacio });
                  } else if (!compCorreo.test(candidato.correo)) {
                    setError({ ...error, correo: true, correoHT: mensajesError.errorEmail });
                  } else {
                    setError({ ...error, correo: false, correoHT: '' });
                  }
                }}
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.correo}
                helperText={error.correoHT}
                id="correo"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Fecha de nacimiento<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="date"
                defaultValue=""
                onChange={handleChange} name='fechaNacimiento'
                variant="outlined"
                className={classes.textFieldRoot}
                InputProps={{ inputProps: { max: fechaActual } }}
                id="fechaNacimiento"
                onBlur={event => {
                  if (event.target.value === '') {
                    setError({ ...error, fechaNacimiento: true, fechaNacimientoHT: mensajesError.errorVacio });
                  } else setError({ ...error, fechaNacimiento: false, fechaNacimientoHT: '' });
                }}
                error={error.fechaNacimiento}
                helperText={error.fechaNacimientoHT}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Género<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.genero}
              >
                <Select
                  onChange={handleChange} name='genero'
                  defaultValue=""
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, genero: true, generoHT: mensajesError.errorVacio });
                    } else setError({ ...error, genero: false, generoHT: '' });
                  }}
                >
                  <MenuItem value="" />
                  <MenuItem value={"F"}>Mujer</MenuItem>
                  <MenuItem value={"M"}>Hombre</MenuItem>
                  <MenuItem value={"O"}>Otros</MenuItem>
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.generoHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Tipo de sangre<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.tipoSangre}
              >
                <Select
                  onChange={handleChange} name='rh'
                  defaultValue=""
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, tipoSangre: true, tipoSangreHT: mensajesError.errorVacio });
                    } else setError({ ...error, tipoSangre: false, tipoSangreHT: '' });
                  }}
                >
                  <MenuItem value="" />
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.tipoSangreHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.marginField} item xs={12} spacing={3}>
            <Grid item xs={12} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>¿Es madre cabeza de hogar?<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.textFieldRoot}
                error={error.madreCH}
              >
                <Select
                  onChange={handleChange} name='madreCabezaHogar'
                  defaultValue=""
                  onBlur={event => {
                    if (event.target.value === '') {
                      setError({ ...error, madreCH: true, madreCHHT: mensajesError.errorVacio });
                    } else setError({ ...error, madreCH: false, madreCHHT: '' });
                  }}
                >
                  <MenuItem value="" />
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                <Typography variant='caption' color='error' className={classes.Errortypography}>{error.madreCHHT}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Button variant="contained" color="primary" type="submit">
              Crear Candidato
            </Button>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                variant="filled"
                severity="error"
              >
                ¡Aún hay campos en rojo!
              </Alert>
            </Collapse>
          </Grid>
        </form>

      </div>
    </AuthWrapper>
  );
}