import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const getDepartamentos = () =>{
    const config = {
        method: 'get',
        url: BASE_URL + 'departamentos',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
   return axios(config)
}

export const getCiudades = () =>{
    const config = {
        method: 'get',
        url: BASE_URL + 'ciudades',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
   return axios(config)
}

export const getDepartamentosId = (id) =>{
    const config = {
        method: 'get',
        url: BASE_URL + `departamentos/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
   return axios(config)
}
