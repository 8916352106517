import axios from "axios";
import { BASE_URL } from "../baseUrl";
import { getToken } from "../../services/auth/jwt/getToken";
const token = getToken();
const contentType = "application/json";

export const getOfertasAvailable = async () => {
  const config = {
    method: "get",
    url: BASE_URL + "ofertas-disponibles",
    headers: {
      Authorization: token,
      "Content-Type": contentType,
    },
    withCredentials: false,
  };
  return await axios(config);
};
