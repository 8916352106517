import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import MainPage from './Pages/MainPage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Validate from './Auth/ValidateUserForm';
import ValidateCandidato from './Auth/ValidateCandidatoForm';
import UserPage from './Pages/UserPage';
import Register from './Auth/Register'
import EditProfile from './EditProfile'
import EditExperiencia from './EditProfile/EditarExperiencia'
import EditFormacion from './EditProfile/EditarFormacion'
import { useLocation } from 'react-router-dom';
import ActualizarDatos from './Pages/Candidato/ActualizarDatos';
import ActualizarCandidato from './Pages/Candidato/EditarCandidato';
import ActualizarExperiencia from './Pages/Candidato/EditarExperiencia';
import ActualizarFormacion from './Pages/Candidato/EditarFormacion';
import DocumentosContrato from './Pages/Contrato/DocumentosContrato';
import FormularioEjemplo from '../constants/Formularios'
import CuentaCobroGeneracion from '../routes/Pages/Contratado/CuentaCobro/index'
import Postulaciones from './Pages/Candidato/Postulaciones';

const Routes = () => {
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/main_page'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/postulacion/update" component={ActualizarDatos} />
        <Route path="/actualizar_candidato" component={ActualizarCandidato} />
        <Route path="/documentos_contrato" component={DocumentosContrato} />
        <Route path="/crear_experiencia" component={ActualizarExperiencia} />
        <Route path="/crear_formacion" component={ActualizarFormacion} />
        <Route path="/actualizar_experiencia_postulacion" component={ActualizarExperiencia} />
        <Route path="/actualizar_formacion_postulacion" component={ActualizarFormacion} />
        <Route path="/postulaciones" component={Postulaciones} />
        <Route path="/main_page" component={MainPage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/account/reset/finish" component={Validate} />
        <Route path="/account/activate/" component={ValidateCandidato} />
        <Route path="/account/profile" component={EditProfile} />
        <Route path="/actualizar_experiencia" component={EditExperiencia} />
        <Route path="/actualizar_formacion" component={EditFormacion} />
        <Route path="/forgot_password" component={ForgotPasswordPage} />
        <Route path="/user_page" component={UserPage} /> 
        <Route path="/form_ejemplo" component={FormularioEjemplo} />
        <Route path="/cuenta_cobro" component={CuentaCobroGeneracion} />

        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
