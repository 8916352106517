import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ContentLoader from '../../components/ContentLoader';
import AuthWrapper from '../../components/authComponents/AuthWrapper'
import CmtImage from '../../@coremat/CmtImage';
import { passwordStrength } from 'check-password-strength'
import { Redirect } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { resetPasswordFinish } from '../../helpers/account/resetPassword';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const ValidarUsuario = ({method, variant = 'default', wrapperVariant = 'default' }) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [strength, setStrength] = useState('');
  const [message, setMessage] = useState(' ');
  const [message2, setMessage2] = useState(' ');
  const[redirect, setRedirect] = useState(false)
  const classes = useStyles({ variant });
  const onSubmit = async() => {
    let key = 'F8ES7J0TNwfV3CcqxGVp'
    if(qs.parse(window.location.search)['?key'] !== undefined){
      key = qs.parse(window.location.search)['?key']
    }
    let data = JSON.stringify({key: key, newPassword: password})
    const MySwal = withReactContent(Swal);
    await resetPasswordFinish(data)
    .then(response => {
      if(response.status === 200){
        setRedirect(true)
      }
    })
    .catch(err => {
      console.log(err)
      MySwal.fire({
        icon: 'error',
        title: 'Ocurrió un error',
        text: 'Ocurrió un error al procesar la solicitud, por favor intente más tarde'
      })
    })
  };
  return (
    //La canditad y tipos de campos puede variar, esto es solo una aproximacion
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/grupois.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          {/* <CmtImage src={'/images/logo.png'} /> */}
        </Box>
        {redirect ? <Redirect push to={{ pathname:'/signin'} } /> : null}
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Crea una nueva contraseña
        </Typography>
        <form>
          {strength !== '' ?
            <Typography variant="h5" gutterBottom>
              Tu contraseña es
              {strength === 'Too weak' ? (' muy débil') :
                strength === 'Weak' ? (' débil') :
                  strength === 'Medium' ? (' medio') :
                    strength === 'Strong' ? (' fuerte') :
                      null}
            </Typography>
            : null
          }
          <Box mb={2}>
            <TextField
              type="password"
              label="Contraseña"
              fullWidth
              onChange={event => {
                setPassword(event.target.value);
                setStrength(passwordStrength(event.target.value).value);
              }}
              onBlur= {e => {
                if(e.target.value.length < 3 ) setMessage('La contraseña debe tener más de 3 caracteres'); else
                if(e.target.value.length > 100 ) setMessage('La contraseña debe tener menos de 100 caracteres');
                else setMessage('')
              }}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              required
            />
          </Box>
          {message !== ''?
            <Typography variant="h5" gutterBottom>
                {message}
            </Typography>
            : null
          }
          <Box mb={2}>
            <TextField
              type="password"
              label="Confirmar Contraseña"
              fullWidth
              onChange={event => { setPassword2(event.target.value) }}
              onBlur ={ e => { 
                if(e.target.value !== password) setMessage2('Las Contraseñas no coinciden');
                else setMessage2('') }}
              defaultValue={password2}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              required
            />
          </Box>
          {message2 !== ''?
            <Typography variant="h5" gutterBottom>
                {message2}
            </Typography>
            : null
          }
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button disabled={message !== '' || message2 !==''} onClick={onSubmit} variant="contained" color="primary">
                  Crear contraseña
            </Button>
          </Box>
        </form>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ValidarUsuario;
