
import { useDropzone } from 'react-dropzone';
import React, { useMemo, useCallback, useEffect } from 'react';
import { Box, List, Typography, Grid } from '@material-ui/core';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#000000',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ed0930',
    color: '#ed0930',
};

export const CustomDropzone = ({ classes, nombre = "archvivo", handleChange, hasError, name, formState ,setFormState }) => {

    const onDrop = useCallback(async file  => {
        if (file.length > 0) {
            setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  [name]: file[0],
                },
                touched: {
                  ...formState.touched,
                  [name]: true,
                },
              });
        } else {
            setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  [name]: file[0],
                },
                touched: {
                  ...formState.touched,
                  [name]: true,
                },
                errors:{
                    ...formState.errors,
                    [name]:true
                }
              });
        }
    },[name,formState,setFormState])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({ accept: 'application/pdf', maxSize: 1000000, maxFiles: 1, onDrop });

     
    useEffect(() => {
        if (formState.reset) {
            acceptedFiles.splice(0, acceptedFiles.length);
            setFormState({ ...formState, reset: false });
        }
    // eslint-disable-next-line
    }, [formState.reset]);

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
         ...(hasError(name) ? rejectStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        hasError,
        name,
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (

        <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
                <Typography variant="body1" className={classes.typography}>{nombre}<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
                <Box>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps({
                            handleChange: (e) => { onchange(e.target.files,name) }
                        })} />
                        <Typography>Arrastra y suelta o selecciona el archivo</Typography>
                        <em>(Solo documentos con extension *.pdf son aceptados)</em>
                    </div>
                    {hasError(name) ? <Typography className={classes.errorHelper} color="error" variant="caption">{"Este campo es requerido"}</Typography> : null}
                    <aside>
                        <Typography component="h4" variant="inherit">
                            Archivo
                        </Typography>
                        <List>{files}</List>
                    </aside>
                </Box>
            </Grid>
        </Grid>

    )

}

