import React, { useState, useRef } from 'react';
import { alpha, Button, Select, FormControl, Typography, Grid, MenuItem, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TypeText from '../../../components/Campos/TypeText'
import TypeForm from '../../../components/Campos/TypeForm'
import { getDepartamentos, getDepartamentosId } from '../../../helpers/departamentos/departamentosHelper';
import { patchtCandidato } from '../../../helpers/candidato/candidatoHelper';
import { IS_NOT_EMPTY, IS_NUMERIC, IS_VALID_EMAIL, LATEST_DATE } from '../../../constants/MensajeHelper'
import moment from 'moment';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../utils/orderHelper';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  marginField: {
    marginBottom: 15,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.23),
    },
    width: "100%",
    height: "15%",
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const fechaActual = moment().subtract(1, "days").format("YYYY-MM-DD");

const mensajeHelper = {
  nombres: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  apellidos: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  tipoDocumento: {
    presence: IS_NOT_EMPTY,
  },
  numeroDocumento: {
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,
  },
  fileDocumento: {
    presence: IS_NOT_EMPTY,
  },
  direccion: {
    presence: IS_NOT_EMPTY,
    length: { minimum: 5, maximum: 80, tooShort: "tiene que tener 5 caracteres o más", tooLong: "no puede sobrepasar los 80 caracteres" },
  },
  celular: {
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,
    length: { is: 10, message: "tiene que tener 10 dígitos" },
  },
  telefonoFijo: {
    length: { maximum: 7, message: "tiene que tener 7 dígitos" },
  },
  fechaNacimiento: {
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  genero: {
    presence: IS_NOT_EMPTY,
  },
  rh: {
    presence: IS_NOT_EMPTY,
  },
  madreCabezaHogar: {
    presence: IS_NOT_EMPTY,
  },
  correo: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 50, message: "no puede sobrepasar los 50 caracteres" },
    email: IS_VALID_EMAIL,
  },
  idCiudad: {
    presence: IS_NOT_EMPTY,
  },
};

const CustomTypeText = ({ titulo, id, formState, setFormState, classes, ...props }) => {
  return (
    <Grid container item xs={12} spacing={3} className={classes.marginField}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.textFieldRoot}>
        <TypeText id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          defaultValue={props?.defaultValue ? props.defaultValue : ""}
          type={props?.type ? props.type : ""}
          InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : {}}
          inputProps={props?.inputProps ? props.inputProps : {}}
          onInput={props?.onInput ? props.onInput : undefined}
          disabled={props?.disabled ? true : false}
        />
      </Grid>
    </Grid>
  )
}

const CustomTypeSelect = ({ titulo, id, value, formState, setFormState, datos, classes, ...props }) => {
  return (
    <Grid container item xs={12} spacing={3} className={classes.marginField}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeForm id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          defaultValue={`${value}`}
          disabled={props?.disabled ? true : false}
        >
          <MenuItem value={value} />
          {datos.map(({ value, text }) => {
            return (<MenuItem key={value} value={value}>{text}</MenuItem>)
          })}
        </TypeForm>
      </Grid>
    </Grid>
  )
}

const CustomTypeDepartamento = ({ handleDepartamento, departamentoList, classes, defaultValue }) => {
  return (
    <Grid container item xs={12} spacing={3} className={classes.marginField}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>Departamento<span className={classes.color}>*</span>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <FormControl variant="outlined" className={classes.textFieldRoot}>
          <Select defaultValue={defaultValue} onChange={event => { handleDepartamento(event) }}>
            <MenuItem value="" />
            {departamentoList.length > 0 ?
              departamentoList.map((departamento) => {
                return (<MenuItem key={departamento.id} value={departamento.id}> {departamento.nombre} </MenuItem>)
              })
              : <Typography value="">No se encontraron departamentos</Typography>}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

const CustomTypeCiudad = ({ formState, setFormState, ciudadList, classes, defaultValue }) => {
  return (
    <Grid container item xs={12} spacing={3} className={classes.marginField}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>Ciudad<span className={classes.color}>*</span>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeForm id="idCiudad" formState={formState} setFormState={setFormState} helperText={mensajeHelper} defaultValue={defaultValue}>
          <MenuItem value="" />
          {ciudadList.length > 0 ?
            ciudadList.map((ciudad) => {
              return (<MenuItem key={ciudad.id} value={ciudad.id}> {ciudad.nombre} </MenuItem>)
            })
            : <Typography value="">No se encontraron ciudades</Typography>}
        </TypeForm>
      </Grid>
    </Grid>
  )
}

function IsNotEmpty(id) {
  const isEmpty = mensajeHelper[id.id]?.presence?.message;
  const style = { color: 'red' };
  if (isEmpty === "no debe estar vacío") {
    return <span style={style}>*</span>;
  }
  return <></>;
}

const documentType = [
  { value: "CC", text: "Cédula de ciudadanía" },
  { value: "CE", text: "Cédula de extranjería" },
  { value: "NIT", text: "NIT" },
  { value: "DE", text: "Tipo de documento extranjero" },
  { value: "PEP", text: "Permiso especial de permanencia" },
  { value: "PPT", text: "Permiso por Protección Temporal" },
  
];
const gender = [
  { value: "F", text: "Mujer" },
  { value: "M", text: "Hombre" },
  { value: "O", text: "Otros" },
]
const cabezaHogar = [
  { value: true, text: "Si" },
  { value: false, text: "No" },
]
const tipoRh = [
  { value: "O+", text: "O+" },
  { value: "O-", text: "O-" },
  { value: "A+", text: "A+" },
  { value: "A-", text: "A-" },
  { value: "B+", text: "B+" },
  { value: "B-", text: "B-" },
  { value: "AB+", text: "AB+" },
  { value: "AB-", text: "AB-" },
]

const MySwal = withReactContent(Swal);

const EditarCandidato = ({ variant = 'default', setInfo, candidatoGeneral }) => {

  const classes = useStyles({ variant });
  const [ciudadList, setCiudadList] = useState([]);
  const [departamentoList, setDepartamentoList] = useState([]);

  function onInputTel(e) {
    if (!isNaN(e.target.value)) {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
    } else {
      e.target.value = ""
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      nombres: candidatoGeneral.nombres,
      apellidos: candidatoGeneral.apellidos,
      tipoDocumento: candidatoGeneral.tipoDocumento,
      numeroDocumento: candidatoGeneral.numeroDocumento,
      fileDocumento: '',
      direccion: candidatoGeneral.direccion,
      celular: candidatoGeneral.celular,
      telefonoFijo: candidatoGeneral.telefonoFijo,
      ciudad: candidatoGeneral.ciudad,
      departamento: candidatoGeneral.ciudad.departamento,
      fechaNacimiento: candidatoGeneral.fechaNacimiento,
      genero: candidatoGeneral.genero,
      rh: candidatoGeneral.rh,
      madreCabezaHogar: candidatoGeneral.madreCabezaHogar,
      correo: candidatoGeneral.correo,
      idCiudad: candidatoGeneral.ciudad.id,
      versionTerminosYCondiciones: candidatoGeneral.versionTerminosYCondiciones
    },
    touched: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
    },
    errors: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
    },
  })

  useSingleton(async () => {
    const departamentos = await getDepartamentos()
      .then(response => {
        return sortDepartamentosAlphabetically(response.data)
      })
      .catch(err => err)
    setDepartamentoList(departamentos)
    const ciudades = await getDepartamentosId(formState.values.departamento.id)
      .then(response => response.data.ciudades)
      .catch(err => err)
    setCiudadList(ciudades)
  });

  const handleDepartamento = async (e) => {
    if (e.target.value !== "") {
      const ciudades = await getDepartamentosId(e.target.value)
        .then((res) => {
          return sortCiudadesAlphabetically(res.data.ciudades, true);
        })
        .catch((err) => {
          console.log(err);
        });
      if (ciudades !== null) {
        setCiudadList(ciudades);
      }
    }
  }

  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Candidato actualizado'
    })
  }

  const validateDocument = () => {
    if (formState.values.fileDocumento === "") {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          fileDocumento: "",
        },
        touched: {
          ...formState.touched,
          fileDocumento: true,
        },
        errors: {
          ...formState.errors,
          fileDocumento: true,
        },
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    validateDocument();
    console.log(formState)
    if (!formState.errors.fileDocumento && formState.isValid) {
      const formData = new FormData();
      let candidatoBody = formState.values
      if (formState.values.numeroDocumento === candidatoGeneral.numeroDocumento) {
        delete candidatoBody.numeroDocumento
      }
      formData.append('file', formState.values.fileDocumento)
      if (formState.values.correo !== candidatoGeneral.correo) {
        formData.append('candidato', new Blob([JSON.stringify(candidatoBody)], { type: "application/json" }));
        MySwal.fire({
          title: 'Estas seguro?',
          text: "Si cambias tu correo tendrás que volver a iniciar sesión!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then(async result => {
          if (result.value) {
            await patchtCandidato(formData)
              .then(function (response) {
                sweetAlerts()
                setInfo(1)
              })
              .catch(function (response) {
                console.log(response);
              });
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            MySwal.fire('Cancelado', '', 'error');
          }
        });

      } else {
        delete formState.values.correo
        formData.append('candidato', new Blob([JSON.stringify(candidatoBody)], { type: "application/json" }));
        await patchtCandidato(formData)
          .then(function (response) {
            sweetAlerts()
            setInfo(1)
          })
          .catch(function (response) {
            console.log(response);
          });
      }
    };
  }

  return (
    <CardContent>
      <Typography variant='h1' className={classes.subtitle}>Editar información</Typography>
      <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
      <form noValidate autoComplete='off' onSubmit={onSubmit}>
        <CustomTypeText titulo="Nombres" id="nombres" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.nombres} />
        <CustomTypeText titulo="Apellidos" id="apellidos" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.apellidos} />
        <CustomTypeSelect disabled titulo="Tipo de documento" id="tipoDocumento" formState={formState} setFormState={setFormState} datos={documentType} classes={classes} value={formState.values.tipoDocumento} />
        <CustomTypeText disabled titulo="Número de documento" id="numeroDocumento" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.numeroDocumento} />
        <CustomTypeText titulo="Adjunte su documento de identidad" id="fileDocumento" formState={formState} setFormState={setFormState} classes={classes} type="file" InputLabelProps={{ shrink: true }} inputProps={{ accept: "image/jpeg,application/pdf" }} />
        <CustomTypeDepartamento handleDepartamento={handleDepartamento} departamentoList={departamentoList} classes={classes} defaultValue={formState.values.departamento.id} />
        <CustomTypeCiudad formState={formState} setFormState={setFormState} ciudadList={ciudadList} classes={classes} defaultValue={formState.values.idCiudad} />
        <CustomTypeText titulo="Dirección" id="direccion" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.direccion} />
        <CustomTypeText titulo="Celular" id="celular" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.celular} />
        <CustomTypeText titulo="Teléfono" id="telefonoFijo" formState={formState} setFormState={setFormState} classes={classes} type="number"
          onInput={event => onInputTel(event)} defaultValue={formState.values.telefonoFijo} />
        <CustomTypeText titulo="Correo" id="correo" formState={formState} setFormState={setFormState} classes={classes} defaultValue={formState.values.correo} />
        <CustomTypeText titulo="Fecha de nacimiento" id="fechaNacimiento" formState={formState} setFormState={setFormState} type="date" classes={classes} inputProps={{ max: fechaActual }} defaultValue={formState.values.fechaNacimiento} />
        <CustomTypeSelect titulo="Género" id="genero" formState={formState} setFormState={setFormState} datos={gender} classes={classes} value={formState.values.genero} />
        <CustomTypeSelect titulo="Tipo de sangre" id="rh" formState={formState} setFormState={setFormState} datos={tipoRh} classes={classes} value={formState.values.rh} />
        <CustomTypeSelect titulo="¿Es madre cabeza de hogar?" id="madreCabezaHogar" formState={formState} setFormState={setFormState} datos={cabezaHogar} classes={classes} value={formState.values.madreCabezaHogar} />
        {formState.values.telefonoFijo === null && formState.values.fileDocumento !== '' ? formState.isValid = true : null}
        <Grid container item xs={12} spacing={3} >
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="secondary" onClick={event => setInfo(1)}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="primary" type="submit" >
              Editar candidato
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  );
};

export default EditarCandidato;