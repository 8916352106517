import React from 'react';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../@coremat/CmtImage';

const FooterLogo = ({ ...props }) => {
  const logoUrl = '/images/ts.png';

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/">
        <CmtImage src={logoUrl} alt="logo" />
      </NavLink>
    </Box>
  );
};

export default FooterLogo;
