import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Grid, Collapse, IconButton, TextField, Card } from '@material-ui/core';
import { alpha, makeStyles, lighten } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CustomDropzone } from './Dropzone';
import TypeText from '../../../../components/Campos/TypeText';
import NumberFormat from "react-number-format";
import validate from 'validate.js';
import { Redirect } from 'react-router';
import { IS_NOT_EMPTY } from '../../../../constants/MensajeHelper';
import { postCuentaCobro } from '../../../../helpers/Contratado/ContratadoHelper';
import { contratoIdByUser, validatePreempleadoContratado } from '../../../../helpers/preempleado/preempleadoHelper';
import { getContratoById } from '../../../../helpers/contrato/contratoHelper';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "100%",
        minWidth: "100%",
        margin: '0 auto',
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    color: {
        color: 'red'
    },
    subtitle: {
        marginLeft: theme.spacing(3),
        marginBottom: '5px',
        marginTop: '20px'
    },
    centerItem: {
        justifyContent: "center"
    },
    subtitleObligatorio: {
        marginLeft: theme.spacing(3),
        marginBottom: '40px',
        marginTop: '5px'
    },
    typography: {
        paddingLeft: theme.spacing(8),
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        marginTop: '30px',
    },
    textFieldRoot: {
        width: "50%",
        height: "15%",
    },
    errorHelper: {
        marginLeft: '14px',
        marginRight: '14px'
    },
    textFieldFullWidth: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: alpha(theme.palette.common.dark, 0.12),
		},

	},
    fullWidth: {
        width: '100%',
        alignContent: 'left'
    },
}));

const MySwal = withReactContent(Swal);

const mensajeHelper = {
    mesCobro: {
        presence: IS_NOT_EMPTY,
    },
    valorCobro: {
        presence: IS_NOT_EMPTY,
        length: { maximum: 20, message: "no pude sobrepasar los 20 caracteres" },
    },
    concepto: {
        presence: IS_NOT_EMPTY,
        length: { maximum: 250, message: "no puede sobrepasar los 250 caracteres" }
    },
    evidenciaObligacion: {
        presence: IS_NOT_EMPTY,
    },
    planillaSeguridadSocial: {
        presence: IS_NOT_EMPTY,
    },
    mesPlanillaSeguridadSocial: {
        presence: IS_NOT_EMPTY,
    },
    pension: {
        presence: IS_NOT_EMPTY,
        length: { maximum: 20, message: "no pude sobrepasar los 20 caracteres" },
    },
    salud: {
        presence: IS_NOT_EMPTY,
        length: { maximum: 20, message: "no pude sobrepasar los 20 caracteres" },
    },
    arl: {
        presence: IS_NOT_EMPTY,
        length: { maximum: 20, message: "no pude sobrepasar los 20 caracteres" },
    }
}


const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const formInit = {
    isValid: false,
    values: {
        mesCobro: "",
        valorCobro: "",
        concepto: "",
        obligacion: "",
        evidenciaObligacion: "",
        planillaSeguridadSocial: "",
        mesPlanillaSeguridadSocial: "",
        pension: "",
        salud: "",
        arl: "",
    },
    touched: {
        mesCobro: false,
        valorCobro: false,
        concepto: false,
        evidenciaObligacion: false,
        planillaSeguridadSocial: false,
        mesPlanillaSeguridadSocial: false,
        pension: false,
        salud: false,
        arl: false,
    },
    errors: {
        mesCobro: false,
        valorCobro: false,
        concepto: false,
        evidenciaObligacion: false,
        planillaSeguridadSocial: false,
        mesPlanillaSeguridadSocial: false,
        pension: false,
        salud: false,
        arl: false,
    },
    reset: false,
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onValueChange, inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={onValueChange}
            thousandSeparator={'.'}
            decimalSeparator={','}
        />
    );
  });

const CuentaCobroGeneracion = ({ variant = 'default' }) => {
    const [open, setOpen] = useState(false);
    const [contrato, setContrato] = useState(null);
    const [obligacion, setObligacion] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const classes = useStyles({ variant });
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 1);
    const fechaActual = fecha.toISOString().split('T')[0];
    const idUser = JSON.parse(sessionStorage.getItem('id'));
    const [formState, setFormState] = useState(formInit);
    const [allowSendForm, setAllowSendForm] = useState(false);
    const [reset, setReset] = useState(false);
    const initialState = async () => {
        validatePreempleadoContratado({ idUser: idUser })
          .then((response) => response.data)
          .then((data) => {if (!data) setRedirect(true)});
        const idContrato = await contratoIdByUser({idUser: JSON.parse(sessionStorage.getItem("id"))})
          .then((response) => {
            setContrato(response.data);
            return response.data;
          })
          .catch((err) => err);
        await getContratoById(idContrato).then((res) => {
          setObligacion(res.data.perfil.descripcionObligacion)
        });
      };

    useSingleton(async () => {
        await initialState();
    });

    const hasError = (field) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(formState.values, mensajeHelper);
        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    useEffect(()=>{
        if(formState.isValid) setAllowSendForm(true)
        else setAllowSendForm(false);
    },[formState.isValid])

    useEffect(()=>{
        if(reset){
            setFormState({...formInit, reset:true})
            setReset(false)
        }
    },[reset])

    const handleChange = (files, name) => {
        setFormState({
            ...formState,
            values: {...formState.values, [name]: files[0]},
            touched: {...formState.touched, [name]: true}
        });
    };

    const cuentaCobro = {
        mesCobro: formState.values.mesCobro,
        valorCobro: formState.values.valorCobro,
        obligacionesMes: obligacion,
        concepto: formState.values.concepto,
        mesPlanillaSeguridadSocial: formState.values.mesPlanillaSeguridadSocial,
        pension: formState.values.pension,
        salud: formState.values.salud,
        arl: formState.values.arl,
        idContrato: contrato,
    }

    useSingleton(async () => {
        await contratoIdByUser({ idUser: JSON.parse(sessionStorage.getItem('id')) })
            .then(response => response.data)
            .then((data) => {
                setContrato(data)
            })
            .catch(err => err)
    })

    const sweetAlerts = () => {
        MySwal.fire({
            icon: 'success',
            title: 'Cuenta de cobro creada con exito por un valor de $' + cuentaCobro.valorCobro,
        });
    }
    const sweetAlertsError = () => {
        MySwal.fire({
            icon: 'error',
            title: 'Ocurrio un error',
            text: 'Ocurrió un error al procesar la solicitud, por favor intente más tarde'
        });
    }
    const cleanForm = () => {
        document.getElementById("formCuentasCobro").reset()
        setReset(true)
    }
    const onSubmit = async (e) => {
        setAllowSendForm(false)
        e.preventDefault();
        const formData = new FormData();
        const cuenta = Object.assign({}, cuentaCobro, {
          valorCobro: Number(cuentaCobro.valorCobro.replaceAll(".", "")),
          pension: Number(cuentaCobro.pension.replaceAll(".", "")),
          salud: Number(cuentaCobro.salud.replaceAll(".", "")),
          arl: Number(cuentaCobro.arl.replaceAll(".", "")),
        });
        formData.append('cuentaCobro', new Blob([JSON.stringify(cuenta)], { type: "application/json" }));
        formData.append('evidenciaObligacion', formState.values.evidenciaObligacion);
        formData.append('planillaSeguridadSocial', formState.values.planillaSeguridadSocial);
        await postCuentaCobro(formData)
          .then(function (response) {
            cleanForm();
            sweetAlerts();
          })
          .catch(function (response) {
            console.log(response)
            sweetAlertsError();
            setAllowSendForm(true);
          });
    }
    return (
        <Card className={classes.root}>
            <form noValidate onSubmit={onSubmit} autoComplete="off" id="formCuentasCobro">
            {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
            <Typography variant='h1' className={classes.subtitle}>Crear cuenta de cobro</Typography>
            <Typography variant='body2' className={classes.subtitleObligatorio}>Ingresa los datos y documentos equivalentes relacionados con tu cuenta de cobro por prestación
                de servicios</Typography>
            <Typography variant='body4' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography> 
              
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Mes a cobrar<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="mesCobro" formState={formState} setFormState={setFormState} helperText={mensajeHelper} type="month" inputProps={{ max: fechaActual }} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Valor a cobrar<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="valorCobro" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ inputComponent: NumberFormatCustom}} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Concepto<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="concepto" formState={formState} setFormState={setFormState} helperText={mensajeHelper} multiline inputProps={{ maxLength: 251 }} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Obligación<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField disabled={true} type="text" value={obligacion || ""} margin="normal" multiline fullWidth={true} variant="outlined" className={classes.textFieldFullWidth}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <CustomDropzone nombre="Documento Equivalente e informe de actividades" classes={classes} hasError={hasError} handleChange={handleChange} name={'evidenciaObligacion'}
                formState={formState} setFormState={setFormState}/> 
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <CustomDropzone nombre="Planilla de seguridad social" classes={classes} hasError={hasError} handleChange={handleChange} name={'planillaSeguridadSocial'}
                formState={formState} setFormState={setFormState} />
                </Grid>
            </Grid>
            
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Mes planilla seguridad social<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="mesPlanillaSeguridadSocial" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ max: fechaActual }} type="month" fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Valor a pagar pensión<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="pension" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ inputComponent: NumberFormatCustom }} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Valor a pagar salud<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="salud" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ inputComponent: NumberFormatCustom }} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                    <Typography variant='body1' className={classes.typography}>Valor a pagar ARL<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={7}>
                    <TypeText id="arl" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ inputComponent: NumberFormatCustom }} fullWidth />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3} className={classes.button}>
                <Button variant="contained" color="primary" type="submit" disabled={!allowSendForm}>
                    Crear cuenta de cobro
                </Button>
            </Grid>
            <Grid container item xs={12} spacing={3} className={classes.button}>
                <Collapse in={open}>
                    <Alert action={<IconButton arial-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
                                    <CloseIcon fontSize="inherit" />
                                   </IconButton>}
                        variant="filled" severity="error">
                        ¡Aún hay campos en rojo!
                    </Alert>
                </Collapse>
            </Grid>
            </form>
        </Card>
    );
};
export default CuentaCobroGeneracion;
