import React from 'react';
import { PostAdd } from '@material-ui/icons';
import { AssignmentTurnedIn } from '@material-ui/icons';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HomeIcon from '@material-ui/icons/Home';

const roles = JSON.parse(sessionStorage.getItem('authorities'))
const CUENTA_COBRO = '/cuenta_cobro';
const MES_CUENTA_COBRO = 'Cuenta de cobro';
const contratado = JSON.parse(sessionStorage.getItem('validacion'));

let sidebarNav;


if (!roles) {
  sidebarNav = [
    {
      name: 'Menu Principal',
      type: 'section',
      children: [
        {
          name: 'Página Principal',
          type: 'item',
          icon: <HomeIcon />,
          link: '/',
        },
        {
          name: 'Iniciar sesión',
          type: 'item',
          icon: <AssignmentIndSharpIcon />,
          link: '/signin',
        },
        {
          name: 'Registrarse',
          type: 'item',
          icon: <PersonAddIcon />,
          link: '/signup',
        },
      ],
    },
  ]
} else if (contratado === true && roles.some(rol => rol === "ROLE_USER")) {
  sidebarNav = [
    {
      name: 'Menú principal',
      type: 'section',
      children: [
        {
          name: 'Página Principal',
          type: 'item',
          icon: <HomeIcon />,
          link: '/',
        },{
          name: 'Ver postulaciones',
          type: 'item',
          icon: <AssignmentTurnedIn />,
          link: '/postulaciones',
        },
        {
          name: 'Editar perfil',
          type: 'item',
          icon: <EditIcon />,
          link: '/account/profile',
        },
        {
          name: MES_CUENTA_COBRO,
          type: 'item',
          icon: <AccountBalanceIcon />,
          link: CUENTA_COBRO,
        },
      ],
    },

  ]
} else if (roles.some(rol => rol === "ROLE_USER")) {
  sidebarNav = [
    {
      name: 'Menu Principal',
      type: 'section',
      children: [
        {
          name: 'Página Principal',
          type: 'item',
          icon: <HomeIcon />,
          link: '/',
        },{
          name: 'Ver postulaciones',
          type: 'item',
          icon: <AssignmentTurnedIn />,
          link: '/postulaciones',
        },
        {
          name: 'Editar perfil',
          type: 'item',
          icon: <CreateSharpIcon />,
          link: '/account/profile',
        },
      ],
    },
  ]
}

export const sidebarNavs = sidebarNav;

export const horizontalDefaultNavs = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Página Principal',
        type: 'item',
        icon: <PostAdd />,
        link: '/',
      },
      {
        name: 'Iniciar sesión',
        type: 'item',
        icon: <PostAdd />,
        link: '/signin',
      },
      {
        name: 'Registrarse',
        type: 'item',
        icon: <PostAdd />,
        link: '/signup',
      },
      {
        name: MES_CUENTA_COBRO,
        type: 'item',
        icon: <PostAdd />,
        link: CUENTA_COBRO
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Página Principal',
        type: 'item',
        icon: <PostAdd />,
        link: '/',
      },
      {
        name: 'Iniciar sesión',
        type: 'item',
        icon: <PostAdd />,
        link: '/signin',
      },
      {
        name: 'Registrarse',
        type: 'item',
        icon: <PostAdd />,
        link: '/signup',
      },
      {
        name: MES_CUENTA_COBRO,
        type: 'item',
        icon: <PostAdd />,
        link: CUENTA_COBRO
      },
    ],
  },
];