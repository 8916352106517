import React, { useState, useRef } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Button, Grid, Typography, Paper, TableContainer, Input, Select, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTable, useRowSelect, usePagination } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditarCandidato from './EditarCandidato';
import CrearFormacion from './IngresarFormacion';
import CrearExperiencia from './IngresarExperiencia';
import AuthWrapper from '../../components/authComponents/AuthWrapper';
import { Redirect } from 'react-router'
import { deleteEditProfile } from '../../helpers/editProfile/editProfileHelper';
import { getCandidato } from '../../helpers/candidato/candidatoHelper';
import { dateFormat } from "../../constants/AppConstants";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    marginBottom: 15,
  },
  title: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '20px',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  Errortypography: {
    marginInline: 15,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  centerItems: {
    width: '100%',
    alignItems: 'center',
  },
  marginField: {
    marginBottom: 15,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    width: "60%",
    height: "60px",
  },
  textFileRoot: {
    width: "80%",
    height: "15%",
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  table: {
    maxWidth: "80%",
    margin: "0 auto",
    marginBottom: 15,
  },
  tableCellTitle:{
    fontWeight:"bold"
  },
  containerNoData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
  }

}));

const EditLink = (row, formacionExperiencia) => {
  let state = {}
  let path = ''
  if (formacionExperiencia) {
    path = '/actualizar_formacion'
    state = { actualizar: true, formacion: row.original }
  }
  else {
    path = '/actualizar_experiencia'
    state = { actualizar: true, experiencia: row.original }
  }
  return (
    <Link color="primary" to={{ pathname: path, state: state }}>
      <CreateIcon />
    </Link>
  )
}
const DeleteLink = (row, formacionExperiencia, initialState) => {
  const Borrar = async (id, route, initialState) => {
    MySwal.fire({
      title: 'Estas seguro?',
      text: "No podrás revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(async result => {
      if (result.value) {
        await deleteEditProfile(route,id)
          .then(response => {
            initialState()
            MySwal.fire('Eliminado', 'El registro fue eliminado.', 'success');
          })
          .catch(err => err)
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        MySwal.fire('Cancelado', '', 'error');
      }
    });

  }
  let route;
  if (formacionExperiencia) route = 'formacions/';
  else route = 'experiencias/'
  return (
    <Button color="primary" onClick={() => Borrar(row.original.id, route, initialState)}>
      <DeleteIcon />
    </Button>
  )
}
function Table({ props, columns, data, formacionExperiencia, initialState }) {
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 }
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columnsHooked => [
        ...columnsHooked,
        {
          id: 'edit',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {EditLink(row, formacionExperiencia)}
            </div>
          ),
        },
        {
          id: 'delete',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {DeleteLink(row, formacionExperiencia, initialState)}
            </div>
          ),
        },

      ])
    }
  )

  return (
    <Grid container>
      <div style={{ width: '100%', 'overflow': 'auto' }}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell className={classes.cell} {...column.getHeaderProps()}>
                    <strong>{column.render('Header')}</strong>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <TableCell className={classes.cell} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
      </div>
      <div className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        <span>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Ir a la página:{' '}
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(pageNumber)
            }}
            style={{ width: "50px", height:" 20px" }}
          />
        </span>{' '}
        <Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10, 15, 20, 30].map(pageSizeMaper => (
            <option key={pageSizeMaper} value={pageSizeMaper}>
              Mostrar {pageSizeMaper}
            </option>
          ))}
        </Select>
      </div>
    </Grid>
  )
}
const FormacionesTable = ({ props, formaciones, initialState }) => {
  const columns = [
    {
      Header: 'Nombre del programa',
      accessor: 'nombre',
    },
    {
      Header: "Tipo de programa",
      accessor: "tipo",
    },
    {
      Header: 'Centro educativo',
      accessor: 'centroEducativo',
    },
    {
      Header: 'Fecha de culminación',
      accessor: 'fechaCulminacion',
    },
    {
      Header: 'Fecha expedición tarjeta profesional',
      accessor: 'fechaExpedicionTarjetaProfesional',
    },
  ]
  if (formaciones !== undefined && formaciones.length > 0) {
    return (
      <div>
        <Table props={props} columns={columns} data={formaciones}
          formacionExperiencia={true} initialState={initialState} />
      </div>
    )
  }
  else {
    return null
  }
}

const ExperienciasTable = ({ props, experiencias, initialState }) => {
  const columns = [
    {
      Header: 'Empresa',
      accessor: 'empresa',
    },
    {
      Header: 'Cargo',
      accessor: 'cargo',
    },
    {
      Header: 'Fecha de inicio',
      accessor: 'fechaInicio',
    },
    {
      Header: 'Fecha de finalización',
      accessor: 'fechaFinal',
    },
  ]
  if (experiencias !== undefined && experiencias.length > 0) {
    return (
      <div>
        <Table props={props} columns={columns} data={experiencias}
          formacionExperiencia={false} initialState={initialState} />
      </div>
    )
  } else return null
}


const MySwal = withReactContent(Swal);

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

export default function Register(props) {

  const classes = useStyles();

  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 1);
  const [info, setInfo] = useState(1)         //Cambiar a Formación y Experiencia
  const [idCandidato, setIdCandidato] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [candidato, setCandidato] = useState({
    nombres: '',
    apellidos: '',
    tipoDocumento: '',
    numeroDocumento: '',
    direccion: '',
    celular: '',
    telefonoFijo: '',
    ciudad: '',
    fechaNacimiento: '',
    genero: '',
    rh: '',
    madreCabezaHogar: '',
    correo: '',
    idCiudad: null,
    versionTerminosYCondiciones: '1.0.0',
    experiencias: [],
    formaciones: [],
  });
  const initialState = async () => {
    const candidato = await getCandidato()
      .then((response) => {
        response.data.formaciones = response.data.formaciones.map(formacion => {
          formacion.fechaCulminacion = dateFormat(formacion.fechaCulminacion)
          formacion.fechaExpedicionTarjetaProfesional = formacion.tarjetaProfesional ? dateFormat(formacion.fechaExpedicionTarjetaProfesional) : '---';
          return formacion
        })
        response.data.experiencias = response.data.experiencias.map(experiencia => {
          experiencia.fechaInicio = dateFormat(experiencia.fechaInicio)
          experiencia.fechaFinal = dateFormat(experiencia.fechaFinal)
          return experiencia
        })
        return response.data
      })
      .catch(err => {
        console.log(err)
        setRedirect(true)
        return {id:''}
      })
    setCandidato(candidato)
    setIdCandidato(candidato.id)
  }
  useSingleton(async () => {
    await initialState()
  });

  function createData(name, description) {
    return { name, description };
  }
  const rows = [
    createData('Nombres', candidato.nombres),
    createData('Apellidos', candidato.apellidos),
    createData('Tipo de documento', candidato.tipoDocumento),
    createData('Número de documento', candidato.numeroDocumento),
    createData('Dirección', candidato.direccion),
    createData('Celular', candidato.celular),
    createData('Teléfono', candidato.telefonoFijo),
    createData('Ciudad', candidato.ciudad.nombre),
    createData('Fecha de Nacimiento', dateFormat(candidato.fechaNacimiento)),
    createData('Género', candidato.genero),
    createData('RH', candidato.rh),
    createData('Correo', candidato.correo),
  ]
  const sweetAlertsProceso = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Informacion actualizada'
    })
  }

  React.useEffect(() => {
    initialState()
  },[info])
  
  const renderExperiencias=()=>{
    if (candidato.experiencias.length > 0) {
      return <ExperienciasTable props={props} experiencias={candidato.experiencias} initialState={initialState} />
    } else {
      return(
      <div className={classes.containerNoData}>
        <Typography variant="h4" color="textPrimary"> No se encontraron experiencias registradas </Typography>
      </div>)
    }
  }

  const renderFormaciones=()=>{
    if (candidato.formaciones.length > 0 ){
     return <FormacionesTable props={props} formaciones={candidato.formaciones} initialState={initialState} />
    }else{
      return (
      <div className={classes.containerNoData}>
        <Typography variant="h4" color="textPrimary"> No se encontraron formaciones registradas </Typography>
      </div>)
    }
  }

  return (
    <AuthWrapper variant="crearCandidato">
      {redirect? <Redirect to={{ pathname:"/signin" ,state:{goBack:'/account/profile'} }}/> : null }
      {info === 1
        ?
        <div className={classes.centerItems}>
          <React.Fragment>
            <Typography variant='h1' color="textPrimary" className={classes.title}>Información del candidato</Typography>
            <Card className={classes.root}>
            <Grid container className={classes.marginField} item xs={12} spacing={3}>
              <Grid item xs={8}>
                <Typography variant='h3' color="textPrimary" className={classes.title}>Información básica</Typography>
              </Grid>
              <Grid item xs={4}>
                <Button type="button" variant="contained" color="primary" size="small" className={classes.title} onClick={event => setInfo(2)}>
                  Editar información
                </Button>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.table}>
              <MaUTable aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row" size="small" className={classes.tableCellTitle}>
                        {row.name}:
                      </TableCell>
                      <TableCell align="left" size="small" className={classes.tableCell}>
                        {row.description}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MaUTable>
            </TableContainer>
            </Card>
            <Card className={classes.marginField}>
              <Grid container className={classes.marginField} item xs={12} spacing={3}>
                <Grid item xs={8}>
                  <Typography variant='h3'color="textPrimary" className={classes.title}>Formación</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button type="button" variant="contained" color="primary" size="small" className={classes.title} onClick={event => setInfo(3)}>
                    Añadir formación académica
                  </Button>
                </Grid>
              </Grid>
              {renderFormaciones()}
            </Card>
            <Card className={classes.marginField}>
              <Grid container className={classes.marginField} item xs={12} spacing={3}>
                <Grid item xs={8}>
                  <Typography variant='h3' color="textPrimary" className={classes.title}>Experiencia laboral</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button type="button" variant="contained" color="primary" size="small" className={classes.title} onClick={event => setInfo(4)}>
                    Añadir experiencia laboral
                  </Button>
                </Grid>
              </Grid>
              {renderExperiencias()}
            </Card>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} className={classes.button}>
                <Link to={"/user_page"}>
                  <Button variant="contained" color="secondary">
                    Salir
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.button}>
                <Link to={"/user_page"}>
                  <Button variant="contained" color="primary" onClick={event => sweetAlertsProceso()}>
                    Guardar información
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>

        </div>
        : info === 2
          ?
          <div className={classes.centerItems}>
            <EditarCandidato idCandidato={idCandidato} setInfo={setInfo} candidatoGeneral={candidato} />
          </div>
          : info === 3
            ?
            <div className={classes.centerItems}>
              <CrearFormacion idCandidato={idCandidato} setInfo={setInfo} />
            </div>
            :
            <div className={classes.centerItems}>
              <CrearExperiencia idCandidato={idCandidato} setInfo={setInfo} />
            </div>
      }
    </AuthWrapper>
  );
}