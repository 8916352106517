import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Collapse, IconButton, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { postExperienciasCandidato } from '../../../helpers/experienciaHelper/experienciaHelper';
import { fileHelperText } from '../../../constants/AppConstants';


const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  marginField: {
    marginBottom: 15,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    width: "100%",
  },
  color: {
    color: 'red'
  }
}));

const IngresarExperiencia = ({ variant = 'default', idCandidato, tokenGeneral, setInfo }) => {

  const fecha = new Date();
  fecha.setDate(fecha.getDate());
  const fechaActual = fecha.toISOString().split('T')[0];
  const classes = useStyles({ variant });
  const idExperiencia = idCandidato
  const fechaFinalHelper = 'En caso de que siga vigente su vinculación, ' +
    'poner la fecha indicada en el certificado laboral.'

  const [open, setOpen] = useState(false)         //Alerta de espacios erróneos
  const [experiencia, setExperiencia] = useState({
    empresa: '',
    area: '',
    fechaInicio: '',
    fechaFinal: '',
    cargo: '',
    descripcion: '',
    idCandidato: idExperiencia
  });
  const [fileExperiencia, setFileExperiencia] = useState('');
  const [error, setError] = useState({
    empresa: false,
    empresaHT: '',
    area: false,
    areaHT: '',
    fechaInicio: false,
    fechaInicioHT: '',
    fechaFinal: false,
    fechaFinalHT: '',
    cargo: false,
    cargoHT: '',
    descripcion: false,
    descripcionHT: '',
    fileExperiencia: false,
    fileExperienciaHT: fileHelperText,
  })
  const mensajesError = {
    noError: '',
    errorVacio: 'Ingrese algún dato',
    errorNombreEmpresa: 'Nombre de la empresa no debe estar vacio',
    errorArea: 'Area encargada no debe estar vacio',
    errorFechaInicio: 'Fecha de inicio no debe estar vacio',
    errorFechaCulminacion: 'Fecha de culminación no debe estar vacio',
    errorCargo: 'Cargo no debe estar vacio',
    errorDescripcionCargo: 'Descripción del cargo no debe estar vacio',
    errorConstancia: 'Debe adjuntar la constancia de la empresa',
    mensajeFile: fileHelperText,
    errorFile: 'El archivo sobrepasa los 6 mb'
  }

  const handleChange = (event) => {
    setExperiencia({
      ...experiencia,
      [event.target.name]: event.target.value
    });
    setError({
      ...error,
      [event.target.name]: false
    });
  };

  const MySwal = withReactContent(Swal);
  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Experiencia creada'
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();

    formData.append('experiencia', new Blob([JSON.stringify(experiencia)], { type: "application/json" }));
    formData.append('file', fileExperiencia);

    await postExperienciasCandidato(formData)
      .then(function (response) {
        sweetAlerts()
        setInfo(1)
      })
      .catch(function (response) {
        console.log(response.response)
      });
  }
  return (
    <React.Fragment>
      <Typography variant='h1' color="textPrimary" className={classes.subtitle}>Añadir experiencia laboral</Typography>
      <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (!error.empresa && !error.cargo && !error.fechaInicio &&
          !error.fechaFinal && !error.area &&
          !error.descripcion && !error.fileExperiencia) {
          setOpen(false)
          onSubmit(e)
        } else {
          setOpen(true)
        }
      }}>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Nombre de la empresa<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              defaultValue=""
              onChange={handleChange} name='empresa'
              variant="outlined"
              className={classes.textFieldRoot}
              id="nombreEmpresa"
              inputProps={{ maxLength: 100 }}
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, empresa: true, empresaHT: mensajesError.errorNombreEmpresa });
                } else setError({ ...error, empresa: false, empresaHT: '' });
              }}
              error={error.empresa}
              helperText={error.empresaHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Área Encargada<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              defaultValue=""
              onChange={handleChange} name='area'
              variant="outlined"
              className={classes.textFieldRoot}
              id="area"
              inputProps={{ maxLength: 30 }}
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, area: true, areaHT: mensajesError.errorArea });
                } else setError({ ...error, area: false, areaHT: '' });
              }}
              error={error.area}
              helperText={error.areaHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Fecha de Inicio<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              type="date"
              defaultValue=""
              onChange={handleChange} name='fechaInicio'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaInicio"
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaInicio: true, fechaInicioHT: mensajesError.errorFechaInicio });
                } else setError({ ...error, fechaInicio: false, fechaInicioHT: '' });
              }}
              error={error.fechaInicio}
              helperText={error.fechaInicioHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Fecha de Culminación<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              type="date"
              defaultValue=""
              onChange={handleChange} name='fechaFinal'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaFinalizacion"
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaFinal: true, fechaFinalHT: mensajesError.errorFechaCulminacion });
                } else setError({ ...error, fechaFinal: false, fechaFinalHT: '' });
              }}
              error={error.fechaFinal}
              helperText={error.fechaFinalHT}
            />
            {fechaFinalHelper && <FormHelperText>{fechaFinalHelper}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Cargo<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              multiline
              maxRows={8}
              defaultValue=""
              onChange={handleChange} name='cargo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="cargo"
              inputProps={{ maxLength: 400 }}
              onBlur={event => {
                if (event.target.value.length >= 400) {
                  setError({
                    ...error,
                    cargo: true,
                    cargoHT: " Máximo 400 caracteres",
                  })
                } else setError({ ...error, cargo: false, cargoHT: '' });
                if (event.target.value === '') {
                  setError({ ...error, cargo: true, cargoHT: mensajesError.errorCargo });
                }
              }}
              error={error.cargo}
              helperText={error.cargoHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Descripción del Cargo<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              multiline
              defaultValue=""
              onChange={handleChange} name='descripcion'
              variant="outlined"
              className={classes.textFieldRoot}
              id="descripcion"
              inputProps={{ maxLength: 2001 }}
              onBlur={event => {
                if (event.target.value.length > 2000) {
                  setError({
                    ...error,
                    descripcion: true,
                    descripcionHT: "Máximo 2000 caracteres",
                  })
                } else setError({ ...error, descripcion: false, descripcionHT: '' });
                if (event.target.value === '') {
                  setError({ ...error, descripcion: true, descripcionHT: mensajesError.errorDescripcionCargo });
                }
              }}
              error={error.descripcion}
              helperText={error.descripcionHT}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginField} container item xs={12} spacing={3}>
          <Grid item xs={5} className={classes.left}>
            <Typography variant='body1' className={classes.typography}>Adjunte Constancia de la Empresa<span className={classes.color}>*</span></Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              type="file"
              defaultValue={fileExperiencia}
              onChange={event => setFileExperiencia(event.target.files[0])}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldRoot}
              id="fileExperiencia"
              InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
              onBlur={event => {
                if (event.target.files[0] === undefined) {
                  setError({ ...error, fileExperiencia: true, fileExperienciaHT: mensajesError.errorConstancia });
                } else if (event.target.files[0].size > 6291456) {
                  setError({ ...error, fileExperiencia: true, fileExperienciaHT: mensajesError.errorFile });
                } else {
                  setError({ ...error, fileExperiencia: false, fileExperienciaHT: mensajesError.mensajeFile });
                }
              }}
              error={error.fileExperiencia}
              helperText={error.fileExperienciaHT}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} >
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="secondary" onClick={event => setInfo(1)}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="primary" type="submit">
              Crear Experiencia
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} className={classes.button}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              variant="filled"
              severity="error"
            >
              ¡Aún hay campos en rojo!
            </Alert>
          </Collapse>
        </Grid>
      </form>
    </React.Fragment>
  )
};

export default IngresarExperiencia;