import React from 'react';

import clsx from 'clsx';

import SideBar from '../partials/SideBar';
import ContentLoader from '../../ContentLoader';
import MainHeader from './MainHeader';
import useStyles from './index.style';
import Footer from '../partials/Footer';
import { HEADER_TYPE, SIDEBAR_TYPE } from '../../../constants/ThemeOptions';
import defaultContext from '../../contextProvider/AppContextProvider/defaultContext';

import CmtFooter from '../../../@coremat/CmtLayouts/Horizontal/Footer';
import CmtHorizontalLayout from '../../../@coremat/CmtLayouts/Horizontal';
import CmtHeader from '../../../@coremat/CmtLayouts/Horizontal/Header';
import CmtHeaderMain from '../../../@coremat/CmtLayouts/Horizontal/Header/HeaderMain';
import CmtSidebar from '../../../@coremat/CmtLayouts/Horizontal/Sidebar';
import CmtContent from '../../../@coremat/CmtLayouts/Horizontal/Content';

const layoutOptions = {
  showFooter: true,
  headerType: HEADER_TYPE.STATIC,
  isSidebarFixed: defaultContext.isSidebarFixed,
  isSidebarOpen: false,
  sidebarType: SIDEBAR_TYPE.MINI,
  layoutStyle: defaultContext.layoutType,
};

const HorizontalMinimal = ({ className, children }) => {
  const classes = useStyles();

  return (
    <CmtHorizontalLayout
      layoutOptions={layoutOptions}
      className={clsx('Cmt-horizontalMinimalLayout', className)}
      header={
        <CmtHeader className={classes.appHeaderDark}>
          <CmtHeaderMain>
            <MainHeader />
          </CmtHeaderMain>
        </CmtHeader>
      }
      footer={
        <CmtFooter type="static">
          <Footer />
        </CmtFooter>
      }
      sidebar={
        <CmtSidebar>
          <SideBar />
        </CmtSidebar>
      }>
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
    </CmtHorizontalLayout>
  );
};

export default HorizontalMinimal;
