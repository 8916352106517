import axios from 'axios'
import { getToken } from '../../services/auth/jwt/getToken'
import { BASE_URL } from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const validateCuentaCobro = (data) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'preempleadosId',
        headers: {
            'Authorization': token,
            'Content-type': 'multipart/form-data',
        },
        withCredentials: true,
        data: data
    }
    return axios(config)
}

export const postCuentaCobro = (data) =>{
    let config={
        method: 'POST',
        url: BASE_URL + 'cuenta-cobros',
        headers:{
            'Authorization': token,
            'Content-type': contentType,
        },
        withCredentials:true,
        data:data
    }
    return axios(config)
}
