import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Grid, Collapse, IconButton, MenuItem, CardContent, Card, TextField } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CustomDropzone } from './DropZone';
import TypeForm from '../../../../components/Campos/TypeForm';
import { IS_LESS_THAN_TO, IS_NOT_EMPTY, IS_NUMERIC } from '../../../../constants/MensajeHelper';
import { bancos } from '../../../../constants/AppConstants';
import validate from 'validate.js';
import { postContratoUpdate } from '../../../../helpers/contrato/contratoHelper';
import { getAuthenticate, getCandidato } from '../../../../helpers/candidato/candidatoHelper';
import { Redirect, useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "100%",
        minWidth: "100%",
        margin: '0 auto',
        backgroundColor: lighten(theme.palette.background.paper, 0.1),

    },
    subtitle: {
        marginLeft: theme.spacing(3),
        marginBottom: '5px',
        marginTop: '20px'
    },
    centerItem: {
        justifyContent: "center"
    },
    subtitleObligatorio: {
        marginLeft: theme.spacing(3),
        marginBottom: '40px',
        marginTop: '5px'
    },
    subtitleIngreso: {
        marginLeft: theme.spacing(3)
    },
    typography: {
        paddingLeft: theme.spacing(8),
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        marginTop: '30px',
    },
    textFieldRoot: {
        width: "50%",
        height: "15%",
    },
    errorHelper: {
        marginLeft: '14px',
        marginRight: '14px'
    },
    color: {
        color: 'red'
    }
}));

const mensajeHelper = {
    banco: {
        presence: IS_NOT_EMPTY,
    },
    tipoCuenta: {
        presence: IS_NOT_EMPTY,
    },
    numeroCuenta: {
        presence: IS_NOT_EMPTY,
        numericality: IS_NUMERIC,
        length: { maximum: 20, message: "no puede sobrepasar los 20 caracteres" },
    },
    afiliacionEps: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    afiliacionArl: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    afiliacionPension: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    certificadoBancario: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    rut: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    declaracionJuramentada: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    },
    contratoFirmado: {
        presence: IS_NOT_EMPTY,
        numericality: IS_LESS_THAN_TO
    }
}

const useSingleton = (callBack = () => { }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const formInit = {
    isValid: false,
    values: {
        banco: "",
        tipoCuenta: "",
        numeroCuenta: "",
        afiliacionArl: "",
        afiliacionEps: "",
        afiliacionPension: "",
        certificadoBancario: "",
        declaracionJuramentada: "",
        rut: "",
        contratoFirmado: "",
    },
    touched: {
        banco: false,
        tipoCuenta: false,
        numeroCuenta: false,
        afiliacionArl: false,
        afiliacionEps: false,
        afiliacionPension: false,
        certificadoBancario: false,
        declaracionJuramentada: false,
        rut: false,
        contratoFirmado: false,
    },
    errors: {
        banco: false,
        tipoCuenta: false,
        numeroCuenta: false,
        afiliacionArl: false,
        afiliacionEps: false,
        afiliacionPension: false,
        certificadoBancario: false,
        declaracionJuramentada: false,
        rut: false,
        contratoFirmado: false,
    },
};

const formFilesInit = {
    afiliacionArl: "",
    afiliacionEps: "",
    afiliacionPension: "",
    certificadoBancario: "",
    declaracionJuramentada: "",
    rut: "",
    contratoFirmado: "",
}





const DocumentosContrato = ({ variant = 'default', props }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles({ variant });
    const [redirect, setRedirect] = useState(false)
    const history = useHistory();
    const [formState, setFormState] = useState(formInit);
    const [formFiles, setFormFiles] = useState(formFilesInit);
    const [numeroDocumento, setNumeroDocumento] = useState("");

    const [error, setError] = useState({
        numeroCuenta:false,
        numeroCuentaHelper: '',
      })
    
    const handleError = (event, name, helper, message) => {
        let errorVar = {}
        if (event.target.value === "") {
            errorVar = { ...error, [name]: true, [helper]: message };
        }
        else if (Number(event.target.value) <= 0) {
            errorVar = { ...error, [name]: true, [helper]: 'No puede ser menor que 0' };
        }else if(event.target.value.toString().length >= 21){
            errorVar = { ...error, [name]: true, [helper]: 'No puede sobrepasar los 20 caracteres'};
        }
        else {
            errorVar = { ...error, [name]: false, [helper]: '' };
        }
        setError(errorVar)
      } 


    const qs = require('qs');
    var idContratoUrl = ''
    if (qs.parse(window.location.search)['?key'] !== undefined) {
        idContratoUrl = qs.parse(window.location.search)['?key']
    }

    const getCandidatoContrato = async () => {
        await getCandidato().then((res) => {
            setNumeroDocumento(res.data.numeroDocumento);
        })
    }

    const initialState = async () => {
        await getAuthenticate().then((res) => {
            if (!res.data) {
                setRedirect(true);
            }
            getCandidatoContrato();
        });
    };
    useSingleton(async () => {
        await initialState();
    });

    const hasError = (field) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    useEffect(() => {
        const errors = validate(formState.values, mensajeHelper);
        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));

    }, [formState.values]);

    const handleChange = (files, name) => {
        const fieldValue = files[0];
        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: fieldValue,
            },
            touched: {
                ...formState.touched,
                [name]: true,
            },
        });
    };

    const handleNumero = (e) => {
        setFormState(
            {
                ...formState,
                values:{
                    ...formState.values,
                    [e.target.name]: e.target.value
                }
            }
        )
    }

    const MySwal = withReactContent(Swal);
    const sweetAlerts = () => {
        MySwal.fire({
            icon: 'success',
            title: 'Documentos Ingresados'
        });
    }

    const sweetAlertsError = () => {
        MySwal.fire({
            icon: 'Error',
            title: 'Ocurrio un error',
            text: 'Ocurrió un error al procesar la solicitud, por favor intente más tarde'
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('certificadoBancario', formFiles.certificadoBancario);
        formData.append('banco', formState.values.banco);
        formData.append('tipoCuenta', formState.values.tipoCuenta);
        formData.append('numeroCuenta', formState.values.numeroCuenta);
        formData.append('salud', formFiles.afiliacionEps);
        formData.append('arl', formFiles.afiliacionArl);
        formData.append('pension', formFiles.afiliacionPension);
        formData.append('rut', formFiles.rut);
        formData.append('declaracionJuramentada', formFiles.declaracionJuramentada);
        formData.append('contratoFirmado', formFiles.contratoFirmado);
        formData.append('numeroDocumento', numeroDocumento);

        await postContratoUpdate(formData, idContratoUrl)
            .then(function (response) {
                sweetAlerts()
                history.push("/user_page")
            })
            .catch(function (error) {
                if (error.response && error.response.data.title === "URL no válida") {
                    MySwal.fire("Hubo un problema", "Por favor válide la URL", "error");
                } else {
                    sweetAlertsError()
                }
            });
    }
    return (
        <Card>
            <CardContent className={classes.root}>
                <form noValidate onSubmit={onSubmit}>
                    {redirect ? (
                        <Redirect to={{ pathname: "/signin", search: `?key=${idContratoUrl}`, state: { goBack: "/documentos_contrato" }, }} />) : null}
                    {idContratoUrl === '' ? <Alert severity="warning">Url inválida, por favor revisela</Alert> : null}
                    <Typography variant='h1' className={classes.subtitle}>Ingresa tus documentos de contrato</Typography>
                    <Typography variant='body2' className={classes.subtitleIngreso}> Ingresa los siguientes documentos relacionados con tu proceso de contratación.</Typography>
                    <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
                    <Grid container xs={12} spacing={3} >
                        <Grid item xs={5} className={classes.left}>
                            <Typography variant='body1' className={classes.typography}>Banco<span className={classes.color}>*</span></Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TypeForm id="banco" formState={formState} setFormState={setFormState} helperText={mensajeHelper} fullWidth defaultValue={formState.values.banco} >
                                {bancos.length > 0 ?
                                    bancos.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    )) : <MenuItem value="No se encontraron Bancos">No se encontraron bancos</MenuItem>
                                };
                            </TypeForm>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={3} >
                        <Grid item xs={5} className={classes.left}>
                            <Typography variant='body1' className={classes.typography}>Tipo de cuenta<span className={classes.color}>*</span></Typography>
                        </Grid>
                        <Grid item xs={7} >
                            <TypeForm id="tipoCuenta" formState={formState} setFormState={setFormState} helperText={mensajeHelper} fullWidth defaultValue={formState.values.tipoCuenta}>
                                <MenuItem value="" />
                                <MenuItem value={"Corriente"}>Corriente</MenuItem>
                                <MenuItem value={"Ahorros"}>Ahorros</MenuItem>
                                <MenuItem value={"Otros"}>Otros (Daviplata, Nequi, etc)</MenuItem>
                            </TypeForm>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={3}>
                        <Grid item xs={5} className={classes.left}>
                            <Typography variant='body1' className={classes.typography}>Número de cuenta<span className={classes.color}>*</span></Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {/* <TypeText id="numeroCuenta" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ maxLength: 31 }}  fullWidth/>                             */}
                            <TextField id="numeroCuenta" name="numeroCuenta" variant='outlined' type="number" error={error.numeroCuenta} onChange={handleNumero} fullWidth
                                onBlur={event => {
                                    handleError(event, 'numeroCuenta', 'numeroCuentaHelper', "Número de cuenta no debe ser vacio")
                                }} />
                            {error.numeroCuentaHelper !== '' && (<Typography className={classes.errorHelper} color="error" variant="caption">{error.numeroCuentaHelper}</Typography>)}
                        </Grid>
                    </Grid>
                    <CustomDropzone nombre="Certificado Bancario" classes={classes} hasError={hasError} handleChange={handleChange} name={'certificadoBancario'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Certificado RUT" classes={classes} handleChange={handleChange} hasError={hasError} name={'rut'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Certificado EPS" classes={classes} handleChange={handleChange} hasError={hasError} name={'afiliacionEps'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Certificado Pensión" classes={classes} handleChange={handleChange} hasError={hasError} name={'afiliacionPension'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Certificado ARL" classes={classes} handleChange={handleChange} hasError={hasError} name={'afiliacionArl'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Declaracion Juramentada" classes={classes} handleChange={handleChange} hasError={hasError} name={'declaracionJuramentada'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <CustomDropzone nombre="Contrato firmado" classes={classes} handleChange={handleChange} hasError={hasError} name={'contratoFirmado'}
                        formState={formState} setFormState={setFormState} formFiles={formFiles} setFormFiles={setFormFiles} />
                    <Grid container item xs={12} spacing={3} className={classes.button}>
                        <Button variant="contained" color="primary" type="submit" disabled={!formState.isValid}>
                            Guardar
                        </Button>
                    </Grid>
                    <Grid container item xs={12} spacing={3} className={classes.button}>
                        <Collapse in={open}>
                            <Alert
                                action={
                                    <IconButton
                                        arial-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }
                                        }>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>}
                                variant="filled"
                                severity="error">
                                ¡Aún hay campos en rojo!
                            </Alert>
                        </Collapse>
                    </Grid>
                </form>
            </CardContent>

        </Card>
    );
};
export default DocumentosContrato;