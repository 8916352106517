import axios from 'axios'
import {BASE_URL} from '../baseUrl'
import {getToken} from '../../services/auth/jwt/getToken';
const contentType = 'application/json'
const token = getToken()

export const confirmarDatosPostulacion = async(id) => {
    const config = {
        method: 'put',
        url: BASE_URL+'confirmarDatosPostulacion/'+id,
        headers: {
          'Authorization': token,
          'Content-Type': contentType
        },
        withCredentials: true,
      }

      return await axios(config)
}

export const candidatosPostulados = async() => {
  const config = {
    method: 'get',
    url: BASE_URL+'postulacion-candidato',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
  }

  return await axios(config)
}

export const postPostulacion = (data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'postulacions',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    data: data
  }

  return axios(config)
  
}
