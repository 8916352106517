import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, alpha, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../@coremat/CmtImage';

import { AuhMethods } from '../../services/auth';
import ContentLoader from '../ContentLoader';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../redux/actions/Auth';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    email: false,
    emailHelper: "",
  });
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 5000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const onSubmit = () => {
    dispatch(AuhMethods[method].onForgotPassword({ email }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <div className={classes.authContent}>
        <div className={'mb-7'}>
          <CmtImage src={'/images/grupois.png'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Recuperar contraseña
        </Typography>
        <Typography component="div" variant="subtitle1" className={classes.titleRoot}>
          Ingrese el correo electrónico inscrito dentro del sistema de gestión de Grupo IS Colombia para recuperar la contraseña
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Revise la bandeja de entrada, un correo electrónico se ha enviado para recuperar la contraseña
          </Alert>
        </Collapse>
        <form onSubmit={(e) => {
            e.preventDefault();
            if (email !== "") {
              if (!error.email) {
                onSubmit();
              }
            } else {
              if(error.email) setError({ ...error, email:true, emailHelper: ' Este campo es requerido'})
              if(email === '' ){
                setError({ ...error,
                  email: true, emailHelper: ' Este campo es requerido' ,
               });
              }
            }
          }}
        >
          <div className={"mb-5"}>
            <TextField
              onBlur={async (event) => {
                const regex =
                  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                if (event.target.value === "") {
                  setError({
                    ...error,
                    email: true,
                    emailHelper: " Este campo es requerido",
                  });
                } else if (!regex.test(email)) {
                  setError({
                    ...error,
                    email: true,
                    emailHelper: " Ingrese un email válido",
                  });
                } else setError({ ...error, email: false, emailHelper: "" });
              }}
              error={error.email}
              helperText={error.emailHelper}
              type="email"
              label="Email"
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              pattern=".+@globex\.com"
            />
          </div>
          <div className={"mb-5"}>
            <Button type="submit" variant="contained" color="primary">
              Recuperar contraseña
            </Button>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
