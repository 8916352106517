import axios from 'axios'
import { getToken } from '../../services/auth/jwt/getToken'
import { BASE_URL } from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const validatePreempleadoContratado = ({idUser})=>{
    let config= {
        method: 'GET',
        url: BASE_URL + 'preempleado-estado-tipo',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            idUser : idUser,
        }
    }
    return axios(config)
}
export const contratoIdByUser = ({idUser})=>{
    let config= {
        method: 'GET',
        url: BASE_URL + 'preempeado-contrato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            idUser : idUser,
        }
    }
    return axios(config)
}

export const getPostulacionKey = (idPreempleado)=>{
    let config= {
        method: 'GET',
        url: BASE_URL + 'preempleado-postulacion-key',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            idPreempleado : idPreempleado,
        }
    }
    return axios(config)
}


  