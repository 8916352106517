export const AppHeaderHeight = 72;
export const AppHeaderHeightSm = 64;
export const AppFooterHeight = 72;
export const AppFooterHeightSm = 64;
export const AppMainContentPadding = 60;
export const InBuildAppHeaderHeight = 70;

export const InBuildAppSidebarHeaderHeight = 86;
export const MailAppContentHeaderHeight = 78;

export const TaskDetailHeaderHeight = 72;
export const TaskDetailAddCommentHeight = 72;

export const ChatSidebarHeaderHeight = 124;
export const ChatSidebarTabContainerHeight = 49;
export const ChatFooterHeight = 70;
export const CurrentAuthMethod = 'jwtAuth'; //jwtAuth,firebase,basic

export const getAppSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppHeaderHeight : 0)
      );
  }
};

export const getMailContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getContactContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getTaskListSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getTaskListContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getTaskDetailContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        TaskDetailHeaderHeight +
        TaskDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        TaskDetailHeaderHeight +
        TaskDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        TaskDetailHeaderHeight +
        TaskDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getChatSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getChatContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getWallHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const spanishTextTable = {
  body: {
    emptyDataSourceMessage: 'No hay datos por mostrar',
    addTooltip: 'Añadir',
    deleteTooltip: 'Eliminar',
    editTooltip: 'Editar',
    filterRow: {
      filterTooltip: 'Filtrar',
    },
    editRow: {
      deleteText: '¿Segura(o) que quiere eliminar?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Guardar',
    },
  },
  grouping: {
    placeholder: "Arrastre un encabezado aquí para agrupar",
    groupedBy: 'Agrupado por',
  },
  header: {
    actions: 'Acciones',
  },
  pagination: {
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsPerPage: 'Filas por página:',
    labelRowsSelect: 'filas',
    lastAriaLabel: 'Ultima página',
    lastTooltip: 'Ultima página',
    nextAriaLabel: 'Pagina siguiente',
    nextTooltip: 'Pagina siguiente',
    previousAriaLabel: 'Pagina anterior',
    previousTooltip: 'Pagina anterior',
  },
  toolbar: {
    addRemoveColumns: 'Agregar o eliminar columnas',
    exportAriaLabel: 'Exportar',
    exportName: 'Exportar a CSV',
    exportTitle: 'Exportar',
    nRowsSelected: '{0} fila(s) seleccionada(s)',
    searchPlaceholder: 'Buscar',
    searchTooltip: 'Buscar',
    showColumnsAriaLabel: 'Mostrar columnas',
    showColumnsTitle: 'Mostrar columnas',
  },
}

export const dateFormat = (date) => {
  return new Date(date).toLocaleString("en-GB", { month: "numeric", year: "numeric", day: "numeric", timeZone: "UTC" })
}

export const fileHelperText = "Sólo se aceptan PDF y JPG. Máximo 6 mb"
export const tipoFormaciones = [
  { text: 'Bachiller', value: 'Bachiller' },
  { text: 'Técnico', value: 'Tecnico' },
  { text: 'Tecnólogo', value: 'Tecnologo' },
  { text: 'Pregrado', value: 'Pregrado' },
  { text: 'Especialización', value: 'Especializacion' },
  { text: 'Maestria', value: 'Maestria' },
  { text: 'Doctorado', value: 'Doctorado' },
  { text: 'Posdoctorado', value: 'Posdoctorado' },
  { text: 'Otros', value: 'Otros' },
]

export const Programas = [
  'ABOGADO(A)',
  'ADMINISTADOR PUBLICO',
  'ADMINISTARDOR TURISTICO Y HOTELERO',
  'ADMINISTRACION DE EMPRESAS',
  'ADMINISTRACION DE EMPRESAS MINERAS',
  'ADMINISTRACION EMPRESARIAL',
  'ADMINISTRACION EMPRESAS AGROINDUSTRIALES',
  'ADMINISTRACION EN SALUD CON ENFASIS EN GESTION DE SERVICIOS DE SALUD',
  'ADMINISTRADOR',
  'ADMINISTRADOR (A) AMBIENTAL',
  'ADMINISTRADOR (A) DE AGRONEGOCIOS',
  'ADMINISTRADOR (A) DE EMPRESAS',
  'ADMINISTRADOR (A) DE EMPRESAS TURISTICAS Y HOTELERAS',
  'ADMINISTRADOR (A) DE NEGOCIOS',
  'ADMINISTRADOR AERONAUTICO',
  'ADMINISTRADOR AGROINDUSTRIAL',
  'ADMINISTRADOR AGROPECUARIO',
  'ADMINISTRADOR AMBIENTAL',
  'ADMINISTRADOR AMBIENTAL Y DE LOS RECURSOS NATURALES',
  'ADMINISTRADOR AMBIENTAL Y SANITARIO',
  'ADMINISTRADOR BANCARIO Y FINANCIERO',
  'ADMINISTRADOR COMERCIAL',
  'ADMINISTRADOR COMERCIAL Y DE MERCADEO',
  'ADMINISTRADOR COMERCIAL Y DE SISTEMAS',
  'ADMINISTRADOR COMERCIAL Y FINANCIERO',
  'ADMINISTRADOR DE AGRONEGOCIOS',
  'ADMINISTRADOR DE COMERCIO EXTERIOR',
  'ADMINISTRADOR DE CONSTRUCCIONES',
  'ADMINISTRADOR DE EMPRESAS',
  'ADMINISTRADOR DE EMPRESAS AGROINDUSTRIALES',
  'ADMINISTRADOR DE EMPRESAS AGROPECUARIAS',
  'ADMINISTRADOR DE EMPRESAS COMERCIALES',
  'ADMINISTRADOR DE EMPRESAS CON ENFASIS EN FINANZAS',
  'ADMINISTRADOR DE EMPRESAS COOPERATIVAS',
  'ADMINISTRADOR DE EMPRESAS DE SERVICIOS',
  'ADMINISTRADOR DE EMPRESAS DEPORTIVAS',
  'ADMINISTRADOR DE EMPRESAS EN TELECOMUNICACIONES',
  'ADMINISTRADOR DE EMPRESAS HOTELERAS Y TURISTICAS',
  'ADMINISTRADOR DE EMPRESAS INDUSTRIALES',
  'ADMINISTRADOR DE EMPRESAS MINERAS',
  'ADMINISTRADOR DE EMPRESAS TURISTICAS',
  'ADMINISTRADOR DE EMPRESAS TURISTICAS Y HOTELERAS',
  'ADMINISTRADOR DE EMPRESAS Y DE TRANSFORMACION DIGITAL',
  'ADMINISTRADOR DE EMPRESAS Y FINANZAS',
  'ADMINISTRADOR DE EMPRESAS Y GERENCIA INTERNACIONAL',
  'ADMINISTRADOR DE EMPRESAS Y GESTOR AMBIENTAL',
  'ADMINISTRADOR DE EMPRESAS Y TRANSFORMACION DIGITAL',
  'ADMINISTRADOR DE GESTION HUMANA',
  'ADMINISTRADOR DE INFORMATICA',
  'ADMINISTRADOR DE INSTITUCIONES DE SERVICIO',
  'ADMINISTRADOR DE LA CADENA DE SUMINISTRO Y LOGISTICA',
  'ADMINISTRADOR DE LA CALIDAD',
  'ADMINISTRADOR DE LA SEGURIDAD INTEGRAL',
  'ADMINISTRADOR DE LA SEGURIDAD SOCIAL',
  'ADMINISTRADOR DE LA SEGURIDAD Y SALUD EN EL TRABAJO',
  'ADMINISTRADOR DE LA SEGURIDAD Y SALUD OCUPACIONAL',
  'ADMINISTRADOR DE MERCADEO',
  'ADMINISTRADOR DE MERCADEO Y FINANZAS',
  'ADMINISTRADOR DE MERCADEO Y LOGISTICA INTERNACIONALES',
  'ADMINISTRADOR DE MERCADEO Y VENTAS',
  'ADMINISTRADOR DE MERCADEO,PUBLICIDAD Y VENTAS',
  'ADMINISTRADOR DE NEGOCIOS',
  'ADMINISTRADOR DE NEGOCIOS CON ENFASIS EN FINANZAS Y SEGUROS',
  'ADMINISTRADOR DE NEGOCIOS INTERNACIONALES',
  'ADMINISTRADOR DE NEGOCIOS SOSTENIBLES',
  'ADMINISTRADOR DE OBRAS CIVILES',
  'ADMINISTRADOR DE OBRAS DE ARQUITECTURA',
  'ADMINISTRADOR DE PRODUCCION Y LOGISTICA INTERNACIONAL',
  'ADMINISTRADOR DE RECURSOS COSTEROS Y MARINOS',
  'ADMINISTRADOR DE RIESGOS, SEGURIDAD Y SALUD EN EL TRABAJO',
  'ADMINISTRADOR DE SERVICIOS',
  'ADMINISTRADOR DE SERVICIOS DE SALUD',
  'ADMINISTRADOR DE SISTEMAS',
  'ADMINISTRADOR DE SISTEMAS DE GESTION AMBIENTAL',
  'ADMINISTRADOR DE SISTEMAS DE INFORMACION',
  'ADMINISTRADOR DE SISTEMAS INFORMATICOS',
  'ADMINISTRADOR DE TECNOLOGIAS DE LA INFORMACION',
  'ADMINISTRADOR DEL COMERCIO INTERNACIONAL',
  'ADMINISTRADOR DEL MEDIO AMBIENTE',
  'ADMINISTRADOR DEL MEDIO AMBIENTE Y DE LOS RECURSOS NATURALES',
  'ADMINISTRADOR DEPORTIVO',
  'ADMINISTRADOR E INNOVADOR EN TURISMO Y HOTELES',
  'ADMINISTRADOR EMPRESARIAL',
  'ADMINISTRADOR EMPRESARIAL SECTORES PUBLICO Y PRIVADO',
  'ADMINISTRADOR EN DESARROLLO AGROINDUSTRIAL',
  'ADMINISTRADOR EN FINANZAS Y NEGOCIOS INTERNACIONALES',
  'ADMINISTRADOR EN HOTELERIA Y TURISMO',
  'ADMINISTRADOR EN INFORMATICA',
  'ADMINISTRADOR EN LOGISTICA Y PRODUCCION',
  'ADMINISTRADOR EN MERCADEO',
  'ADMINISTRADOR EN MERCADEO Y LOGISTICA INTERNACIONALES',
  'ADMINISTRADOR EN RECURSOS HUMANOS',
  'ADMINISTRADOR EN SALUD',
  'ADMINISTRADOR EN SALUD CON ENFASIS EN GESTION DE SERVICIOS EN SALUD',
  'ADMINISTRADOR EN SALUD OCUPACIONAL',
  'ADMINISTRADOR EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'ADMINISTRADOR EN SERVICIOS DE SALUD',
  'ADMINISTRADOR FINANCIERO',
  'ADMINISTRADOR FINANCIERO Y BURSATIL',
  'ADMINISTRADOR FINANCIERO Y DE SISTEMAS',
  'ADMINISTRADOR HOTELERO',
  'ADMINISTRADOR HOTELERO Y TURISTICO',
  'ADMINISTRADOR HUMANO',
  'ADMINISTRADOR INDUSTRIAL',
  'ADMINISTRADOR INFORMATICO',
  'ADMINISTRADOR INTEGRAL DE RIESGOS DE SEGURIDAD Y SALUD EN EL TRABAJO',
  'ADMINISTRADOR LOGISTICO',
  'ADMINISTRADOR MARITIMO',
  'ADMINISTRADOR MARITIMO Y FLUVIAL',
  'ADMINISTRADOR MARITIMO Y PORTUARIO',
  'ADMINISTRADOR POLICIAL',
  'ADMINISTRADOR PUBLICO',
  'ADMINISTRADOR TECNOLOGICO',
  'ADMINISTRADOR TURISTICA Y DEL PATRIMONIO',
  'ADMINISTRADOR TURISTICO',
  'ADMINISTRADOR TURISTICO Y HOTELERO',
  'ADMINISTRADOR Y DIRECTOR DE EMPRESAS',
  'ADMINISTRADOR Y GESTION DE EMPRESA',
  'ADMINISTRADOR Y PLANIFICADOR EN PROYECTOS ECOTURISTICOS',
  'ADMINISTRADOR(A) AGROPECUARIO(A)',
  'ADMINISTRADOR(A) DE EMPRESAS AGROPECUARIAS',
  'ADMINISTRADOR(A) DE EMPRESAS GLOBALES',
  'ADMINISTRADOR(A) DE MERCADEO',
  'ADMINISTRADOR(A) DE NEGOCIOS INTERNACIONALES',
  'ADMINISTRADOR(A) DE SISTEMAS INFORMATICOS',
  'ADMINISTRADOR(A) EN SALUD: ENFASIS EN GESTION DE SERVICIOS DE SALUD Y ENFASIS EN GESTION SANITARIA Y AMBIENTAL',
  'ADMINISTRADOR(A) EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'ADMINISTRADOR(A) FINANCIERO',
  'ADMINISTRADOR(A) FINANCIERO(A)',
  'ADMINISTRADOR(A) TURISTICO(A) Y HOTELERO(A)',
  'ADMINSTRADOR DE EMPRESAS',
  'ADMNISTRADOR PUBLICO',
  'ADMONISTRADOR(A) DE EMPRESAS',
  'AGONOMO',
  'AGROECOLOGO',
  'AGROLOGO',
  'AGRONOMO',
  'AGRONOMO DEL TROPICO HUMEDO',
  'AGROZOOTECNISTA',
  'ANTROPOLOGO(A)',
  'ARCHIVISTA',
  'ARQUEOLOGO',
  'ARQUITECTO CONSTRUCTOR',
  'ARQUITECTO DE INTERIORES',
  'ARQUITECTO(A)',
  'ARQUITECTO(A) CONSTRUCTOR(A)',
  'ASTRONOMO',
  'BACTERIOLOGO (A)',
  'BACTERIOLOGO MICROBIOLOGO CON ENFASIS EN SECTOR INDUSTRIAL',
  'BACTERIOLOGO Y LABORATORISTA CLINICO',
  'BACTERIOLOGO Y LABORISTA CLINICO',
  'BIBLIOTECOLOGO',
  'BIBLIOTECOLOGO Y ARCHIVISTA',
  'BIOINGENIERO DE PROCESOS',
  'BIOINGENIERO(A)',
  'BIOLOGO AMBIENTAL',
  'BIOLOGO CON ENFASIS EN RECURSOS HIDRICOS',
  'BIOLOGO CON ENFASIS EN RECURSOS NATURALES',
  'BIOLOGO MARINO',
  'BIOLOGO VEGETAL',
  'BIOLOGO Y MATEMATICO CON ENFASIS EN ESTADISTICA',
  'BIOLOGO(A)',
  'BIOQUIMICO',
  'BIOQUIMICO DE ALIMENTOS',
  'BIOTECNOLOGO',
  'CIENTIFICO DE DATOS',
  'CIENTIFICO(A) DE DATOS',
  'CIENTIFICO(A) DE LA COMPUTACION',
  'COMERCIO INTERNACIONAL',
  'COMUNCADOR SOCIAL - PERIODISTA',
  'COMUNICACION SOCIAL - PERIODISMO',
  'COMUNICADOR (A)',
  'COMUNICADOR (A) AUDIOVISUAL',
  'COMUNICADOR (A) SOCIAL',
  'COMUNICADOR (A) SOCIAL - PERIODISTA',
  'COMUNICADOR (A) SOCIAL Y PERIODISTA DIGITAL',
  'COMUNICADOR AUDIOVISUAL',
  'COMUNICADOR AUDIOVISUAL Y DIGITAL',
  'COMUNICADOR AUDIOVISUAL Y MULTIMEDIAL',
  'COMUNICADOR AUDIOVISUAL Y MULTIMEDIOS',
  'COMUNICADOR CORPORATIVO',
  'COMUNICADOR DIGITAL',
  'COMUNICADOR EN ENTRETENIMIENTO DIGITAL',
  'COMUNICADOR EN LENGUAJES AUDIOVISUALES',
  'COMUNICADOR EN RADIO Y TELEVISION',
  'COMUNICADOR ESTRATEGICO',
  'COMUNICADOR GRAFICO PUBLICITARIO',
  'COMUNICADOR PERIODISTA',
  'COMUNICADOR SOCIAL',
  'COMUNICADOR SOCIAL - PERIODISMO',
  'COMUNICADOR SOCIAL - PERIODISMO CON ENFASIS EN COMUNICACION ORGANIZACIONAL',
  'COMUNICADOR SOCIAL INSTITUCIONAL',
  'COMUNICADOR SOCIAL ORGANIZACIONAL',
  'COMUNICADOR SOCIAL- PERIODISTA',
  'COMUNICADOR SOCIAL- PERIODISTA CON ENFASIS EN COMUNICACION POLITICO-ECONOMICA',
  'COMUNICADOR SOCIAL Y MEDIOS',
  'COMUNICADOR SOCIAL Y PERIODISMO',
  'COMUNICADOR SOCIAL Y PERIODISTA',
  'COMUNICADOR SOCIAL Y PERIODISTA / COMUNICADORA SOCIAL Y PERIODISTA',
  'COMUNICADOR SOCIAL Y PERIODISTA DIGITAL',
  'COMUNICADOR(A) DIGITAL',
  'COMUNICADOR(A) SOCIAL Y PERIODISTA',
  'COMUNICADOR(A) VISUAL',
  'COMUNIDADOR (A) SOCIAL',
  'CONSERVADOR Y RESTAURADOR DEL PATRIMONIO CULTURAL',
  'CONSTRUCTOR (A)',
  'CONSTRUCTOR CIVIL',
  'CONSTRUCTOR EN ARQUITECTURA E INGENIERIA',
  'CONSTRUCTOR Y GESTOR EN ARQUITECTURA',
  'CONTADOR (A) PUBLICO (A)',
  'CONTADOR PUBLICO CON ENFASIS EN SISTEMAS Y ECONOMIA SOLIDARIA',
  'CONTADOR PUBLICO DIGITAL TECH',
  'CONTADOR PUBLICO INTERNACIONAL',
  'CREATIVO / CREATIVA',
  'CRIMINALISTA',
  'DIPLOMADO EN FILOSOFIA Y LETRAS',
  'DIRECTOR DE FOTOGRAFIA CINEMATOGRAFICA',
  'DIRECTOR MUSICAL',
  'DIRECTOR Y PRODUCTOR DE MEDIOS AUDIOVISUALES',
  'DISEÑADOR',
  'DISEÑADOR (A)',
  'DISEÑADOR (A) DE COMUNICACION VISUAL',
  'DISEÑADOR (A) DE VESTUARIO',
  'DISEÑADOR (A) DIGITAL',
  'DISEÑADOR AUDIOVISUAL',
  'DISEÑADOR CROSSMEDIA',
  'DISEÑADOR DE COMUNICACION',
  'DISEÑADOR DE COMUNICACION GRAFICA',
  'DISEÑADOR DE ESPACIOS ESCENICOS Y EXHIBICION COMERCIAL',
  'DISEÑADOR DE ESPACIOS Y ESCENARIOS',
  'DISEÑADOR DE INTERACCION',
  'DISEÑADOR DE INTERIORES',
  'DISEÑADOR DE LA COMUNICACION GRAFICA',
  'DISEÑADOR DE MEDIOS INTERACTIVOS',
  'DISEÑADOR DE MODAS',
  'DISEÑADOR DE MODAS Y TEXTILES',
  'DISEÑADOR DE PRODUCTO',
  'DISEÑADOR DE VESTUARIO',
  'DISEÑADOR DIGITAL',
  'DISEÑADOR DIGITAL Y DE MULTIMEDIA',
  'DISEÑADOR GRAFICO',
  'DISEÑADOR GRAFICO Y MULTIMEDIAL',
  'DISEÑADOR HIPERMEDIA',
  'DISEÑADOR INDUSTRIAL',
  'DISEÑADOR INTERACTIVO',
  'DISEÑADOR TEXTIL',
  'DISEÑADOR TEXTIL Y DE MODAS',
  'DISEÑADOR VISUAL',
  'DISEÑADOR VISUAL DIGITAL',
  'DISEÑADOR Y ADMINISTRADOR DE NEGOCIOS DE LA MODA',
  'DISEÑADOR Y GESTOR DE LA MODA',
  'DISEÑADOR(A) GRAFICO(A)',
  'DISEÑADOR(A) INDUSTRIAL',
  'ECOLOGO',
  'ECOLOGO DE ZONAS COSTERAS',
  'ECONOMISTA',
  'ECONOMISTA AGRARIO',
  'ECONOMISTA CON ENFASIS EN FINANZAS INTERNACIONALES',
  'ECONOMISTA DEL DESARROLLO',
  'ECONOMISTA DEL TRANSPORTE',
  'ECONOMISTA EMPRESARIAL',
  'ECONOMISTA EN COMERCIO EXTERIOR',
  'ECONOMISTA EN NEGOCIOS INTERNACIONALES',
  'ECONOMISTA Y NEGOCIADOR INTERNACIONAL',
  'ESTADISTICO',
  'ESTADISTICO INFORMATICO',
  'ESTADISTICO(A)',
  'FILOLOGO HISPANISTA',
  'FILOSOFO',
  'FILOSOFO - TEOLOGO',
  'FILOSOFO Y HUMANISTA',
  'FILOSOFO(A)',
  'FILOSOFO/FILOSOFA',
  'FISICO',
  'FISICO (A)',
  'FISIOTERAPEUTA',
  'FISIOTERAPISTA',
  'FISOTERAPEUTA',
  'FONOAUDIOLOGA (O)',
  'FONOAUDIOLOGO',
  'FONOAUDIOLOGO(A)',
  'FORMADOR DE EMPRESARIOS',
  'GASTRONOMO',
  'GEOCIENTIFICO',
  'GEOGRAFO',
  'GEOGRAFO(A)',
  'GEOLOGO',
  'GEOLOGO(A)',
  'GEOPOLITOLOGO',
  'GEOTECNOLOGO',
  'GERENTE DE LA SEGURIDAD Y ANALISIS SOCIOPOLITICO',
  'GERONTOLOGO',
  'GERONTOLOGO (A)',
  'GESTOR DEL EMPRENDIMIENTO Y LA INNOVACION',
  'GESTOR EN ECOLOGIA Y TURISMO',
  'GESTOR(A) EN ECOLOGIA Y TURISMO',
  'HISTORIADOR',
  'HISTORIADOR (A) Y GESTOR (A) PATRIMONIAL',
  'HISTORIADOR DEL ARTE',
  'HISTORIADOR Y ARCHIVISTA',
  'INDUSTRIAL PECUARIAS',
  'INFORMATICO(A) MATEMATICO(A)',
  'INGEIERO (A) CIVIL',
  'INGENIEO ADMINISTRATIVA',
  'INGENIER@ INDUSTRIAL',
  'INGENIERA DE SISTEMAS ENERGETICOS / INGENIERO DE SISTEMAS ENERGETICOS',
  'INGENIERA ELECTRONICO / INGENIERO ELECTRONICA',
  'INGENIERA(O) EN AGROECOLOGIA',
  'INGENIERIA DE MANUFACTURA',
  'INGENIERIA DE SISTEMAS',
  'INGENIERIA ELECTROMECANICA',
  'INGENIERIA ELECTRONICA',
  'INGENIERIA INDUSTRIAL',
  'INGENIERIO CIVIL',
  'INGENIERIO DE PRODUCCION',
  'INGENIERIO DE SISTEMAS INFORMATICOS',
  'INGENIERIO DE SOFTWARE',
  'INGENIERIO DE TELECOMUNICACIONES',
  'INGENIERIO ELECTRONICO',
  'INGENIERIO GEOGRAFICA Y AMBIENTAL',
  'INGENIERIO INDUSTRIAL',
  'INGENIERO (A) AGROPECUARIA',
  'INGENIERO (A) AGROPECUARIO (A)',
  'INGENIERO (A) AMBIENTAL Y SANITARIO (A)',
  'INGENIERO (A) BIOMEDICO (A)',
  'INGENIERO (A) DE ALIMENTOS',
  'INGENIERO (A) DE DATOS E INTELIGENCIA ARTIFICIAL',
  'INGENIERO (A) DE LA CALIDAD',
  'INGENIERO (A) DE MULTIMEDIA',
  'INGENIERO (A) DE SISTEMAS CIBERNETICOS',
  'INGENIERO (A) DE SISTEMAS DE INFORMACION',
  'INGENIERO (A) ELECTRICO',
  'INGENIERO (A) ELECTROMECANICO (A)',
  'INGENIERO (A) ELECTRONICO',
  'INGENIERO (A) EN ANALITICA DE DATOS',
  'INGENIERO (A) EN AUTOMATIZACION',
  'INGENIERO (A) EN AUTOMATIZACION Y CONTROL',
  'INGENIERO (A) EN CIENCIA DE DATOS',
  'INGENIERO (A) EN INFORMATICA',
  'INGENIERO (A) EN NANOTECNOLOGIA',
  'INGENIERO (A) EN PROCESOS AGROINDUSTRIALES',
  'INGENIERO (A) EN SEGURIDAD INDUSTRIAL E HIGIENE OCUPACIONAL',
  'INGENIERO (A) EN TELECOMUNICACIONES',
  'INGENIERO (A) FINANCIERO (A)',
  'INGENIERO (A) FINANCIERO (A) Y DE NEGOCIOS',
  'INGENIERO (A) TELEMATICO(A)',
  'INGENIERO (A) TOPOGRAFICO (A)',
  'INGENIERO ACUICOLA',
  'INGENIERO ACUICULTOR',
  'INGENIERO ADMINISTRADOR',
  'INGENIERO ADMINISTRATIVO',
  'INGENIERO ADMINISTRATIVO Y DE FINANZAS',
  'INGENIERO AEROESPACIAL',
  'INGENIERO AERONAUTICO',
  'INGENIERO AGRICOLA',
  'INGENIERO AGROAMBIENTAL',
  'INGENIERO AGROFORESTAL',
  'INGENIERO AGROINDUSTRIAL',
  'INGENIERO AGRO-INDUSTRIAL',
  'INGENIERO AGRONOMICO',
  'INGENIERO AGRONOMO',
  'INGENIERO AGRONOMO(A)',
  'INGENIERO AGROPECUARIO',
  'INGENIERO AGRPECUARIO',
  'INGENIERO AMBIENTAL',
  'INGENIERO AMBIENTAL Y DE SANEAMIENTO',
  'INGENIERO AMBIENTAL Y DEL SANEAMIENTO',
  'INGENIERO AMBIENTAL Y SANITARIO',
  'INGENIERO AUERONAUTICO',
  'INGENIERO AUTOMOTRIZ',
  'INGENIERO BIOMEDICO',
  'INGENIERO BIOQUIMICO',
  'INGENIERO BIOTECNOLOGICO',
  'INGENIERO CATASTRAL Y GEODESTA',
  'INGENIERO CIVIL',
  'INGENIERO COMERCIAL',
  'INGENIERO DE ALIMENTOS',
  'INGENIERO DE AUDIO',
  'INGENIERO DE BIOPRODUCCION',
  'INGENIERO DE DATOS E INTELIGENCIA ARTIFICIAL',
  'INGENIERO DE DATOS Y SOFTWARE',
  'INGENIERO DE DISEÑO DE PRODUCTO',
  'INGENIERO DE DISEÑO Y AUTOMATIZACION ELECTRONICA',
  'INGENIERO DE GAS Y PETROLEO',
  'INGENIERO DE LAS ARTES DIGITALES',
  'INGENIERO DE MANTENIMIENTO',
  'INGENIERO DE MANUFACTURA',
  'INGENIERO DE MATERIALES',
  'INGENIERO DE MERCADEO',
  'INGENIERO DE MERCADOS',
  'INGENIERO DE MINAS',
  'INGENIERO DE PETROLEO Y GAS',
  'INGENIERO DE PETROLEOS',
  'INGENIERO DE PLASTICOS',
  'INGENIERO DE POLIMEROS',
  'INGENIERO DE PROCESOS',
  'INGENIERO DE PROCESOS DE REFINACION Y PETROQUIMICOS',
  'INGENIERO DE PROCESOS INDUSTRIALES',
  'INGENIERO DE PRODUCCION',
  'INGENIERO DE PRODUCCION AGRICOLA',
  'INGENIERO DE PRODUCCION AGROINDUSTRIAL',
  'INGENIERO DE PRODUCCION ANIMAL',
  'INGENIERO DE PRODUCCION BIOTECNOLOGICA',
  'INGENIERO DE PRODUCCION INDUSTRIAL',
  'INGENIERO DE PRODUCTIVIDAD Y CALIDAD',
  'INGENIERO DE RECURSOS HIDRICOS',
  'INGENIERO DE SISTEMA',
  'INGENIERO DE SISTEMAS',
  'INGENIERO DE SISTEMAS CON ENFASIS EN ADMINISTRACION E INFORMATICA',
  'INGENIERO DE SISTEMAS CON ENFASIS EN SOFWARE',
  'INGENIERO DE SISTEMAS CON ENFASIS EN TELECOMUNICACIONES',
  'INGENIERO DE SISTEMAS DE INFORMACION',
  'INGENIERO DE SISTEMAS E INFORMATICA',
  'INGENIERO DE SISTEMAS INFORMATICOS',
  'INGENIERO DE SISTEMAS Y COMPUTACION',
  'INGENIERO DE SISTEMAS Y TELECOMUNICACIONES',
  'INGENIERO DE SISTEMAS Y TELEMATICA',
  'INGENIERO DE SOFTWARE',
  'INGENIERO DE SOFTWARE Y COMPUTACION',
  'INGENIERO DE SOFTWARE Y COMUNICACIONES',
  'INGENIERO DE SONIDO',
  'INGENIERO DE TELECOMUINICACIONES',
  'INGENIERO DE TELECOMUNICACIONES',
  'INGENIERO DE TELECOMUNICACIONES E INFORMATICA',
  'INGENIERO DEL DESARROLLO AMBIENTAL',
  'INGENIERO DEL MEDIO AMBIENTE',
  'INGENIERO ELECTRICISTA',
  'INGENIERO ELECTRICISTA.',
  'INGENIERO ELECTRICO',
  'INGENIERO ELECTROMECANICO',
  'INGENIERO ELECTRONICO',
  'INGENIERO ELECTRONICO Y DE TELECOMUNICACIONES',
  'INGENIERO ELECTRONICO Y EN TELECOMUNICACIONES',
  'INGENIERO ELECTRONICO Y TELECOMUNICACIONES',
  'INGENIERO EMPRESARIAL',
  'INGENIERO EN AGROECOLOGIA',
  'INGENIERO EN ANALITICA DE DATOS',
  'INGENIERO EN AUTOMATICA INDUSTRIAL',
  'INGENIERO EN AUTOMATIZACION',
  'INGENIERO EN AUTOMATIZACION INDUSTRIAL',
  'INGENIERO EN BIOTECNOLOGIA',
  'INGENIERO EN CALIDAD',
  'INGENIERO EN CIENCIAS COMPUTACIONALES',
  'INGENIERO EN CIENCIAS DE LA COMPUTACION E INTELIGENCIA ARTIFICIAL',
  'INGENIERO EN COMPUTACION',
  'INGENIERO EN CONTROL ELECTRONICO E INSTRUMENTACION',
  'INGENIERO EN CONTROL Y AUTOMATIZACION INDUSTRIAL',
  'INGENIERO EN DISEÑO DE ENTRETENIMIENTO DIGITAL',
  'INGENIERO EN DISEÑO DE MAQUINAS Y PRODUCTOS INDUSTRIALES',
  'INGENIERO EN DISEÑO INDUSTRIAL',
  'INGENIERO EN DISTRIBUCION Y REDES ELECTRICAS',
  'INGENIERO EN ELECTRONICA',
  'INGENIERO EN ELECTRONICA Y TELECOMUNICACIONES',
  'INGENIERO EN ENERGIA',
  'INGENIERO EN ENERGIAS',
  'INGENIERO EN ENERGIAS RENOVABLES',
  'INGENIERO EN HIGIENE Y SEGURIDAD INDUSTRIAL',
  'INGENIERO EN HIGIENE Y SEGURIDAD OCUPACIONAL',
  'INGENIERO EN INFORMATICA',
  'INGENIERO EN INSTRUMENTACION Y CONTROL',
  'INGENIERO EN INTELIGENCIA DE NEGOCIOS',
  'INGENIERO EN LOGISTICA',
  'INGENIERO EN LOGISTICA Y OPERACIONES',
  'INGENIERO EN MADERAS',
  'INGENIERO EN MANTENIMIENTO INDUSTRIAL HOSPITALARIO',
  'INGENIERO EN MECATRONICA',
  'INGENIERO EN MULTIMEDIA',
  'INGENIERO EN PROCESOS INDUSTRIALES',
  'INGENIERO EN PRODUCCION',
  'INGENIERO EN PRODUCCION ACUICOLA',
  'INGENIERO EN PRODUCCION AGROPECUARIA',
  'INGENIERO EN SEGURIDAD DE LA INFORMAICION',
  'INGENIERO EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'INGENIERO EN SEGURIDAD Y SALUD PARA EL TRABAJO',
  'INGENIERO EN SISTEMAS Y TELECOMUNICACIONES',
  'INGENIERO EN SOFTWARE',
  'INGENIERO EN TECNOLOGIAS DE LA INFORMACION Y LAS COMUNICACIONES',
  'INGENIERO EN TELECOMUNICACIONES',
  'INGENIERO EN TELEINFORMATICA',
  'INGENIERO EN TRANSPORTE Y VIAS',
  'INGENIERO ENERGETICO',
  'INGENIERO ESTADISTICO',
  'INGENIERO FINANCIERO',
  'INGENIERO FINANCIERO Y DE NEGOCIOS',
  'INGENIERO FISICO',
  'INGENIERO FORESTAL',
  'INGENIERO GEOGRAFO',
  'INGENIERO GEOGRAFO Y AMBIENTAL',
  'INGENIERO GEOLOGO',
  'INGENIERO INDUSTRIAL',
  'INGENIERO INDUSTRIAL / INGENIERA INDUSTRIAL',
  'INGENIERO INDUSTRIAL DE ALIMENTOS',
  'INGENIERO INDUSTRIAL.',
  'INGENIERO INFORMATICA',
  'INGENIERO INFORMATICO',
  'INGENIERO LOGISTICO',
  'INGENIERO MARINO-COSTERO',
  'INGENIERO MATEMATICO',
  'INGENIERO MECANICO',
  'INGENIERO MECANICO Y DE MANUFACTURA',
  'INGENIERO MECATRONICO',
  'INGENIERO METALURGICO',
  'INGENIERO MULTIMEDIA',
  'INGENIERO NAVAL',
  'INGENIERO NAVAL-ESPECIALIDAD CONSTRUCCIONES',
  'INGENIERO NAVAL-ESPECIALIDAD ELECTRONICA',
  'INGENIERO NAVAL-ESPECIALIDAD MECANICA',
  'INGENIERO O INGENIERA AMBIENTAL',
  'INGENIERO OCEANOGRAFICO',
  'INGENIERO PECUARIO',
  'INGENIERO PESQUERO',
  'INGENIERO PETROQUIMICO',
  'INGENIERO QUIMICO',
  'INGENIERO SANITARIO',
  'INGENIERO SANITARIO Y AMBIENTAL',
  'INGENIERO TELEINFORMATICO',
  'INGENIERO TELEMATICO',
  'INGENIERO TEXTIL',
  'INGENIERO TOPOGRAFICO',
  'INGENIERO TOPOGRAFO',
  'INGENIERO URBANISTA',
  'INGENIERO(A) ADMINISTRADOR(A)',
  'INGENIERO(A) AGRICOLA',
  'INGENIERO(A) AGROECOLOGICO(A)',
  'INGENIERO(A) AGROFORESTAL',
  'INGENIERO(A) AGROINDUSTRIAL',
  'INGENIERO(A) AGRONOMO(A)',
  'INGENIERO(A) AMBIENTAL',
  'INGENIERO(A) AMBIENTAL Y SANITARIO',
  'INGENIERO(A) BIOLOGICO(A)',
  'INGENIERO(A) CIVIL',
  'INGENIERO(A) COMERCIAL',
  'INGENIERO(A) DE CONTROL',
  'INGENIERO(A) DE MANUFACTURA',
  'INGENIERO(A) DE MINAS Y METALURGIA',
  'INGENIERO(A) DE PETROLEOS',
  'INGENIERO(A) DE PROCESOS',
  'INGENIERO(A) DE PRODUCCION',
  'INGENIERO(A) DE SISTEMAS E INFORMATICA',
  'INGENIERO(A) DE SOFTWARE',
  'INGENIERO(A) DE SONIDO',
  'INGENIERO(A) DE TELECOMUNICACIONES',
  'INGENIERO(A) ELECTRICISTA',
  'INGENIERO(A) ELECTRONICO(A)',
  'INGENIERO(A) EN CONTROL',
  'INGENIERO(A) EN CONTROL Y AUTOMATIZACION',
  'INGENIERO(A) EN ELECTRONICA',
  'INGENIERO(A) EN MECATRONICA',
  'INGENIERO(A) EN REDES Y TELECOMUNICACIONES',
  'INGENIERO(A) EN TELEMATICA',
  'INGENIERO(A) FISICO(A)',
  'INGENIERO(A) FORESTAL',
  'INGENIERO(A) GEOLOGO(A)',
  'INGENIERO(A) GEOMATICO (A)',
  'INGENIERO(A) INFORMATICA',
  'INGENIERO(A) MECANICO(A)',
  'INGENIERO(A) MULTIMEDIA',
  'INGENIERO(A) QUIMICO(A)',
  'INGENIERO(A) SANITARIO',
  'INGENIERO(A) TOPOGRAFICO (A) Y GEOMATICO (A)',
  'INSTRUMENTACION QUIRURGICA',
  'INSTRUMENTADOR (A) QUIRURGICO',
  'INSTRUMENTADOR (A) QUIRURGICO (A)',
  'INSTRUMENTADOR QUIRURGICO',
  'INSTRUMENTADOR QUIRURGICO PROFESIONAL',
  'INTERNACIONALISTA',
  'INTERPRETE PARA SORDOS Y GUIA-INTERPRETE PARA SORDOCIEGOS',
  'INTERPRETE PROFESIONAL DE LA LENGUA DE SEÑAS COLOMBIANA',
  'INTERPRETE Y TRADUCTOR LENGUA DE SEÑAS COLOMBIANA - ESPAÑOL',
  'LICECENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION RELIGIOSA',
  'LICECENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN MATEMATICAS',
  'LICENCIADA (O) EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADA / LICENCIADO EN PEDAGOGIA DE LA MADRE TIERRA',
  'LICENCIADO (A) EN CIENCIAS DEL DEPORTE',
  'LICENCIADO (A) EN CIENCIAS NATURALES',
  'LICENCIADO (A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA',
  'LICENCIADO (A) EN EDUCACION BASICA ENFASIS EN MATEMATICAS',
  'LICENCIADO (A) EN EDUCACION BASICA, CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO (A) EN EDUCACION BASICA, CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA.',
  'LICENCIADO (A) EN EDUCACION CON ESPECIALIDAD EN BIOLOGIA',
  'LICENCIADO (A) EN EDUCACION INFANTIL',
  'LICENCIADO (A) EN FISICA',
  'LICENCIADO (A) EN GEOGRAFIA E HISTORIA',
  'LICENCIADO (A) EN LENGUAS MODERNAS CON ENFASIS EN INGLES',
  'LICENCIADO (A) EN LINGUISTICA Y SEMIOTICA',
  'LICENCIADO (A) EN LINGÜISTICA Y SEMIOTICA',
  'LICENCIADO (A) EN PEDAGOGIA DIGITAL PARA LA PRIMERA INFANCIA',
  'LICENCIADO (A) EN PEDAGOGIA INFANTIL.',
  'LICENCIADO (A) EN QUIMICA',
  'LICENCIADO (A) EN TEATRO',
  'LICENCIADO (A) HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO DIGITAL EN HUMANIDADES CLASICAS Y LENGUA CASTELLANA',
  'LICENCIADO EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN ADMINISTRACION EDUCATIVA',
  'LICENCIADO EN ADMINISTRACION Y LEGISLACION EDUCATIVA',
  'LICENCIADO EN ADMINISTRACION Y SUPERVISION EDUCATIVA',
  'LICENCIADO EN AREAS TECNICAS',
  'LICENCIADO EN ARTE ESCENICO',
  'LICENCIADO EN ARTES',
  'LICENCIADO EN ARTES ESCENICAS',
  'LICENCIADO EN ARTES ESCENICAS CON ENFASIS EN TEATRO',
  'LICENCIADO EN ARTES PLASTICAS',
  'LICENCIADO EN ARTES PLASTICAS Y VISUALES',
  'LICENCIADO EN ARTES VISUALES',
  'LICENCIADO EN ARTES Y EN TECNOLOGIAS PARA LA CREACION',
  'LICENCIADO EN BASICA CON ENFASIS EN CIENCIAS SOCIALES, HISTORIA, GEOGRAFIA, CONSTITUCION POLITICA Y',
  'LICENCIADO EN BASICA CON ENFASIS EN EDUCACION FISICA RECREACION Y DEPORTES',
  'LICENCIADO EN BASICA CON ENFASIS EN HUMANISTICA, LENGUA CASTELLANA E IDIOMAS EXTRANJEROS.',
  'LICENCIADO EN BASICA CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN BASICA ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN BASICA PRIMARIA',
  'LICENCIADO EN BASICA PRIMARIA CON ENFASIS EN ESTETICA',
  'LICENCIADO EN BILINGÜISMO CON ENFASIS EN ESPAÑOL E INGLES',
  'LICENCIADO EN BILINGÜISMO CON ENFASIS EN INGLES',
  'LICENCIADO EN BIOLOGIA',
  'LICENCIADO EN BIOLOGIA CON ENFASIS EN EDUCACION AMBIENTAL',
  'LICENCIADO EN BIOLOGIA Y QUIMICA',
  'LICENCIADO EN CATEQUESIS',
  'LICENCIADO EN CIENCIA NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN CIENCIAS AGROPECUARIAS',
  'LICENCIADO EN CIENCIAS BASICAS CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN CIENCIAS DE LA EDUCACION',
  'LICENCIADO EN CIENCIAS DE LA EDUCACION FILOLOGIA Y LITERATURA',
  'LICENCIADO EN CIENCIAS DE LA EDUCACION, ESPECIALIDAD MATEMAT. CON ENFASIS EN COMPUTACION',
  'LICENCIADO EN CIENCIAS DE LA EDUCACION-ESPECIALIZACION EN QUIMICA',
  'LICENCIADO EN CIENCIAS DE LA EDUCACION-FISICA',
  'LICENCIADO EN CIENCIAS DEL DEPORTE Y LA EDUCACION FISICA',
  'LICENCIADO EN CIENCIAS NATURALES',
  'LICENCIADO EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN CIENCIAS NATURALES Y MEDIO AMBIENTE',
  'LICENCIADO EN CIENCIAS NATURALES Y SALUD',
  'LICENCIADO EN CIENCIAS NATURALES: FISICA, QUIMICA Y BIOLOGIA',
  'LICENCIADO EN CIENCIAS RELIGIOSAS',
  'LICENCIADO EN CIENCIAS RELIGIOSAS CON ENFASIS EN CATEQUESIS Y EDUCACION SEXUAL.',
  'LICENCIADO EN CIENCIAS RELIGIOSAS Y ETICA',
  'LICENCIADO EN CIENCIAS SOCIALES',
  'LICENCIADO EN CIENCIAS SOCIALES CON ENFASIS EN EDUCACION BASICA',
  'LICENCIADO EN CIENCIAS SOCIALES ENFASIS EN DEMOCRACIA',
  'LICENCIADO EN CIENCIAS SOCIALES Y DESARROLLO LOCAL',
  'LICENCIADO EN CIENCIAS SOCIALES Y GESTION DEL DESARROLLO',
  'LICENCIADO EN CIENCIAS SOCIALES, CONSTITUCION Y DEMOCRACIA',
  'LICENCIADO EN CIENCIAS SOCIALES, ETICA,DESARROLLO Y PAZ',
  'LICENCIADO EN CIENCIAS SOCIALES.',
  'LICENCIADO EN COMERCIO',
  'LICENCIADO EN COMERCIO Y CONTADURIA',
  'LICENCIADO EN COMUNICACION E INFORMATICA EDUCATIVA',
  'LICENCIADO EN CULTURA FISICA, RECREACION, EDUCACION FISICA Y DEPORTE',
  'LICENCIADO EN DANZA',
  'LICENCIADO EN DANZA CLASICA',
  'LICENCIADO EN DEPORTE',
  'LICENCIADO EN DIBUJO DE LA CONSTRUCCION',
  'LICENCIADO EN DIBUJO MECANICO',
  'LICENCIADO EN DIDACTICA DE LAS CIENCIAS NATURALES PARA LA EDUCACION SECUNDARIA Y MEDIA VOCACIONAL',
  'LICENCIADO EN DOCENCIA COMERCIAL',
  'LICENCIADO EN DOCENCIA DE COMPUTADORES',
  'LICENCIADO EN DOCENCIA DE PROCESOS INDUSTRIALES',
  'LICENCIADO EN DOCENCIA DE SISTEMAS Y COMPUTACION',
  'LICENCIADO EN DOCENCIA DEL AREA AGROPECUARIA',
  'LICENCIADO EN DOCENCIA PARA TECNOLOGOS PROFESIONALES',
  'LICENCIADO EN EDUCACION',
  'LICENCIADO EN EDUCACION - HISTORIA Y FILOSOFIA',
  'LICENCIADO EN EDUCACION - IDIOMAS',
  'LICENCIADO EN EDUCACION AGROAMBIENTAL Y CIENCIAS NATURALES',
  'LICENCIADO EN EDUCACION AMBIENTAL',
  'LICENCIADO EN EDUCACION AMBIENTAL Y DESARROLLO COMUNITARIO',
  'LICENCIADO EN EDUCACION ARTES PLASTICAS',
  'LICENCIADO EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION ARTISTICA Y ARTES ESCENICAS',
  'LICENCIADO EN EDUCACION ARTISTICA Y CULTURAL',
  'LICENCIADO EN EDUCACION BASICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFAIS EN HUMANIDADES: ESPAÑOL E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFAIS EN HUMANIDADES: LENGUA CASTELLANA E IDIOMA EXTRANJERO:INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN ARTE Y FOLKLORE',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN ARTES ESCENICAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN CIENCIAS BASICAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN CIENCIAS SOCIELES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN COMERCIO Y CONTADURIA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA Y CULTURAL',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA Y CULTURAL: ARTES REPRESENTATIVAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA Y CULTURAL: MUSICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA RECREACION Y DEPORTE',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA,RECREACION Y DEPORTE',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN ESPAÑOL Y LITERATURA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN ETICA Y VALORES HUMANOS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES - INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES E IDIOMAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, ESPAÑOL Y LENGUAS EXTRANJERAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA E IDIOMA EXTRANJERO',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA Y LITERATURA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA, E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES: ESPAÑOL E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES: LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN IDIOMAS EXTRANJEROS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN INFORMATICA EDUCATIVA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN LENGUA CASTELLANA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN LENGUA INGLESA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN LENGUAS EXTRANJERAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN LENGUAS EXTRANJERAS INGLES',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN MATEMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN MATEMATICA E INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN MATEMATICAS E INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN MATEMATICAS, HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN TECNOLOGIA E INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN TECNOLOGIA EN INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA CON ENFASIS EN...',
  'LICENCIADO EN EDUCACION BASICA CONËNFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA EN DANZA',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN CIENCIAS SOCIALES Y CULTURA DEMOCRATICA',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN EDUCACION ETICA, VALORES HUMANOS',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN EDUCACION FISICA, RECREACION Y DEPORTE',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN INFORMATICA EDUCATIVA',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN LENGUA CASTELLANA',
  'LICENCIADO EN EDUCACION BASICA ENFASIS EN TECNOLOGIA INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL.',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA CON ENFASIS EN EDUCACIONFISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA CON ENFASIS EN INGLES Y EN FRANCES',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA ENFASIS EN CIENCIAS NATURALES',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA PRIMARIA ENFASIS EN LENGUA CASTELLANA',
  'LICENCIADO EN EDUCACION BASICA SECUNDARIA Y MEDIA CON ENFASIS ENEDUCACION ETICA Y VALORES HUMANOS',
  'LICENCIADO EN EDUCACION BASICA Y MEDIA VOCACIONAL AREA EDUCACION FISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION BASICA Y PROMOCION DE LA COMUNIDAD',
  'LICENCIADO EN EDUCACION BASICA, CON ENFASIS EN ARTES PLASTICAS E INFORMATICA',
  'LICENCIADO EN EDUCACION BASICA, ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA: ENFASIS EN EDUCACION FISICA',
  'LICENCIADO EN EDUCACION BASICA: ENFASIS EN INGLES',
  'LICENCIADO EN EDUCACION BASICA: INGLES',
  'LICENCIADO EN EDUCACION BASICA:ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION BASICA; ENFASIS EN MATEMATICAS',
  'LICENCIADO EN EDUCACION BILINGUE',
  'LICENCIADO EN EDUCACION BILINGUE CON ENAFIS EN...',
  'LICENCIADO EN EDUCACION BILINGUE CON ENFASIS EN LA ENSEÑANZA DEL INGLES',
  'LICENCIADO EN EDUCACION BILINGUE-ESPAÑOL-INGLES',
  'LICENCIADO EN EDUCACION CAMPESINA Y RURAL',
  'LICENCIADO EN EDUCACION CIENCIAS NATURALES',
  'LICENCIADO EN EDUCACION COMUNITARIA',
  'LICENCIADO EN EDUCACION COMUNITARIA CON ENFASIS EN DERECHOS HUMANOS',
  'LICENCIADO EN EDUCACION CON ENFASIS EN BIOLOGIA Y QUIMICA',
  'LICENCIADO EN EDUCACION CON ENFASIS EN CIENCIAS NATURALES',
  'LICENCIADO EN EDUCACION CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN EDUCACION CON ENFASIS EN CIENCIAS SOCIALES Y AMBIENTALES',
  'LICENCIADO EN EDUCACION CON ENFASIS EN CIENCIAS SOCIALES Y COMUNIDAD',
  'LICENCIADO EN EDUCACION CON ENFASIS EN DIBUJO ARQUITECTONICO,QUIMICA INDUSTRIAL,CIENCIAS AGROPECUARIAS, SOCIOLOGIA, PROMOCION SOCIAL, SALUD, ADMON, CIENCIAS POLICIALES, EDUCACION FISICA, RECREACION Y DEPORTES, INFORMATICA, ELECTROMECANICA, TRADUC. TURISTI',
  'LICENCIADO EN EDUCACION CON ENFASIS EN EDUCACION ESPECIAL',
  'LICENCIADO EN EDUCACION CON ENFASIS EN ESPAÑOL Y LITERATURA',
  'LICENCIADO EN EDUCACION CON ENFASIS EN INGLES',
  'LICENCIADO EN EDUCACION CON ENFASIS EN MATEMATICAS Y FISICA',
  'LICENCIADO EN EDUCACION CON ESPECIALIDAD EN MATEMATICAS',
  'LICENCIADO EN EDUCACION DE ADULTOS',
  'LICENCIADO EN EDUCACION ENFASIS EN ADMINISTRACION Y SUPERV.LENGUCASTELLANA, CIENCIAS NATURALES, MATE',
  'LICENCIADO EN EDUCACION ENFASIS EN AREAS TECNOLOGICAS',
  'LICENCIADO EN EDUCACION ENFASIS EN CIENCIAS ECONOMICAS',
  'LICENCIADO EN EDUCACION ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN EDUCACION ENFASIS EN ESPAÑOL Y LITERATURA',
  'LICENCIADO EN EDUCACION ENFASIS EN HUMANIDADES',
  'LICENCIADO EN EDUCACION ENFASIS EN INFORMATICA',
  'LICENCIADO EN EDUCACION ENFASIS EN MATEMATICAS',
  'LICENCIADO EN EDUCACION ESPECIAL',
  'LICENCIADO EN EDUCACIÖN ESPECIAL',
  'LICENCIADO EN EDUCACION ESPECIAL (ANTERIORMENTE: LICENCIADO EN EDUCACION ESPECIAL CON ENFASIS EN RET',
  'LICENCIADO EN EDUCACION ESPECIAL CON ENFASIS EN PEDAGOGIA DE LA INTEGRACION',
  'LICENCIADO EN EDUCACION ESPECIAL-RETARDO EN EL DESARROLLO',
  'LICENCIADO EN EDUCACION FIISICA Y DEPORTES',
  'LICENCIADO EN EDUCACION FISICA',
  'LICENCIADO EN EDUCACION FISICA DEPORTES Y RECREACION',
  'LICENCIADO EN EDUCACION FISICA PARA LA EDUCACION BASICA',
  'LICENCIADO EN EDUCACION FISICA RECREACION Y DEPORTE',
  'LICENCIADO EN EDUCACION FISICA RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION FISICA Y DEPORTE',
  'LICENCIADO EN EDUCACION FISICA Y DEPORTES',
  'LICENCIADO EN EDUCACION FISICA Y RECREACION',
  'LICENCIADO EN EDUCACION FISICA Y SALUD',
  'LICENCIADO EN EDUCACION FISICA, DEPORTE Y RECREACION ENFASIS RURAL',
  'LICENCIADO EN EDUCACION FISICA, DEPORTES Y RECREACION',
  'LICENCIADO EN EDUCACION FISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION FISICA,RECREACION Y DEPORTE',
  'LICENCIADO EN EDUCACION INDIGENA',
  'LICENCIADO EN EDUCACION INDUSTRIAL',
  'LICENCIADO EN EDUCACION INFANTIL',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN EDU.ARTISTICA,EDU.FISICA,REC,DEP,TECN.E INFORMATICA',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN EDUCACION ETICA Y VALORES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN EDUCACION FISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN ESPAÑOL Y LITERATURA',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN ETICA Y VALORES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN HUMANIDADES (LENGUA CASTELLANA E INGLES)',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN MATEMATICA',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION INFANTIL CON ENFASIS EN TECNOLOGIA E INFORMATICA',
  'LICENCIADO EN EDUCACION INFANTIL ENFASIS EN MATEMATICAS Y CASTELLANO',
  'LICENCIADO EN EDUCACION INFANTIL, CON ENFASIS EN EDUCACION FISICA, RECREACION Y DEPORTES',
  'LICENCIADO EN EDUCACION INICIAL',
  'LICENCIADO EN EDUCACION MUSICAL',
  'LICENCIADO EN EDUCACION PARA LA CONVIVENCIA Y EL DESARROLLO HUMANO',
  'LICENCIADO EN EDUCACION PARA LA DEMOCRACIA',
  'LICENCIADO EN EDUCACION PARA LA INFANCIA',
  'LICENCIADO EN EDUCACION PARA LA PRIMERA INFANCIA',
  'LICENCIADO EN EDUCACION PARA LA PRIMERA INFANCIA.',
  'LICENCIADO EN EDUCACION PARA LA SALUD DEL ESCOLAR Y EL ADOLESCENTE',
  'LICENCIADO EN EDUCACION POPULAR Y DE ADULTOS',
  'LICENCIADO EN EDUCACION PREESCOLAR',
  'LICENCIADO EN EDUCACION PREESCOLAR CON ENFASIS EN INGLES',
  'LICENCIADO EN EDUCACION PREESCOLAR Y BASICA PRIMARIA',
  'LICENCIADO EN EDUCACION PREESCOLARY BASICA PRIMARIA ENFASIS EN LENGUA CASTELLANA O MATEMATICAS',
  'LICENCIADO EN EDUCACION PRIMARIA',
  'LICENCIADO EN EDUCACION RELIGIOSA',
  'LICENCIADO EN EDUCACION RELIGIOSA Y MORAL',
  'LICENCIADO EN EDUCACION RURAL',
  'LICENCIADO EN EDUCACION RURAL CON ENFASIS EN CIENCIAS AGROPECUARIAS',
  'LICENCIADO EN EDUCACION RURAL CON ESPECIALIDAD EN DESARROLLO CO-MUNITARIO. METODOLOGIA SEMIPRESENCIA',
  'LICENCIADO EN EDUCACION TECNOLOGIA EN DIFERENTES ENFASIS',
  'LICENCIADO EN EDUCACION TECNOLOGIA INFORMATICA',
  'LICENCIADO EN EDUCACION-MECANICA INDUSTRIAL',
  'LICENCIADO EN EDUCACION-TEOLOGIA',
  'LICENCIADO EN ELECTRICIDAD Y ELECTRONICA',
  'LICENCIADO EN ELECTROMECANICA',
  'LICENCIADO EN ELECTRONICA',
  'LICENCIADO EN ELECTROTECNIA',
  'LICENCIADO EN EN BILINGÜISMO CON ENFASIS EN LA ENSEÑANZA DEL INGLES',
  'LICENCIADO EN EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO EN EN EDUCACION INFANTIL',
  'LICENCIADO EN ENSEÑANZA DE LA LENGUA CASTELLANA',
  'LICENCIADO EN ENSEÑANZA DE LA LENGUA INGLESA',
  'LICENCIADO EN ENSEÑANZA DE LA LENGUA MATERNA',
  'LICENCIADO EN ENSEÑANZA DE LAS TECNOLOGIAS',
  'LICENCIADO EN ENSEÑANZA DEL INGLES',
  'LICENCIADO EN ESPA#OL Y LITERATURA',
  'LICENCIADO EN ESPAÑOL - FRANCES',
  'LICENCIADO EN ESPAÑOL E INGLES',
  'LICENCIADO EN ESPAÑOL Y COMUNICACION AUDIOVISUAL',
  'LICENCIADO EN ESPAÑOL Y COMUNICACION EDUCATIVA',
  'LICENCIADO EN ESPAÑOL Y FILOLOGIA',
  'LICENCIADO EN ESPAÑOL Y FILOLOGIA CLASICA',
  'LICENCIADO EN ESPAÑOL Y LENGUAS EXTRANJERAS',
  'LICENCIADO EN ESPAÑOL Y LENGUAS EXTRANJERAS INGLES Y FRANCES',
  'LICENCIADO EN ESPAÑOL Y LITERATURA',
  'LICENCIADO EN ESTUDIOS BIBLICOS',
  'LICENCIADO EN ESTUDIOS SOCIALES Y HUMANOS',
  'LICENCIADO EN ETICA Y CIENCIAS RELIGIOSAS',
  'LICENCIADO EN ETICA Y DESARROLLO HUMANO',
  'LICENCIADO EN ETNOEDUCACION',
  'LICENCIADO EN ETNOEDUCACION CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN ETNOEDUCACION CON ENFASIS EN COMUNICACION Y LINGUISTICA',
  'LICENCIADO EN ETNOEDUCACION CON ENFASIS EN COMUNIDAD Y NATURALEZA',
  'LICENCIADO EN ETNOEDUCACION CON ENFASIS EN SALUD COMUNITARIA',
  'LICENCIADO EN ETNOEDUCACION E INTERCULTURALIDAD',
  'LICENCIADO EN ETNOEDUCACION ENFASIS EN ECONOMIA Y GESTION COMUNITARIA',
  'LICENCIADO EN ETNOEDUCACION PARA BASICA CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO EN ETNOEDUCACION PARA BASICA CON ENFASIS EN CIENCIAS SOCIALES Y CULTURA',
  'LICENCIADO EN ETNOEDUCACION PARA BASICA CON ENFASIS EN LENGUA CASTELLANA Y BILINGUISMO',
  'LICENCIADO EN ETNOEDUCACION PARA BASICA CON ENFASIS EN MATEMATICAS',
  'LICENCIADO EN ETNOEDUCACION Y DESARROLLO COMUNITARIO',
  'LICENCIADO EN ETNOEDUCACION Y PROYECTO SOCIAL',
  'LICENCIADO EN FILOLOGIA E IDIOMAS',
  'LICENCIADO EN FILOLOGIA E IDIOMAS INGLES-FRANCES-ESPAÑOL',
  'LICENCIADO EN FILOLOGIA ESPAÑOLA',
  'LICENCIADO EN FILOSOFIA',
  'LICENCIADO EN FILOSOFIA CON ENFASIS EN TEORIA POLITICA',
  'LICENCIADO EN FILOSOFIA E HISTORIA',
  'LICENCIADO EN FILOSOFIA E HISTORIA (MODIFICADO ACTUALMENTE POR LICENCIADO EN FILOSOFIA CON ENFASIS',
  'LICENCIADO EN FILOSOFIA Y CIENCIAS RELIGIOSAS',
  'LICENCIADO EN FILOSOFIA Y ESTUDIOS POLITICOS',
  'LICENCIADO EN FILOSOFIA Y HUMANIDADES',
  'LICENCIADO EN FILOSOFIA Y LETRAS',
  'LICENCIADO EN FILOSOFIA Y PEDAGOGIA',
  'LICENCIADO EN FILOSOFIA Y TEOLOGIA',
  'LICENCIADO EN FISICA',
  'LICENCIADO EN FISICA Y MATEMATICAS',
  'LICENCIADO EN FORMACION ESTETICA',
  'LICENCIADO EN GEOGRAFIA E HISTORIA',
  'LICENCIADO EN GESTION DE PROYECTOS EN EDUCACION BILINGUE',
  'LICENCIADO EN GESTION EDUCATIVA',
  'LICENCIADO EN GESTION Y DESARROLLO PEDAGOGICO DE LA EDUCACION PREESCOLAR',
  'LICENCIADO EN HISTORIA',
  'LICENCIADO EN HISTORIA DE COLOMBIA',
  'LICENCIADO EN HISTORIA Y FILOSOFIA',
  'LICENCIADO EN HUMANIDADES',
  'LICENCIADO EN HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO EN IDIOMA EXTRANJERO - INGLES',
  'LICENCIADO EN IDIOMAS',
  'LICENCIADO EN IDIOMAS- ESPAÑOL-INGLES',
  'LICENCIADO EN IDIOMAS MODERNOS ESPAÑOL-FRANCES',
  'LICENCIADO EN IDIOMAS MODERNOS ESPAÑOL-INGLES',
  'LICENCIADO EN INFORMATICA',
  'LICENCIADO EN INFORMATICA EDUCATIVA',
  'LICENCIADO EN INFORMATICA Y MEDIOS AUDIOVISUALES',
  'LICENCIADO EN INFORMATICA Y NUEVAS TECNOLOGIAS',
  'LICENCIADO EN INFORMATICA Y TECNOLOGIA',
  'LICENCIADO EN INGLES',
  'LICENCIADO EN INGLES Y BILINGÜISMO',
  'LICENCIADO EN INGLES Y ESPAÑOL',
  'LICENCIADO EN INGLES Y FRANCES',
  'LICENCIADO EN INGLES-ESPAÑOL',
  'LICENCIADO EN LENGUA CASTELLANA',
  'LICENCIADO EN LENGUA CASTELLANA E INGLES',
  'LICENCIADO EN LENGUA CASTELLANA Y LITERATURA',
  'LICENCIADO EN LENGUA EXTRANJERA INGLES',
  'LICENCIADO EN LENGUA INGLESA',
  'LICENCIADO EN LENGUAJE Y LITERATURA',
  'LICENCIADO EN LENGUAS EXTRANJERAS',
  'LICENCIADO EN LENGUAS EXTRANJERAS CON ENFASIS EN INGLES',
  'LICENCIADO EN LENGUAS EXTRANJERAS CON ENFASIS EN INGLES Y EN FRANCES',
  'LICENCIADO EN LENGUAS EXTRANJERAS INGLES',
  'LICENCIADO EN LENGUAS EXTRANJERAS INGLES- FRANCES',
  'LICENCIADO EN LENGUAS MODERNAS',
  'LICENCIADO EN LENGUAS MODERNAS CON ENFASIS EN INGLES',
  'LICENCIADO EN LENGUAS MODERNAS CON ENFASIS EN INGLES Y FRANCES',
  'LICENCIADO EN LENGUAS MODERNAS ENFASIS EN EDUCACION BILINGUE, TURISMO Y TRADUCCION',
  'LICENCIADO EN LENGUAS MODERNAS ESPAÑOL-INGLES',
  'LICENCIADO EN LENGUAS MODERNAS INGLES-FRANCES',
  'LICENCIADO EN LENGUAS MODERNAS, CON ENFASIS EN INGLES',
  'LICENCIADO EN LINGUISTICA Y EDUCACION INDIGENA',
  'LICENCIADO EN LINGUISTICA Y LITERATURA',
  'LICENCIADO EN LITERATURA',
  'LICENCIADO EN LITERATURA Y LENGUA CASTELLANA',
  'LICENCIADO EN LITERATURA Y LENGUA ESPAÑOLA',
  'LICENCIADO EN MATEMATICAS',
  'LICENCIADO EN MATEMATICAS CON ENFASIS EN COMPUTACION',
  'LICENCIADO EN MATEMATICAS E INFORMATICA',
  'LICENCIADO EN MATEMATICAS Y COMPUTACION',
  'LICENCIADO EN MATEMATICAS Y ESTADISTICA',
  'LICENCIADO EN MATEMATICAS Y FISICA',
  'LICENCIADO EN MATEMATICAS Y TECNOLOGIAS DE LA INFORMACION',
  'LICENCIADO EN MATERMATICAS',
  'LICENCIADO EN MUSICA',
  'LICENCIADO EN MUSICA Y DANZA',
  'LICENCIADO EN NECESIDADES EDUCATIVAS ESPECIALES CON ENFASIS EN VALORES HUMANOS',
  'LICENCIADO EN ORGANIZACION Y GESTION EMPRESARIAL',
  'LICENCIADO EN ORIENTACION Y CONSEJERIA',
  'LICENCIADO EN PEDAGOGIA CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO EN PEDAGOGIA DE ARTES Y SABERES ANCESTRALES',
  'LICENCIADO EN PEDAGOGIA DE LA EDUCACION BASICA ENFASIS EN MATEMATICAS, CIENCIASRELIGIOSAS TECNOLOGIA',
  'LICENCIADO EN PEDAGOGIA DE LA PRIMERA INFANCIA',
  'LICENCIADO EN PEDAGOGIA INFANTIL',
  'LICENCIADO EN PEDAGOGIA INFANTIL Y FAMILIAR',
  'LICENCIADO EN PEDAGOGIA PARA LA REVITALIZACION DE LENGUAS ORIGINARIAS',
  'LICENCIADO EN PEDAGOGIA PARA LA SALUD',
  'LICENCIADO EN PEDAGOGIA REEDUCATIVA',
  'LICENCIADO EN PEDAGOGIA SOCIAL PARA LA REHABILITACION',
  'LICENCIADO EN PEDAGOGIA SOCIAL Y COMUNITARIA',
  'LICENCIADO EN PEDAGOGIA Y DIDACTICA DE LA EDUCACION FISICA RECREACION Y DEPORTE',
  'LICENCIADO EN PEDAGOGIA Y DIDACTICA DE LA TECNOLOGIA E INFORMATICA',
  'LICENCIADO EN PEDAGOGIA Y DIDACTICA DE LAS CIENCIAS NATURALES Y MEDIO AMBIENTE',
  'LICENCIADO EN PEDAGOGIA Y DIDACTICA DE LAS CIENCIAS SOCIALES Y ARTES',
  'LICENCIADO EN PEDAGOGIA Y PSICOLOGIA',
  'LICENCIADO EN PEDAGOGIAS COMUNITARIAS',
  'LICENCIADO EN PREESCOLAR',
  'LICENCIADO EN PREESCOLAR MUSICAL',
  'LICENCIADO EN PRODUCCION AGROPECUARIA',
  'LICENCIADO EN PSICOLOGIA Y PEDAGOGIA',
  'LICENCIADO EN PSICOPEDAGOGIA',
  'LICENCIADO EN PSICOPEDAGOGIA CON ENFASIS EN ASESORIA EDUCATIVA',
  'LICENCIADO EN PSICOPEDAGOGIA PARA TECNOLOGOS Y PROFESIONALES',
  'LICENCIADO EN QUIMICA',
  'LICENCIADO EN QUIMICA Y BIOLOGIA',
  'LICENCIADO EN RECREACION',
  'LICENCIADO EN RECREACION Y TURISMO',
  'LICENCIADO EN SISTEMAS INFORMATICOS',
  'LICENCIADO EN SOCIALES',
  'LICENCIADO EN TEATRO',
  'LICENCIADO EN TECNOLOGIA',
  'LICENCIADO EN TECNOLOGIA E INFORMATICA',
  'LICENCIADO EN TECNOLOGIA EDUCATIVA',
  'LICENCIADO EN TECNOLOGIA Y EDUCACION',
  'LICENCIADO EN TEOLOGIA',
  'LICENCIADO O LICENCIADA EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO PARA LA EDUCACION BASICA EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO(A) EN ARTE DRAMATICO',
  'LICENCIADO(A) EN ARTE TEATRAL',
  'LICENCIADO(A) EN ARTE Y FOLKLORE',
  'LICENCIADO(A) EN ARTE Y FOLKLORE Y CULTURA',
  'LICENCIADO(A) EN ARTES ESCENICAS',
  'LICENCIADO(A) EN ARTES PLASTICAS',
  'LICENCIADO(A) EN ARTES VISUALES',
  'LICENCIADO(A) EN BILINGÜISMO: ESPAÑOL E INGLES',
  'LICENCIADO(A) EN BIOLOGIA',
  'LICENCIADO(A) EN BIOLOGIA Y EDUCACION AMBIENTAL',
  'LICENCIADO(A) EN BIOLOGIA Y QUIMICA',
  'LICENCIADO(A) EN CIENCIAS RELIGIOSAS',
  'LICENCIADO(A) EN CIENCIAS SOCIALES',
  'LICENCIADO(A) EN CIENCIAS SOCIALES Y FILOSOFIA',
  'LICENCIADO(A) EN COMERC IO',
  'LICENCIADO(A) EN CULTURA ,FISICA, RECREACION Y DEPORTES',
  'LICENCIADO(A) EN EDUCACIO PREESCOLAR',
  'LICENCIADO(A) EN EDUCACION ARTES PLASTICAS',
  'LICENCIADO(A) EN EDUCACION ARTISTICA',
  'LICENCIADO(A) EN EDUCACION ARTISTICA CON ENFASIS EN DANZA Y TEATRO',
  'LICENCIADO(A) EN EDUCACION ARTISTICA Y CULTURAL',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN CIENCIAS NATURALES Y EDUCCION AMBIENTAL',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA - MUSICA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA Y CULTURAL: ARTES REPRESENTATIVAS',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA, DEPORTES Y RECREACION',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISICA,RECREACION Y DEPORTES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION FISISCA, RECREACION Y DEPORTES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EDUCACION RELIGIOSA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EL AREA DE MATEMATICAS',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN EL AREA DE TECNOLOGIA E INFORMATICA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN ETICA, VALORES HUMANOS Y EDUCACION RELIGIOSA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES - LENGUA CASTELLANA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES E INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES Y LENGUA EXTRANJERA - INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES Y LENGUAS',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA E INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN HUMANIDADES, LENGUA EXTRANJERA-INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN IDIOMAS EXTRANJEROS',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN INFORMATICA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN LENGUA CASTELLANA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN LENGUA CASTELLANA E INGLES',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN MATEMATICAS',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS EN TECNOLOGIA E INFORMATICA',
  'LICENCIADO(A) EN EDUCACION BASICA CON ENFASIS MATEMATICAS E INFORMATICA',
  'LICENCIADO(A) EN EDUCACION BASICA EN DANZA',
  'LICENCIADO(A) EN EDUCACION BASICA EN, ENFASIS EN CIENCIAS NATURALES Y EDUCACION AMBIENTAL',
  'LICENCIADO(A) EN EDUCACION BASICA, CON ENFASIS EN CIENCIAS SOCIALES.',
  'LICENCIADO(A) EN EDUCACION BASICA, CON ENFASIS EN HUMANIDADES, LENGUA CASTELLANA',
  'LICENCIADO(A) EN EDUCACION EN PREESCOLAR',
  'LICENCIADO(A) EN EDUCACION ESPECIAL',
  'LICENCIADO(A) EN EDUCACION FISICA',
  'LICENCIADO(A) EN EDUCACION FISICA Y DEPORTE',
  'LICENCIADO(A) EN EDUCACION FISICA Y DEPORTES',
  'LICENCIADO(A) EN EDUCACION PARA ADULTOS',
  'LICENCIADO(A) EN EDUCACION PARA PERSONAS CON LIMITACIONES O CAPACIDADES EXCEPCIONALES',
  'LICENCIADO(A) EN EDUCACION POPULAR',
  'LICENCIADO(A) EN EDUCACION PREESCOLAR',
  'LICENCIADO(A) EN EDUCACION RURAL',
  'LICENCIADO(A) EN EDUCACION RURAL Y CAMPESINA',
  'LICENCIADO(A) EN EDUCACION RURAL Y DESARROLLO COMUNITARIO',
  'LICENCIADO(A) EN EDUCACION: ARTES PLASTICAS',
  'LICENCIADO(A) EN EDUCAION BASICA CON ENFASIS EN HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO(A) EN EN EDUCACION BASICA CON ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIADO(A) EN ESPAÑOL E INGLES',
  'LICENCIADO(A) EN ESPAÑOL Y LENGUAS EXTRANJERAS CON ENFASIS EN ...(ESPAÑOL, INGLES O FRANCES)',
  'LICENCIADO(A) EN ESPAÑOL Y LITERATURA',
  'LICENCIADO(A) EN FILOLOGIA E IDIOMAS',
  'LICENCIADO(A) EN FILOSOFIA',
  'LICENCIADO(A) EN FILOSOFIA Y CIENCIAS RELIGIOSAS',
  'LICENCIADO(A) EN FILOSOFIA Y CULTURA PARA LA PAZ',
  'LICENCIADO(A) EN FILOSOFIA Y EDUCACION RELIGIOSA',
  'LICENCIADO(A) EN FILOSOFIA Y LENGUA CASTELLANA',
  'LICENCIADO(A) EN FILOSOFIA Y LETRAS',
  'LICENCIADO(A) EN FILOSOFIA, ETICA Y VALORES HUMANOS',
  'LICENCIADO(A) EN FILOSOFIA, PENSAMIENTO POLITICO Y ECONOMICO',
  'LICENCIADO(A) EN HUMANIDADES',
  'LICENCIADO(A) EN HUMANIDADES Y LENGUA CASTELLANA',
  'LICENCIADO(A) EN HUMANIDADES, LENGUA CASTELLANA E INGLES',
  'LICENCIADO(A) EN IDIOMAS EXTRANJEROS',
  'LICENCIADO(A) EN INFORMATICA',
  'LICENCIADO(A) EN INFORMATICA EDUCATIVA',
  'LICENCIADO(A) EN INGLES',
  'LICENCIADO(A) EN INGLES COMO LENGUA EXTRANJERA',
  'LICENCIADO(A) EN INGLES Y FRANCES',
  'LICENCIADO(A) EN LENGUA CASTELLANA',
  'LICENCIADO(A) EN LENGUA CASTELLANA E INGLES',
  'LICENCIADO(A) EN LENGUA CASTELLANA Y COMUNICACION',
  'LICENCIADO(A) EN LENGUA CASTELLANA Y LITERATURA',
  'LICENCIADO(A) EN LENGUA CASTELLANA, INGLES Y FRANCES',
  'LICENCIADO(A) EN LENGUAS EXTRANJERAS',
  'LICENCIADO(A) EN LENGUAS EXTRANJERAS CON ENFASIS EN INGLES Y EN FRANCES',
  'LICENCIADO(A) EN LENGUAS EXTRANJERAS CON ENFASIS EN INGLES Y FRANCES',
  'LICENCIADO(A) EN LENGUAS EXTRANJERAS:INGLES-FRANCES',
  'LICENCIADO(A) EN LENGUAS MODERNAS',
  'LICENCIADO(A) EN LENGUAS MODERNAS CON ENFASIS EN INGLES Y FRANCES',
  'LICENCIADO(A) EN LENGUAS MODERNAS INGLES - FRANCES',
  'LICENCIADO(A) EN LITERATURA Y LENGUA CASATELLANA',
  'LICENCIADO(A) EN LITERATURA Y LENGUA CASTELLANA',
  'LICENCIADO(A) EN MATEMATICAS E INFORMATICA',
  'LICENCIADO(A) EN MATEMATICAS Y CIENCIAS DE LA COMPUTACION',
  'LICENCIADO(A) EN MATEMATICAS Y FISICA',
  'LICENCIADO(A) EN MUSICA',
  'LICENCIADO(A) EN PEDAGOGIA INFANTIL',
  'LICENCIADO(A) EN PEDAGOGIA REEDUCATIVA',
  'LICENCIADO(A) EN PREESCOLAR',
  'LICENCIADO(A) EN QUIMICA Y EDUCACION AMBIENTAL',
  'LICENCIADO(A) EN TECNOLOGIA E INFORMATICA',
  'LICENCIADO(A) EN TEOLOGIA',
  'LICENCIADO(A) ENLENGUAS EXTRANJERAS CON ENFASIS EN INGLES',
  'LICENCIADO(A) Y LENGUA CASTELLANA Y LITERATURA',
  'LICENCIADO(A) Y LITERATURA Y LENGUA CASTELLANA',
  'LICENCIATURA EN ARTES PLASTICAS PARA LA EDUCACION BASICA',
  'LICENCIATURA EN BILINGÜISMO CON ENFASIS EN INGLES',
  'LICENCIATURA EN EDUCACION AMBIENTAL',
  'LICENCIATURA EN EDUCACION BASICA CON ENFASIS EN EDUCACION ARTISTICA Y CULTURAL:ARTES REPRESENTATIVAS',
  'LICENCIATURA EN EDUCACION BASICA ENFASIS EN CIENCIAS SOCIALES',
  'LICENCIATURA EN EDUCACION PREESCOLAR Y BASICA PRIMARIA:INGLES',
  'LICENCIATURA EN ETICA Y FORMACION RELIGIOSA',
  'LICENCIATURA EN FISICA',
  'LICENCIATURA EN LENGUAS EXTRANJERAS',
  'LICENCIATURA EN PEDAGOGIA INFANTIL',
  'LICENCIDO(A) EN EDUCACION BASICA CON ENFASIS EN TECNOLOGIA E INFORMATICA',
  'LICENDIADO EN INGLES - ESPAÑOL',
  'LICERNCIADO EN ESPAÑOL Y LITERATURA',
  'LINGUISTICA',
  'LITERATO (A)',
  'MATEMATICO',
  'MATEMATICO CON ENFASIS EN COMPUTACION',
  'MATEMATICO(A)',
  'MEDIANTE CR 0427 DEL 8-3-94 SE DIO RESPUESTA AL DILIGENCIAMIENTOCOMPLETO DEL FORMATO DE NOTIFICACION',
  'MEDICO',
  'MEDICO (A)',
  'MEDICO (A) CIRUJANO',
  'MEDICO CIRUJANO',
  'MEDICO GENERAL',
  'MEDICO RADIOLOGO',
  'MEDICO VETERIANARIO Y ZOOTECNISTA',
  'MEDICO VETERINARIO',
  'MEDICO VETERINARIO Y ZOOTECNISTA',
  'MEDICO VETERINARIO ZOOTECNISTA',
  'MEDICO VETERINARIO- ZOOTECNISTA',
  'MEDICO Y CIRUJANO',
  'MEDICO Y CIRUJANO GENERAL',
  'MEDICO(A) CIRUJANO(A)',
  'MEDICO(A) VETERINARIO(A)',
  'MEDICO(A) VETERINARIO(A) ZOOTECNISTA',
  'MERCADEO Y PUBLICIDAD',
  'MERCADOLOGO',
  'MERCADOTECNISTA AGROINDUSTRIAL',
  'METROLOGO',
  'MICROBIOLOGO',
  'MICROBIOLOGO (A)',
  'MICROBIOLOGO AGRICOLA Y VETERINARIO',
  'MICROBIOLOGO CON ENFASIS EN SECTOR CLINICO O SECTOR INDUSTRIAL',
  'MICROBIOLOGO INDUSTRIAL',
  'MICROBIOLOGO INDUSTRIAL Y AMBIENTAL',
  'MICROBIOLOGO Y BIOANALISTA',
  'MUSEOLOGO',
  'MUSICO',
  'MUSICO (ADJETIVO DERIVADO DEL ENFASIS)',
  'MUSICO CON ENFASIS EN ...',
  'MUSICO INSTRUMENTALISTA CON ENFASIS EN...',
  'NARRADOR DIGITAL',
  'NEGOCIADOR INTERNACIONAL',
  'NEGOCIADOR(A) INTERNACIONAL',
  'NEUROLOGO PEDIATRA',
  'NUTRICIONISTA DIETISTA',
  'NUTRICIONISTA Y DIETISTA',
  'NUTRICIONISTA-DIETISTA',
  'OCEANOGRAFO',
  'OCEANOGRAFO FISICO',
  'ODONTOLOGO',
  'ODONTOLOGO(A)',
  'OPTOMETRA',
  'PEDAGOGO',
  'PEDAGOGO (A)',
  'PEDAGOGO (A) EN RURALIDAD Y PAZ',
  'PERIODISTA',
  'PERIODISTA - PRODUCTOR DE MEDIOS DE COMUNICACION',
  'POLITOLOGO',
  'POLITOLOGO CON ENFASIS EN GOBIERNO Y RELACIONES INTERNACIONALES',
  'POLITOLOGO(A)',
  'PRODUCTOR DE CINE Y TELEVISION',
  'PROFERSIONAL EN LENGUAS MODERNAS',
  'PROFESIONAL EN ACTIVIDAD FISICA Y DEPORTE',
  'PROFESIONAL EN ACUICULTURA',
  'PROFESIONAL EN ADMINISTRACION',
  'PROFESIONAL EN ADMINISTRACION & SERVICIO',
  'PROFESIONAL EN ADMINISTRACION AGROPECUARIA',
  'PROFESIONAL EN ADMINISTRACION DE LA SEGURIDAD Y SALUD EN EL TRABAJO',
  'PROFESIONAL EN ADMINISTRACION DE NEGOCIOS',
  'PROFESIONAL EN ADMINISTRACION DE NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN ADMINISTRACION DE SERVICIOS DE SALUD',
  'PROFESIONAL EN ADMINISTRACION DE SISTEMAS DE INFORMACION',
  'PROFESIONAL EN ADMINISTRACION DEL TURISMO SOSTENIBLE',
  'PROFESIONAL EN ADMINISTRACION DEPORTIVA',
  'PROFESIONAL EN ADMINISTRACION EN SALUD',
  'PROFESIONAL EN ADMINISTRACION FINANCIERA',
  'PROFESIONAL EN ADMINISTRACION HOTELERA Y DE SERVICIOS',
  'PROFESIONAL EN ADMINISTRACION HOTELERA Y DE TURISMO',
  'PROFESIONAL EN ADMINISTRACION HOTELERA Y GASTRONOMICA',
  'PROFESIONAL EN ADMINISTRACION HOTELERA Y TURISTICA',
  'PROFESIONAL EN ADMINISTRACION INFORMATICA.',
  'PROFESIONAL EN ADMINISTRACION LOGISTICA',
  'PROFESIONAL EN ADMINISTRACION TURISTICA',
  'PROFESIONAL EN ADMINISTRACION TURISTICA Y HOTELERA',
  'PROFESIONAL EN ADMINISTRACION Y FINANZAS',
  'PROFESIONAL EN ADMINISTRACION Y GESTION AMBIENTAL',
  'PROFESIONAL EN ADMINISTRACION Y GESTION PROPIA',
  'PROFESIONAL EN ADMINISTRACION Y MERCADOS INTERNACIONALES',
  'PROFESIONAL EN ADMINISTRACION Y NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN ADMINISTRACION Y SERVICIO',
  'PROFESIONAL EN AGROINDUSTRIA',
  'PROFESIONAL EN AGRONEGOCIOS',
  'PROFESIONAL EN ANIMACION',
  'PROFESIONAL EN ARCHIVISTICA E INTELIGENCIA DE NEGOCIOS',
  'PROFESIONAL EN ARCHIVISTICA Y GESTION DE LA INFORMACION DIGITAL',
  'PROFESIONAL EN ARTES CULINARIAS Y GASTRONOMIA',
  'PROFESIONAL EN ARTES DE LA GRABACION Y PROFESIONAL MUSICAL',
  'PROFESIONAL EN ARTES GASTRONOMICAS',
  'PROFESIONAL EN ARTES LIBERALES EN CIENCIAS SOCIALES',
  'PROFESIONAL EN ARTES PLASTICAS',
  'PROFESIONAL EN ARTES PLASTICAS CON ENFASIS EN PROCESOS BIDIMENSIONALES Y TRIDIMENSIONALES',
  'PROFESIONAL EN BANCA Y FINANZAS',
  'PROFESIONAL EN BANCA Y FINANZAS INTERNACIONALES',
  'PROFESIONAL EN BANCA Y FINANZAS.',
  'PROFESIONAL EN BIBLIOTECOLOGIA Y ESTUDIOS DE LA INFORMACION',
  'PROFESIONAL EN BUEN VIVIR COMUNITARIO',
  'PROFESIONAL EN CIENCIA DE DATOS',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION - BIBLIOTECOLOGIA',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION -BIBLIOTECOLOGO(A)',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION Y LA DOCUMENTACION BIBLIOTECOLOGIA,ARCHIVISTICA,DOCUMENTACI',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION Y LA DOCUMENTACION, BIBLIOTECOLOGIA Y ARCHIVISTICA',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION, BIBLIOTECOLOGIA Y ARCHIVISTICA',
  'PROFESIONAL EN CIENCIA DE LA INFORMACION: BIBLIOTECOLOGIA, DOCUMENTACION Y ARCHIVISTICA',
  'PROFESIONAL EN CIENCIA POLITICA Y GOBIERNO',
  'PROFESIONAL EN CIENCIA Y TECNOLOGIA DE ALIMENTOS',
  'PROFESIONAL EN CIENCIAS AERONAUTICAS',
  'PROFESIONAL EN CIENCIAS AMBIENTALES',
  'PROFESIONAL EN CIENCIAS BIBLICAS',
  'PROFESIONAL EN CIENCIAS CULINARIAS',
  'PROFESIONAL EN CIENCIAS CULINARIAS DE LA GASTRONOMIA',
  'PROFESIONAL EN CIENCIAS DE LA ADMINISTRACION',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE Y DE LA ACTIVIDAD FISICA',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE Y DE LA EDUCACION FISICA',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE Y LA ACTIVIDAD FISICA',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE Y LA EDUCACION FISICA',
  'PROFESIONAL EN CIENCIAS DEL DEPORTE Y LA RECREACION',
  'PROFESIONAL EN CIENCIAS DEL SISTEMA TIERRA',
  'PROFESIONAL EN CIENCIAS MILITARES',
  'PROFESIONAL EN CIENCIAS MILITARES AERONAUTICAS',
  'PROFESIONAL EN CIENCIAS NAUTICAS ESPECIALIDAD MAQUINAS',
  'PROFESIONAL EN CIENCIAS NAUTICAS ESPECIALIDAD PUENTE',
  'PROFESIONAL EN CIENCIAS NAVALES',
  'PROFESIONAL EN CIENCIAS POLITICAS',
  'PROFESIONAL EN CIENCIAS POLITICAS Y RELACIONES INTERNACIONALES',
  'PROFESIONAL EN CIENCIAS SOCIALES',
  'PROFESIONAL EN CINE',
  'PROFESIONAL EN CINE Y COMUNICACION DIGITAL',
  'PROFESIONAL EN CINE Y TELEVISION',
  'PROFESIONAL EN COMERCIO EXTERIOR',
  'PROFESIONAL EN COMERCIO INTERNACIONAL',
  'PROFESIONAL EN COMERCIO INTERNACIONAL Y FINANZAS',
  'PROFESIONAL EN COMERCIO INTERNACIONAL Y MERCADEO',
  'PROFESIONAL EN COMERCIO Y FINANZAS INTERNACIONALES',
  'PROFESIONAL EN COMERCIO Y NEGOCIOS GLOBALES',
  'PROFESIONAL EN COMERCIO Y NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN COMPUTACION CIENTIFICA',
  'PROFESIONAL EN COMUNICACION',
  'PROFESIONAL EN COMUNICACION AUDIOVISUAL',
  'PROFESIONAL EN COMUNICACION CORPORATIVA Y RELACIONES PUBLICAS',
  'PROFESIONAL EN COMUNICACION DIGITAL',
  'PROFESIONAL EN COMUNICACION EN NARRATIVAS TRANSMEDIA',
  'PROFESIONAL EN COMUNICACION EN RADIO Y TELEVISION',
  'PROFESIONAL EN COMUNICACION ORGANIZACIONAL',
  'PROFESIONAL EN COMUNICACION PROPIA INTERCULTURAL',
  'PROFESIONAL EN COMUNICACION PUBLICITARIA',
  'PROFESIONAL EN COMUNICACION SOCIAL',
  'PROFESIONAL EN COMUNICACION SOCIAL - PERIODISMO',
  'PROFESIONAL EN COMUNICACION SOCIAL Y MEDIOS DIGITALES.',
  'PROFESIONAL EN COMUNICACION SOCIAL Y ORGANIZACIONAL',
  'PROFESIONAL EN COMUNICACION VISUAL Y MULTIMEDIA',
  'PROFESIONAL EN COMUNICACION Y RELACIONES CORPORATIVAS',
  'PROFESIONAL EN COMUNICACIONES Y MARKETING',
  'PROFESIONAL EN CONSERVACION Y RESTAURACION DE BIENES MUEBLES',
  'PROFESIONAL EN CONSERVACION Y RESTAURACION DE PATRIMONIO CULTURAL MUEBLE',
  'PROFESIONAL EN CONTADURIA PUBLICA',
  'PROFESIONAL EN CONTADURIA PUBLICA Y FINANZAS',
  'PROFESIONAL EN CONTADURIA PUBLICA Y FINANZAS INTERNACIONALES',
  'PROFESIONAL EN CREACION LITERARIA',
  'PROFESIONAL EN CRIMINALISTICA',
  'PROFESIONAL EN CULINARIA Y GASTRONOMIA',
  'PROFESIONAL EN CULTURA FISICA DEPORTE Y RECREACION',
  'PROFESIONAL EN CULTURA FISICA Y DEPORTE',
  'PROFESIONAL EN CULTURA FISICA, DEPORTE Y RECREACION',
  'PROFESIONAL EN DANZA',
  'PROFESIONAL EN DANZA Y PERFORMANCE',
  'PROFESIONAL EN DEPORTE',
  'PROFESIONAL EN DEPORTE Y ACTIVIDAD FISICA',
  'PROFESIONAL EN DEPORTE Y CULTURA FISICA',
  'PROFESIONAL EN DERECHO PROPIO INTERCULTURAL',
  'PROFESIONAL EN DESARROLLO FAMILIAR',
  'PROFESIONAL EN DESARROLLO TERRITORIAL',
  'PROFESIONAL EN DIRECCION HUMANA Y ORGANIZACIONAL',
  'PROFESIONAL EN DIRECCION TECNICA DE FUTBOL',
  'PROFESIONAL EN DIRECCION Y ADMINISTRACION DE EMPRESAS',
  'PROFESIONAL EN DIRECCION Y PRODUCCION DE CINE Y TELEVISION',
  'PROFESIONAL EN DIRECCION Y PRODUCCION DE MEDIOS AUDIOVISUALES',
  'PROFESIONAL EN DIRECCION Y PRODUCCION DE RADIO Y TELEVISION',
  'PROFESIONAL EN DISEÑO DE ALTA COSTURA',
  'PROFESIONAL EN DISEÑO DE COMUNICACION GRAFICA',
  'PROFESIONAL EN DISEÑO DE ESPACIOS',
  'PROFESIONAL EN DISEÑO DE INTERIORES',
  'PROFESIONAL EN DISEÑO DE MODA',
  'PROFESIONAL EN DISEÑO DE MODAS',
  'PROFESIONAL EN DISEÑO DE MODAS Y ALTA COSTURA',
  'PROFESIONAL EN DISEÑO DE VESTUARIO',
  'PROFESIONAL EN DISEÑO DIGITAL',
  'PROFESIONAL EN DISEÑO ESTRATEGICO',
  'PROFESIONAL EN DISEÑO GRAFICO',
  'PROFESIONAL EN DISEÑO INTERIOR.',
  'PROFESIONAL EN DISEÑO URBANO Y GESTION DEL HABITAT',
  'PROFESIONAL EN DISEÑO VISUAL',
  'PROFESIONAL EN DISEÑO VISUAL ESTRATEGICO',
  'PROFESIONAL EN DISEñO Y ADMINISTRACION DE SISTEMAS',
  'PROFESIONAL EN DISEÑO Y CREACION DE JOYAS',
  'PROFESIONAL EN DISEÑO Y GESTION DE ESPACIOS',
  'PROFESIONAL EN DISEÑO Y GESTION DE LA MODA Y EL TEXTIL',
  'PROFESIONAL EN DISEÑO Y GESTION DEL PRODUCTO',
  'PROFESIONAL EN DISEÑO Y PRODUCCION DE MODA',
  'PROFESIONAL EN DISEÑO Y REALIZACION DE MEDIOS DIGITALES',
  'PROFESIONAL EN ECOLOGIA',
  'PROFESIONAL EN ECONOMIA',
  'PROFESIONAL EN EDUCACION FISICA MILITAR',
  'PROFESIONAL EN EMPRENDIMIENTO',
  'PROFESIONAL EN ENFERMERIA',
  'PROFESIONAL EN ENTRENAMIENTO DEPORTIVO',
  'PROFESIONAL EN ENTRENAMIENTO DEPORTIVO, PREPARACION FISICA',
  'PROFESIONAL EN ESTADISTICA',
  'PROFESIONAL EN ESTUDIOS GENERALES CIENCIAS',
  'PROFESIONAL EN ESTUDIOS GENERALES EN ARTES Y HUMANIDADES',
  'PROFESIONAL EN ESTUDIOS GENERALES EN CIENCIAS SOCIALES',
  'PROFESIONAL EN ESTUDIOS GLOBALES',
  'PROFESIONAL EN ESTUDIOS LITERARIOS',
  'PROFESIONAL EN ESTUDIOS LITERARIOS Y EDICION',
  'PROFESIONAL EN ESTUDIOS POLITICOS Y RESOLUCION DE CONFLICTOS',
  'PROFESIONAL EN ESTUDIOS Y GESTION CULTURAL',
  'PROFESIONAL EN FILOSOFIA',
  'PROFESIONAL EN FILOSOFIA Y HUMANIDADES',
  'PROFESIONAL EN FILOSOFIA Y LETRAS',
  'PROFESIONAL EN FINANZAS',
  'PROFESIONAL EN FINANZAS INTERNACIONALES',
  'PROFESIONAL EN FINANZAS Y BANCA',
  'PROFESIONAL EN FINANZAS Y COMERCIO EXTERIOR',
  'PROFESIONAL EN FINANZAS Y COMERCIO INTERNACIONAL',
  'PROFESIONAL EN FINANZAS Y NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN FINANZAS Y NEGOCIOS MULTINACIONALES',
  'PROFESIONAL EN FINANZAS Y RELACIONES INTERNACIONALES',
  'PROFESIONAL EN FINANZAS Y SEGUROS',
  'PROFESIONAL EN FINANZAS, FINTECH Y COMERCIO EXTERIOR',
  'PROFESIONAL EN FISIOTERAPIA',
  'PROFESIONAL EN FISIOTERAPIA Y KINESIOLOGIA',
  'PROFESIONAL EN FOTOGRAFIA',
  'PROFESIONAL EN FOTOGRAFIA PARA MEDIOS',
  'PROFESIONAL EN FOTOGRAFIA Y COMUNICACION VISUAL',
  'PROFESIONAL EN GASTRONOMIA',
  'PROFESIONAL EN GASTRONOMIA Y ALTA COCINA',
  'PROFESIONAL EN GASTRONOMIA Y COCINA',
  'PROFESIONAL EN GASTRONOMIA Y COCINA PROFESIONAL',
  'PROFESIONAL EN GASTRONOMIA Y CULINARIA',
  'PROFESIONAL EN GERENCIA DE MERCADEO',
  'PROFESIONAL EN GERENCIA EN SISTEMAS DE INFORMACION EN SALUD',
  'PROFESIONAL EN GESTION CULTURAL',
  'PROFESIONAL EN GESTION CULTURAL Y COMUNICATIVA',
  'PROFESIONAL EN GESTION CULTURAL Y DE INDUSTRIAS CREATIVAS',
  'PROFESIONAL EN GESTION DE LA SEGURIDAD Y LA SALUD LABORAL',
  'PROFESIONAL EN GESTION DE SISTEMAS DE INFORMACION',
  'PROFESIONAL EN GESTION DEL DISEÑO',
  'PROFESIONAL EN GESTION DEL EMPRENDIMIENTO Y LA INNOVACION',
  'PROFESIONAL EN GESTION DEPORTIVA',
  'PROFESIONAL EN GESTION EMPRESARIAL',
  'PROFESIONAL EN GESTION EN SEGURIDAD Y LA SALUD LABORAL',
  'PROFESIONAL EN GESTION EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'PROFESIONAL EN GESTION TERRITORIAL Y ECONOMIA SOCIAL Y SOLIDARIA',
  'PROFESIONAL EN GESTION Y DESARROLLO URBANOS',
  'PROFESIONAL EN GESTION Y ESTUDIOS CULTURALES',
  'PROFESIONAL EN GOBIERNO',
  'PROFESIONAL EN GOBIERNO Y ASUNTOS PUBLICOS',
  'PROFESIONAL EN GOBIERNO Y RELACIONES INTERNACIONALES',
  'PROFESIONAL EN HISTORIA',
  'PROFESIONAL EN HISTORIA Y GESTION POLITICA',
  'PROFESIONAL EN HOTELERIA Y TURISMO',
  'PROFESIONAL EN HOTELERIA Y TURISMO ECOLOGICO',
  'PROFESIONAL EN IDIOMAS',
  'PROFESIONAL EN INNOVACION Y TECNOLOGIA ECONOMICA',
  'PROFESIONAL EN INSTRUMENTACION QUIRURGICA',
  'PROFESIONAL EN INTELIGENCIA DE NEGOCIOS',
  'PROFESIONAL EN INVESTIGACION CRIMINAL',
  'PROFESIONAL EN LENGUA INGLESA',
  'PROFESIONAL EN LENGUAJES Y ESTUDIOS SOCIO CULTURALES',
  'PROFESIONAL EN LENGUAS',
  'PROFESIONAL EN LENGUAS CLASICAS',
  'PROFESIONAL EN LENGUAS EXTRANJERAS',
  'PROFESIONAL EN LENGUAS EXTRANJERAS CON ENFASIS EN INGLES Y FRANCES',
  'PROFESIONAL EN LENGUAS EXTRANJERAS INGLES-FRANCES',
  'PROFESIONAL EN LENGUAS MODERNAS',
  'PROFESIONAL EN LENGUAS MODERNAS Y CULTURA',
  'PROFESIONAL EN LENGUAS Y CULTURA',
  'PROFESIONAL EN LINGUISTICA Y LITERATURA',
  'PROFESIONAL EN LITERATURA',
  'PROFESIONAL EN LOGISTICA EMPRESARIAL',
  'PROFESIONAL EN LOGISTICA Y MERCADEO ORGANIZACIONAL',
  'PROFESIONAL EN MANEJO AGROECOLOGICO Y DE POST-COSECHA',
  'PROFESIONAL EN MANEJO AGROFORESTAL',
  'PROFESIONAL EN MARKETING',
  'PROFESIONAL EN MARKETING & NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN MARKETING DE NEGOCIOS',
  'PROFESIONAL EN MARKETING DIGITAL Y COMUNICACION ESTRATEGICA',
  'PROFESIONAL EN MARKETING INTEGRAL Y NEGOCIOS GLOBALES',
  'PROFESIONAL EN MARKETING INTERNACIONAL',
  'PROFESIONAL EN MARKETING Y LOGISTICA',
  'PROFESIONAL EN MARKETING Y NEGOCIOS DIGITALES',
  'PROFESIONAL EN MARKETING Y NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN MARKETING Y PUBLICIDAD',
  'PROFESIONAL EN MARKETING Y TRANSFORMACION DIGITAL',
  'PROFESIONAL EN MATEMATICAS APLICADAS',
  'PROFESIONAL EN MATEMATICAS APLICADAS EN CIENCIA DE DATOS',
  'PROFESIONAL EN MATEMATICAS APLICADAS Y CIENCIAS DE LA COMPUTACION',
  'PROFESIONAL EN MATEMATICAS CON ENFASIS EN ESTADISTICA',
  'PROFESIONAL EN MATEMATICAS Y CIENCIAS DE LA COMPUTACION',
  'PROFESIONAL EN MEDIOS AUDIOVISUALES',
  'PROFESIONAL EN MEDIOS DE COMUNICACION PASTORAL',
  'PROFESIONAL EN MEDIOS DIGITALES Y AUDIOVISUALES',
  'PROFESIONAL EN MERCADEO',
  'PROFESIONAL EN MERCADEO AGROALIMENTARIO',
  'PROFESIONAL EN MERCADEO AGROINDUSTRIAL',
  'PROFESIONAL EN MERCADEO AGROPECUARIO',
  'PROFESIONAL EN MERCADEO CON ENFASIS EN NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN MERCADEO DIGITAL',
  'PROFESIONAL EN MERCADEO EMPRESARIAL',
  'PROFESIONAL EN MERCADEO GLOBAL',
  'PROFESIONAL EN MERCADEO INTERNACIONAL Y PUBLICIDAD',
  'PROFESIONAL EN MERCADEO NACIONAL E INTERNACIONAL',
  'PROFESIONAL EN MERCADEO PUBLICIDAD Y VENTAS',
  'PROFESIONAL EN MERCADEO Y ESTRATEGIA COMERCIAL',
  'PROFESIONAL EN MERCADEO Y LOGISTICA EMPRESARIAL',
  'PROFESIONAL EN MERCADEO Y NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN MERCADEO Y VENTAS',
  'PROFESIONAL EN MERCADEO,PUBLICIDAD Y VENTAS',
  'PROFESIONAL EN MODELADO Y SIMULACION COMPUTACIONAL',
  'PROFESIONAL EN MUSICA',
  'Profesional en Narrativas y Entretenimiento Digital',
  'PROFESIONAL EN NEGOCIOS DIGITALES',
  'PROFESIONAL EN NEGOCIOS ESTRATEGICOS DE MODA',
  'PROFESIONAL EN NEGOCIOS INTERNACIONALES',
  'PROFESIONAL EN NEGOCIOS INTERNACIONALES BILINGUE',
  'PROFESIONAL EN NEGOCIOS INTERNACIONALES Y MERCADEO',
  'PROFESIONAL EN NEGOCIOS Y COMERCIO DIGITAL',
  'PROFESIONAL EN NEGOCIOS Y FINANZAS INTERNACIONALES',
  'PROFESIONAL EN NEGOCIOS Y RELACIONES INTERNACIONALES',
  'PROFESIONAL EN NEGOCIOS, EMPRENDIMIENTO E INNOVACION',
  'PROFESIONAL EN PERIODISMO Y OPINION PUBLICA',
  'PROFESIONAL EN PLANEACION PARA EL DESARROLLO SOCIAL',
  'PROFESIONAL EN PLANEACION Y DESARROLLO SOCIAL',
  'PROFESIONAL EN PRIMERA INFANCIA',
  'PROFESIONAL EN PRODUCCION AGROINDUSTRIAL',
  'PROFESIONAL EN PRODUCCION Y COMUNICACION TRANSMEDIA',
  'PROFESIONAL EN PSICOLOGIA',
  'PROFESIONAL EN PUBLICIDAD',
  'PROFESIONAL EN PUBLICIDAD DIGITAL Y MERCADEO',
  'PROFESIONAL EN PUBLICIDAD INTERNACIONAL',
  'PROFESIONAL EN PUBLICIDAD Y MARKETING CREATIVO',
  'PROFESIONAL EN PUBLICIDAD Y MARKETING DIGITAL',
  'PROFESIONAL EN PUBLICIDAD Y MERCADEO',
  'PROFESIONAL EN PUBLICIDAD Y MERCADEO DIGITAL',
  'PROFESIONAL EN PUBLICIDAD Y RELACIONES PUBLICAS',
  'PROFESIONAL EN RECREACION',
  'PROFESIONAL EN RELACIONES ECONOMICAS INTERNACIONALES',
  'PROFESIONAL EN RELACIONES INDUSTRIALES ENF. EN DIRECCION DE RECURSOS HUMANOS',
  'PROFESIONAL EN RELACIONES INTERNACIONALES',
  'PROFESIONAL EN RELACIONES INTERNACIONALES Y ESTUDIOS POLITICOS',
  'PROFESIONAL EN RELACIONES PUBLICAS E INSTITUCIONALES',
  'PROFESIONAL EN REVITALIZACION DE LA MADRE TIERRA',
  'PROFESIONAL EN SALUD OCUPACIONAL',
  'PROFESIONAL EN SEGURIDAD Y SALUD EN ELTRABAJO',
  'PROFESIONAL EN SISTEMAS DE INFORMACION',
  'PROFESIONAL EN SISTEMAS DE INFORMACION ORGANIZACIONAL',
  'PROFESIONAL EN SISTEMAS DE INFORMACION, BIBLIOTECOLOGIA Y ARCHIVISTICA',
  'PROFESIONAL EN SOCIOLOGIA',
  'PROFESIONAL EN TEOLOGIA',
  'PROFESIONAL EN TEOLOGIA Y PASTORAL',
  'PROFESIONAL EN TERAPIA OCUPACIONAL',
  'PROFESIONAL EN TERAPIA RESPIRATORIA',
  'PROFESIONAL EN TERAPIAS PSICOSOCIALES',
  'PROFESIONAL EN TRABAJO SOCIAL',
  'PROFESIONAL EN TURISMO',
  'PROFESIONAL EN TURISMO Y GASTRONOMIA',
  'PROFESIONAL EN ZOOTECNIA',
  'PROFESIONAL ENMERCADEO Y PUBLICIDAD',
  'PROFESIONAL QUIMICO AMBIENTAL',
  'PROFESIONAL SEGURIDAD Y SALUD EN EL TRABAJO',
  'PROFESIONAL UNIVERSITARIO EN ANIMACION',
  'PROFESIONAL UNIVERSITARIO EN CIENCIAS AMBIENTALES',
  'PROFESIONAL UNIVERSITARIO EN COMUNICACION SOCIAL-PERIODISMO',
  'PROFESIONAL UNIVERSITARIO EN DISEÑO DE MODAS',
  'PROFESIONAL UNIVERSITARIO EN DISEÑO GRAFICO DIGITAL',
  'PROFESIONAL UNIVERSITARIO EN DISEÑO TEXTIL PARA LA INDUSTRIA',
  'PROFESIONAL UNIVERSITARIO EN ENTRENAMIENTO DEPORTIVO',
  'PROFESIONAL UNIVERSITARIO EN ESTADISTICA Y CIENCIAS ACTUARIALES',
  'PROFESIONAL UNIVERSITARIO EN FARMACIA INDUSTRIAL',
  'PROFESIONAL UNIVERSITARIO EN FOTOGRAFIA PARA MEDIOS',
  'PROFESIONAL UNIVERSITARIO EN MERCADEO',
  'PROFESIONALEN ADMINISTRACION DE EMPRESAS',
  'PROFESIONALUNIVERSITARIO EN ADMINISTRACION HOTELERA Y TURISTICA',
  'PROFESIONLA EN GASTRONOMIA',
  'PROFRESIONAL EN FINANZAS, FINTECH Y COMERCIO EXTERIOR',
  'PSICOLGO (A)',
  'PSICOLOGO',
  'PSICOLOGO CON ENFASIS EN PSICOLOGIA FAMILIAR',
  'PSICOLOGO CON ENFASIS EN PSICOLOGIA SOCIAL',
  'PSICOLOGO EMPRESARIAL',
  'PUBLICISTA',
  'PUBLICISTA MERCADOTECNISTA',
  'QUIMIC0 AMBIENTAL',
  'QUIMICO',
  'QUIMICO DE ALIMENTOS',
  'QUIMICO DE PRODUCTOS VEGETALES',
  'QUIMICO FARMACEUTICO',
  'QUIMICO INDUSTRIAL',
  'QUIMICO(A)',
  'QUIMICO(A) FARMACEUTICO(A)',
  'REALIZADOR (A) DE CINE Y AUDIOVISUALES',
  'REALIZADOR DE CINE Y TELEVISION',
  'REALIZADOR EN ANIMACION',
  'REALIZADOR Y PRODUCTOR MUSICAL',
  'REALIZADOR(A) DE CINE Y TELEVISION',
  'SERVIDOR SOCIAL',
  'SOCIOLOGO',
  'SOCIOLOGO(A)',
  'SOCIOLOGO/ SOCIOLOGA',
  'TECNOLGO EN GESTION DE SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLO EN MECATRONICA',
  'TECNOLOG EN BANCA',
  'TECNOLOG EN CONSTRUCCIONES CIVILES',
  'TECNOLOG EN GESTION BANCARIA Y ENTIDADES FINANCIERAS',
  'TECNOLOGA(O) EN GESTION JURIDICA DE LA INFORMACION',
  'TECNOLOGI EN GESTION DEL TALENTO HUMANO',
  'TECNOLOGI EN SISTEMA DE INFORMACION',
  'TECNOLOGIA AGROINDUSTRIAL',
  'TECNOLOGIA DE GESTION ADMINISTRATIVA',
  'TECNOLOGIA EN ACUICULTURA',
  'TECNOLOGIA EN ADMINISTRACION DE EMPRESAS',
  'TECNOLOGIA EN ADMINISTRACION DE EMPRESAS DE TRANSPORTE',
  'TECNOLOGIA EN ADMINISTRACION TURISTICA Y ASISTENCIA GERENCIAL',
  'TECNOLOGIA EN ANALISIS Y DESARROLLO DE SISTEMAS DE INFORMACION',
  'TECNOLOGIA EN DIRECCION DE SERVICIOS TURISTICOS',
  'TECNOLOGIA EN ELECTRICIDAD DE MEDIA Y BAJA TENSION',
  'TECNOLOGIA EN ELECTRONICA Y TELECOMUNICACIONES',
  'TECNOLOGIA EN FOTOGRAFIA Y PROCESOS DIGITALES',
  'TECNOLOGIA EN GESTION ADMINISTRATIVA',
  'TECNOLOGIA EN GESTION BIBLIOTECARIA',
  'TECNOLOGIA EN GESTION CONTABLE Y FINANCIERA',
  'TECNOLOGIA EN GESTION CONTABLE Y TRIBUTARIA',
  'TECNOLOGIA EN GESTION DE LA PRODUCCION AGRICOLA',
  'TECNOLOGIA EN GESTION DE PROCESOS DE PRODUCCION INDUSTRIAL',
  'TECNOLOGIA EN GESTION DEL COMERCIO EXTERIOR',
  'TECNOLOGIA EN GESTION EMPRESARIAL',
  'TECNOLOGIA EN GESTION TURISTICA Y HOTELERA',
  'TECNOLOGIA EN IMPLEMENTACION DE REDES Y SERVICIOS DE TELECOMUNICACIONES',
  'TECNOLOGIA EN INFORMATICA Y TELECOMUNICACIONES',
  'TECNOLOGIA EN INVESTIGACION JUDICIAL',
  'TECNOLOGIA EN MANTENIMIENTO Y OPERACION DE MAQUINARIA PARA LA CONSTRUCCION',
  'TECNOLOGIA EN NEGOCIACION INTERNACIONAL',
  'TECNOLOGIA EN NEGOCIOS INTERNACIONALES',
  'TECNOLOGIA EN PUBLICIDAD Y COMUNICACION VISUAL',
  'TECNOLOGIA EN REALIZACION AUDIOVISUAL',
  'TECNOLOGIA EN REFRIGERACION Y AIRE ACONDICIONADO',
  'TECNOLOGIA EN SEGURIDAD E HIGIENE OCUPACIONAL',
  'TECNOLOGIA EN SISTEMAS DE INFORMACION',
  'TECNOLOGIA EN SISTEMAS DE INFORMACION EN SALUD',
  'TECNOLOGIO EN DESARROLLO DE SISTEMAS INFORMATICOS',
  'TECNOLOGIO EN SISTEMAS',
  'TECNOLOGIO EN SISTEMAS DE INFORMACION',
  'TECNOLOGO',
  'TECNOLOGO (A )EN INTELIGENCIA AEREA',
  'TECNOLOGO (A) AGROPECUARIO (A)',
  'TECNOLOGO (A) CONTABLE Y TRIBUTARIO (A)',
  'TECNOLOGO (A) DESARROLLO DE SOFTWARE',
  'TECNOLOGO (A) EN ACUICULTURA',
  'TECNOLOGO (A) EN ADMINISTRACION HOTELERA Y DE TURISMO',
  'TECNOLOGO (A) EN AGROFORESTERIA',
  'TECNOLOGO (A) EN ALIMENTOS',
  'TECNOLOGO (A) EN ANALISIS Y DISEÑO DE SISTEMAS Y COMPUTACION',
  'TECNOLOGO (A) EN ARTESANIAS',
  'TECNOLOGO (A) EN CONTROL Y GESTION DE PROCESOS AGROINDUSTRIALES',
  'TECNOLOGO (A) EN COORDINACION DE ESCUELAS DE MUSICA',
  'TECNOLOGO (A) EN DEFENSA AEREA',
  'TECNOLOGO (A) EN DESARROLLO DE SISTEMAS INFORMATICOS',
  'TECNOLOGO (A) EN DISEÑO PUBLICITARIO',
  'TECNOLOGO (A) EN ELECTRONICA',
  'TECNOLOGO (A) EN ELECTRONICA INDUSTRIAL',
  'TECNOLOGO (A) EN ENTRENAMIENTO DEPORTIVO',
  'TECNOLOGO (A) EN GESTION ADMINISTRATIVA Y FINANCIERA',
  'TECNOLOGO (A) EN GESTION CATASTRAL',
  'TECNOLOGO (A) EN GESTION COMUNICATIVA PARA LAS ORGANIZACIONES',
  'TECNOLOGO (A) EN GESTION DE CALIDAD',
  'TECNOLOGO (A) EN GESTION DE CONTENIDOS GRAFICOS PUBLICITARIOS',
  'TECNOLOGO (A) EN GESTION DE EMPRESAS Y DESTINOS TURISTICOS',
  'TECNOLOGO (A) EN GESTION DE MANTENIMIENTO ELECTROMECANICO',
  'TECNOLOGO (A) EN GESTION DE REDES DE COMPUTADORES Y SEGURIDAD INFORMATICA',
  'TECNOLOGO (A) EN GESTION DE REDES DE TELECOMUNICACIONES',
  'TECNOLOGO (A) EN GESTION DE SERVICIOS FINANCIEROS',
  'TECNOLOGO (A) EN GESTION DE SISTEMAS DE INFORMACION',
  'TECNOLOGO (A) EN GESTION DE TURISMO SOSTENIBLE',
  'TECNOLOGO (A) EN GESTION DOCUMENTAL',
  'TECNOLOGO (A) EN GESTION LOGISTICA',
  'TECNOLOGO (A) EN GESTION LOGISTICA INTEGRAL',
  'TECNOLOGO (A) EN GESTION MINERO AMBIENTAL DE LOS METALES PRECIOSOS',
  'TECNOLOGO (A) EN GESTION SOSTENIBLE DE LA PRODUCCION AGROPECUARIA',
  'TECNOLOGO (A) EN GESTION TURISTICA',
  'TECNOLOGO (A) EN GESTION TURISTICA Y HOTELERA',
  'TECNOLOGO (A) EN INTERPRETACION PARA SORDOS Y SORDOCIEGOS',
  'TECNOLOGO (A) EN LEVANTAMIENTOS TOPOGRAFICOS',
  'TECNOLOGO (A) EN MANTENIMIENTO DE SISTEMAS ELECTROMECANICOS',
  'TECNOLOGO (A) EN MECANICA DENTAL',
  'TECNOLOGO (A) EN MOVILIDAD Y SEGURIDAD VIAL',
  'TECNOLOGO (A) EN PROCESAMIENTO ALIMENTOS NUTRACEUTICOS',
  'TECNOLOGO (A) EN PRODUCCION AUDIOVISUAL',
  'TECNOLOGO (A) EN PRODUCCION DE IMAGEN Y SONIDO',
  'TECNOLOGO (A) EN PRODUCCION GRAFICA DIGITAL',
  'TECNOLOGO (A) EN PRODUCCION HORTICOLA',
  'TECNOLOGO (A) EN RADIODIAGNOSTICO Y RADIOTERAPIA',
  'TECNOLOGO (A) EN RADIOLOGIA E IMAGENES DIAGNOSTICAS',
  'TECNOLOGO (A) EN REALIZACION AUDIOVISUAL',
  'TECNOLOGO (A) EN REGENCIA DE FARMACIA',
  'TECNOLOGO (A) EN SISTEMAS DE INFORMACION',
  'TECNOLOGO (A) EN SISTEMAS DE PRODUCCION',
  'TECNOLOGO (A) EN SISTEMATIZACION DE DATOS',
  'TECNOLOGO (A) EN SUPERVICION DE SISTEMAS ELECTRICOS DE POTENCIA',
  'TECNOLOGO (A) EN TOPOGRAFIA',
  'TECNOLOGO (A) GESTION DE LA SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO (A) GESTION DE REDES DE TELECOMUNICACIONES',
  'TECNOLOGO -A EN GESTION CONTABLE',
  'TECNOLOGO ADMINISTRADOR DE NUEVAS TECNOLOGIAS EN REDES',
  'TECNOLOGO AGRARIO',
  'TECNOLOGO AGRICOLA',
  'TECNOLOGO AGROAMBIENTAL',
  'TECNOLOGO AGROFORESTAL',
  'TECNOLOGO AGROINDUSTRIAL',
  'TECNOLOGO AGROPECUARIA',
  'TECNOLOGO AGROPECUARIO',
  'TECNOLOGO AMBIENTAL',
  'TECNOLOGO AN ADMINISTRACION JUDICIAL',
  'TECNOLOGO BANCARIO Y FINANCIERO',
  'TECNOLOGO BIOMEDICO',
  'TECNOLOGO CATASTRAL',
  'TECNOLOGO COMERCIAL Y FINANCIERA',
  'TECNOLOGO COMERCIAL Y FINANCIERO',
  'TECNOLOGO CONTABLE Y TRIBUTARIA',
  'TECNOLOGO CONTABLE Y TRIBUTARIO',
  'TECNOLOGO CONTROL DE CALIDAD EN LA INDUSTRIA DE ALIMENTOS',
  'TECNOLOGO DE ALIMENTOS',
  'TECNOLOGO DE DESARROLLO Y MODELADO DE PRODUCTOS INDUSTRIALES',
  'TECNOLOGO DE GESTION ADMINISTRATIVA EN SERVICIOS DE SALUD',
  'TECNOLOGO DE GESTION EN SISTEMAS INTEGRADOS',
  'TECNOLOGO DE PRODUCCION DE COMPONENTES MECANICOS CON MAQUINAS DE CONTROL NUMERICO COMPUTARIZADO.',
  'TECNOLOGO DE SISTEMAS',
  'TECNOLOGO DE TRANSFORMACION DE PRODUCTOS AGROPECUARIOS',
  'TECNOLOGO DE VIAS Y AEROPISTAS',
  'TECNOLOGO DEPORTIVO',
  'TECNOLOGO DEPORTIVO PARA PERSONAS CON DISCAPACIDAD',
  'TECNOLOGO DESARROLLO DE MEDIOS GRAFICOS VISUALES',
  'TECNOLOGO DISEÑO DE ELEMENTOS MECANICOS PARA SU FABRICACION EN MAQUINAS HERRAMIENTAS CNC',
  'TECNOLOGO DISEÑO E INTEGRACION DE AUTOMATISMOS MECATRONICOS',
  'TECNOLOGO ELECTRICISTA',
  'TECNOLOGO ELECTRICO',
  'TECNOLOGO ELECTRICO EN GENERACION Y GESTION EFICIENTE DE ENERGIAS RENOVABLES',
  'TECNOLOGO ELECTROMECANICO',
  'TECNOLOGO ELECTRONICO',
  'TECNOLOGO EMPRESARIAL',
  'TECNOLOGO EN ABASTECIMIENTOS AERONAUTICOS',
  'TECNOLOGO EN ACONDICIONAMIENTO FISICO Y ENTRENAMIENTO DEPORTIVO',
  'TECNOLOGO EN ACTIVIDAD FISICA',
  'TECNOLOGO EN ACTUACION',
  'TECNOLOGO EN ACTUACION Y ESCRITURA DE GUIONES PARA LAS PRACTICAS ESCENICAS TEATRALES',
  'TECNOLOGO EN ACTUACION Y PRESENTACION PARA CINE Y TELEVISION',
  'TECNOLOGO EN ACUICULTURA',
  'TECNOLOGO EN ACUICULTURA CONTINENTAL',
  'TECNOLOGO EN ACUICULTURA DE AGUAS MARINAS CONTINENTALES',
  'TECNOLOGO EN ADMINISTARCION EMPRESARIAL',
  'TECNOLOGO EN ADMINISTRACION',
  'TECNOLOGO EN ADMINISTRACION ADUANERA Y COMERCIO INTERNACIONAL',
  'TECNOLOGO EN ADMINISTRACION AEROPORTUARIA',
  'TECNOLOGO EN ADMINISTRACION AGROPECUARIA',
  'TECNOLOGO EN ADMINISTRACION AMBIENTAL',
  'TECNOLOGO EN ADMINISTRACION AMBIENTAL Y DE LOS RECURSOS NATURALES',
  'TECNOLOGO EN ADMINISTRACION AMBIENTAL Y DE RECURSOS COSTERO- MARINOS',
  'TECNOLOGO EN ADMINISTRACION BANCARIA',
  'TECNOLOGO EN ADMINISTRACION COMERCIAL',
  'TECNOLOGO EN ADMINISTRACION COMERCIAL Y FINANCIERA',
  'TECNOLOGO EN ADMINISTRACION CONTABLE,TRIBUTARIA Y FINANCIERA',
  'TECNOLOGO EN ADMINISTRACION DE AEROLINEAS Y AGENCIAS DE VIAJE',
  'TECNOLOGO EN ADMINISTRACION DE AEROLINEAS Y AGENCIAS DE VIAJES',
  'TECNOLOGO EN ADMINISTRACION DE ALMACENES',
  'TECNOLOGO EN ADMINISTRACION DE EMPRERSAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS AGRARIAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS AGROPECUARIAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS COMERCIALES',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS COOPERATIVAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS DE ECONOMIA SOLIDARIA',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS DE SALUD',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS DE TRANSPORTE',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS HOTELERAS Y TURISTICAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS TURISTICAS Y HOTELERAS',
  'TECNOLOGO EN ADMINISTRACION DE EMPRESAS Y BANCA',
  'TECNOLOGO EN ADMINISTRACION DE ENTORNOS WEB',
  'TECNOLOGO EN ADMINISTRACION DE FINANZAS Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN ADMINISTRACION DE LA PRODUCCION ACUICOLA',
  'TECNOLOGO EN ADMINISTRACION DE LA PRODUCCION INDUSTRIAL',
  'TECNOLOGO EN ADMINISTRACION DE MARKETING Y VENTAS',
  'TECNOLOGO EN ADMINISTRACION DE MERCADEO',
  'TECNOLOGO EN ADMINISTRACION DE MERCADEO Y VENTAS',
  'TECNOLOGO EN ADMINISTRACION DE NEGOCIOS',
  'TECNOLOGO EN ADMINISTRACION DE NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN ADMINISTRACION DE OBRAS CIVILES',
  'TECNOLOGO EN ADMINISTRACION DE OFICINAS BILINGUE',
  'TECNOLOGO EN ADMINISTRACION DE PERSONAL Y DESARROLLO HUMANO',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS COSTEROS Y MARINOS CON ENFASIS EN TURISMO',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS COSTEROS Y MARINOS ENFASIS EN PESCA',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS COSTEROS Y MARINOS ENFASIS EN PROCESAMIENTO DE ALIMENTOS',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS COSTEROS Y MARINOS ENFASIS EN RECURSOS FORESTALES',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS HIDRICOS',
  'TECNOLOGO EN ADMINISTRACION DE RECURSOS NATURALES',
  'TECNOLOGO EN ADMINISTRACION DE REDES DE COMPUTADORES',
  'TECNOLOGO EN ADMINISTRACION DE REDES DE DATOS',
  'TECNOLOGO EN ADMINISTRACION DE REDES Y NUEVAS TECNOLOGIAS',
  'TECNOLOGO EN ADMINISTRACION DE REDES Y SEGURIDAD INFORMATICA',
  'TECNOLOGO EN ADMINISTRACION DE RIESGOS Y SEGUROS',
  'TECNOLOGO EN ADMINISTRACION DE SEGUROS',
  'TECNOLOGO EN ADMINISTRACION DE SERCICIOS DE SALUD',
  'TECNOLOGO EN ADMINISTRACION DE SERVICIOS DE SALUD',
  'TECNOLOGO EN ADMINISTRACION DE SISTEMAS',
  'TECNOLOGO EN ADMINISTRACION DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN ADMINISTRACION DE SISTEMAS DE INFORMACION Y DOCUMENTACION',
  'TECNOLOGO EN ADMINISTRACION DE VENTAS',
  'TECNOLOGO EN ADMINISTRACION DEL TALENTO HUMANO',
  'TECNOLOGO EN ADMINISTRACION DEPORTIVA',
  'TECNOLOGO EN ADMINISTRACION E INFORMATICA',
  'TECNOLOGO EN ADMINISTRACION EN AEREOLINEAS Y AGENCIAS DE VIAJES',
  'TECNOLOGO EN ADMINISTRACION EN CONTABILIDAD Y EN SISTEMAS',
  'TECNOLOGO EN ADMINISTRACION EN INFORMATICA',
  'TECNOLOGO EN ADMINISTRACION FINANCIERA',
  'TECNOLOGO EN ADMINISTRACION FINANCIERA ENFASIS EN SISTEMAS',
  'TECNOLOGO EN ADMINISTRACION FINANCIERA Y COMERCIAL',
  'TECNOLOGO EN ADMINISTRACION FINANCIERA Y DE SISTEMAS',
  'TECNOLOGO EN ADMINISTRACION HOSPITALARIA',
  'TECNOLOGO EN ADMINISTRACION HOTELERA',
  'TECNOLOGO EN ADMINISTRACION HOTELERA Y DE SERVICIOS',
  'TECNOLOGO EN ADMINISTRACION HOTELERA Y DE SERVICIOS DE BIENESTAR',
  'TECNOLOGO EN ADMINISTRACION HOTELERA Y DE TURISMO',
  'TECNOLOGO EN ADMINISTRACION HOTELERA Y TURISTICA',
  'TECNOLOGO EN ADMINISTRACION INDUSTRIAL',
  'TECNOLOGO EN ADMINISTRACION INDUSTRIAL DEL TRANSPORTE',
  'TECNOLOGO EN ADMINISTRACION INFORMATICA',
  'TECNOLOGO EN ADMINISTRACION INTEGRAL DE SEGUROS',
  'TECNOLOGO EN ADMINISTRACION JUDICIAL',
  'TECNOLOGO EN ADMINISTRACION LOGISTICA',
  'TECNOLOGO EN ADMINISTRACION MEDIOAMBIENTAL',
  'TECNOLOGO EN ADMINISTRACION MUNICIPAL',
  'TECNOLOGO EN ADMINISTRACION NAVIERA Y PORTUARIA',
  'TECNOLOGO EN ADMINISTRACION PUBLICA',
  'TECNOLOGO EN ADMINISTRACION TRIBUTARIA',
  'TECNOLOGO EN ADMINISTRACION TURISTICA',
  'TECNOLOGO EN ADMINISTRACION TURISTICA Y DEL PATRIMONIO',
  'TECNOLOGO EN ADMINISTRACION TURISTICA Y HOTELERA',
  'TECNOLOGO EN ADMINISTRACION Y ANALISIS DE LA SEGURIDAD',
  'TECNOLOGO EN ADMINISTRACION Y CERTIFICACION DE SISTEMAS DE CALIDAD',
  'TECNOLOGO EN ADMINISTRACION Y CONSTRUCCION DE OBRAS CIVILES',
  'TECNOLOGO EN ADMINISTRACION Y CONTABILIDAD SISTEMATIZADA',
  'TECNOLOGO EN ADMINISTRACION Y DISEÑO DE MODAS',
  'TECNOLOGO EN ADMINISTRACION Y EJECUCION DE CONSTRUCCIONES',
  'TECNOLOGO EN ADMINISTRACION Y FINANZAS',
  'TECNOLOGO EN ADMINISTRACION Y MERCADEO AGROPECUARIO',
  'TECNOLOGO EN ADMINISTRACION Y PRODUCCION AGROPECUARIA',
  'TECNOLOGO EN ADMINSTRACION DE REDES',
  'TECNOLOGO EN AGESTION NAVIERA Y PORTUARIA',
  'TECNOLOGO EN AGRICULTURA DE PRECISION',
  'TECNOLOGO EN AGROBIOTECNOLOGIA',
  'TECNOLOGO EN AGROBIOTECNOLOGIA PARA EL DESARROLLO SOSTENIBLE',
  'TECNOLOGO EN AGROINDUSTRIA',
  'TECNOLOGO EN AGRONOMIA DEL TROPICO HUMEDO',
  'TECNOLOGO EN AGROPECUARIA AMBIENTAL',
  'TECNOLOGO EN AGUA Y SANEAMIENTO',
  'TECNOLOGO EN ALIMENTOS',
  'TECNOLOGO EN ALIMENTOS ENFASIS EN PRODUCCION',
  'TECNOLOGO EN ALTA COSTURA',
  'TECNOLOGO EN ANALISIS AMBIENTALES',
  'TECNOLOGO EN ANALISIS DE COSTOS Y PRESUPUESTOS',
  'TECNOLOGO EN ANALISIS DE MATERIALES PARA LA INDUSTRIA',
  'TECNOLOGO EN ANALISIS DE OPERACIONES ADUANERAS Y FINANCIERAS',
  'TECNOLOGO EN ANALISIS DE SISTEMAS Y PROGRAMACION DE COMPUTADORES',
  'TECNOLOGO EN ANALISIS Y DESARROLLO DE SISTEMAS',
  'TECNOLOGO EN ANALISIS Y DESARROLLO DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN ANALISIS Y DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN ANALISIS Y DISEÑO DE SISTEMAS',
  'TECNOLOGO EN ANALISIS Y DISEÑO DE SISTEMAS DE INFORMACION.',
  'TECNOLOGO EN ANALISIS Y GESTION DE DATOS',
  'TECNOLOGO EN ANALISIS Y PROGRAMACION DE COMPUTADORES',
  'TECNOLOGO EN ANALISIS Y PROGRAMACION DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN ANALITICA Y BIG DATA',
  'TECNOLOGO EN ANIMACION 3D',
  'TECNOLOGO EN ANIMACION DIGITAL',
  'TECNOLOGO EN ANIMACION PARA MEDIOS DIGITALES',
  'TECNOLOGO EN ANIMACION Y POSPRODUCCION AUDIOVISUAL',
  'TECNOLOGO EN ANIMACION Y POSPRODUCCION AUDIOVISUALES',
  'TECNOLOGO EN ANIMATRONICA Y DESARROLLO MULTIMEDIAL',
  'TECNOLOGO EN APLICACION DE RECURSOS MILITARES',
  'TECNOLOGO EN ARCHIVISTICA',
  'TECNOLOGO EN ARQUITECTURA DE SOFTWARE',
  'TECNOLOGO EN ARTE Y DECORACION ARQUITECTONICA',
  'TECNOLOGO EN ARTES GRAFICAS',
  'TECNOLOGO EN ARTES MUSICALES',
  'TECNOLOGO EN ARTESANIAS',
  'TECNOLOGO EN ARTESANIAS.',
  'TECNOLOGO EN ASEGURAMIENTO DE LA CALIDAD',
  'TECNOLOGO EN ASEGURAMIENTO DE LA CALIDAD ANALITICA PARA EL SECTOR AGROALIMENTARIO',
  'TECNOLOGO EN ASEGURAMIENTO DE LA CALIDAD EN EMPRESAS AGROINDUSTRIALES',
  'TECNOLOGO EN ASEGURAMIENTO METROLOGICO INDUSTRIAL',
  'TECNOLOGO EN ASFALTOS Y PAVIMENTOS',
  'TECNOLOGO EN ASISTENCIA GERENCIAL',
  'TECNOLOGO EN ASUNTOS DE GOBIERNO Y EJECUCION DE PROYECTOS',
  'TECNOLOGO EN ATENCION INTEGRAL A LA PRIMERA INFANCIA',
  'TECNOLOGO EN ATENCION PREHOSPITALARIA',
  'TECNOLOGO EN ATENCION PREHOSPITALARIA DE URGENCIAS, EMERGENCIAS Y DESASTRES',
  'TECNOLOGO EN AUDIO',
  'TECNOLOGO EN AUDIO Y PRODUCCION MUSICAL',
  'TECNOLOGO EN AUDITORIA Y ASEGURAMIENTO DE LA INFORMACION',
  'TECNOLOGO EN AUDITORIA Y CONTROL DE INFORMATICA',
  'TECNOLOGO EN AUDITORIA Y CONTROL EMPRESARIAL',
  'TECNOLOGO EN AUDITORIA Y CONTROL INTERNO',
  'TECNOLOGO EN AUDITORIA Y COSTOS',
  'TECNOLOGO EN AUTOMATIZACION DE SISTEMAS MECATRONICOS',
  'TECNOLOGO EN AUTOMATIZACION ELECTRONICA',
  'TECNOLOGO EN AUTOMATIZACION ELECTRONICA INDUISTRIAL',
  'TECNOLOGO EN AUTOMATIZACION ELECTRONICA INDUSTRIAL',
  'TECNOLOGO EN AUTOMATIZACION INDUSTRIAL',
  'TECNOLOGO EN AUTOMATIZACION Y ROBOTICA INDUSTRIAL',
  'TECNOLOGO EN AUTOTRONICA',
  'TECNOLOGO EN BANCA E INSTITUCIONES FINANCIERAS',
  'TECNOLOGO EN BANCA ELECTRONICA',
  'TECNOLOGO EN BANCA Y FINANZAS',
  'TECNOLOGO EN BIOCOMERCIO SOSTENIBLE',
  'TECNOLOGO EN BIOELECTRONICA',
  'TECNOLOGO EN BIOTECNOLOGIA ACUATICA',
  'TECNOLOGO EN BIOTECNOLOGIA AMBIENTAL',
  'TECNOLOGO EN BUCEO Y SALVAMENTO MARITIMO Y FLUVIAL',
  'TECNOLOGO EN CAFE Y DIVERSIFICACION',
  'TECNOLOGO EN CALIDAD',
  'TECNOLOGO EN CALIDAD ALIMENTARIA',
  'TECNOLOGO EN CAMARA Y FOTOGRAFIA PARA CINE',
  'TECNOLOGO EN CARTOGRAFIA',
  'TECNOLOGO EN CERAMICA',
  'TECNOLOGO EN CIENCIAS AGRICOLAS Y PECUARIAS',
  'TECNOLOGO EN CIENCIAS MILITARES',
  'TECNOLOGO EN CINE Y FOTOGRAFIA',
  'TECNOLOGO EN CINE Y TELEVISION',
  'TECNOLOGO EN CITOHISTOLOGIA',
  'TECNOLOGO EN COMERCIO ELECTRONICO',
  'TECNOLOGO EN COMERCIO EXTERIOR',
  'TECNOLOGO EN COMERCIO EXTERIOR Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN COMERCIO INTERNACIONAL',
  'TECNOLOGO EN COMERCIO Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN COMPUTACION',
  'TECNOLOGO EN COMPUTACION GRAFICA',
  'TECNOLOGO EN COMPUTACION Y DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN COMUNICACION COMERCIAL',
  'TECNOLOGO EN COMUNICACION GRAFICA',
  'TECNOLOGO EN COMUNICACION PUBLICITARIA',
  'TECNOLOGO EN COMUNICACION SOCIAL',
  'TECNOLOGO EN COMUNICACION SOCIAL - PERIODISMO',
  'TECNOLOGO EN COMUNICACION SOCIAL - PERIODISMO -',
  'TECNOLOGO EN COMUNICACION SOCIAL Y PERIODISMO',
  'TECNOLOGO EN COMUNICACIONES',
  'TECNOLOGO EN COMUNICACIONES AERONAUTICAS',
  'TECNOLOGO EN CONFECCION INDUSTRIAL',
  'TECNOLOGO EN CONFECCIONES',
  'Tecnologo en conservaciOn de cuencas hidrogrAficas',
  'TECNOLOGO EN CONSTRUCCION',
  'TECNOLOGO EN CONSTRUCCION DE ACABADOS ARQUITECTONICOS',
  'TECNOLOGO EN CONSTRUCCION DE CIUDADANIA',
  'TECNOLOGO EN CONSTRUCCION DE EDIFICACIONES',
  'TECNOLOGO EN CONSTRUCCION DE EDIFICACIONES E INFRAESTRUCTURA URBANA',
  'TECNOLOGO EN CONSTRUCCION DE INFRAESTRUCTURA VIAL',
  'TECNOLOGO EN CONSTRUCCION DE OBRAS CIVILES',
  'TECNOLOGO EN CONSTRUCCION DE SOFTWARE',
  'TECNOLOGO EN CONSTRUCCION EN EDIFICACIONES',
  'TECNOLOGO EN CONSTRUCCION PARA LA CONSERVACION DEL PATRIMONIO CULTURAL CAFETERO',
  'TECNOLOGO EN CONSTRUCCION Y CONSERVACION DE VIAS',
  'TECNOLOGO EN CONSTRUCCION Y REPARACION DE INSTRUMENTOS DE CUERDA FROTADA',
  'TECNOLOGO EN CONSTRUCCIONES ARQUITECTONICAS',
  'TECNOLOGO EN CONSTRUCCIONES CIVILES',
  'TECNOLOGO EN CONSTRUCCIONES SOLDADAS',
  'TECNOLOGO EN CONTABILIDAD',
  'TECNOLOGO EN CONTABILIDAD E IMPUESTOS',
  'TECNOLOGO EN CONTABILIDAD FINANCIERA',
  'TECNOLOGO EN CONTABILIDAD SISTEMATIZADA',
  'TECNOLOGO EN CONTABILIDAD Y COSTOS',
  'TECNOLOGO EN CONTABILIDAD Y FINANZAS',
  'TECNOLOGO EN CONTABILIDAD Y TRIBUTARIA',
  'TECNOLOGO EN CONTADURIA',
  'TECNOLOGO EN CONTADURIA FINANCIERA',
  'TECNOLOGO EN CONTADURIA PUBLICA',
  'TECNOLOGO EN CONTADURIA Y FINANZAS',
  'TECNOLOGO EN CONTADURIA Y TRIBUTARIA',
  'TECNOLOGO EN CONTROL AMBIENTAL',
  'TECNOLOGO EN CONTROL DE BIOPROCESOS INDUSTRIALES',
  'TECNOLOGO EN CONTROL DE CALIDAD',
  'TECNOLOGO EN CONTROL DE CALIDAD DE ALIMENTOS',
  'TECNOLOGO EN CONTROL DE CALIDAD DE LOS ALIMENTOS',
  'TECNOLOGO EN CONTROL DE CALIDAD EN ALIMENTOS',
  'TECNOLOGO EN CONTROL DE CALIDAD EN LA INDUSTRIA DE ALIMENTOS',
  'TECNOLOGO EN CONTROL DE GESTION EN SISTEMAS DE INFORMACION CONTABLE',
  'TECNOLOGO EN CONTROL DE LA CALIDAD',
  'TECNOLOGO EN CONTROL DE PROCESOS ELECTRONICOS',
  'TECNOLOGO EN CONTROL DE PROCESOS INDUSTRIALES',
  'TECNOLOGO EN CONTROL ELECTRONICO DE PROCESOS',
  'TECNOLOGO EN CONTROL INTEGRADO DE LA CALIDAD, MEDIO AMBIENTE, SEGURIDAD Y SALUD OCUPACIONAL',
  'TECNOLOGO EN CONTROL Y AUTOMATIZACION INDUSTRIAL',
  'TECNOLOGO EN CONTROL Y GESTION DE PROCESOS AGROINDUSTRIALES',
  'TECNOLOGO EN COORDINACION DE ESCUELAS DE MUSICA',
  'TECNOLOGO EN COORDINACION DE PROCESOS LOGISTICOS',
  'TECNOLOGO EN COORDINACION DE PROYECTOS CULTURALES',
  'TECNOLOGO EN COORDINACION DE SERVICIOS HOTELEROS',
  'TECNOLOGO EN COORDINACION DEL PROCESO PARA DISEÑO DE MEDIOS IMPRESOS',
  'TECNOLOGO EN COORDINACION LOGISTICA DE EVENTOS CULTURALES Y ARTISITICOS',
  'TECNOLOGO EN COSMETOLOGIA Y ESTETICA CORPORAL',
  'TECNOLOGO EN COSTOS Y AUDITORIA',
  'TECNOLOGO EN COSTOS Y PRESUPUESTOS',
  'TECNOLOGO EN COSTOS Y TRIBUTARIA',
  'TECNOLOGO EN CREACION DE CONTENIDOS VISUALES Y MULTIMEDIALES',
  'TECNOLOGO EN CREACION DE SOLUCIONES DE SOFTWARE Y REDES DE DATOS',
  'TECNOLOGO EN CREACION PUBLICITARIA',
  'TECNOLOGO EN CREACION Y PRODUCCION DE ESPACIOS COMERCIALES',
  'TECNOLOGO EN CREACION Y PRODUCCION DE MODA',
  'TECNOLOGO EN CRIMINALISTICA',
  'TECNOLOGO EN CRIMINALISTICA DE CAMPO',
  'TECNOLOGO EN CRIMINALISTICA E INVESTIGACION JUDICIAL',
  'TECNOLOGO EN CRIMINALISTICA Y CIENCIAS FORENSES',
  'TECNOLOGO EN CRIMINALISTICA Y GESTION JUDIICIAL',
  'TECNOLOGO EN CRIMINOLOGIA E INVESTIGACION FORENSE',
  'TECNOLOGO EN CYBERSOCIEDAD',
  'TECNOLOGO EN DECORACION DE ESPACIOS INTERIORES',
  'TECNOLOGO EN DECORACION DE INTERIORES',
  'TECNOLOGO EN DELINEADO Y ARMADO DE ESTRUCTURAS EN MADERA',
  'TECNOLOGO EN DELINEANTE DE ARQUITECTURA E INGENIERIA',
  'TECNOLOGO EN DELINEANTES DE ARQUITECTURA',
  'TECNOLOGO EN DELINEANTES DE ARQUITECTURA E INGENIERIA',
  'TECNOLOGO EN DEPORTE',
  'TECNOLOGO EN DEPORTE Y RECREACION',
  'TECNOLOGO EN DESAROLLO AMBIENTAL',
  'TECNOLOGO EN DESAROLLO Y PROMOCION SOCIAL',
  'TECNOLOGO EN DESARROLLLO DE SISTEMAS DE INFORMACION Y DE SOFTWARE',
  'TECNOLOGO EN DESARROLLO AMBIENTAL',
  'TECNOLOGO EN DESARROLLO ARTESANAL',
  'TECNOLOGO EN DESARROLLO COMUNITARIO',
  'TECNOLOGO EN DESARROLLO CREATIVO DE PRODUCTOS PARA LA INDUSTRIA',
  'TECNOLOGO EN DESARROLLO DE APLICACIONES MOVILES Y WEB',
  'TECNOLOGO EN DESARROLLO DE APLICACIONES WEB',
  'TECNOLOGO EN DESARROLLO DE APLICACIONES WEB Y MOVILES',
  'TECNOLOGO EN DESARROLLO DE COLECCIONES DE CALZADO Y MARROQUINERIA',
  'TECNOLOGO EN DESARROLLO DE COLECCIONES PARA LA INDUSTRIA DE LA MODA',
  'TECNOLOGO EN DESARROLLO DE COLECCIONES PARA LA INSDUSTRIA DE LA MODA',
  'TECNOLOGO EN DESARROLLO DE COMPONENTES',
  'TECNOLOGO EN DESARROLLO DE COMPONENTES MECANICOS',
  'TECNOLOGO EN DESARROLLO DE LA PRODUCCION GRAFICA Y DIGITAL',
  'TECNOLOGO EN DESARROLLO DE MEDIOS GRAFICOS VISUALES',
  'TECNOLOGO EN DESARROLLO DE PROCESOS DE MERCADEO',
  'TECNOLOGO EN DESARROLLO DE PRODUCTOS ELECTRONICOS',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS DE INFORMACION Y DE SOFTWARE',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS DE INFORMACION Y REDES',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS ELECTRONICOS',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS ELECTRONICOS INDUSTRIALES',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS ELECTRONICOS Y DE TELECOMUNICACIONES',
  'TECNOLOGO EN DESARROLLO DE SISTEMAS INFORMATICOS',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE PARA NEGOCIOS DIGITALES',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE Y APLICATIVOS MOVILES',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE Y GESTION DE BASES DE DATOS',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE Y REDES',
  'TECNOLOGO EN DESARROLLO DE SOFTWARE Y REDES TELEMATICAS',
  'TECNOLOGO EN DESARROLLO DE SOLUCIONES WEB',
  'TECNOLOGO EN DESARROLLO DE VIDEOJUEGOS',
  'TECNOLOGO EN DESARROLLO DE VIDEOJUEGOS Y ENTORNOS INTERACTIVOS',
  'TECNOLOGO EN DESARROLLO DE VIDEOJUEGOS Y ENTORNOS INTERACTIVOS.',
  'TECNOLOGO EN DESARROLLO DEL SOFTWARE',
  'TECNOLOGO EN DESARROLLO GRAFICO DE PROYECTOS DE ARQUITECTURA E INGENIERIA',
  'TECNOLOGO EN DESARROLLO INFORMATICO',
  'TECNOLOGO EN DESARROLLO MULTIMEDIA Y WEB',
  'TECNOLOGO EN DESARROLLO PECUARIO',
  'TECNOLOGO EN DESARROLLO PUBLICITARIO',
  'TECNOLOGO EN DESARROLLO SOCIO EMPRESARIAL',
  'TECNOLOGO EN DESARROLLO SOCIO- EMPRESARIAL',
  'TECNOLOGO EN DESARROLLO WEB MULTIMEDIAL',
  'TECNOLOGO EN DESARROLLO Y ADAPTACION DE PROTESIS Y ORTESIS',
  'TECNOLOGO EN DESARROLLO Y ADMINISTRACION DE APLICACIONES INFORMATICAS',
  'TECNOLOGO EN DESARROLLO Y BIENESTAR SOCIAL',
  'TECNOLOGO EN DESARROLLO Y GESTION DE SISTEMAS INFORMATICOS',
  'TECNOLOGO EN DESARROLLO Y MODELADO DE PRODUCTOS INDUSTRIALES',
  'TECNOLOGO EN DESARROLLO Y PROMOCION SOCIAL',
  'TECNOLOGO EN DIAGNOSTICO Y GESTION AUTOMOTRIZ',
  'TECNOLOGO EN DIBUJO ARQUITECTONICO Y DE INGENIERIA',
  'TECNOLOGO EN DIBUJO Y MODELADO ARQUITECTONICO Y DE INGENIERIA',
  'TECNOLOGO EN DIBUJO Y MODELADO ARTQUTECTONICO Y DE INGENIERIA',
  'TECNOLOGO EN DIGITALIZACION DE MEDIOS',
  'TECNOLOGO EN DIRECCION COMERCIAL',
  'TECNOLOGO EN DIRECCION DE EMPRESAS TURISTICAS Y HOTELERAS',
  'TECNOLOGO EN DIRECCION DE SERVICIOS TURISTICOS',
  'TECNOLOGO EN DIRECCION DE SERVICIOS TURISTICOS Y RECREATIVOS',
  'TECNOLOGO EN DIRECCION DE VENTAS',
  'TECNOLOGO EN DIRECCION LOGISTICA DEL TURISMO DE SALUD',
  'TECNOLOGO EN DIRECCION Y GESTION DE EMPRESAS GASTRONOMICAS',
  'TECNOLOGO EN DIRECCION Y GESTION DE LA CADENA TURISTICA',
  'TECNOLOGO EN DIRECCION Y PRODUCCION DE RADIO Y TELEVISION',
  'TECNOLOGO EN DIRECCION Y PRODUCCION DE TELEVISION',
  'TECNOLOGO EN DISE&#209;O GRAFICO',
  'TECNOLOGO EN DISEÑO ARTESANAL',
  'TECNOLOGO EN DISEÑO DE APLICACIONES MOVILES Y WEB',
  'TECNOLOGO EN DISEÑO DE CALZADO Y MARROQUINERIA',
  'TECNOLOGO EN DISEÑO DE COMPONENTES PARA CALZADO',
  'TECNOLOGO EN DISEÑO DE COMUNICACION VISUAL',
  'TECNOLOGO EN DISEÑO DE ELEMENTOS MECANICOS PARA SU FABRICACION CON MAQUINAS CNC',
  'TECNOLOGO EN DISEÑO DE ELEMENTOS MECANICOS PARA SU FABRICACION CON MAQUINAS HERRAMIENTAS CNC',
  'TECNOLOGO EN DISEÑO DE ELEMENTOS MECANICOS PARA SU FABRICACION CON MAQUINAS HERRAMIENTAS CNC.',
  'TECNOLOGO EN DISEÑO DE INTERIORES',
  'TECNOLOGO EN DISEÑO DE MAQUINAS Y PRODUCTOS INDUSTRIALES',
  'TECNOLOGO EN DISEÑO DE MOBILIARIO',
  'TECNOLOGO EN DISEÑO DE MODAS',
  'TECNOLOGO EN DISEÑO DE MODAS Y ALTA COSTURA',
  'TECNOLOGO EN DISEÑO DE MODAS Y APLICACIONES TEXTILES',
  'TECNOLOGO EN DISEÑO DE MODAS Y TEXTIL',
  'TECNOLOGO EN DISEÑO DE MOLDES PARA TRANSFORMACION DE MATERIALES PLASTICOS',
  'TECNOLOGO EN DISEÑO DE PRODUCCION INDUSTRIAL',
  'TECNOLOGO EN DISEÑO DE PRODUCTOS INDUSTRIALES',
  'TECNOLOGO EN DISEÑO DE SISTEMAS MECANICOS',
  'TECNOLOGO EN DISEÑO DE SOFTWARE',
  'TECNOLOGO EN DISEÑO DE TROQUELES',
  'TECNOLOGO EN DISEÑO E INDUSTRIALIZACION DE LA MODA',
  'TECNOLOGO EN DISEÑO E INTEGRACION DE AUTOMATISMOS MECATRONICOS',
  'TECNOLOGO EN DISEÑO E INTEGRACION DE AUTOMATISMOS MECATRONICOS.',
  'TECNOLOGO EN DISEÑO GRAFICO',
  'TECNOLOGO EN DISEÑO GRAFICO ANIMACION, MULTIMEDIA Y PAGINAS WEB',
  'TECNOLOGO EN DISEÑO GRAFICO MULTIMEDIAL',
  'TECNOLOGO EN DISEÑO GRAFICO PUBLICITARIO',
  'TECNOLOGO EN DISEÑO GRAFICO Y ASISTIDO POR COMPUTADOR',
  'TECNOLOGO EN DISEÑO GRAFICO Y MULTIMEDIAL',
  'TECNOLOGO EN DISEÑO GRAFICO Y PUBLICITARIO',
  'TECNOLOGO EN DISEÑO GRAFICO, ANIMACION, MULTIMEDIA Y PAGINAS WEB',
  'TECNOLOGO EN DISEÑO INDUSTRIAL',
  'TECNOLOGO EN DISEÑO MECANICO',
  'TECNOLOGO EN DISEÑO PARA LA COMUNICACION GRAFICA',
  'TECNOLOGO EN DISEÑO PARA LA INDUSTRIA DE LA MODA',
  'TECNOLOGO EN DISEÑO PARA PROYECTOS WEB',
  'TECNOLOGO EN DISEÑO POR COMPUTADOR',
  'TECNOLOGO EN DISEÑO PUBLICITARIO',
  'TECNOLOGO EN DISEÑO TEXTIL',
  'TECNOLOGO EN DISEÑO TEXTIL Y DE MODA',
  'TECNOLOGO EN DISEÑO TEXTIL Y DE MODAS',
  'TECNOLOGO EN DISEÑO VISUAL',
  'TECNOLOGO EN DISEÑO VISUAL E ILUSTRACION',
  'TECNOLOGO EN DISEÑO Y ADMINISTRACION DE SISTEMAS',
  'TECNOLOGO EN DISEÑO Y ANIMACION GRAFICA',
  'TECNOLOGO EN DISEÑO Y COMUNICACION GRAFICA',
  'TECNOLOGO EN DISEÑO Y COMUNICACION VISUAL',
  'TECNOLOGO EN DISEÑO Y DECORACION DE ESPACIOS Y AMBIENTES',
  'TECNOLOGO EN DISEÑO Y DESARROLLO DE PRODUCTO ARTESANAL',
  'TECNOLOGO EN DISEÑO Y DESARROLLO DE PRODUCTOS MANUFACTURADOS',
  'TECNOLOGO EN DISEÑO Y DESARROLLO DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN DISEÑO Y DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN DISEÑO Y DESARROLLO WEB',
  'TECNOLOGO EN DISEÑO Y GESTION DE LA IMAGEN',
  'TECNOLOGO EN DISEÑO Y GESTION DE SISTEMAS',
  'TECNOLOGO EN DISEÑO Y PATRONAJE DE MODAS',
  'TECNOLOGO EN DISEÑO Y PRODUCCION DE MODA',
  'TECNOLOGO EN DISEÑO Y PRODUCCION DE MODAS',
  'TECNOLOGO EN DISEÑO Y PRODUCCION GRAFICA',
  'TECNOLOGO EN DISEÑO, IMPLEMENTACION Y MANTENIMIENTO DE SISTEMAS DE TELECOMUNICACIONES',
  'TECNOLOGO EN DISTRIBUCION COMERCIAL',
  'TECNOLOGO EN DISTRIBUCION DE LA ENERGIA ELECTRICA',
  'TECNOLOGO EN DISTRIBUCION FISICA INTERNACIONAL',
  'TECNOLOGO EN DOCENCIA RURAL',
  'TECNOLOGO EN DOCUMENTACION Y ARCHIVISTICA',
  'TECNOLOGO EN ECOLOGIA Y MANEJO AMBIENTAL',
  'TECNOLOGO EN ECOLOGIA Y TURISMO',
  'TECNOLOGO EN ECOTURISMO',
  'TECNOLOGO EN EDICION PARA TELEVISION',
  'TECNOLOGO EN EDIFICACION Y OBRA CIVIL',
  'TECNOLOGO EN EDUCACION BASICA PRIMARIA',
  'TECNOLOGO EN EDUCACION ESPECIAL',
  'TECNOLOGO EN EDUCACION FISICA',
  'TECNOLOGO EN EDUCACION PREESCOLAR',
  'TECNOLOGO EN EJECUCION INSTRUMENTAL SINFONICA',
  'TECNOLOGO EN ELCTRONICA INDUSTRIAL',
  'TECNOLOGO EN ELECTRICA',
  'TECNOLOGO EN ELECTRICIDAD',
  'TECNOLOGO EN ELECTRICIDAD INDUSTRIAL',
  'TECNOLOGO EN ELECTRICIDAD INDUSTRIAL Y DE POTENCIA',
  'TECNOLOGO EN ELECTRICIDAD Y TELEFONIA',
  'TECNOLOGO EN ELECTROMECANICA',
  'TECNOLOGO EN ELECTROMEDICINA',
  'TECNOLOGO EN ELECTRONICA',
  'TECNOLOGO EN ELECTRONICA AERONAUTICA',
  'TECNOLOGO EN ELECTRONICA DESESCOLARIZADA',
  'TECNOLOGO EN ELECTRONICA INDUSTRIAL',
  'TECNOLOGO EN ELECTRONICA Y AUTOMATIZACION INDUSTRIAL',
  'TECNOLOGO EN ELECTRONICA Y COMUNICACIONES',
  'TECNOLOGO EN ELECTRONICA Y CONTROLES INDUSTRIALES',
  'TECNOLOGO EN ELECTRONICA Y TELECOMUNICACIONES',
  'TECNOLOGO EN ELECTROQUIMICA',
  'TECNOLOGO EN EMPRESAS TURISTICAS Y HOTELERAS',
  'TECNOLOGO EN EN DELINEANTE DE ARQUITECTURA E INGENIERIA',
  'TECNOLOGO EN EN DESARROLLO DE SISTEMAS INFORMATICOS',
  'TECNOLOGO EN EN GESTION DE MERCADOS',
  'TECNOLOGO EN EN GESTION DE PROYECTOS DE DESARROLLO ECONOMICO Y SOCIAL',
  'TECNOLOGO EN EN PROCESOS BIOTECNOLOGICOS APLICADOS A LA INDUSTRIA',
  'TECNOLOGO EN EN SISTEMAS DE INFORMACION Y DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN ENERGIA Y PETROQUIMICA',
  'TECNOLOGO EN ENFERMERIA DENTAL',
  'TECNOLOGO EN ENTRENAMIENTO DE FUTBOL PROFESIONAL',
  'TECNOLOGO EN ENTRENAMIENTO DEL FUTBOL',
  'TECNOLOGO EN ENTRENAMIENTO DEPORTIVO',
  'TECNOLOGO EN ENTRENAMIENTO DEPORTIVO EN FUTBOL',
  'TECNOLOGO EN ENTRENAMIENTO Y GESTION MILITAR',
  'TECNOLOGO EN EQUIPOS BIOMEDICOS',
  'TECNOLOGO EN ESCRITURA PARA PRODUCTOS AUDIOVISUALES',
  'TECNOLOGO EN ESTADISTICA',
  'TECNOLOGO EN ESTADISTICA E INFORMATICA',
  'TECNOLOGO EN ESTETICA Y COSMETOLOGIA',
  'TECNOLOGO EN ESTUDIOS POLICIALES',
  'TECNOLOGO EN EVALUACION DE PROYECTOS',
  'TECNOLOGO EN EVALUACION Y PROGRAMACION DE LA ACTIVIDAD FISICA',
  'TECNOLOGO EN EXPLOTACION Y BENEFICIO DE MINERALES',
  'TECNOLOGO EN EXPRESION GRAFICA Y PUBLICACIONES',
  'TECNOLOGO EN FABRICACION DE MOLDES Y TROQUELES',
  'TECNOLOGO EN FABRICACION DE PRODUCTOS PLASTICOS POR EXTRUSION',
  'TECNOLOGO EN FABRICACION DE PRODUCTOS PLASTICOS POR INYECCION Y SOPLADO',
  'TECNOLOGO EN FINANZAS',
  'TECNOLOGO EN FINANZAS PUBLICAS',
  'TECNOLOGO EN FINANZAS Y AUDITORIA SISTEMATIZADA',
  'TECNOLOGO EN FINANZAS Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN FINANZAS Y RELACIONES INTERNACIONALES',
  'TECNOLOGO EN FINANZAS Y SISTEMAS CONTABLES',
  'TECNOLOGO EN FORMULACION DE PROYECTOS',
  'TECNOLOGO EN FORMULACION Y GESTION DE PROYECTOS SOCIALES',
  'TECNOLOGO EN FOTOGRAFIA',
  'TECNOLOGO EN FOTOGRAFIA E IMAGEN DIGITAL',
  'TECNOLOGO EN FOTOGRAFIA Y PROCESOS DIGITALES',
  'TECNOLOGO EN FOTOGRAFIA Y PRODUCCION DIGITAL.',
  'TECNOLOGO EN FRUTICULTURA Y AGROFORESTERIA',
  'TECNOLOGO EN GAS',
  'TECNOLOGO EN GASES COMBUSTIBLES',
  'TECNOLOGO EN GASES COMERCIALES',
  'TECNOLOGO EN GASTRONOMIA',
  'TECNOLOGO EN GASTRONOMIA Y ARTE CULINARIO',
  'TECNOLOGO EN GASTRONOMIA Y BEBIDAS',
  'TECNOLOGO EN GASTRONOMIA Y COCINA',
  'TECNOLOGO EN GASTRONOMIA Y GESTION DE RESTAURANTES',
  'TECNOLOGO EN GASTRONOMIA Y SOMMELIER',
  'TECNOLOGO EN GEMOLOGIA',
  'TECNOLOGO EN GEORREFERENCIACION TOPOGRAFICA',
  'TECNOLOGO EN GERENCIA DE OFICINAS',
  'TECNOLOGO EN GERONTOLOGIA',
  'TECNOLOGO EN GESIOPN EMPRESARIAL',
  'TECNOLOGO EN GESTION ADMINISTRATIVA',
  'TECNOLOGO EN GESTION ADMINISTRATIVA DE EMPRESAS DE SALUD',
  'TECNOLOGO EN GESTION ADMINISTRATIVA DE EMPRESAS DE SALUDO',
  'TECNOLOGO EN GESTION ADMINISTRATIVA DE LA SEGURIDAD SOCIAL',
  'TECNOLOGO EN GESTION ADMINISTRATIVA DE SERVICIOS DE SALUD',
  'TECNOLOGO EN GESTION ADMINISTRATIVA DEL SECTOR SALUD',
  'TECNOLOGO EN GESTION ADMINISTRATIVA Y DE PROYECTOS',
  'TECNOLOGO EN GESTION ADMINISTRATIVA Y FINANCIERA',
  'TECNOLOGO EN GESTION AERONAUTICA',
  'TECNOLOGO EN GESTION AEROPORTUARIA',
  'TECNOLOGO EN GESTION AGROEMPRESARIAL',
  'TECNOLOGO EN GESTION AGROFORESTAL',
  'TECNOLOGO EN GESTION AGROFORESTAL, POR CICLOS PROPEDEUTICOS',
  'TECNOLOGO EN GESTION AGROINDUSTRIAL',
  'TECNOLOGO EN GESTION AGROPECUARIA',
  'TECNOLOGO EN GESTION AGROPECUARIA ECOLOGICA',
  'TECNOLOGO EN GESTION AGROPECUARIA SOSTENIBLE',
  'TECNOLOGO EN GESTION AMBIENTAL',
  'TECNOLOGO EN GESTION AMBIENTAL DE RESIDUOS',
  'TECNOLOGO EN GESTION AMBIENTAL INDUSTRIAL',
  'TECNOLOGO EN GESTION AMBIENTAL TERRITORIAL',
  'TECNOLOGO EN GESTION AMBIENTAL Y SERVICIOS PUBLICOS',
  'TECNOLOGO EN GESTION AMBIENTAL Y TURISTICA',
  'TECNOLOGO EN GESTION BANCARIA Y DE ENTIDADES FINANCIERAS',
  'TECNOLOGO EN GESTION BANCARIA Y DE INVERSION FINANCIERA',
  'TECNOLOGO EN GESTION BANCARIA Y FINANCIERA',
  'TECNOLOGO EN GESTION BIBLIOTECARIA',
  'TECNOLOGO EN GESTION CATASTRAL Y AGRIMENSURA',
  'TECNOLOGO EN GESTION COMERCIAL',
  'TECNOLOGO EN GESTION COMERCIAL AGROEMPRESARIAL',
  'TECNOLOGO EN GESTION COMERCIAL DE NEGOCIOS VERDES',
  'TECNOLOGO EN GESTION COMERCIAL DE SERVICIOS',
  'TECNOLOGO EN GESTION COMERCIAL DE SERVICIOS DE SALUD',
  'TECNOLOGO EN GESTION COMERCIAL INTERNACIONAL',
  'TECNOLOGO EN GESTION COMERCIAL Y ANALISIS DE MERCADOS',
  'TECNOLOGO EN GESTION COMERCIAL Y DE MERCADOS',
  'TECNOLOGO EN GESTION COMERCIAL Y DE NEGOCIOS',
  'TECNOLOGO EN GESTION COMERCIAL Y FINANCIERA',
  'TECNOLOGO EN GESTION COMERCIAL Y MERCADEO',
  'TECNOLOGO EN GESTION COMERCIAL Y PUBLICITARIA',
  'TECNOLOGO EN GESTION COMUNICATIVA PARA LAS ORGANIZACIONES',
  'TECNOLOGO EN GESTION COMUNICATIVA Y DE MERCADOS',
  'TECNOLOGO EN GESTION COMUNICATIVA Y RELACIONES PUBLICAS',
  'TECNOLOGO EN GESTION COMUNITARIA',
  'TECNOLOGO EN GESTION CONTABLE',
  'TECNOLOGO EN GESTION CONTABLE E INFORMACION FINANCIERA',
  'TECNOLOGO EN GESTION CONTABLE Y DE COSTOS',
  'TECNOLOGO EN GESTION CONTABLE Y DE INFORMACION FINANCIERA',
  'TECNOLOGO EN GESTION CONTABLE Y DE INFORMACION FINANCIERAES',
  'TECNOLOGO EN GESTION CONTABLE Y FINANCIERA',
  'TECNOLOGO EN GESTION CONTABLE Y FINNACIERA',
  'TECNOLOGO EN GESTION CONTABLE Y TRIBUTARIA',
  'TECNOLOGO EN GESTION CULTURAL',
  'TECNOLOGO EN GESTION CULTURAL Y ARTISTICA',
  'TECNOLOGO EN GESTION DE AEROLINEAS Y AGENCIAS DE VIAJES',
  'TECNOLOGO EN GESTION DE AGRICULTURA ORGANICA',
  'TECNOLOGO EN GESTION DE AGROINDUSTRIAS ALIMENTICIAS',
  'TECNOLOGO EN GESTION DE AUTOMATISMOS MECATRONICOS',
  'TECNOLOGO EN GESTION DE BASES DE DATOS',
  'TECNOLOGO EN GESTION DE BIONEGOCIOS',
  'TECNOLOGO EN GESTION DE BPO',
  'TECNOLOGO EN GESTION DE CALIDAD',
  'TECNOLOGO EN GESTION DE CALIDAD, SEGURIDAD, SALUD EN EL TRABAJO Y AMBIENTE',
  'TECNOLOGO EN GESTION DE CALIDAD, SEGURIDAD, SALUD OCUPACIONAL Y AMBIENTE',
  'TECNOLOGO EN GESTION DE CENTROS DE SERVICIO',
  'TECNOLOGO EN GESTION DE COMERCIO DIGITAL',
  'TECNOLOGO EN GESTION DE COMERCIO EXTERIOR',
  'TECNOLOGO EN GESTION DE COMERCIO EXTERIOR DE BIENES Y SERVICIOS',
  'TECNOLOGO EN GESTION DE COMERCIO EXTERIOR Y LOGISTICA',
  'TECNOLOGO EN GESTION DE COMERCIO INTERNACIONAL',
  'TECNOLOGO EN GESTION DE COMERCIO INTERNACIONAL Y FINANZAS',
  'TECNOLOGO EN GESTION DE CONTENDOS GRAFICOS PUBLICITARIOS',
  'TECNOLOGO EN GESTION DE CONTENIDOS PARA LA INTERACCION DIGITAL',
  'TECNOLOGO EN GESTION DE DATOS',
  'TECNOLOGO EN GESTION DE DERECHOS HUMANOS, PAZ Y RESOLUCION DE CONFLICTOS',
  'TECNOLOGO EN GESTION DE DESTINOS TURISTICOS',
  'TECNOLOGO EN GESTION DE DISEÑO DE MODAS',
  'TECNOLOGO EN GESTION DE DISEÑO MULTIMEDIAL Y ANIMACION',
  'TECNOLOGO EN GESTION DE EMPRESAS',
  'TECNOLOGO EN GESTION DE EMPRESAS AGRICOLAS',
  'TECNOLOGO EN GESTION DE EMPRESAS AGROFORESTALES',
  'TECNOLOGO EN GESTION DE EMPRESAS AGROINDUSTRIAL',
  'TECNOLOGO EN GESTION DE EMPRESAS AGROINDUSTRIALES',
  'TECNOLOGO EN GESTION DE EMPRESAS AGROPECUARIAS',
  'TECNOLOGO EN GESTION DE EMPRESAS ASOCIATIVAS Y ORGANIZACIONES COMUNITARIAS',
  'TECNOLOGO EN GESTION DE EMPRESAS DE ECONOMIA SOLIDARIA',
  'TECNOLOGO EN GESTION DE EMPRESAS DE LA SALUD',
  'TECNOLOGO EN GESTION DE EMPRESAS DE SALUD',
  'TECNOLOGO EN GESTION DE EMPRESAS DE SERVICIOS',
  'TECNOLOGO EN GESTION DE EMPRESAS ECOTURISTICAS',
  'TECNOLOGO EN GESTION DE EMPRESAS GANADERAS',
  'TECNOLOGO EN GESTION DE EMPRESAS PECUARIA',
  'TECNOLOGO EN GESTION DE EMPRESAS PECUARIAS',
  'TECNOLOGO EN GESTION DE EMPRESAS TURISTICAS',
  'TECNOLOGO EN GESTION DE EMPRESAS TURISTICAS Y HOTELERAS',
  'TECNOLOGO EN GESTION DE EMPRESAS Y ORGANIZACIONES SOLIDARIAS',
  'TECNOLOGO EN GESTION DE EXPORTACION E IMPORTACION DE PRODUCTOS DE MANUFACTURA',
  'TECNOLOGO EN GESTION DE EXPORTACIONES E IMPORTACIONES',
  'TECNOLOGO EN GESTION DE FABRICACION MECANICA',
  'TECNOLOGO EN GESTION DE FACILIDADES PARA PRODUCCION DE HIDROCARBUROS',
  'TECNOLOGO EN GESTION DE FINANZAS Y COMERCIO INTERNACIONAL',
  'TECNOLOGO EN GESTION DE FINANZAS Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN GESTION DE IMPORTACIONES Y EXPORTACIONES',
  'TECNOLOGO EN GESTION DE INDUSTRIAS CREATIVAS',
  'TECNOLOGO EN GESTION DE INFORMACION EN SALUD',
  'TECNOLOGO EN GESTION DE INFRAESTRUCTURAS DE TECNOLOGIAS DE INFORMACION Y COMUNICACIONES',
  'TECNOLOGO EN GESTION DE INFRAESTRUCTURAS DE TECNOLOGIAS DE LA INFORMACION Y COMUNICACIONES',
  'TECNOLOGO EN GESTION DE INSUMOS AGROPECUARIOS',
  'TECNOLOGO EN GESTION DE INTFRAESTRUCTURA DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION DE LA CALIDAD',
  'TECNOLOGO EN GESTION DE LA COMUNICACION DIGITAL',
  'TECNOLOGO EN GESTION DE LA COMUNICACION EN MEDIOS',
  'TECNOLOGO EN GESTION DE LA COMUNICACION EN MEDIOS Y ORGANIZACIONES',
  'TECNOLOGO EN GESTION DE LA COMUNICACION GRAFICA',
  'TECNOLOGO EN GESTION DE LA COMUNICACION MULTIMEDIA',
  'TECNOLOGO EN GESTION DE LA CONSTRUCCION',
  'TECNOLOGO EN GESTION DE LA CONSTRUCCION DE EDIFICACIONES',
  'TECNOLOGO EN GESTION DE LA CONSTRUCCION DE PROYECTOS ARQUITECTONICOS',
  'TECNOLOGO EN GESTION DE LA EMPRESA AGRICOLA',
  'TECNOLOGO EN GESTION DE LA EMPRESA CAFETERA',
  'TECNOLOGO EN GESTION DE LA INFORMACION Y LA COMUNICACION',
  'TECNOLOGO EN GESTION DE LA MODA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION AGRICOLA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION AGRICOLA ECOLOGICA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION AGROFORESTAL',
  'TECNOLOGO EN GESTION DE LA PRODUCCION AGROINDUSTRIAL',
  'TECNOLOGO EN GESTION DE LA PRODUCCION DE BIOMASA ENERGETICA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION GRAFICA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION INDUSTRIAL',
  'TECNOLOGO EN GESTION DE LA PRODUCCION MULTIMEDIA',
  'TECNOLOGO EN GESTION DE LA PRODUCCION Y CALIDAD',
  'TECNOLOGO EN GESTION DE LA PRODUCCION Y LA CALIDAD',
  'TECNOLOGO EN GESTION DE LA PRODUCION INDUSTRIAL',
  'TECNOLOGO EN GESTION DE LA PROPIEDAD HORIZONTAL',
  'TECNOLOGO EN GESTION DE LA RECREACION Y EL DEPORTE',
  'TECNOLOGO EN GESTION DE LA SEGURIDAD E HIGIENE LABORAL',
  'TECNOLOGO EN GESTION DE LA SEGURIDAD EN EL TRABAJO',
  'TECNOLOGO EN GESTION DE LA SEGURIDAD Y SALUD EN ELTRABAJO',
  'TECNOLOGO EN GESTION DE LOGISTICA EMPRESARIAL',
  'TECNOLOGO EN GESTION DE LOGISTICA INTERNACIONAL',
  'TECNOLOGO EN GESTION DE LOS NEGOCIOS INTERNACIONALES EN ENTORNOS DIGITALES',
  'TECNOLOGO EN GESTION DE MANTENIMIENTO INDUSTRIAL',
  'TECNOLOGO EN GESTION DE MANTENIMIENTO Y OPERACION DE PLANTAS INDUSTRIALES',
  'TECNOLOGO EN GESTION DE MARKETING',
  'TECNOLOGO EN GESTION DE MERCADEO',
  'TECNOLOGO EN GESTION DE MERCADEO DIGITAL',
  'TECNOLOGO EN GESTION DE MERCADEO INTERNACIONAL',
  'TECNOLOGO EN GESTION DE MERCADEO Y ESTRATEGIAS COMERCIALES',
  'TECNOLOGO EN GESTION DE MERCADEO Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN GESTION DE MERCADEO Y PUBLICIDAD',
  'TECNOLOGO EN GESTION DE MERCADEO Y VENTAS',
  'TECNOLOGO EN GESTION DE MERCADOS',
  'TECNOLOGO EN GESTION DE MERCADOTECNIA',
  'TECNOLOGO EN GESTION DE MERCADOTECNIA Y PUBLICIDAD',
  'TECNOLOGO EN GESTION DE MICROFINANZAS',
  'TECNOLOGO EN GESTION DE NEGOCIOS',
  'TECNOLOGO EN GESTION DE NEGOCIOS FIDUCIARIOS',
  'TECNOLOGO EN GESTION DE NEGOCIOS FORESTALES',
  'TECNOLOGO EN GESTION DE NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN GESTION DE NUEVAS TECNOLOGIAS EN REDES',
  'TECNOLOGO EN GESTION DE OBRAS CIVILES',
  'TECNOLOGO EN GESTION DE OBRAS CIVILES Y CONSTRUCCIONES',
  'TECNOLOGO EN GESTION DE OPERACIONES EN TERMINALES PORTUARIAS',
  'TECNOLOGO EN GESTION DE OPERACIONES INDUSTRIALES',
  'TECNOLOGO EN GESTION DE ORGANIZACIONES TURISTICAS',
  'TECNOLOGO EN GESTION DE PLANTACIONES DE CACAO',
  'TECNOLOGO EN GESTION DE PLANTACIONES DE PALMA DE ACEITE',
  'TECNOLOGO EN GESTION DE PLANTACIONES FORESTALES.',
  'TECNOLOGO EN GESTION DE PROCESOS ADMINISTRATIVOS',
  'TECNOLOGO EN GESTION DE PROCESOS ADMINISTRATIVOS DE SALUD',
  'TECNOLOGO EN GESTION DE PROCESOS AGROINDUSTRIALES',
  'TECNOLOGO EN GESTION DE PROCESOS AUDIOVISUALES',
  'TECNOLOGO EN GESTION DE PROCESOS DE CALIDAD',
  'TECNOLOGO EN GESTION DE PROCESOS DE MANUFACTURA',
  'TECNOLOGO EN GESTION DE PROCESOS DE PREPRENSA PARA MEDIOS',
  'TECNOLOGO EN GESTION DE PROCESOS INDUSTRIALES',
  'TECNOLOGO EN GESTION DE PROCESOS LOGISTICOS',
  'TECNOLOGO EN GESTION DE PROCESOS LOGISTICOS.',
  'TECNOLOGO EN GESTION DE PROCESOS MECATRONICOS',
  'TECNOLOGO EN GESTION DE PROCESOS PRODUCTIVOS Y ADMINISTRATIVOS',
  'TECNOLOGO EN GESTION DE PROCESOS Y PRODUCCION DE MOBILIARIO',
  'TECNOLOGO EN GESTION DE PRODUCCION SOSTENIBLE',
  'TECNOLOGO EN GESTION DE PRODUCCION Y CALIDAD',
  'TECNOLOGO EN GESTION DE PRODUCCION Y TRANSFORMACION DEL ACERO',
  'TECNOLOGO EN GESTION DE PRODUCTO',
  'TECNOLOGO EN GESTION DE PRODUCTOS Y EMPRESAS DE ARTESANIAS',
  'TECNOLOGO EN GESTION DE PROYECTOS AGROECOLOGICOS',
  'TECNOLOGO EN GESTION DE PROYECTOS ARTISTICOS',
  'TECNOLOGO EN GESTION DE PROYECTOS DE DESARROLLO ECONOMICO Y SOCIAL',
  'TECNOLOGO EN GESTION DE PROYECTOS SOCIALES Y COMUNITARIOS',
  'TECNOLOGO EN GESTION DE PROYECTOS URBANISTICOS',
  'TECNOLOGO EN GESTION DE PUBLICIDAD Y MEDIOS DIGITALES',
  'TECNOLOGO EN GESTION DE RECUPERACION MINERO AURIFERA',
  'TECNOLOGO EN GESTION DE RECURSOS AEREOS',
  'TECNOLOGO EN GESTION DE RECURSOS EN PLANTAS DE PRODUCCION',
  'TECNOLOGO EN GESTION DE RECURSOS ENERGETICOS',
  'TECNOLOGO EN GESTION DE RECURSOS HUMANOS',
  'TECNOLOGO EN GESTION DE RECURSOS MINERO ENERGETICOS',
  'TECNOLOGO EN GESTION DE RECURSOS NATURALES',
  'TECNOLOGO EN GESTION DE RECUSOS NATURALES',
  'TECNOLOGO EN GESTION DE REDES DE ACCESO DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION DE REDES DE DATOS',
  'TECNOLOGO EN GESTION DE REDES DE DATOS Y SISTEMAS INFORMATICOS',
  'TECNOLOGO EN GESTION DE REDES DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION DE REDES INALAMBRICAS',
  'TECNOLOGO EN GESTION DE REDES INFORMATICAS',
  'TECNOLOGO EN GESTION DE REDES Y COMUNICACIONES',
  'TECNOLOGO EN GESTION DE REDES.',
  'TECNOLOGO EN GESTION DE RIESGOS Y SEGUROS',
  'TECNOLOGO EN GESTION DE SALUD',
  'TECNOLOGO EN GESTION DE SEGURIDAD EN REDES DE COMPUTADORES',
  'TECNOLOGO EN GESTION DE SEGURIDAD VIAL',
  'TECNOLOGO EN GESTION DE SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO EN GESTION DE SEGURIDAD, SALUD EN EL TRABAJO Y MEDIO AMBIENTE',
  'TECNOLOGO EN GESTION DE SEGUROS',
  'TECNOLOGO EN GESTION DE SERVICIO Y BEBIDAS',
  'TECNOLOGO EN GESTION DE SERVICIOS ALIMENTARIOS',
  'TECNOLOGO EN GESTION DE SERVICIOS DE SALUD',
  'TECNOLOGO EN GESTION DE SERVICIOS EN CULTIVOS ENERGETICOS',
  'TECNOLOGO EN GESTION DE SERVICIOS GASTRONOMICOS',
  'TECNOLOGO EN GESTION DE SERVICIOS HOTELEROS',
  'TECNOLOGO EN GESTION DE SERVICIOS HOTELEROS Y TURISTICOS',
  'TECNOLOGO EN GESTION DE SERVICIOS INFORMATICOS',
  'TECNOLOGO EN GESTION DE SERVICIOS PARA AEROLINEAS',
  'TECNOLOGO EN GESTION DE SERVICIOS PUBLICOS SANITARIOS',
  'TECNOLOGO EN GESTION DE SERVICIOS RECREATIVOS',
  'TECNOLOGO EN GESTION DE SERVICIOS TURISTICOS',
  'TECNOLOGO EN GESTION DE SERVICIOS TURISTICOS Y HOTELEROS',
  'TECNOLOGO EN GESTION DE SISTEMAS DE INFORMACION',
  'TECNOLOGO EN GESTION DE SISTEMAS DE INFORMACION DOCUMENTAL Y ARCHIVISTICA',
  'TECNOLOGO EN GESTION DE SISTEMAS DE INFORMACION Y DOCUMENTACION',
  'TECNOLOGO EN GESTION DE SISTEMAS DE INFORMACION Y REDES DE COMPUTO',
  'TECNOLOGO EN GESTION DE SISTEMAS DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION DE SISTEMAS ELECTRICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS ELECTROMECANICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS ELECTRONICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS INFORMATICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS INFORMATICOS EMPRESARIALES',
  'TECNOLOGO EN GESTION DE SISTEMAS INTEGRADOS',
  'TECNOLOGO EN GESTION DE SISTEMAS LOGISTICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS MECATRONICOS',
  'TECNOLOGO EN GESTION DE SISTEMAS MECATRONICOS INDUSTRIALES',
  'TECNOLOGO EN GESTION DE SISTEMAS OPERATIVOS Y REDES DE COMPUTADORAS',
  'TECNOLOGO EN GESTION DE SOFTWARE Y REDES',
  'TECNOLOGO EN GESTION DE SOFTWARE Y SISTEMAS',
  'TECNOLOGO EN GESTION DE TESORERIA Y DE RECURSOS FINANCIEROS',
  'TECNOLOGO EN GESTION DE TESORERIA Y RECURSOS FINANCIEROS',
  'TECNOLOGO EN GESTION DE TIC',
  'TECNOLOGO EN GESTION DE TRANSITO AEREO',
  'TECNOLOGO EN GESTION DE TRASPORTES',
  'TECNOLOGO EN GESTION DE TURISMO Y HOTELES',
  'TECNOLOGO EN GESTION DE VENTAS Y NEGOCIOS',
  'TECNOLOGO EN GESTION DEL CAPITAL HUMANO',
  'TECNOLOGO EN GESTION DEL CICLO DE VIDA DEL PRODUCTO',
  'TECNOLOGO EN GESTION DEL COMERCIO EXTERIOR',
  'TECNOLOGO EN GESTION DEL COMERCIO INTERNACIONAL',
  'TECNOLOGO EN GESTION DEL DISEÑO GRAFICO',
  'TECNOLOGO EN GESTION DEL DISEÑO TEXTIL Y DE MODAS',
  'TECNOLOGO EN GESTION DEL ECOTURISMO',
  'TECNOLOGO EN GESTION DEL MANTENIMIENTO AERONAUTICO',
  'TECNOLOGO EN GESTION DEL MANTENIMIENTO DE AUTOMOTORES',
  'TECNOLOGO EN GESTION DEL MANTENIMIENTO DE MAQUINARIA PESADA',
  'TECNOLOGO EN GESTION DEL MANTENIMIENTO ELECTROMECANICO',
  'TECNOLOGO EN GESTION DEL SERVICIO DE POLICIA',
  'TECNOLOGO EN GESTION DEL TALENTO HUMANO',
  'TECNOLOGO EN GESTION DEL TERRITORIO Y ORGANIZACIONES',
  'TECNOLOGO EN GESTION DEL TRANSPORTE',
  'TECNOLOGO EN GESTION DEL TURISMO CULTURAL Y DE NATURALEZA',
  'TECNOLOGO EN GESTION DEL TURISMO DE NATURALEZA',
  'TECNOLOGO EN GESTION DEL TURISMO SOSTENIBLE',
  'TECNOLOGO EN GESTION DEPORTIVA',
  'TECNOLOGO EN GESTION DOCUMENTAL',
  'TECNOLOGO EN GESTION ECOTURISTICA Y HOTELERA',
  'TECNOLOGO EN GESTION EFICIENTE DE LA ENERGIA',
  'TECNOLOGO EN GESTION EFICIENTE DE LA ENERGIA ELECTRICA',
  'TECNOLOGO EN GESTION EFICIENTE DE LA ENERGIA.',
  'TECNOLOGO EN GESTION EJECUTIVA',
  'TECNOLOGO EN GESTION EJECUTIVA BILINGÜE',
  'TECNOLOGO EN GESTION EJECUTIVA DE OFICINAS',
  'TECNOLOGO EN GESTION EMPRESARIAL',
  'TECNOLOGO EN GESTION EMPRESARIAL AGROPECUARIA',
  'TECNOLOGO EN GESTION EMPRESARIAL COMUNITARIA',
  'TECNOLOGO EN GESTION EMPRESARIAL DE ECONOMIA SOLIDARIA',
  'TECNOLOGO EN GESTION EMPRESARIAL Y DE LA INNOVACION',
  'TECNOLOGO EN GESTION EMPRESARIAL Y DE NEGOCIOS',
  'TECNOLOGO EN GESTION EMPRESARIAL Y FINANCIERA',
  'TECNOLOGO EN GESTION EN COMUNIDADES ETNICAS DE PROYECTOS AGROPECUARIOS SOSTENIBLES',
  'TECNOLOGO EN GESTION EN DISEÑO DE MODA',
  'TECNOLOGO EN GESTION EN FINANZAS',
  'TECNOLOGO EN GESTION EN LOGISTICA MILITAR',
  'TECNOLOGO EN GESTION EN SALUD OCUPACIONAL',
  'TECNOLOGO EN GESTION FINANCIERA',
  'TECNOLOGO EN GESTION FINANCIERA DEL COMERCIO INTERNACIONAL',
  'TECNOLOGO EN GESTION FINANCIERA Y CONTABLE',
  'TECNOLOGO EN GESTION FINANCIERA Y DE RECURSOS DE LA SALUD',
  'TECNOLOGO EN GESTION FINANCIERA Y DE SISTEMAS',
  'TECNOLOGO EN GESTION FINANCIERA Y DE TESORERIA',
  'TECNOLOGO EN GESTION FINANCIERA Y TRIBUTARIA',
  'TECNOLOGO EN GESTION GASTRONOMICA',
  'TECNOLOGO EN GESTION GASTRONOMICA Y DE BEBIDAS',
  'TECNOLOGO EN GESTION GASTRONOMICA Y SOMMELIER',
  'TECNOLOGO EN GESTION GRAFICA MULTIMEDIAL',
  'TECNOLOGO EN GESTION HOTELERA',
  'TECNOLOGO EN GESTION HOTELERA Y DE SERVICIOS',
  'TECNOLOGO EN GESTION HOTELERA Y SERVICIOS TURISTICOS',
  'TECNOLOGO EN GESTION HOTELERA Y TURISTICA',
  'TECNOLOGO EN GESTION HUMANA',
  'TECNOLOGO EN GESTION INDUSTRIAL',
  'TECNOLOGO EN GESTION INFORMATICA',
  'TECNOLOGO EN GESTION INFRAESTRUCTURA DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION INTEGRADA DE LA CALIDAD MEDIO AMBIENTE SEGURIDAD Y SALUD OCUPACIONAL',
  'TECNOLOGO EN GESTION INTEGRADA DE LA CALIDAD, MEDIO AMBIENTE, SEGURIDAD Y SALUD OCUPACIONAL',
  'TECNOLOGO EN GESTION INTEGRADA DE LA CALIDAD, MEDIO AMBIENTE, SEGURIDAD Y SALUD OCUPACIONAL.',
  'TECNOLOGO EN GESTION INTEGRAL DE LA CALIDAD Y SEGURIDAD INDUSTRIAL',
  'TECNOLOGO EN GESTION INTEGRAL DE SEGUROS',
  'TECNOLOGO EN GESTION INTEGRAL DEL RIESGO EN SEGUROS',
  'TECNOLOGO EN GESTION INTEGRAL DEL TRANSPORTE',
  'TECNOLOGO EN GESTION INTEGRAL EN FONDOS DE PENSIONES Y CESANTIAS',
  'TECNOLOGO EN GESTION INTERNACIONAL DEL COMERCIO',
  'TECNOLOGO EN GESTION JUDICIAL',
  'TECNOLOGO EN GESTION JUDICIAL Y CRIMINALISTICA',
  'TECNOLOGO EN GESTION LOGISTICA',
  'TECNOLOGO EN GESTION LOGISTICA DEL COMERCIO EXTERIOR',
  'TECNOLOGO EN GESTION LOGISTICA EMPRESARIAL',
  'TECNOLOGO EN GESTION LOGISTICA EN COMPRAS E INVENTARIOS',
  'TECNOLOGO EN GESTION LOGISTICA INTEGRAL',
  'TECNOLOGO EN GESTION LOGISTICA INTERNACIONAL',
  'TECNOLOGO EN GESTION LOGISTICA NACIONAL E INTERNACIONAL',
  'TECNOLOGO EN GESTION LOGISTICA PORTUARIA',
  'TECNOLOGO EN GESTION LOGISTICA PORTUARIA Y ADUANERA',
  'TECNOLOGO EN GESTION LOGISTICA Y COMERCIO EXTERIOR',
  'TECNOLOGO EN GESTION LOGISTICA Y DE COMERCIO INTERNACIONAL',
  'TECNOLOGO EN GESTION LOGISTICA Y DISTRIBUCION DE PRODUCTOS MANUFACTURADOS',
  'TECNOLOGO EN GESTION LOGISTICA Y PORTUARIA',
  'TECNOLOGO EN GESTION LOGISTICA.',
  'TECNOLOGO EN GESTION MARKETING INTERNACIONAL',
  'TECNOLOGO EN GESTION MERCADEO',
  'TECNOLOGO EN GESTION MINERA',
  'TECNOLOGO EN GESTION MINERO AMBIENTAL',
  'TECNOLOGO EN GESTION MODAS',
  'TECNOLOGO EN GESTION NAVIERA Y PORTUARIA',
  'TECNOLOGO EN GESTION PARA EL DESARROLLO DEL TURISMO',
  'TECNOLOGO EN GESTION PARA EL SUMINISTRO DE GASES COMBUSTIBLES Y NO COMBUSTIBLES',
  'TECNOLOGO EN GESTION PARA ESTABLECIMIENTOS DE ALIMENTOS Y BEBIDAS',
  'TECNOLOGO EN GESTION PARA PROYECTOS WEB',
  'TECNOLOGO EN GESTION PARA SUMINISTRO DE GAS COMBUSTIBLE',
  'TECNOLOGO EN GESTION PORTUARIA',
  'TECNOLOGO EN GESTION PORTUARIA Y LOGISTICA DE TRANSPORTE',
  'TECNOLOGO EN GESTION PUBLICA',
  'TECNOLOGO EN GESTION PUBLICA AMBIENTAL',
  'TECNOLOGO EN GESTION PUBLICA CONTABLE',
  'TECNOLOGO EN GESTION PUBLICA FINANCIERA',
  'TECNOLOGO EN GESTION PUBLICA TERRITORIAL',
  'TECNOLOGO EN GESTION PUBLICITARIA',
  'TECNOLOGO EN GESTION SOCIAL',
  'TECNOLOGO EN GESTION SOCIAL Y SALUD COMUNITARIA',
  'TECNOLOGO EN GESTION SOSTENIBLE DE LA BIODIVERSIDAD VEGETAL',
  'TECNOLOGO EN GESTION SOSTENIBLE DE LA BIODIVERSIDAD Y EL BIOCOMERCIO',
  'TECNOLOGO EN GESTION SOSTENIBLE DEL CAFE',
  'TECNOLOGO EN GESTION TRIBUTARIA Y FINANCIERA',
  'TECNOLOGO EN GESTION TRIBUTARIA, CONTABLE Y FINANCIERA',
  'TECNOLOGO EN GESTION TURISTICA',
  'TECNOLOGO EN GESTION TURISTICA Y DESARROLLO LOCAL',
  'TECNOLOGO EN GESTION TURISTICA Y GASTRONOMICA',
  'TECNOLOGO EN GESTION TURISTICA Y HOTELERA',
  'TECNOLOGO EN GESTION Y ADMINISTRACION AGRICOLA',
  'TECNOLOGO EN GESTION Y ADMINISTRACION DE EMPRESAS',
  'TECNOLOGO EN GESTION Y AUDITORIA ADMINISTRATIVA',
  'TECNOLOGO EN GESTION Y AUDITORIA AMBIENTAL',
  'TECNOLOGO EN GESTION Y CONFIGURACION DE REDES',
  'TECNOLOGO EN GESTION Y CONFIGURACION DE REDES DE TELECOMUNICACIONES',
  'TECNOLOGO EN GESTION Y CONSTRUCCION DE OBRAS CIVILES',
  'TECNOLOGO EN GESTION Y DESARROLLO DE PROCESOS AUTOMATIZADOS',
  'TECNOLOGO EN GESTION Y DESARROLLO DE PRODUCTOS CERAMICOS',
  'TECNOLOGO EN GESTION Y DESARROLLO DE PROYECTOS',
  'TECNOLOGO EN GESTION Y EJECUCION DE CONSTRUCCIONES',
  'TECNOLOGO EN GESTION Y EJECUCION INSTRUMENTAL PARA LAS PRACTICAS MUSICALES',
  'TECNOLOGO EN GESTION Y OPERACION ECOTURISTICA',
  'TECNOLOGO EN GESTION Y PRODUCCION CREATIVA PARA LAS PRACTICAS VISUALES',
  'TECNOLOGO EN GESTION Y PRODUCCION HORTICOLA',
  'TECNOLOGO EN GESTION Y REALIZACION DE CONTENIDOS AUDIOVISUALES',
  'TECNOLOGO EN GOBIERNO LOCAL',
  'TECNOLOGO EN GRABACION, EDICION Y MEZCLA DE VOCES Y DOBLAJES PARA MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN GRAFICA DIGITAL',
  'TECNOLOGO EN GSTION CONTABLE Y FINANCIERA',
  'TECNOLOGO EN GUIANZA TURISTICA',
  'TECNOLOGO EN HARDWARE Y SOFTWARE',
  'TECNOLOGO EN HIGIENE Y SEGURIDAD EN EL TRABAJO',
  'TECNOLOGO EN HIGIENE Y SEGURIDAD INDUSTRIAL',
  'TECNOLOGO EN HIGIENE Y SEGURIDAD OCUPACIONAL',
  'TECNOLOGO EN HISTOCITOTECNOLOGIA',
  'TECNOLOGO EN HORTICULTURA',
  'TECNOLOGO EN HOTELERIA TURISMO Y RELACIONES PUBLICAS',
  'TECNOLOGO EN HOTELERIA Y TURISMO',
  'TECNOLOGO EN HOTELERIA Y TURISMO MBIENTAL',
  'TECNOLOGO EN HOTELERIA, TURISMO Y RELACIONES PUBLICAS',
  'TECNOLOGO EN ILUMINACION, SOPORTE Y ELECTRICIDAD PARA CINE Y TELEVISION',
  'TECNOLOGO EN ILUSTRACION PARA PRODUCCIONES AUDIOVISUALES',
  'TECNOLOGO EN ILUSTRACION PARA PRODUCTOS EDITORIALES MULTIMEDIALES',
  'TECNOLOGO EN IMAGENES DIAGNOSTICAS',
  'TECNOLOGO EN IMPLEMENTACION DE INFRAESTRUCTURA DE TECNOLOGIAS DE LA INFORMACION Y LAS COMUNICACIONES',
  'TECNOLOGO EN IMPLEMENTACION DE INFRAESTRUCTURA DE TECNOLOGIAS DE LA INFORMACION Y LAS COMUNICACIONES.',
  'TECNOLOGO EN IMPLEMENTACION DE REDES Y SERVICIOS DE TELECOMUNCIACIONES',
  'TECNOLOGO EN IMPLEMENTACION DE REDES Y SERVICIOS DE TELECOMUNICACIONES',
  'TECNOLOGO EN IMPLEMENTACION DE SISTEMAS ELECTRONICOS INDUSTRIALES',
  'TECNOLOGO EN IMPLEMENTACION Y GESTION DE SERVICIOS EN REDES',
  'TECNOLOGO EN IMPLEMENTACION Y MANTENIMIENTO DE SISTEMAS DE INSTRUMENTACION Y CONTROL DE PROCESOS INDUSTRIALES',
  'TECNOLOGO EN IMPORTACIONES Y EXPORTACIONES',
  'TECNOLOGO EN INDUSTRIA DE ALIMENTOS',
  'TECNOLOGO EN INDUSTRIAL',
  'TECNOLOGO EN INFORMATICA',
  'TECNOLOGO EN INFORMATICA APLICADA',
  'TECNOLOGO EN INFORMATICA EMPRESARIAL',
  'TECNOLOGO EN INFORMATICA FORENSE',
  'TECNOLOGO EN INFORMATICA MUSICAL',
  'TECNOLOGO EN INFORMATICA Y SISTEMAS',
  'TECNOLOGO EN INFORMATICA Y TELECOMUNICACIONES',
  'TECNOLOGO EN INGENIERIA COMERCIAL',
  'TECNOLOGO EN INGENIERIA DE ALIMENTOS',
  'TECNOLOGO EN INGENIERIA DE INSTRUMENTACION Y CONTROL',
  'TECNOLOGO EN INGENIERIA DE SISTEMAS',
  'TECNOLOGO EN INGENIERIA DE SISTEMAS CON ENFASIS EN TELECOMUNICACIONES',
  'TECNOLOGO EN INGENIERIA DE SISTEMAS Y COMPUTADORES',
  'TECNOLOGO EN INGENIERIA DE SISTEMAS Y TELECOMUNICACIONES',
  'TECNOLOGO EN INGENIERIA ELECTRONICA DIGITAL',
  'TECNOLOGO EN INGENIERIA INDUSTRIAL',
  'TECNOLOGO EN INGENIERIA INDUSTRIAL CON ENFASIS EN PRODUCCION',
  'TECNOLOGO EN INGENIERIA MECATRONICA',
  'TECNOLOGO EN INNOVACION Y MERCADEO DE MODA',
  'TECNOLOGO EN INSPECCION DE ESTRUCTURAS SOLDADAS',
  'TECNOLOGO EN INSPECCION DE PROCESOS DE SOLDADURA',
  'TECNOLOGO EN INSTALACIONES DE REDES DE GAS',
  'TECNOLOGO EN INSTALACIONES HIDRAULICAS, SANITARIAS Y DE GAS',
  'TECNOLOGO EN INSTRUMENTACION ELECTRONICA',
  'TECNOLOGO EN INSTRUMENTACION INDUSTRIAL',
  'TECNOLOGO EN INSTRUMENTACION QUIRURGICA',
  'TECNOLOGO EN INSTRUMENTACION Y CONTROL DE PROCESO',
  'TECNOLOGO EN INSTRUMENTACION Y CONTROL DE PROCESOS INDUSTRIALES',
  'TECNOLOGO EN INSTRUMENTACION Y CONTROLES INDUSTRIALES',
  'TECNOLOGO EN INTELIGENCIA DE MERCADOS',
  'TECNOLOGO EN INTERIORISMO E INTERVENCION DE ESPACIOS',
  'TECNOLOGO EN INTERIORISMO SOSTENIBLE',
  'TECNOLOGO EN INTERIORISMO Y DECORACION DE ESPACIOS',
  'TECNOLOGO EN INTERPRETACION PARA SORDOS Y SORDOCIEGOS',
  'TECNOLOGO EN INTERPRETACION Y TRADUCCION EN LENGUA DE SEÑAS COLOMBIANAS (LSC) - ESPAÑOL',
  'TECNOLOGO EN INTERVENCION DE ESPACIOS Y AMBIENTES',
  'TECNOLOGO EN INTERVENCION Y AMBIENTACION DE ESPACIOS',
  'TECNOLOGO EN INVESTIGACION CRIMINAL',
  'TECNOLOGO EN INVESTIGACION CRIMINAL Y CIENCIAS FORENSES',
  'TECNOLOGO EN INVESTIGACION CRIMINAL Y CRIMINALISTICA',
  'TECNOLOGO EN INVESTIGACION CRIMINAL Y JUDICIAL',
  'TECNOLOGO EN INVESTIGACION CRIMINALISTICA Y JUDICIAL',
  'TECNOLOGO EN INVESTIGACION DE ACCIDENTES DE TRANSITO',
  'TECNOLOGO EN INVESTIGACION JUDICIAL',
  'TECNOLOGO EN INVESTIGACION JUDICIAL Y CRIMINALISTICA',
  'TECNOLOGO EN JOYERIA',
  'TECNOLOGO EN LABORATORIO DE PROTESIS DENTAL',
  'TECNOLOGO EN LABORATORIO DE REHABILITACION DENTAL',
  'TECNOLOGO EN LABORATORIO DENTAL',
  'TECNOLOGO EN LEVANTAMIENTOS TOPOGRAFIC0S Y GEOREFERENCIACION',
  'TECNOLOGO EN LEVANTAMIENTOS TOPOGRAFICOS',
  'TECNOLOGO EN LEVANTAMIENTOS TOPOGRAFICOS E INFORMACION GEOGRAFICA',
  'TECNOLOGO EN LEVANTAMIENTOS TOPOGRAFICOS Y GEOREFERENCIACION',
  'TECNOLOGO EN LEVANTAMIENTOS TOPOGRAFICOS Y GEORREFERENCIACION',
  'TECNOLOGO EN LOCUCION Y MEDIOS DE COMUNICACION',
  'TECNOLOGO EN LOCUCION Y PRODUCCION RADIAL',
  'TECNOLOGO EN LOGISTICA',
  'TECNOLOGO EN LOGISTICA DE ALMACENAJE E INVENTARIOS',
  'TECNOLOGO EN LOGISTICA DE OPERACIONES',
  'TECNOLOGO EN LOGISTICA DE SERVICIOS EMPRESARIALES',
  'TECNOLOGO EN LOGISTICA DE TRANSPORTE',
  'TECNOLOGO EN LOGISTICA DEL COMERCIO EXTERIOR',
  'TECNOLOGO EN LOGISTICA DEL TRANSPORTE',
  'TECNOLOGO EN LOGISTICA DEL TRANSPORTE INTENACIONAL DE MERCANCIAS',
  'TECNOLOGO EN LOGISTICA DEL TRANSPORTE MULTIMODAL',
  'TECNOLOGO EN LOGISTICA EMPRESARIAL',
  'TECNOLOGO EN LOGISTICA INDUSTRIAL',
  'TECNOLOGO EN LOGISTICA INTEGRAL',
  'TECNOLOGO EN LOGISTICA INTERNACIONAL',
  'TECNOLOGO EN LOGISTICA MILITAR',
  'TECNOLOGO EN LOGISTICA PARA ALIMENTOS PERECEDEROS',
  'TECNOLOGO EN LOGISTICA PORTUARIA Y DEL TRANSPORTE',
  'TECNOLOGO EN LOGISTICA Y DISTRIBUCION',
  'TECNOLOGO EN LOGISTICA Y MERCADEO',
  'TECNOLOGO EN LOGISTICA Y MERCADEO INTERNACIONAL',
  'TECNOLOGO EN MADERAS',
  'TECNOLOGO EN MANEJO DE FUENTES ABIERTAS DE USO DIAGNOSTICO Y TERAPEUTICO',
  'TECNOLOGO EN MANEJO DE GESTION AMBIENTAL',
  'TECNOLOGO EN MANEJO DE LA INFORMACION CONTABLE',
  'TECNOLOGO EN MANEJO DE PETROLEO Y GAS EN SUPERFICIE',
  'TECNOLOGO EN MANEJO DE RECURSOS NATURALES',
  'TECNOLOGO EN MANEJO DE SISTEMAS DE AGROBOSQUES',
  'TECNOLOGO EN MANEJO DEL AGUA',
  'TECNOLOGO EN MANEJO RECURSOS AMBIENTALES',
  'TECNOLOGO EN MANEJO SOSTENIBLE DEL CULTIVO DE CAÑA DE AZUCAR',
  'TECNOLOGO EN MANEJO Y APROVECHAMIENTO DE BOSQUES NATURALES',
  'TECNOLOGO EN MANEJO Y APROVECHAMIENTO DE RECURSOS HIDRICOS',
  'TECNOLOGO EN MANEJO Y APROVECHAMIENTO DE RESIDUOS SOLIDOS',
  'TECNOLOGO EN MANEJO Y APROVECHAMIENTO FORESTAL',
  'TECNOLOGO EN MANEJO Y COMERCIALIZACION AGROFORESTAL',
  'TECNOLOGO EN MANEJO Y CONSERVACION DE SUELOS Y AGUAS',
  'TECNOLOGO EN MANEJO Y CULTIVO DEL CAUCHO',
  'TECNOLOGO EN MANTENIMIENTO AERONAUTICO',
  'TECNOLOGO EN MANTENIMIENTO DE AERONAVES',
  'TECNOLOGO EN MANTENIMIENTO DE AVIONES',
  'TECNOLOGO EN MANTENIMIENTO DE COMPUTADORES',
  'TECNOLOGO EN MANTENIMIENTO DE EQUIPO BIOMEDICO',
  'TECNOLOGO EN MANTENIMIENTO DE EQUIPO PORTUARIO Y DEL TRANSPORTE',
  'TECNOLOGO EN MANTENIMIENTO DE EQUIPOS BIOMEDICOS',
  'TECNOLOGO EN MANTENIMIENTO DE EQUIPOS DE COMPUTO DISEÑO E INSTALACION DE CABLEADO ESTRUCTURADO',
  'TECNOLOGO EN MANTENIMIENTO DE MAQUINARIA INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO DE MAQUINAS Y HERRAMIENTAS ASOCIADAS A MINERIA',
  'TECNOLOGO EN MANTENIMIENTO ELECTRICO',
  'TECNOLOGO EN MANTENIMIENTO ELECTRICO INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTROMEANICO INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTROMECANICO DE EQUIPO PESADO',
  'TECNOLOGO EN MANTENIMIENTO ELECTROMECANICO DE EQUIPOS INDUSTRIALES',
  'TECNOLOGO EN MANTENIMIENTO ELECTROMECANICO INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTROMECANICO INDUTRIAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTRONICO',
  'TECNOLOGO EN MANTENIMIENTO ELECTRONICO E INSTRUMENTAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTRONICO E INSTRUMENTAL INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO ELECTRONICO E INSTRUMENTAL INDUSTRIAL.',
  'TECNOLOGO EN MANTENIMIENTO EN LINEA DE AVIONES',
  'TECNOLOGO EN MANTENIMIENTO EN LINEA DE HELICOPTEROS',
  'TECNOLOGO EN MANTENIMIENTO EN SISTEMAS ELECTRICOS ELECTRONICOS E INSTRUMENTOS DE AERONAVES',
  'TECNOLOGO EN MANTENIMIENTO INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO MECANICO',
  'TECNOLOGO EN MANTENIMIENTO MECANICO INDUSTRIAL',
  'TECNOLOGO EN MANTENIMIENTO MECATRONICO DE AUTOMOTORES',
  'TECNOLOGO EN MANTENIMIENTO Y GESTION ELECTRONICA',
  'TECNOLOGO EN MAQUINARIA E INSTRUMENTACION INDUSTRIAL',
  'TECNOLOGO EN MAQUINARIA INDUSTRIAL',
  'TECNOLOGO EN MAQUINAS Y HERRAMIENTAS',
  'TECNOLOGO EN MARKETING',
  'TECNOLOGO EN MARKETING DE SERVICIOS TURISTICOS',
  'TECNOLOGO EN MARKETING DIGITAL',
  'TECNOLOGO EN MECANICA',
  'TECNOLOGO EN MECANICA AUTOMOTRIZ',
  'TECNOLOGO EN MECANICA AUTOMOTRIZ DIESEL',
  'TECNOLOGO EN MECANICA AUTONOTRIZ',
  'TECNOLOGO EN MECANICA DENTAL',
  'TECNOLOGO EN MECANICA INDUSTRIAL',
  'TECNOLOGO EN MECANICA Y MANTENIMIENTO DE MAQUINAS DE HILANDERIA',
  'TECNOLOGO EN MECANICA Y MANTENIMIENTO DE MAQUINAS DE LOS PROCESOS DE TEJIDO DE PUNTO',
  'TECNOLOGO EN MECANIDA DENTAL',
  'TECNOLOGO EN MECANIZACION AGRICOLA',
  'TECNOLOGO EN MECATRONICA',
  'TECNOLOGO EN MECATRONICA INDUSTRIAL',
  'TECNOLOGO EN MEDIO AMBIENTE',
  'TECNOLOGO EN MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN MEDIOS DE COMUNICACION',
  'TECNOLOGO EN MERCADEO',
  'TECNOLOGO EN MERCADEO AGROINDUSTRIAL',
  'TECNOLOGO EN MERCADEO AGROPECUARIO',
  'TECNOLOGO EN MERCADEO DE PRODUCTOS ECOTURISTICOS',
  'TECNOLOGO EN MERCADEO DE SERVICIOS FINANCIEROS',
  'TECNOLOGO EN MERCADEO INTERNACIONAL',
  'TECNOLOGO EN MERCADEO PUBLICIDAD Y VENTAS',
  'TECNOLOGO EN MERCADEO Y CAMPAÑAS PUBLICITARIAS',
  'TECNOLOGO EN MERCADEO Y COMERCIALIZACION',
  'TECNOLOGO EN MERCADEO Y COMUNICACION DE LA MODA',
  'TECNOLOGO EN MERCADEO Y DISEÑO PUBLICITARIO',
  'TECNOLOGO EN MERCADEO Y ESTRATEGIAS COMERCIALES',
  'TECNOLOGO EN MERCADEO Y FINANZAS',
  'TECNOLOGO EN MERCADEO Y GESTION COMERCIAL',
  'TECNOLOGO EN MERCADEO Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN MERCADEO Y PUBLICIDAD',
  'TECNOLOGO EN MERCADEO YVENTAS',
  'TECNOLOGO EN MERCADOTECNIA',
  'TECNOLOGO EN MERCADOTECNIA Y COMERCIO INTERNACIONAL',
  'TECNOLOGO EN MERCADOTECNIA Y PUBLICIDAD',
  'TECNOLOGO EN MERCADOTECNIA Y VENTAS',
  'TECNOLOGO EN METALMECANICA',
  'TECNOLOGO EN METROLOGIA INDUSTRIAL',
  'TECNOLOGO EN MICROBIOLOGIA VETERINARIA',
  'TECNOLOGO EN MINAS',
  'TECNOLOGO EN MINERIA',
  'TECNOLOGO EN MODA E INDUMENTARIA',
  'TECNOLOGO EN MODELADO DIGITAL ARQUITECTONICO',
  'TECNOLOGO EN MODELADO Y ANIMACION GRAFICA',
  'TECNOLOGO EN MONTAJES INDUSTRIALES',
  'TECNOLOGO EN MULTIMEDIA',
  'TECNOLOGO EN MULTIMEDIA Y SOLUCIONES WEB',
  'TECNOLOGO EN NEGOCIACION INTERNACIONAL',
  'TECNOLOGO EN NEGOCIOS INTERNACIONALES',
  'TECNOLOGO EN NEGOCIOS Y MERCADEO',
  'TECNOLOGO EN OBRAS CIVILES',
  'TECNOLOGO EN OFICIAL DE CUBIERTA',
  'TECNOLOGO EN OFICIAL DE MAQUINAS',
  'TECNOLOGO EN OFICIAL ENCARGADO DE LA GUARDIA DE NAVEGACION. REGLA II/3.3 STCW/78 ENMENDADO. NIVEL OPERACIONAL.',
  'TECNOLOGO EN OFICIAL ENCARGADO DE LA GUARDIA EN CAMARA DE MAQUINAS. REGLA III/1 - STCW/78 ENMENDADO, NIVEL OPERACIONAL',
  'TECNOLOGO EN OPERACION DE PLANTAS PARA EL PROCESAMIENTO DE PLASTICOS',
  'TECNOLOGO EN OPERACION DE PLANTAS PETROQUIMICAS',
  'TECNOLOGO EN OPERACION DE PLANTAS Y PROCESOS INDUSTRIALES',
  'TECNOLOGO EN OPERACION DE SISTEMAS ELECTROMECANICOS',
  'TECNOLOGO EN OPERACION INTEGRAL DEL TRANSPORTE',
  'TECNOLOGO EN OPERACION Y MANTENIMIENTO DE MAQUINAS DE CONTROL NUMERICO',
  'TECNOLOGO EN OPERACION Y MANTENIMIENTO ELECTROMECANICO',
  'TECNOLOGO EN OPERACIONES DE MANUFACTURA Y SERVICIOS',
  'TECNOLOGO EN OPERACIONES FINANCIERAS',
  'TECNOLOGO EN OPERACIONES TURISTICAS',
  'TECNOLOGO EN ORGANIZACION DE EVENTOS',
  'TECNOLOGO EN ORGANIZACION DEL ESPACIO ESCENICO',
  'TECNOLOGO EN PEDAGOGIA Y LINGÜISTICA APLICADA PARA LA REVITALIZACION DE LA LENGUA NASA',
  'TECNOLOGO EN PERIODISMO',
  'TECNOLOGO EN PERIODISMO DEPORTIVO',
  'TECNOLOGO EN PLANEACION DE VESTUARIO PARA ARTES ESCENICAS',
  'TECNOLOGO EN PLANEACION MINERO ENERGETICA',
  'TECNOLOGO EN PLANEACION PARA EL CONTROL AMBIENTAL',
  'TECNOLOGO EN PLANEACION Y GESTION DE PROCESOS AUTOMATIZADOS',
  'TECNOLOGO EN PLANEACION Y GESTION DE REDES',
  'TECNOLOGO EN PLASTICOS',
  'TECNOLOGO EN POSCOSECHA Y COMERCIALIZACION DE FLORES FRUTAS Y HOSTALIZAS',
  'TECNOLOGO EN PREPRENSA, IMPRESION Y ACABADOS',
  'TECNOLOGO EN PRESENTACION Y ANIMACION DE TELEVISION Y RADIO',
  'TECNOLOGO EN PREVENCION Y CONTROL AMBIENTAL',
  'TECNOLOGO EN PREVENCION Y MANEJO SOSTENIBLE DE RESIDUOS',
  'TECNOLOGO EN PREVENCION Y MITIGACION AMBIENTAL',
  'TECNOLOGO EN PROCEDIMIENTOS JUDICIALES',
  'TECNOLOGO EN PROCESAMIENTO DE ALIMENTOS',
  'TECNOLOGO EN PROCESAMIENTO DE PLASTICOS',
  'TECNOLOGO EN PROCESAMIENTO DE POLIMEROS',
  'TECNOLOGO EN PROCESAMIENTO EN ALIMENTOS',
  'TECNOLOGO EN PROCESAMIENTO Y CONSERVACION DE PRODUCTOS',
  'TECNOLOGO EN PROCESOS ADMINISTRATIVOS DE SALUD',
  'TECNOLOGO EN PROCESOS AGROINDUSTRIALES',
  'TECNOLOGO EN PROCESOS AGROINDUSTRIALES DE LA CAÑA DE AZUCAR',
  'TECNOLOGO EN PROCESOS BIOTECNOLOGICOS',
  'TECNOLOGO EN PROCESOS BIOTECNOLOGICOS APLICADOS A LA INDUSTRIA',
  'TECNOLOGO EN PROCESOS COMERCIALES',
  'TECNOLOGO EN PROCESOS DE LA INDUSTRIA QUIMICA',
  'TECNOLOGO EN PROCESOS DE PRODUCCION INDUSTRIAL',
  'TECNOLOGO EN PROCESOS DE SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO EN PROCESOS DE TRANSFORMACION DE BIOMASA',
  'TECNOLOGO EN PROCESOS DE TRASFORMACION DE LA MADERA',
  'TECNOLOGO EN PROCESOS ELECTRONICOS Y DE TELECOMUNICACIONES',
  'TECNOLOGO EN PROCESOS ELECTRONICOS Y TELECOMUNICACIONES',
  'TECNOLOGO EN PROCESOS INDUSTRIALES',
  'TECNOLOGO EN PROCESOS INDUSTRIALES DE MECATRONICA',
  'TECNOLOGO EN PROCESOS LOGISTICOS',
  'TECNOLOGO EN PROCESOS MECANICOS INDUSTRIALES',
  'TECNOLOGO EN PROCESOS METALURGICOS',
  'TECNOLOGO EN PROCESOS PARA LA COMERCIALIZACION INTERNACIONAL',
  'TECNOLOGO EN PROCESOS PRODUCTIVOS',
  'TECNOLOGO EN PROCESOS PRODUCTIVOS DE LA MADERA',
  'TECNOLOGO EN PROCESOS PUBLICITARIOS',
  'TECNOLOGO EN PROCESOS QUIMICOS INDUSTRIALES',
  'TECNOLOGO EN PRODUCCION',
  'TECNOLOGO EN PRODUCCION ACUICOLA',
  'TECNOLOGO EN PRODUCCION AGRARIA',
  'TECNOLOGO EN PRODUCCION AGRICOLA',
  'TECNOLOGO EN PRODUCCION AGROECOLOGICA',
  'Tecnologo en ProducciOn AgroecolOgica de Cultivos.',
  'TECNOLOGO EN PRODUCCION AGROINDUSTRIAL',
  'TECNOLOGO EN PRODUCCION AGROPECUARIA',
  'TECNOLOGO EN PRODUCCION AGROPECUARIA ECOLOGICA',
  'TECNOLOGO EN PRODUCCION ANIMAL',
  'TECNOLOGO EN PRODUCCION AUDIO',
  'TECNOLOGO EN PRODUCCION AUDIOVISUAL',
  'TECNOLOGO EN PRODUCCION DANCISTICA',
  'TECNOLOGO EN PRODUCCION DE AGROCULTIVOS',
  'TECNOLOGO EN PRODUCCION DE ALIMENTOS',
  'TECNOLOGO EN PRODUCCION DE ANIMACION DIGITAL',
  'TECNOLOGO EN PRODUCCION DE CALZADO Y MARROQUINERIA',
  'TECNOLOGO EN PRODUCCION DE CAMPO PARA CINE Y TELEVISION',
  'TECNOLOGO EN PRODUCCION DE COLECCIONES INDUSTRIALES',
  'TECNOLOGO EN PRODUCCION DE COMPONENTES MECANICOS CON MAQUINAS DE CONTROL NUMERICO COMPUTARIZADO',
  'TECNOLOGO EN PRODUCCION DE COMPONENTES MECANICOS CON MAQUINAS DE CONTROL NUMERICOS COMPUTARIZADO',
  'TECNOLOGO EN PRODUCCION DE CONTENIDOS GRAFICOS DIGITALES',
  'TECNOLOGO EN PRODUCCION DE CONTENIDOS PERIODISTICOS',
  'TECNOLOGO EN PRODUCCION DE ESPACIOS Y AMBIENTES',
  'TECNOLOGO EN PRODUCCION DE ESPECIES MENORES',
  'TECNOLOGO EN PRODUCCION DE EVENTOS',
  'TECNOLOGO EN PRODUCCION DE GANADERIA SOSTENIBLE',
  'TECNOLOGO EN PRODUCCION DE IMAGEN FOTOGRAFICA',
  'TECNOLOGO EN PRODUCCION DE IMAGENES CON CAMARAS DE CINE Y TELEVISION DIGITAL',
  'TECNOLOGO EN PRODUCCION DE JOYERIA',
  'TECNOLOGO EN PRODUCCION DE LA MODA',
  'TECNOLOGO EN PRODUCCION DE MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN PRODUCCION DE MEDIOS AUDIOVISUALES DIGITALES',
  'TECNOLOGO EN PRODUCCION DE MEDIOS PUBLICITARIOS',
  'TECNOLOGO EN PRODUCCION DE MULTIMEDIA',
  'TECNOLOGO EN PRODUCCION DE MUSICA Y AUDIO',
  'TECNOLOGO EN PRODUCCION DE PETROLEO',
  'TECNOLOGO EN PRODUCCION DE RADI0 Y TELEVISION',
  'TECNOLOGO EN PRODUCCION DE RADIO Y MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN PRODUCCION DE RADIO Y TELEVISION',
  'TECNOLOGO EN PRODUCCION DE SONIDO',
  'TECNOLOGO EN PRODUCCION DE SONIDO PARA MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN PRODUCCION DE SONIDO Y MUSICALIZACION',
  'TECNOLOGO EN PRODUCCION DE TELEVISION Y RADIO',
  'TECNOLOGO EN PRODUCCION DE VIDEO Y ANIMACION GRAFICA',
  'TECNOLOGO EN PRODUCCION DIGITAL EN MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN PRODUCCION DIGITAL PARA MEDIOS IMPRESOS Y ELECTRONICOS',
  'TECNOLOGO EN PRODUCCION EN JOYERIA',
  'TECNOLOGO EN PRODUCCION ESCENICA Y VISUAL',
  'TECNOLOGO EN PRODUCCION FORESTAL',
  'TECNOLOGO EN PRODUCCION FOTOGRAFICA',
  'TECNOLOGO EN PRODUCCION GANADERA',
  'TECNOLOGO EN PRODUCCION GASTRONOMICA',
  'TECNOLOGO EN PRODUCCION GRAFICA',
  'TECNOLOGO EN PRODUCCION GRAFICA DIGITAL',
  'TECNOLOGO EN PRODUCCION GRAFICA PARA MEDIOS PUBLICITARIOS',
  'TECNOLOGO EN PRODUCCION GRAFICA PUBLICITARIA',
  'TECNOLOGO EN PRODUCCION INDUSTRIAL',
  'TECNOLOGO EN PRODUCCION INDUSTRIAL DE VESTUARIO',
  'TECNOLOGO EN PRODUCCION INDUSTRIAL Y DE SERVICIOS',
  'TECNOLOGO EN PRODUCCION INTERACTIVA Y MULTIMEDIA',
  'TECNOLOGO EN PRODUCCION MULTIMEDIA',
  'TECNOLOGO EN PRODUCCION MULTIMEDIAL',
  'TECNOLOGO EN PRODUCCION PECUARIA',
  'TECNOLOGO EN PRODUCCION PESQUERA',
  'TECNOLOGO EN PRODUCCION RADIAL',
  'TECNOLOGO EN PRODUCCION TRANSMEDIA',
  'TECNOLOGO EN PRODUCCION Y CALIDAD',
  'TECNOLOGO EN PRODUCCION Y CREACION DE MODA',
  'TECNOLOGO EN PRODUCCION Y DISEÑO DE MODAS',
  'TECNOLOGO EN PRODUCCION Y GESTION AGROPECUARIA',
  'TECNOLOGO EN PRODUCCION Y REALIZACION EN TELEVISION',
  'TECNOLOGO EN PRODUCTIVIDAD Y MANTENIMIENTO INDUSTRIAL',
  'TECNOLOGO EN PRODUCTIVIDAD Y MEJORAMIENTO CONTINUO',
  'TECNOLOGO EN PROGRAMACION DE APLICACIONES WEB',
  'TECNOLOGO EN PROGRAMACION DE SISTEMAS INFORMATICOS',
  'TECNOLOGO EN PROGRAMACION Y DESARROLLO DE SOFTWARE',
  'TECNOLOGO EN PROGRAMACION Y SISTEMAS',
  'TECNOLOGO EN PROMOCION DE LA SALUD',
  'TECNOLOGO EN PROMOCION DEL DESARROLLO HUMANO',
  'TECNOLOGO EN PROMOCION PUBLICITARIA',
  'TECNOLOGO EN PROMOCION SOCIAL',
  'TECNOLOGO EN PROMOCION Y APLICACION DEL DERECHO INTERNACIONAL HUMANITARIO Y DE LOS DERECHOS HUMANOS EN EL CONTEXTO MILITAR',
  'TECNOLOGO EN PROTECCION Y RECUPERACION DE ECOSISTEMAS FORESTALES',
  'TECNOLOGO EN PUBLICIDAD',
  'TECNOLOGO EN PUBLICIDAD CON ENFASIS EN MEDIOS',
  'TECNOLOGO EN PUBLICIDAD DIGITAL',
  'TECNOLOGO EN PUBLICIDAD Y COMERCIALIZACION',
  'TECNOLOGO EN PUBLICIDAD Y COMUNICACION VISUAL',
  'TECNOLOGO EN PUBLICIDAD Y MERCADEO',
  'TECNOLOGO EN QUIMICA',
  'TECNOLOGO EN QUIMICA APLICADA A LA INDUSTRIA',
  'TECNOLOGO EN QUIMICA DE PRODUCTOS VEGETALES',
  'TECNOLOGO EN QUIMICA INDUSTRIAL',
  'TECNOLOGO EN QUIMICA INDUSTRIAL Y DE LABORATORIO',
  'TECNOLOGO EN QUIMICA TEXTIL',
  'TECNOLOGO EN RADIOLOGIA E IMAGENES DIAGNOSTICAS',
  'TECNOLOGO EN RADIOTERAPIA',
  'TECNOLOGO EN REALIZACION AUDIOVISUAL',
  'TECNOLOGO EN REALIZACION DE AUDIOVISUALES Y MULTIMEDIA',
  'TECNOLOGO EN REALIZACION DE PROYECTOS SONOROS PARA CONTENIDOS DIGITALES',
  'TECNOLOGO EN REALIZACION PARA TELEVISION',
  'TECNOLOGO EN REALIZACION VISUAL Y AUDIOVISUAL',
  'TECNOLOGO EN RECREACION',
  'TECNOLOGO EN RECREACION DIRIGIDA',
  'TECNOLOGO EN RECURSOS AMBIENTALES',
  'TECNOLOGO EN RECURSOS FORESTAL',
  'TECNOLOGO EN RECURSOS FORESTALES',
  'TECNOLOGO EN RECURSOS HIDRICOS',
  'TECNOLOGO EN RECURSOS NATURALES',
  'TECNOLOGO EN RECURSOS NATURALES RENOVABLES',
  'TECNOLOGO EN RECURSOS NATURALES Y DEL AMBIENTE',
  'TECNOLOGO EN RECURSOS NATURALES Y MEDIO AMBIENTE',
  'TECNOLOGO EN REDES COMPUTACIONALES Y COMUNICACIONES',
  'TECNOLOGO EN REDES DE COMPUTADORAS',
  'TECNOLOGO EN REDES DE COMPUTADORES',
  'TECNOLOGO EN REDES DE COMPUTADORES Y SEGURIDAD INFORMATICA',
  'TECNOLOGO EN REDES DE COMPUTO Y SEGURIDAD INFORMATICA',
  'TECNOLOGO EN REDES DE COMUNICACIONES',
  'TECNOLOGO EN REDES DE COMUNICACIONES Y SERVICIOS CONVERGENTES',
  'TECNOLOGO EN REDES DE DATOS',
  'TECNOLOGO EN REDES DE DATOS EN TELECOMUNICACIONES',
  'TECNOLOGO EN REDES Y COMUNICACION DE DATOS',
  'TECNOLOGO EN REDES Y COMUNICACIONES',
  'TECNOLOGO EN REDES Y COMUNICACIONES DE DATOS',
  'TECNOLOGO EN REDES Y SEGURIDAD INFORMATICA',
  'TECNOLOGO EN REDES Y SERVICIOS TELEMATICOS',
  'TECNOLOGO EN REFRIGERACION INDUSTRIAL Y AIRE ACONDICIONADO',
  'TECNOLOGO EN REGENCIA DE FARMACIA',
  'TECNOLOGO EN RELACIONES INDUSTRIALES',
  'TECNOLOGO EN RELACIONES INDUSTRIALES Y CONTABLES',
  'TECNOLOGO EN RENDIMIENTO DEPORTIVO',
  'TECNOLOGO EN REPARACION Y MANTENIMIENTO DE INSTRUMENTOS MUSICALES',
  'TECNOLOGO EN REPRESENTACION DE LA ARQUITECTURA',
  'TECNOLOGO EN REPRODUCCION BOVINA',
  'TECNOLOGO EN RIEGO DRENAJE Y MANEJO DE SUELOS AGRICOLAS',
  'TECNOLOGO EN ROBOTICA Y AUTOMATIZACION',
  'TECNOLOGO EN ROBOTICA Y AUTOMATIZACION INDUSTRIAL',
  'TECNOLOGO EN SALUD AMBIENTAL',
  'TECNOLOGO EN SALUD AMBIENTAL Y SEGURIDAD SANITARIA',
  'TECNOLOGO EN SALUD COMUNITARIA',
  'TECNOLOGO EN SALUD COMUNITARIA Y ATENCION HOSPITALARIA BASICA',
  'TECNOLOGO EN SALUD OCUPACIONAL',
  'TECNOLOGO EN SECRETARIADO BILINGUE',
  'TECNOLOGO EN SECRETARIADO BILINGUE Y COMPUTACION',
  'TECNOLOGO EN SECRETARIADO EJECUTIVO',
  'TECNOLOGO EN SECRETARIADO EJECUTIVO BILINGUE',
  'TECNOLOGO EN SECRETARIADO EJECUTIVO CON ENFASIS EN SISTEMAS',
  'TECNOLOGO EN SECRETARIADO EJECUTIVO Y DE SISTEMAS',
  'TECNOLOGO EN SEGURIDAD AEROPORTUARIA',
  'TECNOLOGO EN SEGURIDAD ALIMENTARIA Y NUTRICIONAL',
  'TECNOLOGO EN SEGURIDAD E HIGIENE INDUSTRIAL',
  'TECNOLOGO EN SEGURIDAD E HIGIENE OCUPACIONAL',
  'TECNOLOGO EN SEGURIDAD INDUSTRIAL SALUD OCUPACIONAL Y MEDIO AMBIENTE EN LA CONSTRUCCION',
  'TECNOLOGO EN SEGURIDAD INDUSTRIAL Y AMBIENTAL',
  'TECNOLOGO EN SEGURIDAD INFORMATICA',
  'TECNOLOGO EN SEGURIDAD INTEGRAL',
  'TECNOLOGO EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO EN SEGURIDAD Y SALUDO EN EL TRABAJO',
  'TECNOLOGO EN SEGUROS',
  'TECNOLOGO EN SERVICIO A BORDO',
  'TECNOLOGO EN SERVICIOS DE TELECOMUNICACIONES',
  'TECNOLOGO EN SERVICIOS INFORMATICOS',
  'TECNOLOGO EN SILVICULTURA Y APROVECHAMIENTO DE PLANTACIONES FORESTALES',
  'TECNOLOGO EN SISTEMAS',
  'TECNOLOGO EN SISTEMAS AGROFORESTALES',
  'TECNOLOGO EN SISTEMAS COMPUTACIONALES',
  'TECNOLOGO EN SISTEMAS CON ENFASIS EN TELECOMUNICACIONES',
  'TECNOLOGO EN SISTEMAS CONTABLES Y FINANCIEROS',
  'TECNOLOGO EN SISTEMAS DE COMPUTACION',
  'TECNOLOGO EN SISTEMAS DE COMUNICACIONES INALAMBRICAS',
  'TECNOLOGO EN SISTEMAS DE GESTION AMBIENTAL',
  'TECNOLOGO EN SISTEMAS DE GESTION DE CALIDAD',
  'TECNOLOGO EN SISTEMAS DE GESTION DE LA CALIDAD ALIMENTARIA',
  'TECNOLOGO EN SISTEMAS DE INFORMACION',
  'TECNOLOGO EN SISTEMAS DE INFORMACION COMERCIALES PARA INTERNET',
  'TECNOLOGO EN SISTEMAS DE INFORMACION EN SALUD',
  'TECNOLOGO EN SISTEMAS DE INFORMACION Y DE SOFTWARE',
  'TECNOLOGO EN SISTEMAS DE PRODUCCION LIMPIA',
  'TECNOLOGO EN SISTEMAS DE SANEAMIENTO AMBIENTAL',
  'TECNOLOGO EN SISTEMAS DE TELECOMUNICACIONES',
  'TECNOLOGO EN SISTEMAS E INFORMATICA',
  'TECNOLOGO EN SISTEMAS E INFORMATICA EMPRESARIAL',
  'TECNOLOGO EN SISTEMAS ELECTRICOS DE MEDIA Y BAJA TENSION',
  'TECNOLOGO EN SISTEMAS ELECTROMECANICOS',
  'TECNOLOGO EN SISTEMAS ELECTRONICOS Y DE AUTOMATIZACION',
  'TECNOLOGO EN SISTEMAS EMPRESARIALES DE INFORMACION',
  'TECNOLOGO EN SISTEMAS INFORMATICOS',
  'TECNOLOGO EN SISTEMAS INFORMATICOS CON ENFASIS EN SALUD',
  'TECNOLOGO EN SISTEMAS INTEGRADOS DE GESTION',
  'TECNOLOGO EN SISTEMAS INTEGRADOS DE GESTION HSEQ',
  'TECNOLOGO EN SISTEMAS MECATRONICOS',
  'TECNOLOGO EN SISTEMAS Y COMPUTACION',
  'TECNOLOGO EN SISTEMATIZACION DE DATOS',
  'TECNOLOGO EN SISTEMATIZACON DE DATOS',
  'TECNOLOGO EN SONIDO DIRECTO PARA PRODUCCION DE MEDIOS AUDIOVISUALES',
  'TECNOLOGO EN SOPORTE DE TELECOMUNICACIONES',
  'TECNOLOGO EN SUMINISTRO DE BIENES Y SERVICIOS MINERO ENERGETICOS',
  'TECNOLOGO EN SUPERVISION DE LA FABRICACION DE PRODUCTOS METALICOS SOLDADOS',
  'TECNOLOGO EN SUPERVISION DE LABORES MINERAS',
  'TECNOLOGO EN SUPERVISION DE OBRAS CIVILES',
  'TECNOLOGO EN SUPERVISION DE PROCESOS DE CONFECCION',
  'TECNOLOGO EN SUPERVISION DE PROCESOS GRAFICOS',
  'TECNOLOGO EN SUPERVISION DE PROCESOS MINEROS',
  'TECNOLOGO EN SUPERVISION DE PROCESOS PRODUCTIVOS ACUICOLAS',
  'TECNOLOGO EN SUPERVISION DE REDES DE DISTRIBUCION DE ENERGIA ELECTRICA',
  'TECNOLOGO EN SUPERVISION DE SEGURIDAD EN MINERIA',
  'TECNOLOGO EN SUPERVISION EN PROCESOS DE CONFECCION',
  'TECNOLOGO EN SUPERVISION EN SEGURIDAD MINERA',
  'TECNOLOGO EN SUPERVISION EN SISTEMA DE AGUA Y SANEAMIENTO',
  'TECNOLOGO EN SUPERVISION EN SISTEMAS DE AGUA Y SANEAMIENTO',
  'TECNOLOGO EN SUPERVISION Y MANTENIMIENTO DE SISTEMAS DE COMUNICACION E INFORMATICA',
  'TECNOLOGO EN TALENTO HUMANO',
  'TECNOLOGO EN TELECOMUNICACIONES',
  'TECNOLOGO EN TELECOMUNICACIONES Y REDES DE DATOS',
  'TECNOLOGO EN TELEINFORMATICA',
  'TECNOLOGO EN TELEINFORMATICA MOVIL',
  'TECNOLOGO EN TELEMATICA',
  'TECNOLOGO EN TELEVISION',
  'TECNOLOGO EN TERAPIA DEL LENGUAJE',
  'TECNOLOGO EN TERAPIA OCUPACIONAL',
  'TECNOLOGO EN TERAPIA RECREATIVA',
  'TECNOLOGO EN TERAPIA RESPIRATORIA',
  'TECNOLOGO EN TEXTILES Y MODA',
  'TECNOLOGO EN TOPOGRAFIA',
  'TECNOLOGO EN TRABAJO SOCIAL Y COMUNITARIO',
  'TECNOLOGO EN TRANSFORMACION DE CACAO Y ELABORACION DE PRODUCTOS DE CHOCOLATERIA',
  'TECNOLOGO EN TRANSFORMACION DEL CACAO Y ELABORACION DE PRODUCTOS DE CHOCOLATERIA INDUSTRIAL',
  'TECNOLOGO EN TRANSPORTE DE CEMENTO Y MATERIALES PARA LA CONSTRUCCION',
  'TECNOLOGO EN TRIBUTARIA',
  'TECNOLOGO EN TURISMO',
  'TECNOLOGO EN TURISMO E IDIOMAS',
  'TECNOLOGO EN TURISMO RECREATIVO',
  'TECNOLOGO EN TURISMO SOSTENIBLE',
  'TECNOLOGO EN TURISMO Y DESARROLLO LOCAL',
  'TECNOLOGO EN TURISMO Y HOTELERIA',
  'TECNOLOGO EN VIDEO DIGITAL',
  'TECNOLOGO EN ZOOTECNIA',
  'TECNOLOGO ES SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO ESPECIALIZADO EN ADMINISTRACION DE EMPRESAS AGROPECUARIAS',
  'TECNOLOGO ESPECIALIZADO EN ADMINISTRACION FINANCIERA',
  'TECNOLOGO ESPECIALIZADO EN ARQUITECTURA DE REDES Y COMUNICACIONES CLIENTE/SERVIDOR',
  'TECNOLOGO ESPECIALIZADO EN ASEGURAMIENTO DE LA CALIDAD EN LA INDUSTRIA ALIMENTARIA',
  'TECNOLOGO ESPECIALIZADO EN AUDITORIA',
  'TECNOLOGO ESPECIALIZADO EN AUDITORIA DE SISTEMAS DE INFORMACION',
  'TECNOLOGO ESPECIALIZADO EN AUTOMATIZACION INDUSTRIAL',
  'TECNOLOGO ESPECIALIZADO EN COMUNICACION Y MERCADEO ORGANIZACIONAL',
  'TECNOLOGO ESPECIALIZADO EN CONTROL DE PROCESOS QUIMICOS',
  'TECNOLOGO ESPECIALIZADO EN CONTROL ELECTRONICO DE SISTEMAS INDUSTRIALES',
  'TECNOLOGO ESPECIALIZADO EN COSTOS PARA LA TOMA DE DECISIONES',
  'TECNOLOGO ESPECIALIZADO EN DIBUJO DE RESTAURACION',
  'TECNOLOGO ESPECIALIZADO EN DIRECCION DE OFICINAS BILINGUES Y SISTEMATIZADAS',
  'TECNOLOGO ESPECIALIZADO EN DISTRIBUCION FISICA NACIONAL E INTERNACIONAL',
  'TECNOLOGO ESPECIALIZADO EN EL MEJORAMIENTO DEL ENTORNO LABORAL',
  'TECNOLOGO ESPECIALIZADO EN ELECTRONICA DE CONTROLES',
  'TECNOLOGO ESPECIALIZADO EN EQUIPO ELECTROMEDICO',
  'TECNOLOGO ESPECIALIZADO EN FERIAS Y EXPOSICIONES',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA COMERCIAL',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE ALIMENTOS Y BEBIDAS',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE COMUNICACION PUBLICITARIA',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE MARKETING',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE MERCADEO',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE PROYECTOS',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE RECURSOS HUMANOS',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA DE TRANSPORTES INTERNACIONALES',
  'TECNOLOGO ESPECIALIZADO EN GERENCIA FINANCIERA',
  'TECNOLOGO ESPECIALIZADO EN IMPORTACIONES Y EXPORTACIONES',
  'TECNOLOGO ESPECIALIZADO EN INDUSTRIALIZACION DE ALIMENTOS AGRICOLAS',
  'TECNOLOGO ESPECIALIZADO EN INDUSTRIALIZACION DE ALIMENTOS PECUARIOS.',
  'TECNOLOGO ESPECIALIZADO EN INFORMATICA APLICADA A LAS CIENCIAS JURIDICAS',
  'TECNOLOGO ESPECIALIZADO EN INFORMATICA FORENSE',
  'TECNOLOGO ESPECIALIZADO EN INGENIERIA DE CONTROLES',
  'TECNOLOGO ESPECIALIZADO EN INSTITUCIONES BANCARIAS',
  'TECNOLOGO ESPECIALIZADO EN INVESTIGACION DE MERCADOS',
  'TECNOLOGO ESPECIALIZADO EN LOGISTICA DE PRODUCCION Y DISTRIBUCION',
  'TECNOLOGO ESPECIALIZADO EN MARKETING TURISTICO Y HOTELERO',
  'TECNOLOGO ESPECIALIZADO EN MECANICA AUTOMOTRIZ',
  'TECNOLOGO ESPECIALIZADO EN MECATRONICA',
  'TECNOLOGO ESPECIALIZADO EN MERCADEO',
  'TECNOLOGO ESPECIALIZADO EN MERCADEO INTERNACIONAL',
  'TECNOLOGO ESPECIALIZADO EN MERCADEO ORGANIZACIONAL',
  'TECNOLOGO ESPECIALIZADO EN MERCADEO Y FINANZAS INTERNACIONALES',
  'TECNOLOGO ESPECIALIZADO EN MERCADEO Y NEGOCIOS INTERNACIONALES',
  'TECNOLOGO ESPECIALIZADO EN MULTIMEDIA',
  'TECNOLOGO ESPECIALIZADO EN NEGOCIACION INTERNACIONAL',
  'TECNOLOGO ESPECIALIZADO EN NEGOCIOS INTERNACIONALES',
  'TECNOLOGO ESPECIALIZADO EN PERIODISMO DEPORTIVO',
  'TECNOLOGO ESPECIALIZADO EN PERIODISMO POLITICO Y ECONOMICO',
  'TECNOLOGO ESPECIALIZADO EN PROCESOS DE CALIDAD',
  'TECNOLOGO ESPECIALIZADO EN PRODUCCION DE TELEVISION',
  'TECNOLOGO ESPECIALIZADO EN PRODUCTIVIDAD Y CALIDAD TOTAL',
  'TECNOLOGO ESPECIALIZADO EN QUIMICA AMBIENTAL',
  'TECNOLOGO ESPECIALIZADO EN RED DE TRANSPORTE DE TELECOMUNICACIONES',
  'TECNOLOGO ESPECIALIZADO EN REDES DE COMPUTADORES',
  'TECNOLOGO ESPECIALIZADO EN REPRODUCCION Y PRODUCCION PECUARIAS: BOVINOS, PORCINOS Y AVES.',
  'TECNOLOGO ESPECIALIZADO EN SEGURIDAD DE INSTALACIONES FISICAS',
  'TECNOLOGO ESPECIALIZADO EN SISTEMAS AUTOMATICOS DE CONTROL',
  'TECNOLOGO ESPECIALIZADO EN SISTEMAS DE COMPUTACION',
  'TECNOLOGO ESPECIALIZADO EN SISTEMAS DE INFORMACION TOPOGRAFICA',
  'TECNOLOGO ESPECIALIZADO EN SISTEMAS DE INFORMACION Y COMUNICACION EN LAS ORGANIZACIONES',
  'TECNOLOGO ESPECIALIZADO EN TELECOMUNICACIONES',
  'TECNOLOGO FINANCIERO Y CONTABLE',
  'TECNOLOGO FORESTAL',
  'TECNOLOGO FORESTAL CON ENFASIS EN PROTECCION Y RECUPERACION DE ECOSISTEMAS Y CON ENFASIS EN PRODUCCION Y APROVECHAMIENTO FORESTAL',
  'TECNOLOGO FORESTAL CON ENFASIS EN PROTECCION Y RECUPERACION ECOSISTEMAS,FORESTALES Y EN PRODUCCION Y APROVECHAMIENTO FORESTAL',
  'TECNOLOGO FORESTAL ENFASIS EN PROTECCION Y RECUPERACION ECOSISTEMAS,FORESTALES Y EN PRODUCCION Y APROVECHAMIENTO FORESTAL',
  'TECNOLOGO GESTION ADMINISTRATIVA DEL SECTOR SALUD',
  'TECNOLOGO GESTION AGROAMBIENTAL',
  'TECNOLOGO INDUSTRIAL',
  'TECNOLOGO INFORMATICO',
  'TECNOLOGO JUDICIAL',
  'TECNOLOGO JURIDICO',
  'TECNOLOGO LOGISTICA INDUSTRIAL',
  'TECNOLOGO MANEJO Y APROVECHAMIENTO FORESTAL',
  'TECNOLOGO MECANICO',
  'TECNOLOGO MECANICO INDUSTRIAL',
  'TECNOLOGO N CONSTRUCCION DE INFRAESTRUCTURA VIAL',
  'TECNOLOGO NAVAL EN ADMINISTRACION DE SERVICIOS',
  'TECNOLOGO NAVAL EN ADMINISTRACION MARITIMA',
  'TECNOLOGO NAVAL EN ELECTROMECANICA',
  'TECNOLOGO NAVAL EN ELECTRONICA',
  'TECNOLOGO NAVAL EN HIDROGRAFIA',
  'TECNOLOGO NAVAL EN MANTENIMIENTO AERONAVAL',
  'TECNOLOGO NAVAL EN OCEANOGRAFIA FISICA',
  'TECNOLOGO NAVAL EN OPERACION DE SUBMARINOS',
  'TECNOLOGO NAVIERO',
  'TECNOLOGO O TECNOLOGA EN ADMINISTRACION PUBLICA ESPECIAL PARA LOS TERRITORIOS ANCESTRALES INDIGENAS',
  'TECNOLOGO O TECNOLOGA EN GESTION AERONAUTICA',
  'TECNOLOGO O TECNOLOGA EN MANTENIMIENTO AERONAUTICO',
  'TECNOLOGO PECUARIO',
  'TECNOLOGO PESQUERO',
  'TECNOLOGO PRODUCCION DE MULTIMEDIA',
  'TECNOLOGO PROFESIONAL EN GESTION TURISTICA',
  'TECNOLOGO PROFESIONAL EN PRODUCCION BOVINA',
  'TECNOLOGO QUIMICO',
  'TECNOLOGO QUIMICO TEXTIL',
  'TECNOLOGO REGENTE DE FARMACIA',
  'TECNOLOGO SUPERVISION EN SISTEMAS DE AGUA Y SANEAMIENTO',
  'TECNOLOGO TEXTIL',
  'TECNOLOGO TRILINGUE EN COMERCIO EXTERIOR (ESPAÑOL-ALEMAN-INGLES)',
  'TECNOLOGO TRILINGUE EN GESTION DE EMPRESAS INDUSTRIALES',
  'TECNOLOGO TRILINGUE EN LOGISTICA INTERNACIONAL',
  'TECNOLOGO TRILINGUE EN ORGANIZACION EMPRESARIAL (ESPAÑOL-ALEMAN-INGLES)',
  'TECNOLOGO TURISTICO EN DESARROLLO LOCAL',
  'TECNOLOGO VETERINARIO',
  'TECNOLOGO(A) AGROAMBIENTAL',
  'TECNOLOGO(A) DE PROCESAMIENTO DE ALIMENTOS',
  'TECNOLOGO(A) EN ANALISIS DE COSTOS Y PRESUPUESTOS',
  'TECNOLOGO(A) EN ANALISIS Y LABORATORIO QUIMICO',
  'TECNOLOGO(A) EN ARCHIVISTICA',
  'TECNOLOGO(A) EN ATENCION PREHOSPITALARIA',
  'TECNOLOGO(A) EN AUTOMATIZACION ELECTRONICA',
  'TECNOLOGO(A) EN COMUNICACION SOCIAL MULTIMEDIAL',
  'TECNOLOGO(A) EN DESARROLLO AMBIENTAL Y SOSTENIBLE',
  'TECNOLOGO(A) EN DESARROLLO DE APLICACIONES PARA DISPOSITIVOS MOVILES',
  'TECNOLOGO(A) EN DESARROLLO DE SOFTWARE',
  'TECNOLOGO(A) EN DESARROLLO DEL SOFTWARE',
  'TECNOLOGO(A) EN DISEÑO DE MODAS',
  'TECNOLOGO(A) EN DISEÑO GRAFICO',
  'TECNOLOGO(A) EN DISEÑO Y PROGRAMACION DE SOLUCIONES DE SOFTWARE COMO SERVICIO',
  'TECNOLOGO(A) EN EDUCACION FISICA , RECREACION Y DEPORTE',
  'TecnOlogo(a) en EnfermerIa Veterinaria',
  'TECNOLOGO(A) EN FORMULACION Y GESTION DE PROYECTOS SOCIALES',
  'TECNOLOGO(A) EN GESTION AMBIENTAL',
  'TECNOLOGO(A) EN GESTION COMERCIAL',
  'TECNOLOGO(A) EN GESTION CONTABLE',
  'TECNOLOGO(A) EN GESTION DE LA COMUNICACION CORPORATIVA',
  'TECNOLOGO(A) EN GESTION DE MERCADEO',
  'TECNOLOGO(A) EN GESTION DE MERCADEO Y VENTAS',
  'TECNOLOGO(A) EN GESTION DE SERVICIOS PUBLICOS DOMICILIARIOS',
  'TECNOLOGO(A) EN GESTION DE VENTAS Y NEGOCIOS',
  'TECNOLOGO(A) EN GESTION DEL TALENTO HUMANO',
  'TECNOLOGO(A) EN GESTION FINANCIERA',
  'TECNOLOGO(A) EN GESTION HOTELERA Y TURISTICA',
  'TECNOLOGO(A) EN GESTION INDUSTRIAL',
  'TECNOLOGO(A) EN LOGISTICA EMPRESARIAL',
  'TECNOLOGO(A) EN MERCADEO Y VENTAS',
  'TECNOLOGO(A) EN PRODUCCION DE ANIMACION DIGITAL',
  'TECNOLOGO(A) EN PRODUCCION DE MODAS',
  'TECNOLOGO(A) EN QUIMICA',
  'TECNOLOGO(A) EN SALUD OCUPACIONAL',
  'TECNOLOGO(A) EN SANIDAD NAVAL',
  'TECNOLOGO(A) EN SEGURIDAD Y SALUD EN EL TRABAJO',
  'TECNOLOGO(A) EN SUPERVISION DE SISTEMAS DE GENERACION Y DISTRIBUCION DE ENERGIA ELECTRICA',
  'TECNOLOGO(A) EN TELEMATICA',
  'TECNOLOGO(A) EN TERAPIA OCUPACIONAL',
  'TECNOLOGO(A) EN TERAPIA RESPIRATORIA',
  'TECNOLOGO(A) EN TRANSITO, TRANSPORTE TERRESTRE Y SEGURIDAD VIAL',
  'TECNOLOGO(A) FORESTAL',
  'TECNOLOGO(A) QUIMICO(A)',
  'TECNOLOGOEN GESTION DE SISTEMAS',
  'TECNOLOGOEN SANEAMIENTO AMBIENTAL',
  'TECNOLOGOS EN ADMINISTRACION DE COMPLEJOS URBANOS',
  'TECNOLOLOGO EN AUTOMATIZACION INDUSTRIAL',
  'TECNOLOLOGO EN EDICION Y ANIMACION DE MEDIOS AUDIOVISUALES',
  'TECNOLOO EN ACUICULTURA',
  'TECNOLOO EN GESTION DE BIONEGOCIOS',
  'TECOLOGO EN PRODUCCION DE JOYERIA',
  'TENCOLOGO EN GESTION DE PROYECTOS DE DESARROLLO ECONOMICO Y SOCIAL',
  'TENOLOGO EN GESTION DE PROYECTOS DE DESARROLLO ECONOMICO Y SOCIAL',
  'TEOLOGO EN GESTION DE PUBLICIDAD DIGITAL',
  'TEOLOGO(A)',
  'TERAPEUTA CARDIORRESPIRATORIO',
  'TERAPEUTA FISICO',
  'TERAPEUTA OCUPACIONAL',
  'TERAPEUTA RESPIRATORIA',
  'TERAPEUTA RESPIRATORIO',
  'TERAPISTA OCUPACIONAL',
  'TERAPISTA RESPIRATORIA',
  'TRABAJADOR SOCIAL',
  'TRABAJADOR SOCIAL/TRABAJADORA SOCIALL',
  'TRABAJADOR(A) SOCIAL',
  'URBANISTA',
  'ZOOTECNISTA',
]

export const bancos = [
  {
    value: 'Banco Agrario de Colombia',
    label: 'Banco Agrario de Colombia',
  },
  {
    value: 'Banco AV Villas',
    label: 'Banco AV Villas',
  },
  {
    value: 'Banco BBVA',
    label: 'Banco BBVA',
  },
  {
    value: 'Banco BCSC',
    label: 'Banco BCSC',
  },
  {
    value: 'Banco Citibank ',
    label: 'Banco Citibank ',
  },
  {
    value: 'Banco Coopcentral',
    label: 'Banco Coopcentral',
  },
  {
    value: 'Banco Davivienda ',
    label: 'Banco Davivienda ',
  },
  {
    value: 'Banco de Bogotá',
    label: 'Banco de Bogotá ',
  },
  {
    value: 'Banco de Occidente ',
    label: 'Banco de Occidente ',
  },
  {
    value: 'Banco Falabella ',
    label: 'Banco Falabella',
  },
  {
    value: 'Banco GNB Sudameris ',
    label: 'Banco GNB Sudameris ',
  },
  {
    value: 'Banco Itaú Corpbanca Colombia S.A. ',
    label: 'Banco Itaú Corpbanca Colombia S.A. ',
  },
  {
    value: 'Banco Mundo Mujer ',
    label: 'Banco Mundo Mujer ',
  },
  {
    value: 'Banco Pichincha ',
    label: 'Banco Pichincha ',
  },
  {
    value: 'Banco Popular',
    label: 'Banco Popular ',
  },
  {
    value: 'Banco Credifinanciera ',
    label: 'Banco Credifinanciera ',
  },
  {
    value: 'Banco Serfinanza ',
    label: 'Banco Serfinanza ',
  },
  {
    value: 'Bancolombia ',
    label: 'Bancolombia ',
  },
  {
    value: 'Colpatria ',
    label: 'Colpatria ',
  },
];
