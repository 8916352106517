export const sortDepartamentosAlphabetically = (departamentos) => {
  return departamentos.sort((a, b) => a.nombre.localeCompare(b.nombre))
};

export const sortCiudadesAlphabetically = (ciudades, removeAllCiudadesOption) => {
  const orderedCiudades = ciudades.sort((a, b) =>
    a.nombre.localeCompare(b.nombre)
  );
  if (removeAllCiudadesOption) {
    return orderedCiudades.filter(
      (ciudad) => ciudad.nombre !== "Todas las ciudades"
    );
  } else {
    const todasLasCiudadesOption = orderedCiudades.find(
      (ciudad) => ciudad.nombre === "Todas las ciudades"
    );
    if(todasLasCiudadesOption){
      const indexTodasLaCiudadesOption = orderedCiudades.indexOf(todasLasCiudadesOption)
      orderedCiudades.splice(indexTodasLaCiudadesOption, 1);
      orderedCiudades.unshift(todasLasCiudadesOption);
    }
    return orderedCiudades;
  }
};
