import React, { useState } from 'react';
import { TextField, Button, Card, CardContent, CardHeader, InputLabel, FormHelperText } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Redirect } from 'react-router'
import { postPatchExperiencia } from '../../../../helpers/editProfile/editProfileHelper';
import { fileHelperText } from '../../../../constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),

  },
  textFieldRoot: {
    width: "65%",
    margin: 15,
  },
  textHelperRoot: {
    width: "65%",
    marginBottom: 15
  },
  title: {
    textAlign: 'center',
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  button: {
    margin: 15
  },
  color: {
    color: 'red'
  },
}));

const IngresarExperiencia = (props, { variant = 'default' }) => {

  const fecha = new Date();
  const fechaActual = fecha.toISOString().split('T')[0];
  const fechaCulminacionHT = "En caso de que la vinculación del candidato siga vigente, " +
    "ingrese la fecha indicada en el certificado laboral."
  const classes = useStyles({ variant });
  let urlExperiencia = {}
  if (props.location.state === undefined) window.location.href = '/signin'
  if (props.location.state !== undefined && props.location.state.actualizar) {
    if (props.location.state.experiencia.empresa !== undefined) urlExperiencia.empresa = props.location.state.experiencia.empresa;
    else urlExperiencia.empresa = '';
    if (props.location.state.experiencia.area !== undefined) urlExperiencia.area = props.location.state.experiencia.area;
    else urlExperiencia.area = '';
    if (props.location.state.experiencia.cargo !== undefined) urlExperiencia.cargo = props.location.state.experiencia.cargo;
    else urlExperiencia.cargo = '';
    if (props.location.state.experiencia.fechaInicio !== undefined) urlExperiencia.fechaInicio = props.location.state.experiencia.fechaInicio.replaceAll("/", "-").split("-").reverse().join("-");
    else urlExperiencia.fechaInicio = '';
    if (props.location.state.experiencia.fechaFinal !== undefined) urlExperiencia.fechaFinal = props.location.state.experiencia.fechaFinal.replaceAll("/", "-").split("-").reverse().join("-");
    else urlExperiencia.fechaFinal = '';
    if (props.location.state.experiencia.descripcion !== undefined) urlExperiencia.descripcion = props.location.state.experiencia.descripcion;
    else urlExperiencia.descripcion = '';
  } else {
    urlExperiencia = {
      empresa: '',
      area: '',
      cargo: '',
      fechaInicio: '',
      fechaFinal: '',
      descripcion: ''
    }
  }
  const [experiencia, setExperiencia] = useState(urlExperiencia);
  const [fileExperiencia, setFileExperiencia] = useState('');
  const [redirect, setRedirect] = useState(false)
  const [error, setError] = useState({
    empresa: false, empresaHelper: '',
    area: false, areaHelper: '',
    cargo: false, cargoHelper: '',
    fechaInicio: false, fechaInicioHelper: '',
    fechaFinal: false, fechaFinalHelper: '',
    descripcion: false, descripcionHelper: '',
    documento: false, documentoHelper: fileHelperText
  })
  const handleChange = (event) => {
    setExperiencia({
      ...experiencia,
      [event.target.name]: event.target.value
    })
  };

  const onSubmit = async (props, e) => {
    e.preventDefault()
    const formData = new FormData();
    let experienciaQuery = Object.assign({}, experiencia);
    if (props.location.state.actualizar) experienciaQuery.id = props.location.state.experiencia.id
    formData.append('experiencia', new Blob([JSON.stringify(experienciaQuery)], { type: "application/json" }));
    if (fileExperiencia !== '') formData.append('file', fileExperiencia);

    let method;
    if (props.location.state.actualizar) method = 'patch';
    else method = 'post';
    await postPatchExperiencia(method, formData)
      .then(response => {
        setRedirect(true)
      })
      .catch(response => {
        console.log(response)
      });
  }

  return (
    <Card>
      {props.location.state.actualizar ?
        <CardHeader title="Editar Experiencia" className={classes.title} titleTypographyProps={{ variant: 'h1' }} />
        :
        <CardHeader title="Registrar Experiencia" className={classes.title} titleTypographyProps={{ variant: 'h1' }} />
      }
      <CardContent>
        {redirect ? <Redirect to={{ pathname: '/postulacion/update', state: props.location.state, search: props.location.search }} /> : null}
        <form className={classes.form} onSubmit={(e) => {
          e.preventDefault()
          if (!props.location.state.actualizar) {
            if (experiencia.empresa !== '' && experiencia.area !== '' && experiencia.cargo !== ''
              && experiencia.fechaInicio !== '' && experiencia.fechaFinal !== '' && experiencia.descripcion !== ''
            ) {
              if (!error.empresa && !error.area && !error.cargo && !error.fechaInicio
                && !error.fechaFinal && !error.descripcion)
                onSubmit(props, e);
            }
            else {
              if (experiencia.empresa === '') { setError({ ...error, empresa: true, empresaHelper: ' Este campo es requerido' }); }
              if (experiencia.area === '') { setError({ ...error, area: true, areaHelper: ' Este campo es requerido' }); }
              if (experiencia.cargo === '') { setError({ ...error, cargo: true, cargoHelper: ' Este campo es requerido' }); }
              if (experiencia.fechaInicio === '') { setError({ ...error, fechaInicio: true, fechaInicioHelper: ' Este campo es requerido' }); }
              if (experiencia.fechaFinal === '') { setError({ ...error, fechaFinal: true, fechaFinalHelper: ' Este campo es requerido' }); }
              if (experiencia.descripcion === '') { setError({ ...error, descripcion: true, descripcionHelper: ' Este campo es requerido' }); }
            }
          } else {
            onSubmit(props, e);
          }
        }}>
          <div>
            <InputLabel>Nombre de la Empresa<span className={classes.color}>*</span></InputLabel>
            <TextField
              helperText={error.empresaHelper}
              error={error.empresa}
              defaultValue={experiencia.empresa}
              onChange={handleChange} name='empresa'
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, empresa: true, empresaHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length > 100) {
                    setError({ ...error, empresa: true, empresaHelper: ' Máximo 100 caracteres' });
                  }
                  else setError({ ...error, empresa: false, empresaHelper: '' });
              }}
              variant="outlined"
              className={classes.textFieldRoot}
              id="nombreEmpresa"
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div>
            <InputLabel>Área Encargada<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, area: true, areaHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length >= 30) {
                    setError({ ...error, area: true, areaHelper: ' Máximo 30 caracteres' });
                  }
                  else setError({ ...error, area: false, areaHelper: '' });
              }}
              error={error.area}
              helperText={error.areaHelper}
              defaultValue={experiencia.area}
              onChange={handleChange} name='area'
              variant="outlined"
              className={classes.textFieldRoot}
              id="area"
              inputProps={{ maxLength: 30 }}
            />
          </div>
          <div>
            <InputLabel>Fecha de Inicio<span className={classes.color}>*</span></InputLabel>
            <TextField
              type="date"
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaInicio: true, fechaInicioHelper: ' Este campo es requerido' });
                } else
                  if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                    setError({ ...error, fechaInicio: true, fechaInicioHelper: ' La fecha debe ser anterior a ' + fechaActual });
                  }
                  else setError({ ...error, fechaInicio: false, fechaInicioHelper: '' });
              }}
              error={error.fechaInicio}
              helperText={error.fechaInicioHelper}
              defaultValue={experiencia.fechaInicio}
              onChange={handleChange} name='fechaInicio'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaInicio"
            />
          </div>
          <div>
            <InputLabel>Fecha de Culminación<span className={classes.color}>*</span></InputLabel>
            <TextField
              type="date"
              onBlur={event => {
                if (event.target.value === '') {
                  setError({ ...error, fechaFinal: true, fechaFinalHelper: ' Este campo es requerido' });
                } else
                  if (Date.parse(event.target.value) > Date.parse(fechaActual)) {
                    setError({ ...error, fechaFinal: true, fechaFinalHelper: ' La fecha debe ser anterior a ' + fechaActual });
                  }
                  else setError({ ...error, fechaFinal: false, fechaFinalHelper: '' });
              }}
              error={error.fechaFinal}
              helperText={error.fechaFinalHelper}
              defaultValue={experiencia.fechaFinal}
              onChange={handleChange} name='fechaFinal'
              variant="outlined"
              className={classes.textFieldRoot}
              InputProps={{ inputProps: { max: fechaActual } }}
              id="fechaFinalizacion"
            />
            <FormHelperText className={classes.textHelperRoot} >{fechaCulminacionHT}</FormHelperText>
          </div>
          <div>
            <InputLabel>Cargo<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, cargo: true, cargoHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length >= 400) {
                    setError({ ...error, cargo: true, cargoHelper: ' Máximo 400 caracteres' });
                  }
                  else setError({ ...error, cargo: false, cargoHelper: '' });
              }}
              error={error.cargo}
              helperText={error.cargoHelper}
              defaultValue={experiencia.cargo}
              onChange={handleChange} name='cargo'
              variant="outlined"
              className={classes.textFieldRoot}
              id="cargo"
              inputProps={{ maxLength: 400 }}
            />
          </div>
          <div>
            <InputLabel>Descripción del Cargo<span className={classes.color}>*</span></InputLabel>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, descripcion: true, descripcionHelper: ' Este campo es requerido' });
                } else
                  if (event.target.value.length > 2000) {
                    setError({ ...error, descripcion: true, descripcionHelper: ' Máximo 2000 caracteres' });
                  }
                  else setError({ ...error, descripcion: false, descripcionHelper: '' });
              }}
              error={error.descripcion}
              helperText={error.descripcionHelper}
              multiline
              defaultValue={experiencia.descripcion}
              onChange={handleChange} name='descripcion'
              variant="outlined"
              className={classes.textFieldRoot}
              id="descripcion"
              inputProps={{ maxLength: 2001 }}
            />
          </div>
          <div>
            <InputLabel>Adjunte la Constancia de la Empresa<span className={classes.color}>*</span></InputLabel>
            <TextField
              required={props.location.state.crear}
              type="file"
              defaultValue={fileExperiencia}
              onChange={event => setFileExperiencia(event.target.files[0])}
              onBlur={event => {
                if (props.location.state.crear && event.target.files[0] === undefined) {
                  setError({ ...error, documento: true, documentoHelper: ' Este campo es requerido' });
                }
                else setError({ ...error, documento: false, documentoHelper: fileHelperText });
              }}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldRoot}
              id="constancia"
              InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
              helperText={error.documentoHelper}
              error={error.documento}
            />
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => { setRedirect(true) }}
              className={classes.button}>
              Volver
            </Button>
            {props.location.state.actualizar ?
              <Button
                type="submit"
                variant="contained" color="primary"
                className={classes.button}>
                Actualizar Experiencia
              </Button>
              :
              <Button
                type="submit"
                variant="contained" color="primary"
                className={classes.button}>
                Registrar experiencia
              </Button>
            }

          </div>
        </form>
      </CardContent>
    </Card>
  )
};

export default IngresarExperiencia;