import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setJWT, setForgetPassMailSent, updateLoadUser, setUserRoles, setUserCandidato } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            sessionStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ props, email, password, oferta }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('/authenticate', {
            username: email,
            password: password,
          })
          .then((response) => {
            if (response.status === 200) {
              var CryptoJS = require("crypto-js");
              var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.id_token), 'S661S-CR1PT0_S3CUR1T1_V53R').toString();
              sessionStorage.setItem('token', ciphertext);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.id_token;
              axios
                .get('/account')
                .then((response) => {
                  if (response.data) {
                    sessionStorage.setItem('authorities', JSON.stringify(response.data.authorities));
                    sessionStorage.setItem('id', JSON.stringify(response.data.id))
                    dispatch(JWTAuth.getUserCandidato(true, response.data.id_token));
                    dispatch(fetchSuccess());
                    dispatch(setJWT(response.data.id_token));
                    dispatch(JWTAuth.getAuthUser(true, response.data.id_token));
                    dispatch(setUserRoles(response.data.authorities));
                    let path = props.location.state
                    if (path !== null && path !== undefined && path.goBack !== undefined) {
                      window.location.pathname = path.goBack;
                    } else {
                      if(oferta != null){
                        window.location.pathname = `/user_page/${encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(oferta), 'S661S-CR1PT0_S3CUR1T1_V53R').toString())}`
                      }else{
                        window.location.pathname = "/user_page"
                      }
                    }
                  } else {
                    dispatch(updateLoadUser(true));
                  }
                })
            } else {
              dispatch(fetchError(response.error));
            }
          })
          .catch(error => {
            console.log(error.response)
            if (error.response.data.status === 401) {
              if (error.response.data.detail.includes("not activated"))
                dispatch(fetchError("El usuario no está activado"));
              else if (error.response.data.detail.includes("Credenciales erróneas"))
                dispatch(fetchError("Credenciales erróneas"));
              else
                dispatch(fetchError("Ocurrió un error al iniciar sesión, por favor inténtelo más tarde"));
            }
            else
              dispatch(fetchError('Ocurrió un error al iniciar sesión, por favor inténtelo más tarde'));
          });
      } catch (error) {
        dispatch(fetchError('Ocurrió un error al iniciar sesión, por favor inténtelo más tarde'));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
          dispatch(fetchSuccess());
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('validacion');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('authorities');
          dispatch(setAuthUser(null));
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const tokenSession = sessionStorage.getItem('token');
        var tokenSecure;
        if (tokenSession !== null) {
          var CryptoJS = require("crypto-js");
          var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_V53R');
          tokenSecure = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
          tokenSecure = '';
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenSecure;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('/authenticate')
        .then((response) => {
          if (response.data) {
            dispatch(fetchSuccess());
            dispatch(setJWT(tokenSecure));
            dispatch(setAuthUser(response.data));
            dispatch(JWTAuth.getRolesUser(true, response.data.id_token));
            dispatch(JWTAuth.getUserCandidato(true, response.data.id_token));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: ({ email }) => {
    return (dispatch) => {
      dispatch(fetchStart());
      axios
        .post("/account/reset-password/init", email, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        });
    };
  },

  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
  getRolesUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const tokenSession = sessionStorage.getItem('token');
        var tokenSecure;
        if (tokenSession !== null) {
          var CryptoJS = require("crypto-js");
          var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_V53R');
          tokenSecure = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
          tokenSecure = '';
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenSecure;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('/account')
        .then((response) => {
          if (response.data) {
            sessionStorage.setItem('authorities', JSON.stringify(response.data.authorities));
            dispatch(fetchSuccess());
            dispatch(setJWT(tokenSecure));
            dispatch(setUserRoles(response.data.authorities));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
        });
    };
  },
  getUserCandidato: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const tokenSession = sessionStorage.getItem('token');
        var tokenSecure;
        if (tokenSession !== null) {
          var CryptoJS = require("crypto-js");
          var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_V53R');
          tokenSecure = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
          tokenSecure = '';
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenSecure;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('/preempleado-estado-tipo?idUser=' + JSON.parse(sessionStorage.getItem('id')))
        .then((response) => {
          if (response.data) {
            sessionStorage.setItem('validacion', JSON.stringify(response.data))
            dispatch(fetchSuccess());
            dispatch(setJWT(tokenSecure));
            dispatch(setUserRoles(response.data.authorities));
            dispatch(setUserCandidato(response.data))
          } else {
            dispatch(updateLoadUser(true));
            sessionStorage.setItem('validacion', JSON.stringify(response.data))
            dispatch(setUserCandidato(response.data))
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
        });
    };
  },
};




export default JWTAuth;
