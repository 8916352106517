import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const resetPasswordFinish = (data) =>{
    let config = {
        method: 'post',
        url: BASE_URL + 'account/reset-password/finish',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data: data
    }
    return axios(config)
}
