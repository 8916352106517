import { SEND_FORGET_PASSWORD_EMAIL, UPDATE_AUTH_USER, UPDATE_AUTH_JWT, UPDATE_LOAD_USER, UPDATE_AUTH_ROLES, UPDATE_USER_CANDIDATO } from '../../constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const setJWT = jwt => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_JWT,
      payload: jwt,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const setUserRoles = roles => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_ROLES,
      payload: roles
    })
  }
}

export const setUserCandidato = id => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_CANDIDATO,
      payload: id
    })
  }
}