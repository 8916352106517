import axios from 'axios'
import { getToken } from '../../services/auth/jwt/getToken'
import { BASE_URL } from '../baseUrl'
const token = getToken();
const contentType = 'application/json'


export const postContratoUpdate = (data, idContrato) => {
    let config = {
        method: 'post',
        url: BASE_URL + `contratoUpdate/${idContrato}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data: data
    }
    return axios(config)
}

export const getContratoById = (id) => {
    let config = {
        method: 'get',
        url: BASE_URL+'contratoes/'+id,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true
    }
    return axios(config)
}


