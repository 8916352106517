import axios from 'axios'
import { BASE_URL } from '../baseUrl'
import { getToken } from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json'

export const downloadDocumentsCandidato = async (numeroDocumento, fileName) => {
    const config = {
        method: 'get',
        params: {
            numeroDocumento,
            fileName
        },
        url: BASE_URL + `download_documentos_candidato`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,

    }

    return await axios(config)
}