import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, alpha } from '@material-ui/core';
import validate from 'validate.js';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  textFieldFullWidth: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textFieldRoot: {
    width: "100%",
    height: "15%",
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.23),
    },
  },
}));

let mensajeHelper = {}

export default function TypeText({ id, formState, setFormState, helperText, ...props }) {

  const classes = useStyles();
  mensajeHelper = helperText

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    validate.extend(validate.validators.datetime, {
      parse: function (value, options) {
        return +moment.utc(value);
      },
      format: function (value, options) {
        var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return moment.utc(value).format(format);
      }
    });

    const errors = validate(formState.values, mensajeHelper);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);


  useEffect(() => {
    if (formState.reset) {
      setFormState({ ...formState, reset: false });
    }
    // eslint-disable-next-line
  }, [formState.reset]);

  const handleChange = (event) => {
    event.persist();
    const { type, name, value, files } = event.target;
    const fieldValue = type === 'file' ? files[0] : value;

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [name]: fieldValue,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    });
  };

  return (
    <TextField
      disabled={props?.disabled ? true : false}
      type={props?.type ? props.type : "text"}
      defaultValue={props?.defaultValue ? props.defaultValue : ""}
      onBlur={handleChange} name={id}
      key={formState.reset}
      margin="normal"
      variant="outlined"
      fullWidth={props?.fullWidth ? true : false}
      multiline={props?.multiline ? true : false}
      InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : {}}
      className={props?.fullWidth ? classes.textFieldFullWidth : classes.textFieldRoot}
      InputProps={props?.inputProps ? props.inputProps : {}}
      onInput={props?.onInput ? props.onInput : undefined}
      error={hasError(id)}
      helperText={
        hasError(id)
          ? formState.errors[id][0]
          : null
      }
    />
  )
}
