import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ContentLoader from '../../components/ContentLoader';
import { Redirect} from 'react-router';
import AuthWrapper from '../../components/authComponents/AuthWrapper'
import CmtImage from '../../@coremat/CmtImage';
import { passwordStrength } from 'check-password-strength'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { activateCandidato } from '../../helpers/account/activateCandidato';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'; 
import { createTheme } from '@material-ui/core/styles';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const ValidarUsuario = (props,{method, variant = 'default', wrapperVariant = 'default' }) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [strength, setStrength] = useState('');
  const [message, setMessage] = useState(' ');
  const [message2, setMessage2] = useState(' ');
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles({ variant });
  const MySwal = withReactContent(Swal);

  const formLabelsTheme = createTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  })

  const onSubmit = async() => {
    let keyact = ''
    if(qs.parse(window.location.search)['?keyact'] !== undefined){
      keyact = qs.parse(window.location.search)['?keyact']
    }
    let keyres = ''
    if(qs.parse(window.location.search)['keyres'] !== undefined){
      keyres = qs.parse(window.location.search)['keyres']
    }
    let data = JSON.stringify({resetKey: keyres,activateKey: keyact, password: password})
    await activateCandidato(data)
    .then(response => {
      if(response.status === 200){
        MySwal.fire({
          icon: 'success',
          title: 'Tu cuenta ha sido activada exitosamente',
        })
        setRedirect(true)
      }
    })
    .catch(err => {
      MySwal.fire({
        icon: 'error',
        title: 'Ocurrió un error',
        text:err.response.detail
      })
    })
  };
  return (
    //La canditad y tipos de campos puede variar, esto es solo una aproximacion
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/grupois.png'} />
        </Box>
      ) : null}
      {redirect? <Redirect to={'/signin'} /> : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          {/* <CmtImage src={'/images/logo.png'} /> */}
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Crea tu contraseña
        </Typography>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form>
            {strength !== '' ?
              <Typography variant="h5" gutterBottom>
                Tu contraseña es
                {strength === 'Too weak' ? (' muy débil') :
                  strength === 'Weak' ? (' débil') :
                    strength === 'Medium' ? (' medio') :
                      strength === 'Strong' ? (' fuerte') :
                        null}
              </Typography>
              : null
            }
            <Box mb={2}>
              <TextField
                type="password"
                label="Contraseña"
                fullWidth
                onChange={event => {
                  setPassword(event.target.value);
                  setStrength(passwordStrength(event.target.value).value);
                }}
                onBlur= {e => {
                  if(e.target.value.length < 3 ) setMessage('La contraseña debe tener más de 3 caracteres'); else
                  if(e.target.value.length > 100 ) setMessage('La contraseña debe tener menos de 100 caracteres');
                  else setMessage('')
                }}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                required
                
              />
              {message !== ''?
              <Typography variant="subtitle2" color="error" gutterBottom>
                  {message}
              </Typography>
              : null
              }
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label="Confirmar Contraseña"
                fullWidth
                onChange={event => { setPassword2(event.target.value) }}
                onBlur ={ e => { 
                  if(e.target.value !== password) setMessage2('Las Contraseñas no coinciden');
                  else setMessage2('') }}
                defaultValue={password2}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                required
              />
              {message2 !== ''?
              <Typography variant="subtitle2" color="error" gutterBottom>
                  {message2}
              </Typography>
              : null
              }
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
              <Button disabled={message !== '' || message2 !==''} onClick={onSubmit} variant="contained" color="primary">
                    Crear contraseña
              </Button>
            </Box>
          </form>
        </MuiThemeProvider>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ValidarUsuario;
